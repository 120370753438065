import React, { Dispatch } from 'react';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { toast } from 'sonner';

import { useAuth } from '@/auth/context/AuthProvider';
import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as API from '@/shared/api/users';
import { Invite } from '@/shared/types';
import { SearchFilters } from '@/shared/types/contacts';
import {
  InviteParams,
  User,
  UserParams,
  UsersAction,
  UsersActionTypes,
} from '@/shared/types/users';
import i18next from '@/shared/utils/translation';

import UserReducer from './UserReducer';

export type UsersState = {
  filteredUsers: Array<User>;
  users: Array<User>;
  invites: Array<Invite>;
  showUpdatePanel: boolean;
  loading: boolean;
  current: User | null;
  filterParams: SearchFilters;
  invitesFilterParams: SearchFilters;
  totalCount: number;
};

export const PAGE_SIZE = 10;

export const initialUsersState: UsersState = {
  filteredUsers: [],
  users: [],
  invites: [],
  showUpdatePanel: false,
  loading: true,
  current: null,
  totalCount: 0,
  filterParams: {
    offset: 0,
    limit: PAGE_SIZE,
    sort: [],
    filter: [],
    searchFilter: [],
    defaultFilters: [
      {
        column: 'state',
        comparison: '!=',
        resource: 'user_organization',
        value: 'archived',
      },
    ],
  },
  invitesFilterParams: {
    offset: 0,
    limit: PAGE_SIZE,
    sort: [],
    filter: [],
    searchFilter: [],
  },
};

export const UserContext = createContext<{
  userState: UsersState;
  getUsers: (params: { location_id?: string }) => Promise<void>;
  getUser: (id: number) => Promise<void>;
  addUsers: (params: InviteParams) => Promise<void>;
  updateUser: (params: Partial<UserParams>) => Promise<void>;
  archiveUser: (params: Partial<UserParams>) => Promise<void>;
  getInvites: (params: SearchFilters) => Promise<void>;
  deleteInvite: (id: string, email: string) => void;
  setCurrent: (user: User | null) => void;
  clearCurrent: () => void;
  disableUser: (params: User) => Promise<void>;
  enableUser: (params: User) => Promise<void>;
  updateFilterParams: (params: SearchFilters) => void;
  updateInvitesFilterParams: (params: SearchFilters) => void;
  searchUsers: (params: SearchFilters) => Promise<void>;
}>({
  userState: initialUsersState,
  getUsers: () => Promise.resolve(),
  getUser: () => Promise.resolve(),
  addUsers: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  archiveUser: () => Promise.resolve(),
  getInvites: () => Promise.resolve(),
  deleteInvite: () => Promise.resolve(),
  setCurrent: () => Promise.resolve(),
  clearCurrent: () => Promise.resolve(),
  disableUser: () => Promise.resolve(),
  enableUser: () => Promise.resolve(),
  updateFilterParams: () => Promise.resolve(),
  updateInvitesFilterParams: () => Promise.resolve(),
  searchUsers: () => Promise.resolve(),
});

export const useUsers = () => useContext(UserContext);

const UserState = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const [userState, dispatch]: [UsersState, Dispatch<UsersAction>] = useReducer(
    UserReducer,
    initialUsersState
  );

  useEffect(() => {
    if (auth.isAuthenticated) {
      getUsers({});
    }
  }, [auth.organizationId, auth.isAuthenticated]);

  useEffect(() => {
    searchUsers(userState.filterParams);
  }, [userState.filterParams]);

  useEffect(() => {
    getInvites(userState.invitesFilterParams);
  }, [userState.invitesFilterParams]);

  const getUsers = async (params: { location_id?: string }) => {
    const queryParams = new URLSearchParams(params).toString();

    try {
      const data = await API.getUsers(queryParams);
      dispatch({
        type: UsersActionTypes.GET_USERS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const searchUsers = async (params: SearchFilters) => {
    try {
      const filters = prepareFilters(params);
      const data = await API.searchUsers(filters);

      dispatch({
        type: UsersActionTypes.SEARCH_USERS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: UsersActionTypes.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateInvitesFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: UsersActionTypes.UPDATE_INVITES_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const addUsers = async (params: InviteParams) => {
    try {
      const data = await API.createInvite(params);

      if ((data as User).id !== undefined) {
        // we take this path if there was already a disabled/archived user in the database
        // we do not send any invitation, we just enable the user and return a user
        // as response so that the store is updated without any additional calls
        dispatch({
          type: UsersActionTypes.ENABLE_USER,
          payload: data as User,
        });

        toast.success(i18next.t('user_enabled') as string);
      } else {
        // this path is taken when a invite is sent to a completely new user
        dispatch({
          type: UsersActionTypes.ADD_INVITES,
          payload: data as Array<Invite>,
        });
        getInvites(userState.invitesFilterParams);
        toast.success(i18next.t('invite_sent') as string);
      }
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('create_invite_error') as string);
    }
  };

  const updateUser = async (params: Partial<UserParams>) => {
    try {
      const data = await API.updateUser(params);
      dispatch({
        type: UsersActionTypes.UPDATE_USER,
        payload: data,
      });
      searchUsers(userState.filterParams);
      toast.success(i18next.t('user_update') as string);
    } catch (err) {
      console.error(err);

      if (err?.response?.data?.error?.message == 'invalid_phone_number') {
        toast.error(i18next.t('invalid_phone_number') as string);
      } else {
        toast.error(i18next.t('user_update_error') as string);
      }
    }
  };

  const archiveUser = async (params: Partial<UserParams>) => {
    try {
      await API.updateUser(params);

      dispatch({
        type: UsersActionTypes.ARCHIVE_USER,
        payload: params,
      });

      toast.success(i18next.t('user_update') as string);
      searchUsers(userState.filterParams);
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('user_update_error') as string);
    }
  };

  const getInvites = async (params: SearchFilters) => {
    try {
      const filters = prepareFilters(params);
      const data = await API.getInvites(filters);

      dispatch({
        type: UsersActionTypes.GET_INVITES,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getUser = async (id: number) => {
    try {
      const data = await API.getUser(id);

      dispatch({
        type: UsersActionTypes.SET_CURRENT,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const disableUser = async (user: User) => {
    try {
      await API.deleteUser(user.id);

      dispatch({
        type: UsersActionTypes.DISABLE_USER,
        payload: user,
      });
      searchUsers(userState.filterParams);
      toast.success(i18next.t('user_disable') as string);
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('user_disable_error') as string);
    }
  };

  const enableUser = async (user: User) => {
    try {
      await API.createUser(user.id);

      dispatch({
        type: UsersActionTypes.ENABLE_USER,
        payload: user,
      });
      toast.success(i18next.t('user_enable') as string);
      searchUsers(userState.filterParams);
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('user_enable_error') as string);
    }
  };

  const deleteInvite = async (id: string, email: string) => {
    try {
      await API.deleteInvite(id);

      dispatch({
        type: UsersActionTypes.DELETE_INVITE,
        payload: email,
      });
      getInvites(userState.invitesFilterParams);
      toast.success(i18next.t('invite_delete_success') as string);
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('invite_delete_failure') as string);
    }
  };

  const setCurrent = (user: User | null) => {
    dispatch({
      type: UsersActionTypes.SET_CURRENT,
      payload: user,
    });
  };

  const clearCurrent = () => {
    dispatch({
      type: UsersActionTypes.SET_CURRENT,
      payload: null,
    });
  };

  return (
    <UserContext.Provider
      value={{
        userState,
        addUsers,
        getUsers,
        getInvites,
        updateUser,
        archiveUser,
        getUser,
        setCurrent,
        clearCurrent,
        disableUser,
        enableUser,
        deleteInvite,
        updateFilterParams,
        updateInvitesFilterParams,
        searchUsers,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserState;
