import dayjs from 'dayjs';
import { memo, useCallback } from 'react';
import { useMedia } from 'react-use';

import { Domain } from '@/shared/types/domains';
import { Skeleton } from '@/shared/ui';
import { MONTH_DAY_YEAR } from '@/shared/utils/timestamps';

import { setupResultsColumns } from '../constants';
import { Column, ColumnLabel, RecordTable, Value } from './DNSRecords';

type SetupResultsProps = {
  domain: Domain | null;
  loading: boolean;
};

export const SetupResults = memo(({ domain, loading }: SetupResultsProps) => {
  const isMobile = useMedia('(max-width: 700px)');
  const isLarge = useMedia('(min-width: 1400px)');

  const renderColumnValue = useCallback(
    (id: string) => {
      if (id === 'updated_at' || id === 'expires_at') {
        return domain?.[id] ? dayjs(domain?.[id]).format(MONTH_DAY_YEAR) : '-';
      }
      if (id === 'renew' && domain?.[id]) {
        return 'Yes';
      }
      return (domain?.[id as keyof Domain] as string) || '-';
    },
    [domain]
  );

  return (
    <RecordTable isMobile={isMobile} css={{ mt: 32 }}>
      {setupResultsColumns?.map((column) => (
        <Column
          gap={1}
          isMobile={isMobile}
          key={column.id}
          css={{ minWidth: isLarge ? 169 : 0, width: 'calc(100% - 60)' }}
        >
          <ColumnLabel>{column.label}</ColumnLabel>
          <Value css={{ textTransform: 'capitalize' }}>
            {loading ? (
              <Skeleton
                variant="title"
                css={{
                  width: column.id === 'setup_type' || column.id === 'renew' ? 44 : 97,
                  m: 0,
                }}
              />
            ) : (
              renderColumnValue(column.id)
            )}
          </Value>
        </Column>
      ))}
    </RecordTable>
  );
});
