import React from 'react';
import { HiX } from 'react-icons/hi';

import { Flex, HStack, IconButton, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useAuth } from '../auth/context/AuthProvider';

export const OnboardingContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <OnboardingWrapper align="center" justify="center">
      <VStack gap={3} css={{ maxWidth: 600, minWidth: 400 }}>
        {children}
      </VStack>
    </OnboardingWrapper>
  );
};

export const SkipOnboarding = () => {
  const auth = useAuth();

  async function completeOnboarding() {
    await auth.updateOnboardingStatus();
  }

  return (
    <Flex css={{ with: '100%', p: 10 }} justify="end">
      <HStack>
        <Text>Skip Onboarding</Text>
        <IconButton onClick={completeOnboarding}>
          <HiX />
        </IconButton>
      </HStack>
    </Flex>
  );
};

export const OnboardingWrapper = styled(Flex, {
  backgroundColor: '#ffffff',
  color: '#333333',
  height: '100vh',
  padding: 30,
});

export const OnboardingHeading = styled('h1', {
  fontWeight: '700',
  fontSize: '28px !important',
  minWidth: '100%',
});

export const OnboardingDescription = styled('p', {
  lineHeight: 1.5,
  fontSize: '16px',
  minWidth: '100%',
});
