import * as SelectPrimitive from '@radix-ui/react-select';
import React, { useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useParams } from 'react-router-dom';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { Agent } from '@/shared/types/ai/agents';
import { LLM, Model, Provider } from '@/shared/types/ai/llms';
import {
  Button,
  Fieldset,
  Flex,
  Input,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  TextArea,
  VStack,
} from '@/shared/ui';

import { useLLMAgent } from '../context/AgentsContext';
import { TestAgent } from './TestAgent';

export const EditAgent = () => {
  const agents = useLLMAgent();
  const { getAgent, getLLM, updateLLM } = agents;

  const params = useParams<{ id: string }>();
  const { id } = params;

  const [currentAgent, setCurrentAgent] = useState<Agent>();
  const [currentLLM, setCurrentLLM] = useState<LLM>();

  const [prompt, setPrompt] = useState('');
  const [beginMessage, setBeginMessage] = useState('');

  useEffect(() => {
    fetchAndSetAgent(id);
  }, [id]);

  useEffect(() => {
    if (currentAgent?.current_version?.llm_id) {
      fetchAndSetLLM(currentAgent?.current_version?.llm_id);
    }
  }, [currentAgent?.current_version?.llm_id]);

  useEffect(() => {
    if (currentLLM?.current_version?.provider_settings?.begin_message) {
      setBeginMessage(currentLLM.current_version.provider_settings.begin_message);
    }
    if (currentLLM?.current_version?.prompt) {
      setPrompt(currentLLM.current_version.prompt);
    }
  }, [currentLLM]);

  const fetchAndSetAgent = async (id: string) => {
    const agent: Agent = await getAgent(id);
    setCurrentAgent(agent);
  };

  const fetchAndSetLLM = async (id: string) => {
    const llm: LLM = await getLLM(id);
    setCurrentLLM(llm);
  };

  const locationsContext = useLocations();
  const { locationsState, updateLocation } = locationsContext;
  const { locations } = locationsState;

  // when the user selects a location we need to update the location withe the agent_id
  const value = locations.find((location) => location?.agent_id === currentAgent?.id);

  useEffect(() => {
    if (value) {
      setLocation(value);
    }
  }, [value]);

  const [location, setLocation] = useState(value);

  // Handle location selection change
  const handleLocationChange = async (selectedLocationId: string) => {
    const selectedLocation = locations.find(
      (location) => location.id === selectedLocationId
    );
    if (selectedLocation && currentAgent) {
      await updateLocation({ ...selectedLocation, agent_id: currentAgent.id });
      setLocation(selectedLocation); // Update local state
    }
  };

  const saveLLM = async () => {
    if (!currentAgent) return;

    await updateLLM(currentAgent.current_version.llm_id, {
      llm: {
        description: 'LLM Description',
        model: Model.Gpt35Turbo,
        provider: Provider.Retell,
        prompt: prompt,
        provider_settings: { begin_message: beginMessage },
      },
    });
  };

  return (
    <Flex direction="row" css={{ height: '100%', overflow: 'hidden' }}>
      <Flex css={{ width: '50%', overflowY: 'auto' }}>
        <VStack gap="1" css={{ p: 30, width: '100%' }}>
          <Fieldset>
            <Label>Begin Message</Label>
            <Input
              placeholder="Agent will open by saying..."
              value={beginMessage}
              onChange={(e) => setBeginMessage(e.target.value)}
            />
          </Fieldset>
          <Fieldset>
            <Label>Call Instructions</Label>
            <TextArea
              css={{ lineHeight: '1.5' }}
              placeholder="Add instructions for the agent to call you..."
              value={prompt}
              onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                setPrompt(e.target.value)
              }
            />
          </Fieldset>
          <Fieldset>
            <Label>Location</Label>
            <Select value={location?.id} onValueChange={(e) => handleLocationChange(e)}>
              <SelectTrigger aria-label="option-select-trigger">
                <SelectValue />
                <SelectIcon>
                  <HiChevronDown />
                </SelectIcon>
              </SelectTrigger>
              <SelectPrimitive.Portal>
                <SelectContent>
                  <SelectScrollUpButton>
                    <HiChevronUp />
                  </SelectScrollUpButton>
                  <SelectViewport>
                    <SelectGroup>
                      <SelectLabel>Locations</SelectLabel>
                      {locations.map((option) => (
                        <SelectItem key={option.id} value={option.id}>
                          <SelectItemIndicator />
                          <SelectItemText>{`${option.name} - ${option?.phone}`}</SelectItemText>
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectViewport>
                </SelectContent>
              </SelectPrimitive.Portal>
            </Select>
          </Fieldset>
          <Button onClick={saveLLM}>Save Agent</Button>
        </VStack>
      </Flex>
      <TestAgent prompt={prompt} />
    </Flex>
  );
};
