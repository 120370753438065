/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef } from 'react';
import { HiChat, HiCursorClick, HiKey, HiX } from 'react-icons/hi';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { createCampaignTemplateAssociation } from '@/shared/api/campaigns/campaign_automation_template';
import { CreateCard } from '@/shared/components/CreateCard';
import { useDisclosure, useProgress } from '@/shared/hooks';
import { AutomationTemplate } from '@/shared/types/automations/index';
import { TemplateUsage } from '@/shared/types/automations/index';
import { Campaign } from '@/shared/types/campaigns';
import { SequenceType } from '@/shared/types/sequences';
import { Trigger } from '@/shared/types/triggers';
import { Action } from '@/shared/types/triggers/actions';
import {
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Flex,
} from '@/shared/ui';

import { AddKeywordTrigger } from './AddKeywordTrigger';
import { AddLinkClickTrigger } from './AddLinkClickTrigger';
import { AddNoReplyTrigger } from './AddNoReplyTrigger';
import { AddReplyTrigger } from './AddReplyTrigger';
import { SelectAutomationTemplates } from './SelectAutomationTemplates';

export type KeywordTriggerType = {
  /* The id of the trigger */
  id?: string;
  /* The type of trigger */
  type: 'reply_containing_keyword' | 'reply_with_keyword';
  /* The keyword to trigger on */
  keyword: {
    /* The keywords to trigger on */
    keywords?: Array<string>;
    /* The keywords to negate */
    negate_keywords?: Array<string | null> | null;
    /* Whether to match the exact keyword */
    exact_match: boolean | string | null;
    /* Whether to ignore case */
    enabled: boolean;
  };
  /* The actions to perform when the trigger is triggered */
  actions: Array<Action>;
};

export type ReplyTriggerType = {
  /* The id of the trigger */
  id?: string;
  /* The type of trigger */
  type: 'reply';
  /* The actions to perform when the trigger is triggered */
  actions: Array<Action>;
};

export type LinkClickTriggerType = {
  /* The id of the trigger */
  id?: string;
  /* The type of trigger */
  type: 'link_click';
  /* The actions to perform when the trigger is triggered */
  actions: Array<Action>;
};

export type NoReplyTriggerType = {
  /* The id of the trigger */
  id?: string;
  /* The type of trigger */
  type: 'no_reply';
  /* The schedule to check for replies */
  schedule_options: {
    days: string;
    hours: string;
    minutes: string;
  };
  /* The actions to perform when the trigger is triggered */
  actions: Array<Action>;
};

export type TriggerType =
  | KeywordTriggerType
  | ReplyTriggerType
  | LinkClickTriggerType
  | NoReplyTriggerType;

type StepProps = {
  /* The step to display */
  setStep: (step: number) => void;
  /* The action to add when the save button is clicked */
  addAction?: (action: Trigger) => void;
  /* Callback for when the dialog is closed */
  onClose?: () => void;
  /* are we under Campaigns page? */
  isUnderCampaignsPage?: boolean;
  /* is this a sequence trigger? */
  isSequence?: boolean;
  /* The type of sequence */
  sequenceType?: SequenceType;
};

const SelectNewAutomationOrTemplate = (props: StepProps) => {
  const { setStep, isUnderCampaignsPage } = props;

  const isDesktop = useMedia('(min-width: 600px)');

  // if we are not under Campaigns page, directly go to step 1
  // if we don't have the feature access, also directly go to step 1
  if (!isUnderCampaignsPage) {
    setStep(1);
  }

  return (
    <>
      <DialogHeader title="Add Trigger" />
      <Flex
        justify={isUnderCampaignsPage && isDesktop ? 'between' : 'center'}
        css={{ height: '100%', flexWrap: isDesktop ? 'nowrap' : 'wrap' }}
      >
        <CreateCard
          title="New Automation"
          description="Create a automation from scratch..."
          icon={<HiKey />}
          onClick={() => setStep(1)}
          cardStyles={{ width: isDesktop ? 250 : 200, mb: isDesktop ? 0 : 10 }}
        />

        <CreateCard
          title="Automation Templates"
          description="Select from your automation templates..."
          icon={<HiChat />}
          onClick={() => setStep(5)}
          cardStyles={{ width: isDesktop ? 250 : 200, mb: isDesktop ? 0 : 10 }}
        />
      </Flex>
    </>
  );
};

const SelectTriggerType = (props: StepProps) => {
  const { setStep, isSequence, sequenceType } = props;

  const isLargeScreen = useMedia('(min-width: 1130px)');
  const isSmallScreen = useMedia('(max-width: 930px)');

  return (
    <>
      <DialogHeader title="Add Trigger" />
      <Flex
        justify={isSmallScreen ? 'center' : 'between'}
        align="center"
        css={{ height: '100%', flexWrap: isSmallScreen ? 'wrap' : 'nowrap' }}
      >
        <CreateCard
          title="Keyword Trigger"
          description="When a contact responds with a keyword..."
          icon={<HiKey />}
          onClick={() => setStep(2)}
          cardStyles={{ width: isLargeScreen ? 250 : 200, mb: isSmallScreen ? 10 : 0 }}
        />
        <CreateCard
          title="Response Trigger"
          description="When a contact responds to a message..."
          icon={<HiChat />}
          onClick={() => setStep(3)}
          cardStyles={{ width: isLargeScreen ? 250 : 200, mb: isSmallScreen ? 10 : 0 }}
        />
        {isSequence && sequenceType === SequenceType.BRANCHED && (
          <CreateCard
            title="No Response Trigger"
            description="When a contact does not respond..."
            icon={<HiCursorClick />}
            onClick={() => setStep(6)}
            cardStyles={{ width: isLargeScreen ? 250 : 200, mb: isSmallScreen ? 10 : 0 }}
          />
        )}
        <CreateCard
          title="Link Click Trigger"
          description="When a contact clicks on a message link..."
          icon={<HiCursorClick />}
          onClick={() => setStep(4)}
          cardStyles={{ width: isLargeScreen ? 250 : 200, mb: isSmallScreen ? 10 : 0 }}
        />
      </Flex>
    </>
  );
};

type AddTriggerProps = {
  /* The action to add when the save button is clicked */
  addTrigger: (action: Trigger) => void;
  /* disable the save button */
  disabled?: boolean;
  /* variant of the button */
  variant?: 'primary' | 'gray';
  /* The campaign id to add the trigger to */
  campaignId?: string;
  /* The existing automation templates */
  existingAllAutomationTemplates?: Array<AutomationTemplate>;
  /* Callback for when the dialog is closed */
  setExistingAllAutomationTemplates?: (params: Array<AutomationTemplate>) => void;
  /* The current campaign */
  currentCampaign?: Campaign | null;
  /* set the current campaign */
  setCurrentCampaign?: (campaign: Campaign | null) => void;
  /* are we under Campaigns page? */
  isUnderCampaignsPage?: boolean;
  /* is this a sequence trigger? */
  isSequence?: boolean;
  /* add a single trigger of duplicate templates with no set state */
  addSingleTriggerOfDuplicateTemplates?: (trigger: Trigger) => void;
  /* Update the current campaign */
  updateCurrentCampaign?: () => Promise<void>;
  /* The type of sequence */
  sequenceType?: SequenceType;
};

export const AddTrigger = forwardRef<HTMLButtonElement, AddTriggerProps>((props, ref) => {
  const {
    addTrigger,
    campaignId,
    existingAllAutomationTemplates,
    currentCampaign,
    setCurrentCampaign,
    isUnderCampaignsPage,
    addSingleTriggerOfDuplicateTemplates,
    updateCurrentCampaign,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentStep, setCurrentStep] = useProgress();

  const isLargeDesktop = useMedia('(min-width: 860px)');
  const isDesktop = useMedia('(min-width: 710px)');
  const isSmallScreen = useMedia('(max-width: 500px)');

  const handleAddTrigger = (params: Trigger) => {
    addTrigger(params);
    setCurrentStep(0);
    onClose();
  };

  const handleClose = () => {
    setCurrentStep(0);
    onClose();
  };

  const addTriggersInsideDuplicateTemplatesToCampaign = (
    duplicateAutomationTemplates: AutomationTemplate[]
  ) => {
    duplicateAutomationTemplates.forEach((template) => {
      if (template.triggers) {
        template.triggers.forEach((trigger) => {
          let newTrigger: Trigger;
          if (trigger.type === 'reply_containing_keyword') {
            newTrigger = {
              actions: trigger.actions,
              keyword: trigger.keyword,
              type: trigger.type,
            };
          } else {
            newTrigger = {
              actions: trigger.actions,
              type: trigger.type,
            };
          }
          addSingleTriggerOfDuplicateTemplates &&
            addSingleTriggerOfDuplicateTemplates(newTrigger as Trigger); // updateCurrentCampaign() is inside addSingleTriggerOfDuplicateTemplates()
        });
      }
    });
  };

  // add the template ids of as-is templates to the campaign
  const addAutomationTemplateIdsOfAsIsTemplatesToCampaign = async (
    asIsAutomationTemplates: AutomationTemplate[]
  ) => {
    const associationPromises = asIsAutomationTemplates.map(async (template) => {
      const templateId = template.id;
      if (templateId && campaignId) {
        const associationId = await createCampaignTemplateAssociation(
          campaignId,
          templateId
        );
        return {
          automation_template_id: templateId,
          campaign_automation_template_id: associationId,
        };
      }
      return null;
    });

    try {
      const resolvedAssociations = (await Promise.all(associationPromises)).filter(
        Boolean
      ) as Array<{
        automation_template_id: string;
        campaign_automation_template_id: string;
      }>;
      // Now, set the new currentCampaign with updated automation_template_ids
      if (currentCampaign && setCurrentCampaign) {
        const updatedCampaign = {
          ...currentCampaign,
          associated_automation_templates: [
            ...(currentCampaign.associated_automation_templates || []),
            ...resolvedAssociations,
          ],
        };
        setCurrentCampaign(updatedCampaign);
        // updateCurrentCampaign so that the UI is refreshed
        updateCurrentCampaign && updateCurrentCampaign();
      }
    } catch (error) {
      toast.error('Failed to add as-is templates to campaign');
    }
  };

  // handle adding both duplicate and as-is templates to the campaign
  const handleAddAutomationTemplatesToCampaign = (params: Array<AutomationTemplate>) => {
    // filter out the duplicate templates
    const duplicateAutomationTemplates = params.filter(
      (template) => template.usage === TemplateUsage.DUPLICATE
    );

    // filter out the as-is templates
    const asIsAutomationTemplates = params.filter(
      (template) => template.usage === TemplateUsage.AS_IS
    );

    // for each duplicate template, add all the triggers inside the templates to the array
    if (duplicateAutomationTemplates.length > 0) {
      addTriggersInsideDuplicateTemplatesToCampaign(duplicateAutomationTemplates);
    }

    // for each as-is template, attach their template ids to the campaign
    if (asIsAutomationTemplates.length > 0) {
      addAutomationTemplateIdsOfAsIsTemplatesToCampaign(asIsAutomationTemplates);
    }

    // after adding all the templates, close the dialog
    setCurrentStep(0);
    onClose();
  };

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
        // ask the user to choose from a new automation or an automation template
        return (
          <SelectNewAutomationOrTemplate
            setStep={setCurrentStep}
            isUnderCampaignsPage={isUnderCampaignsPage}
          />
        );
      case 1:
        return (
          <SelectTriggerType
            setStep={setCurrentStep}
            isSequence={props.isSequence}
            sequenceType={props.sequenceType}
          />
        );
      case 2:
        return <AddKeywordTrigger onSubmit={handleAddTrigger} />;
      case 3:
        return <AddReplyTrigger onSubmit={handleAddTrigger} />;
      case 4:
        return <AddLinkClickTrigger onSubmit={handleAddTrigger} />;
      case 5:
        return (
          <SelectAutomationTemplates
            onSubmit={handleAddAutomationTemplatesToCampaign}
            existingAllAutomationTemplates={existingAllAutomationTemplates ?? []}
            handleClose={handleClose}
          />
        );
      case 6:
        return <AddNoReplyTrigger onSubmit={handleAddTrigger} />;
      default:
        return <SelectNewAutomationOrTemplate setStep={setCurrentStep} />;
    }
  };

  const calculateDialogWidth = () => {
    if (isUnderCampaignsPage && currentStep === 0) {
      return '535px';
    } else if (currentStep === 1) {
      return '950px';
    } else {
      return '500px';
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <Button
          onClick={onOpen}
          disabled={props.disabled || false}
          ref={ref}
          variant={props.variant || 'primary'}
        >
          Add Trigger
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay>
          <DialogContent
            onEscapeKeyDown={handleClose}
            onPointerDownOutside={handleClose}
            css={{
              width: 'auto',
              minWidth: isSmallScreen ? '100%' : '500px',
              top: isSmallScreen ? 0 : '50%',
              transform: `translate(-50%, ${isSmallScreen ? '0' : '-50%'})`,
              mt: isSmallScreen ? 0 : '-5vh',
            }}
          >
            {renderStep(currentStep)}
            <DialogCloseIcon onClick={handleClose} size="2">
              <HiX size="15px" style={{ color: 'white' }} />
            </DialogCloseIcon>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
});
