import { useState } from 'react';

import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { User } from '@/shared/types/users';
import { Box } from '@/shared/ui';

import { ValueCombobox } from './Combobox';

type UsersValuesComboboxProps = {
  selected: { label?: string; value: string | number } | null | undefined;
  onSelect: (option: { label: string; value: string | number }) => void;
  selectLabel?: string;
  searchLabel?: string;
  selectorStyles?: { [key: string]: any };
  isOpen?: boolean;
};

export const UsersValuesCombobox = (props: UsersValuesComboboxProps): JSX.Element => {
  const { selected, onSelect, selectLabel, searchLabel } = props;

  const [searchValue, setSearchValue] = useState('');

  const usersContext = useUsers();
  const { userState } = usersContext;
  const { users } = userState;

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Box>
      <ValueCombobox
        isOpen={props.isOpen}
        selectorStyles={props.selectorStyles}
        options={users.map((user: User) => ({
          label: user.name || user.email || '',
          value: user.id || '',
        }))}
        selected={{ label: selected?.label || '', value: selected?.value || '' }}
        onSelect={onSelect}
        searchValue={searchValue}
        handleSearchValue={handleSearchValue}
        selectLabel={selectLabel}
        searchLabel={searchLabel}
      />
    </Box>
  );
};
