import React, { memo } from 'react';
import { HiCheckCircle, HiExclamationCircle, HiXCircle } from 'react-icons/hi';

import { Domain, DomainSetupState, DomainStatusType } from '@/shared/types/domains';
import { Flex, Text } from '@/shared/ui';

type VerificationStatusBadgeProps = {
  domain: Domain | null;
};

const getBadgeIcon = (status?: DomainStatusType) => {
  switch (status) {
    case DomainStatusType.PENDING:
      return <HiExclamationCircle color="#F8BE3E" size={20} />;
    case DomainStatusType.ACTIVE:
      return <HiCheckCircle color="green" size={20} />;
    case DomainStatusType.CANCELLED:
      return <HiXCircle color="red" size={20} />;
    default:
      return null;
  }
};

const getBadgeLabel = (domain: Domain | null) => {
  if (domain?.status === DomainStatusType.ACTIVE) return 'Ready to Use';
  if (
    domain?.status === DomainStatusType.PENDING &&
    domain?.setup_state === DomainSetupState.CHECKING_VERIFIED &&
    domain?.verification
  ) {
    return 'Pending Verification';
  }
  if (
    domain?.status === DomainStatusType.PENDING &&
    domain?.setup_state === DomainSetupState.CHECKING_MISCONFIGURED &&
    domain?.required_dns_records?.length
  ) {
    return 'Pending Configuration';
  }
  if (
    domain?.status === DomainStatusType.PENDING &&
    (domain?.setup_state === DomainSetupState.PURCHASING_DOMAIN ||
      domain?.setup_state === DomainSetupState.PURCHASED_DOMAIN ||
      domain?.setup_state === DomainSetupState.PURCHASING_DOMAIN_PENDING)
  ) {
    return 'Name servers are configuring…';
  }
  if (domain?.status === DomainStatusType.CANCELLED) return 'Setup Failed';
  return null;
};

export const VerificationStatusBadge = memo(
  ({ domain }: VerificationStatusBadgeProps) => {
    return getBadgeLabel(domain) ? (
      <Flex justify="start" align="center">
        {getBadgeIcon(domain?.status)}
        <Text css={{ ml: 3, fontSize: 14, fontWeight: '500' }}>
          {getBadgeLabel(domain)}
        </Text>
      </Flex>
    ) : null;
  }
);
