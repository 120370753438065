import { Formik } from 'formik';
import React from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import * as Yup from 'yup';

import { createBrand } from '@/shared/api/tendlc';
import {
  CheckboxFormFieldWrapper,
  CheckboxInput,
  FormFieldWrapper,
  helpers,
  PhoneNumberInput,
  SelectCombobox,
  TextInput,
} from '@/shared/components/forms';
import { Box, Button, Flex, HStack, VStack } from '@/shared/ui';
import { isValidPhoneNumber, toE164 } from '@/shared/utils/validations/validations';

import { OnboardingLayout } from '..';
import {
  altBusinessRegistrationTypes,
  businessIdentities,
  businessRegionsOfOperation,
  businessRegistrationTypes,
  businessTypes,
  representativeJobPositions,
  stateList,
  verticalList,
} from './lists';

function BrandVerificationForm(): JSX.Element {
  const history = useHistory();
  return (
    <OnboardingLayout>
      <Flex align="center" css={{ marginBottom: 30 }}>
        <Box
          css={{ cursor: 'pointer' }}
          onClick={() => history.push('/settings/onboarding')}
        >
          <HiArrowLeft />
        </Box>
        <Box css={{ fontWeight: 600, fontSize: 16, ml: 8 }}>Create Brand</Box>
      </Flex>
      <Formik
        initialValues={{
          company_name: '',
          display_name: '',
          email: '',
          phone: '',
          street: '',
          city: '',
          state: 'CA',
          postal_code: '',
          website: '',
          vertical: 'COMMUNICATION',
          entity_type: 'PRIVATE_PROFIT',
          business_registration_number: '',
          business_registration_type: 'EIN',
          alt_business_registration_number: '',
          alt_business_registration_type: 'NONE',
          business_identity: 'RESELLER',
          business_regions_of_operation: 'USA_AND_CANADA',
          stock_symbol: '',
          stock_exchange: '',
          government_entity: false,
          business_type: 'CORPORATION',
          ip_address: '',
          representative_job_position: 'CEO',
          representative_job_title: '',
          representative_name: '',
        }}
        validationSchema={Yup.object({
          company_name: Yup.string().required('Required'),
          display_name: Yup.string().required('Required'),
          email: Yup.string().required('Required').email('Please provide a valid email'),
          phone: Yup.string()
            .required('Required')
            .test({
              name: 'isValidPhoneNumber',
              message: 'Enter a valid number',
              test: (value) => {
                if (value) {
                  return isValidPhoneNumber(value);
                } else {
                  return true;
                }
              },
            }),
          street: Yup.string().required('Required'),
          city: Yup.string().required('Required'),
          state: Yup.string()
            .required('Required')
            .oneOf(
              stateList.map((state) => state.value),
              'Select from dropdown'
            ),
          postal_code: Yup.string()
            .required('Required')
            .matches(/^[0-9]+$/, 'Must be only digits')
            .min(5, 'Must be exactly 5 digits')
            .max(5, 'Must be exactly 5 digits'),
          website: Yup.string().required('Required'),
          vertical: Yup.string()
            .required('Required')
            .oneOf(
              verticalList.map((vertical) => vertical.value),
              'Select from dropdown'
            ),
          entity_type: Yup.string(),
          business_registration_number: Yup.string().required('Required'),
          business_registration_type: Yup.string().required('Required'),
          alt_business_registration_number: Yup.string(),
          alt_business_registration_type: Yup.string(),
          business_identity: Yup.string().required('Required'),
          business_regions_of_operation: Yup.string().required('Required'),
          stock_symbol: Yup.string(),
          stock_exchange: Yup.string(),
          government_entity: Yup.boolean(),
          business_type: Yup.string().required('Required'),
          ip_address: Yup.string(),
          representative_job_position: Yup.string().required('Required'),
          representative_job_title: Yup.string().required('Required'),
          representative_name: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          const params = {
            ...values,
            country: 'US',
            phone: toE164(values.phone),
            alt_business_registration_type:
              values.alt_business_registration_type !== 'NONE'
                ? values.alt_business_registration_type
                : '',
          };
          try {
            await createBrand(params);
            history.push('/settings/onboarding');
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <VStack gap={2}>
              <HStack gap={3}>
                <FormFieldWrapper
                  label="Company Name"
                  tooltip="Legal company name."
                  name="company_name"
                >
                  <TextInput placeholder="Example Company Inc." />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Display Name"
                  tooltip="Display or marketing name of the brand."
                  name="display_name"
                >
                  <TextInput placeholder="Example Company" />
                </FormFieldWrapper>
              </HStack>
              <HStack gap={3}>
                <FormFieldWrapper
                  label="Business Email"
                  tooltip="Valid email address of brand support contact."
                  name="email"
                >
                  <TextInput placeholder="examplename@examplecompany.com" />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Business Phone Number"
                  tooltip="Valid phone number of brand support contact."
                  name="phone"
                >
                  <PhoneNumberInput placeholder="+13259390512" />
                </FormFieldWrapper>
              </HStack>
              <InputWrapper>
                <FormFieldWrapper label="City" tooltip="City name." name="city">
                  <TextInput placeholder="Chicago" />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="State"
                  tooltip="State. Must be 2 letters code for United States."
                  name="state"
                >
                  <SelectCombobox
                    placeholder={helpers.displaySelectedItem}
                    isDropdown={true}
                    options={stateList}
                  />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Postal Code"
                  tooltip="Postal codes. Use 5 digit zip code for United States."
                  name="postal_code"
                >
                  <TextInput placeholder="60654" />
                </FormFieldWrapper>
              </InputWrapper>
              <InputWrapper>
                <FormFieldWrapper
                  label="Primary Address"
                  tooltip="Street number and name."
                  name="street"
                >
                  <TextInput placeholder="123 Example St." />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Business Type"
                  tooltip="The form of the business organization."
                  name="business_type"
                >
                  <SelectCombobox
                    placeholder={helpers.displaySelectedItem}
                    isDropdown={true}
                    options={businessTypes}
                  />
                </FormFieldWrapper>
              </InputWrapper>
              <FormFieldWrapper
                label="Website"
                tooltip="Brand website URL."
                name="website"
              >
                <TextInput placeholder="www.examplecompany.com" />
              </FormFieldWrapper>
              <HStack gap={3}>
                <FormFieldWrapper
                  label="Business Registration Number"
                  tooltip="Тhe value of the business registration number."
                  name="business_registration_number"
                >
                  <TextInput placeholder="142536893" />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Business Registration Type"
                  tooltip="Тhe type of the business registration number."
                  name="business_registration_type"
                >
                  <SelectCombobox
                    placeholder={helpers.displaySelectedItem}
                    isDropdown={true}
                    options={businessRegistrationTypes}
                  />
                </FormFieldWrapper>
              </HStack>
              <HStack gap={3}>
                <FormFieldWrapper
                  label="Alt Business Registration Number"
                  tooltip="Тhe value an alternative business registration number."
                  name="alt_business_registration_number"
                >
                  <TextInput placeholder="142536894" />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Alt Business Registration Type"
                  tooltip="Тhe type of the alternative business registration number."
                  name="alt_business_registration_type"
                >
                  <SelectCombobox
                    placeholder={helpers.displaySelectedItem}
                    isDropdown={true}
                    options={altBusinessRegistrationTypes}
                  />
                </FormFieldWrapper>
              </HStack>
              <HStack gap={3}>
                <FormFieldWrapper
                  label="Business Identity"
                  tooltip="The business identity - Reseller or Direct Customer."
                  name="business_identity"
                >
                  <SelectCombobox
                    placeholder={helpers.displaySelectedItem}
                    isDropdown={true}
                    options={businessIdentities}
                  />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Business Regions of Operation"
                  tooltip="The region where the business operates"
                  name="business_regions_of_operation"
                >
                  <SelectCombobox
                    placeholder={helpers.displaySelectedItem}
                    isDropdown={true}
                    options={businessRegionsOfOperation}
                  />
                </FormFieldWrapper>
              </HStack>
              <HStack gap={3}>
                <FormFieldWrapper
                  label="Stock Symbol"
                  tooltip="The stock symbol of the company, if the company is public."
                  name="stock_symbol"
                >
                  <TextInput placeholder="GOOG" />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Stock Exchange"
                  tooltip="The exchange where the stock is listed."
                  name="stock_exchange"
                >
                  <TextInput placeholder="NYSE" />
                </FormFieldWrapper>
              </HStack>
              <HStack gap={3}>
                <FormFieldWrapper
                  label="IP Address"
                  tooltip="IP address of the browser requesting to create brand."
                  name="ip_address"
                >
                  <TextInput placeholder="104.31.2.164" />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Representative Name"
                  tooltip="The first and last name of the brand representative."
                  name="representative_name"
                >
                  <TextInput placeholder="John Doe" />
                </FormFieldWrapper>
              </HStack>
              <HStack gap={3}>
                <FormFieldWrapper
                  label="Representative Job Position"
                  tooltip="The job position of the brand representative."
                  name="representative_job_position"
                >
                  <SelectCombobox
                    placeholder={helpers.displaySelectedItem}
                    isDropdown={true}
                    options={representativeJobPositions}
                  />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Representative Job Title"
                  tooltip="The exact job title of the brand representative in free form."
                  name="representative_job_title"
                >
                  <TextInput placeholder="Marketing Coordinator" />
                </FormFieldWrapper>
              </HStack>
              <CheckboxFormFieldWrapper
                label="Government Entity"
                tooltip="Is the entity described as a government one?"
                name="government_entity"
              >
                <CheckboxInput />
              </CheckboxFormFieldWrapper>
              <Box>
                <Button type="submit">Create Brand</Button>
              </Box>
            </VStack>
          </form>
        )}
      </Formik>
    </OnboardingLayout>
  );
}

// Use to dynamically change number of inputs in a row
export const InputWrapper = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');

  return isDesktop ? <Flex gap={3}>{children}</Flex> : <>{children}</>;
};

export default BrandVerificationForm;
