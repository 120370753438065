import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import React, { useMemo } from 'react';
import {
  HiClock,
  HiOutlinePaperClip,
  HiPlus,
  HiQuestionMarkCircle,
  HiSearch,
  HiSpeakerphone,
  HiTranslate,
  HiUserCircle,
} from 'react-icons/hi';

import { AddContactToSequence } from '@/pages/sequences/sequence/audience/AddContactToSequence';
import { ToggleIconClose, ToggleIconOpen } from '@/shared/components/Icons';
import {
  ConversationPanelTypes,
  ConversationScheduledMessageType,
} from '@/shared/types/conversations';
import { IconButton, Tooltip, TooltipContent, TooltipTrigger, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useConversation } from '../../context/ConversationContext';

export const ConversationToolbar = () => {
  const conversation = useConversation();
  const { toggleConversationPanel, setConversationPanel, conversationState } =
    conversation;
  const { conversationPanel, showConversationPanel, current } = conversationState;

  const handleClick = (action: ConversationPanelTypes | null) => {
    if (action === conversationPanel) {
      toggleConversationPanel(!showConversationPanel);
    } else {
      toggleConversationPanel(true);
    }
    setConversationPanel(action);
  };

  // filter out scheduled messages that are not for this conversation
  // only keep one if there are multiple with the same job_id
  const scheduledMessages = useMemo(
    () =>
      current?.scheduled_messages
        ? current?.scheduled_messages
            ?.filter((scheduled_message: ConversationScheduledMessageType) => {
              return scheduled_message.conversation_id === current.id;
            })
            .reduce(
              (
                acc: Array<ConversationScheduledMessageType>,
                scheduled_message: ConversationScheduledMessageType
              ) => {
                if (acc.find((m) => m.job_id === scheduled_message.job_id)) {
                  return acc;
                }
                return [...acc, scheduled_message];
              },
              []
            )
        : [],
    [current?.scheduled_messages]
  );

  return (
    <Toolbar open={showConversationPanel}>
      <VStack gap={4}>
        <ToolbarButton
          description={'Conversation Details'}
          onClick={() => handleClick(ConversationPanelTypes.CONVERSATION)}
        >
          {showConversationPanel ? <ToggleIconOpen /> : <ToggleIconClose />}
        </ToolbarButton>
        <ToolbarButton
          description={'View Contact'}
          onClick={() => handleClick(ConversationPanelTypes.CONTACT)}
        >
          <HiUserCircle size={18} />
        </ToolbarButton>
        {scheduledMessages?.length > 1 && (
          <ToolbarButton
            description={'Scheduled Conversations'}
            onClick={() => handleClick(ConversationPanelTypes.SCHEDULED)}
          >
            <HiClock size={18} />
          </ToolbarButton>
        )}
        <ToolbarButton
          description={'Search Messages'}
          onClick={() => handleClick(ConversationPanelTypes.SEARCH)}
        >
          <HiSearch size={18} />
        </ToolbarButton>
        <ToolbarButton
          description={'View Attachments'}
          onClick={() => handleClick(ConversationPanelTypes.ATTACHMENTS)}
        >
          <HiOutlinePaperClip
            size={18}
            style={{ transform: 'rotate(45deg) scaleY(-1)' }}
          />
        </ToolbarButton>
        {current && (
          <AddContactToSequence contact_ids={[current?.contact.id]}>
            <ToolbarButton description={'Add To Sequence'}>
              <HiPlus size={18} />
            </ToolbarButton>
          </AddContactToSequence>
        )}
        <ToolbarButton
          description={'View Campaigns'}
          onClick={() => handleClick(ConversationPanelTypes.CAMPAIGNS)}
        >
          <HiSpeakerphone size={17} />
        </ToolbarButton>
        <ToolbarButton
          description={'Set Language Settings'}
          onClick={() => handleClick(ConversationPanelTypes.LANGUAGE)}
        >
          <HiTranslate size={18} />
        </ToolbarButton>
        <ToolbarButton
          description={'Help Videos'}
          onClick={() => handleClick(ConversationPanelTypes.HELP)}
        >
          <HiQuestionMarkCircle size={18} />
        </ToolbarButton>
      </VStack>
    </Toolbar>
  );
};

type ToolbarButtonProps = {
  children: any;
  description: string;
  onClick?: () => void;
  testID?: string;
};

export const ToolbarButton = (props: ToolbarButtonProps) => {
  const { children, description, onClick, testID } = props;
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <ToolbarPrimitive.ToolbarButton asChild>
          <IconButton size={2} onClick={onClick} data-testid={testID}>
            {children}
          </IconButton>
        </ToolbarPrimitive.ToolbarButton>
      </TooltipTrigger>
      <TooltipContent side="left">{description}</TooltipContent>
    </Tooltip>
  );
};

export const Toolbar = styled(ToolbarPrimitive.Root, {
  display: 'flex',
  justifyContent: 'center',
  width: 55,
  minWidth: 55,
  py: 15,
  variants: {
    open: {
      true: {
        borderLeftWidth: 0,
      },
      false: {
        borderLeftWidth: 1,
        borderColor: '$slate4',
      },
    },
  },
});
