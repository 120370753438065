import React from 'react';
import { useMedia } from 'react-use';
import { Virtuoso } from 'react-virtuoso';

import { PageHeader } from '@/shared/components/navigation/PageHeader';
import { Contact } from '@/shared/types';
import { Box, Button, Flex, HStack, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { AddContact } from './AddContact';
import { ContactRow, LoadingContactRow } from './ContactRow';
import { ContactPageHeaderContainer, ContactsPageHeader } from './ContactsPageHeader';
import { ContactsSearch } from './ContactsSearch';
import { useContacts } from './context/ContactContext';

export type ContactsListProps = {
  /** is the list loading */
  loading: boolean;
  /** is the list filtered */
  isFiltered: boolean;
  /** the title to display on the contacts page */
  title: string;
  /** an array of contacts */
  contacts: Array<Contact>;
  /** list of rendered contacts */
  renderedContacts: Array<Contact>;
  /** modifies the list of rendered contacts */
  setRenderedContacts: (contacts: Array<Contact>) => void;
  /** an array of the checked contacts */
  checkedContacts: Array<Contact>;
  /** array of sorted contacts A - Z */
  sortedContacts: Array<Contact>;
  /** set the get contacts api request offset */
  setOffset: (offset: number) => void;
  /** update tge list of checked contacts */
  setCheckedContacts: (contacts: Contact[]) => void;
  /** saves the filters of the current group */
  saveFilters?: (filters: Record<string, any>) => void;
  /** call function to check search result contacts */
  setSearchResultChecked: (a: boolean) => void;
  /** call function to check all contacts */
  setAllContactsChecked: (b: boolean) => void;
  /** is this the groups page? */
  isGroup: boolean;
  /** is this the upload page? */
  isUpload: boolean;
  /** total number of contacts in group/upload/all_contacts */
  totalContacts: number | null;
  /** is there input in search bar or are filters applied? */
  searchOrFiltersApplied: boolean;
  /** are all contacts checked? */
  allContactsChecked: boolean;
  /** are contacts from search result checked? */
  searchResultChecked: boolean;
  /** object of filters used to dynamically search contacts */
  filters?: any;
  /** search input value */
  search: string;
  /** set search input value */
  setSearch: (search: string) => void;
};

export const ContactsList = (props: ContactsListProps): JSX.Element => {
  const isDesktop = useMedia('(min-width: 912px)');
  const contacts = useContacts();
  const { contactState } = contacts;
  const { isSearched } = contactState;

  // loading state
  if (props.loading) {
    return <ContactsLoadingState />;
  }

  return (
    <ContactListContainer direction="column">
      {isDesktop && (
        <ContactsPageHeader
          title={props.title}
          setCheckedContacts={props.setCheckedContacts}
          saveFilters={props.saveFilters}
          isGroup={props.isGroup}
          isUpload={props.isUpload}
        />
      )}
      {!isDesktop && (
        <PageHeader>
          <Box />
          <AddContact>
            <Button>Create Contact</Button>
          </AddContact>
        </PageHeader>
      )}
      <ContactsSearch
        contacts={props.contacts}
        renderedContacts={props.renderedContacts}
        searchContacts={props.setRenderedContacts}
        checkedContacts={props.checkedContacts}
        checkContact={props.setCheckedContacts}
        setCheckedContacts={props.setCheckedContacts}
        filters={props.filters}
        isGroupsPage={props.isUpload || props.isGroup}
        isUpload={props.isUpload}
        searchOrFiltersApplied={props.searchOrFiltersApplied}
        setSearchResultChecked={props.setSearchResultChecked}
        searchResultChecked={props.searchResultChecked}
        setAllContactsChecked={props.setAllContactsChecked}
        allContactsChecked={props.allContactsChecked}
        searchValue={props.search}
        setSearchValue={props.setSearch}
      />
      <Virtuoso
        data={props.sortedContacts}
        endReached={() => {
          isSearched ? console.log() : props.setOffset(props.contacts.length);
        }}
        itemContent={(index: number, contact: Contact) => (
          <ContactRow
            key={index}
            contact={contact}
            checkedContacts={props.checkedContacts}
            checkContact={props.setCheckedContacts}
            setAllContactsChecked={props.setAllContactsChecked}
          />
        )}
      />
    </ContactListContainer>
  );
};

export const ContactListContainer = styled(Flex, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minHeight: '100%',
  overflow: 'auto',
  maxWidth: '100%',
  borderRight: '1px solid $gray4',
});

export const ContactsLoadingState = (): JSX.Element => {
  const isDesktop = useMedia('(min-width: 912px)');

  return (
    <ContactListContainer direction="column" css={{ overflowY: 'hidden' }}>
      {isDesktop && (
        <ContactPageHeaderContainer>
          <VStack>
            <Flex align="center" justify="between">
              <Flex align="center">
                <Box css={{ fontSize: '18px', fontWeight: 700 }} />
              </Flex>
              <HStack>
                <Button variant="gray">Upload Contacts</Button>
                <AddContact>
                  <Button>Create Contact</Button>
                </AddContact>
              </HStack>
            </Flex>
            <HStack gap={3} css={{ paddingBottom: 15 }}></HStack>
          </VStack>
        </ContactPageHeaderContainer>
      )}
      {!isDesktop && (
        <PageHeader>
          <Box />
          <AddContact />
        </PageHeader>
      )}
      {Array.from({ length: 20 }, (_: any, k: React.Key | null | undefined) => (
        <LoadingContactRow key={k} />
      ))}
    </ContactListContainer>
  );
};
