import { styled } from '@/stitches.config';

export const Fieldset = styled('fieldset', {
  all: 'unset',
  marginBottom: 15,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

export const Label = styled('label', {
  fontSize: 13,
  fontWeight: 500,
  lineHeight: 1,
  marginBottom: 10,
  display: 'block',
});
