/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { getCampaignsOverview } from '@/shared/api/reports';
import { DAY } from '@/shared/components/reports/constants';
import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { ReportsTable } from '@/shared/components/reports/ReportsTable';
import { User } from '@/shared/types/users';

import { useReport } from '../context/ReportsContext';

export type UserCampaignsGroupedResult = {
  /** data to display in table */
  grouped_result: UserCampaignsData[];
};

export type UserCampaignsData = {
  /** total campaigns count for user in given time period */
  total_campaigns_count: number;
  /** total messages count for user in given time period */
  total_messages_count: number;
  /** total credits used for campaign for user in given time period */
  total_credit_used: number;
  /** average response rate for campaign in given time period */
  avg_response_rate: number;
  /** average unsubscribe rate */
  avg_unsubscribe_rate: number;
  /** user information */
  user_id: number;
  /** This will be filled with calling findUserAndPutUser function */
  user: User;
};

export const UserCampaign = () => {
  const reports = useReport();
  const { dates, initialState } = reports;

  const [data, setData] = useState<UserCampaignsGroupedResult>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, [dates.startDate, dates.endDate]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getCampaignsOverview(
        dates.startDate && dates.endDate
          ? {
              type: 'user_campaigns',
              start_date: dayjs(dates.startDate as string).format('YYYY-MM-DD'),
              end_date: dayjs(dates.endDate as string).format('YYYY-MM-DD'),
              granularity: DAY,
            }
          : { ...initialState, type: 'user_campaigns' }
      );
      setData(response);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const table_config = [
    {
      header: 'User',
      tooltip: 'The user who sent the campaigns',
      key: 'user',
    },
    {
      header: 'Total Campaigns',
      tooltip: 'Total Campaigns Sent by a User',
      key: 'total_campaigns_count',
    },
    {
      header: 'Total Messages',
      tooltip: 'Total Messages used by the User',
      key: 'total_messages_count',
    },
    // {
    //   header: 'Total Credit',
    //   tooltip: 'Total Credit used by the User',
    //   key: 'total_credit_used',
    // },
    {
      header: 'Avg Response Rate',
      tooltip: 'Average Response rate for Campaigns by the User ',
      key: 'avg_response_rate',
    },
    {
      header: 'Avg Unsubscribe Rate',
      tooltip: 'Average Unsubscribe rate by the User ',
      key: 'avg_unsubscribe_rate',
    },
  ];

  const headers = [
    { label: 'User', key: 'user.email' },
    { label: 'Total Campaigns', key: 'total_campaigns_count' },
    { label: 'Total Messages', key: 'total_messages_count' },
    { label: 'Avg Response Rate', key: 'avg_response_rate' },
    { label: 'Avg Unsubscribe Rate', key: 'avg_unsubscribe_rate' },
  ];

  const u = useUsers();
  const { users } = u.userState;

  console.log(users);

  // Prepare data for CSV export from grouped result
  const csvData =
    data?.grouped_result.map((item) => ({
      ...item,
      'user.email': users.find((user) => user.id === item.user_id)?.email || '',
    })) || [];

  return (
    <ReportsCollapsible
      title="Campaigns by User"
      description=""
      headers={headers}
      data={csvData}
      filename="campaigns_by_user"
    >
      <ReportsTable
        data={data?.grouped_result}
        config={table_config}
        sortable
        tableTitle="Campaigns by User"
        loading={loading}
        error={error}
        errorCallback={getData}
      />
    </ReportsCollapsible>
  );
};
