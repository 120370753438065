import React, { memo, useCallback } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Domain } from '@/shared/types/domains';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
} from '@/shared/ui';

import { useDomainsContext } from '../context/DomainsContext';

type DomainNavigationButtonProps = {
  domain: Domain;
};

export const DomainNavigationButton = memo(({ domain }: DomainNavigationButtonProps) => {
  const { deleteDomain } = useDomainsContext();
  const history = useHistory();
  const isDomainPage = useRouteMatch('/campaigns/domains/:id');

  const goToDomain = useCallback(() => {
    history.push(`/campaigns/domains/${domain.id}`);
  }, [domain]);

  const handleConfirm = useCallback(() => {
    domain?.id && deleteDomain(domain?.id);
    isDomainPage && history.push(`/campaigns/domains`);
  }, [deleteDomain, domain?.id]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild data-testid="domain-dropdown-trigger">
        <IconButton aria-label="open domain dropdown" variant="outline" size="2">
          <HiDotsHorizontal size="15px" />
        </IconButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={10}>
        <DropdownMenuItem onClick={goToDomain}>View Domain</DropdownMenuItem>
        <ConfirmationDialog
          title="Delete Domain"
          description="Are you sure want to delete this domain? This action cannot be undone."
          onConfirm={handleConfirm}
          confirmButtonTitle="Yes, Delete Domain"
        >
          <DropdownMenuItem
            onClick={(e) => e.preventDefault()}
            disabled={domain?.is_domain_shared}
          >
            Delete Domain
          </DropdownMenuItem>
        </ConfirmationDialog>
      </DropdownMenuContent>
    </DropdownMenu>
  );
});
