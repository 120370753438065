/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';

import { Attachment, isValidAttachment } from '@/shared/components/attachments';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import {
  ConversationAttachmentType,
  ConversationItemTypesType,
  ConversationItemVisibilityStatusTypes,
  ConversationMessageType,
} from '@/shared/types/conversations';
import { Box, Flex } from '@/shared/ui';
import { TIME_STAMP } from '@/shared/utils/timestamps';

import { StyledAttachmentContainer, StyledMessage, StyledMessageFooter } from '..';
import { ContactIcon } from '../ContactIcon';

type InboundSMSProps = {
  message: ConversationMessageType;
  /* Date of message */
  date?: string | undefined | null;
  /* Name of contact */
  contact_name: string;
};

// Combined message container and message divs for inbound messages
export const InboundSMS = (props: InboundSMSProps) => {
  const { message, date, contact_name } = props;
  const { body, attachments, type, translated_body, visibility_status } = message;

  // filter attachments we can't display
  const filteredAttachments = attachments.filter(
    (a: ConversationAttachmentType | null) =>
      a !== null && isValidAttachment(a.url) === true
  );

  // default to `visible` when the visibility is not set
  const visibility = visibility_status
    ? visibility_status
    : ConversationItemVisibilityStatusTypes.VISIBLE;

  return (
    <Box>
      {/* Show `Message Removed` bubble when the visibility is `removed` */}
      {visibility === ConversationItemVisibilityStatusTypes.REMOVED ? (
        <StyledMessage direction="inbound_sms">
          {renderText('This message has been deleted...')}
        </StyledMessage>
      ) : null}

      {(body !== null || filteredAttachments.length > 0) && (
        <Box>
          {body !== null ? (
            <Flex justify="start" align="end">
              <ContactIcon contact_name={contact_name} />
              <StyledMessage visibility={`inbound_${visibility}`} direction="inbound_sms">
                {renderText(body)}
              </StyledMessage>
            </Flex>
          ) : null}
          <Box>
            {translated_body && translated_body !== null ? (
              <Flex justify="start" align="end" css={{ mt: 5, ml: 37 }}>
                <StyledMessage
                  visibility={`inbound_${visibility}`}
                  direction="inbound_sms_translation"
                >
                  {renderText(`Translation: ${translated_body}`)}
                </StyledMessage>
              </Flex>
            ) : null}
          </Box>
          <Box>
            {filteredAttachments.length > 0 ? (
              visibility === ConversationItemVisibilityStatusTypes.HIDDEN ? (
                'Attachments have been hidden'
              ) : (
                <Box>
                  {filteredAttachments.map(
                    (a: ConversationAttachmentType | null, i: number) => {
                      return a ? (
                        <Flex
                          justify="start"
                          align="end"
                          key={a.url}
                          css={{ ml: i !== 0 || message.body !== null ? 35 : 0, mt: 2 }}
                        >
                          {message.body === null && i === 0 && (
                            <ContactIcon contact_name={contact_name} />
                          )}
                          <StyledAttachmentContainer>
                            <Flex css={{ width: '300px !important' }} justify="start">
                              <Attachment src={a.url} />
                            </Flex>
                          </StyledAttachmentContainer>
                        </Flex>
                      ) : null;
                    }
                  )}
                </Box>
              )
            ) : null}
          </Box>
          <StyledMessageFooter align="start" justify="start" css={{ ml: 5, mt: 4 }}>
            <Flex align="center">
              <Box css={{ mr: 5 }}>{dayjs(date).format(TIME_STAMP)}</Box>
              <Box css={{ fontWeight: 500 }}>
                {!contact_name && type === ConversationItemTypesType.IMPORTED
                  ? 'Imported'
                  : contact_name}
              </Box>
            </Flex>
          </StyledMessageFooter>
        </Box>
      )}
    </Box>
  );
};
