import { styled } from '@stitches/react';
import { HiCheck } from 'react-icons/hi';

import { Avatar, Checkbox, Flex } from '@/shared/ui';

import { ComboboxItemProps, TestDataType } from './types';

export const ComboboxMultiselectItem = ({
  checked,
  label,
}: ComboboxItemProps<string>) => {
  return (
    <>
      <ComboboxCheckbox selected={checked}>{checked && <HiCheck />}</ComboboxCheckbox>
      <div
        data-testid="combobox-option-label"
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </div>
    </>
  );
};

export const ComboboxMultiselectItemWithAvatar = ({
  checked,
  label,
}: ComboboxItemProps<TestDataType>) => {
  return (
    <>
      <Checkbox css={{ minWidth: 15, mr: 5 }} checked={checked} />
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Flex>
          <Avatar css={{ marginRight: 5 }} fallback={label.image} src={label.image} />
          {label?.name}
        </Flex>
      </div>
    </>
  );
};

const ComboboxCheckbox = styled('div', {
  width: '$3',
  height: '$3',
  minWidth: '$3',
  borderRadius: '$1',
  color: 'primary',
  padding: '2px',
  fontSize: '14px',
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  boxShadow: 'inset 0 0 0 1px $colors$slate7 !important',
  marginRight: '5px',
  variants: {
    selected: {
      true: {
        border: 'none',
        backgroundColor: '$primaryColor',
        color: 'white',
      },
    },
  },
});
