import moment from 'moment';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { DAY } from '@/shared/components/reports/constants';
import { Location } from '@/shared/types/locations';

export type ReportState = {
  initialState: {
    end_date: string;
    start_date: string;
    granularity: string;
    // location_ids?: string[];
  };
  /** array of selected locations */
  selectedLocations: {
    locations: string[];
  };
  /** date picker start and date */
  dates: { startDate: moment.Moment | string; endDate: moment.Moment | string };
  /** date picker start and end date setter */
  setDates: Dispatch<any>;
  /** selected locations setter */
  setSelectedLocations?: Dispatch<
    SetStateAction<{
      locations: string[];
    }>
  >;
};

export const initialReportState: ReportState = {
  initialState: {
    end_date: '',
    start_date: '',
    granularity: 'day',
  },
  selectedLocations: { locations: [] },
  dates: { startDate: '', endDate: '' },
  setDates: () => undefined,
  setSelectedLocations: () => undefined,
};

const ReportContext = createContext<ReportState>(initialReportState);

export const useReport = () => useContext(ReportContext);

const ReportsState = ({ children }: { children: React.ReactNode }) => {
  // the selected locations of the reporting filter
  const [dates, setDates] = useState({
    startDate: moment().add(-30, 'day'),
    endDate: moment(),
  });
  const [selectedLocations, setSelectedLocations] = useState<{ locations: string[] }>({
    locations: [],
  });

  const {
    locationsState: { allLocations },
  } = useLocations();

  const initialState = {
    end_date: moment().format('YYYY-MM-DD'),
    start_date: moment(moment().format('YYYY-MM-DD'))
      .add(-30, 'day')
      .format('YYYY-MM-DD'),
    granularity: DAY,
  };

  // if there are no locations selected, use all locations
  useEffect(() => {
    if (selectedLocations.locations.length === 0) {
      setSelectedLocations({
        locations: allLocations.map((location: Location) => location.id),
      });
    }
  }, []);

  return (
    <ReportContext.Provider
      value={{
        initialState,
        dates,
        setDates,
        selectedLocations,
        setSelectedLocations,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export default ReportsState;
