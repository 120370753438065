import { memo, useMemo } from 'react';
import { useMedia } from 'react-use';

import { DNSRecord, Domain, DomainSetupState } from '@/shared/types/domains';
import { Badge, Box, Divider, Flex, HStack, Label, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { DNSRecordsWarnings } from '../constants';

const getWarningText = (type: string) => {
  return DNSRecordsWarnings.find((item) => item.type === type);
};

type DNSRecordsProps = {
  domain: Domain | null;
};

type RecordItemProps = {
  name: string;
  apex_domain_name: string;
  record: DNSRecord;
};

export const DNSRecords = ({ domain }: DNSRecordsProps) => {
  if (!domain?.verification && !domain?.required_dns_records?.length) {
    return null;
  }

  const renderItem = (item: DNSRecord) => (
    <RecordItem
      key={item.type}
      name={domain?.name}
      apex_domain_name={domain?.apex_domain_name}
      record={item}
    />
  );

  if (
    domain?.setup_state === DomainSetupState.CHECKING_MISCONFIGURED &&
    domain?.required_dns_records?.length
  ) {
    return (
      <>
        {domain?.required_dns_records?.map(renderItem)}
        <Divider css={{ mt: 20, mb: 0 }} />
      </>
    );
  }

  return (
    domain?.setup_state === DomainSetupState.CHECKING_VERIFIED &&
    domain?.verification && (
      <>
        <RecordItem
          name={domain?.name}
          apex_domain_name={domain?.apex_domain_name}
          record={domain?.verification}
        />
        <Divider css={{ mt: 20, mb: 0 }} />
      </>
    )
  );
};

const RecordItem = memo(({ name, apex_domain_name, record }: RecordItemProps) => {
  const isMobile = useMedia('(max-width: 700px)');

  const warningText = useMemo(() => {
    return getWarningText(record?.type)?.warning;
  }, [record?.type]);

  return (
    <VStack gap={4}>
      <Divider css={{ mt: 10, mb: 0 }} />
      <Flex justify={'start'}>
        {record.type === 'CAA' ? (
          <RecordText>
            {`Please set the following CAA record on `}
            <Badge variant={'indigo'}>{apex_domain_name}</Badge>
            {` to authorize the specific Certificate Authority (CAs) to issue SSL/TLS certificates for your domain`}
          </RecordText>
        ) : (
          <RecordText>
            {`Please set the following ${record.type} record on `}
            <Badge variant={'indigo'}>{apex_domain_name}</Badge>
            {` to prove ownership of `}
            <Badge variant={'indigo'}>{name}</Badge>
            {': '}
          </RecordText>
        )}
      </Flex>
      <RecordTable isMobile={isMobile} align={'start'}>
        <Column gap={1} isMobile={isMobile} css={{ minWidth: 32 }}>
          <ColumnLabel>Type</ColumnLabel>
          <Value>{record.type}</Value>
        </Column>
        <Column gap={1} isMobile={isMobile} css={{ minWidth: 178 }}>
          <ColumnLabel>Name</ColumnLabel>
          <Value>{record.name}</Value>
        </Column>
        <Column gap={1} isMobile={isMobile} isLast>
          <ColumnLabel>Value</ColumnLabel>
          <Value>{record.value}</Value>
        </Column>
      </RecordTable>
      {warningText && <RecordText>{warningText}</RecordText>}
    </VStack>
  );
});

export const RecordText = styled(Box, {
  fontSize: '14px',
  fontWeight: '500',
});

export const RecordTable = styled(HStack, {
  p: 20,
  backgroundColor: '$slate3',
  variants: {
    isMobile: {
      true: {
        fd: 'column',
      },
    },
  },
});

export const ColumnLabel = styled(Label, {
  fontSize: '12px',
  fontWeight: '500',
  color: '#687076',
  mb: 1,
});

export const Column = styled(VStack, {
  mr: 60,
  variants: {
    isMobile: {
      true: {
        mr: 0,
        mb: 20,
        width: '100%',
      },
    },
    isLast: {
      true: {
        mr: 0,
        mb: 0,
      },
    },
  },
});

export const Value = styled(Box, {
  fontSize: '14px',
  fontWeight: '500',
  wordBreak: 'break-word',
});
