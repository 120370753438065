import { Location } from '@/shared/types/locations';

export const getDefaultLocation = (
  location?: string,
  locations?: Array<Location>,
  preferredLocation?: string
): string => {
  if (location) {
    return location;
  } else if (locations?.length === 1) {
    return locations[0].id;
  } else if (preferredLocation && isLocationInList(preferredLocation, locations)) {
    return preferredLocation;
  } else {
    return '';
  }
};

const isLocationInList = (id: string, locations?: Array<Location>) => {
  return !!locations?.some((location: Location) => location.id === id);
};
