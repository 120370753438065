import { CustomObjectRecord } from '../data';
import { ExtendedFilterItem, FilterItem, Sort } from '../filter';

export enum UnsubscribePreferenceActionTypes {
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_EMPTY = 'SET_IS_EMPTY',
  SET_ALL_LOCATIONS_TO_SHOW = 'SET_ALL_LOCATIONS_TO_SHOW',
  SET_ACTIVE_LOCATIONS = 'SET_ACTIVE_LOCATIONS',
  SET_PAUSED_LOCATIONS = 'SET_PAUSED_LOCATIONS',
  SET_OPTED_OUT_LOCATIONS = 'SET_OPTED_OUT_LOCATIONS',
  SET_SELECTED_LOCATIONS = 'SET_SELECTED_LOCATIONS',
  SET_SELECTED_ACTIVE_LOCATIONS = 'SET_SELECTED_ACTIVE_LOCATIONS',
  SET_SELECTED_PAUSED_LOCATIONS = 'SET_SELECTED_PAUSED_LOCATIONS',
  SET_CONFIRMED_PAUSE_DURATION = 'SET_CONFIRMED_PAUSE_DURATION',
  SET_PAUSED_PREFERENCES = 'SET_PAUSED_PREFERENCES',
}

export type Contact = {
  id: string;
  name: string | null;
  email: string | null;
  phone: string | null;
  source: string | null;
  opt_in_sms: boolean;
  opt_in_email: boolean;
  opt_out_of_all: boolean;
  blocked: boolean;
  external_id: string | null;
  default_channel_id: string | null;
  language: string | null;
  birth_date: { year: number; month: number; day: number } | null;
  address: {
    address_line_one: string | null;
    address_line_two: string | null;
    city: string | null;
    state: string | null;
    post_code: string | null;
    country: string | null;
  } | null;
  inserted_at: string;
  updated_at: string;
};

export enum ContactStateType {
  OPEN = 'OPEN',
  ARCHIVED = 'ARCHIVED',
}

export enum DataActionTypes {
  UPDATE_CONTACT_FILTERS = 'UPDATE_CONTACT_FILTERS',
  GET_CONTACTS = 'GET_CONTACTS',
  CREATE_CONTACT = 'CREATE_CONTACT',
  BLOCK_CONTACT = 'BLOCK_CONTACT',
  DELETE_CONTACT = 'DELETE_CONTACT',
  UPDATE_OBJECT_RECORD_FILTERS = 'UPDATE_OBJECT_RECORD_FILTERS',
  GET_CUSTOM_OBJECT_RECORDS = 'GET_CUSTOM_OBJECT_RECORDS',
}

export type ContactsType = {
  data: Array<Contact>;
  total: number;
  isLoadMore?: boolean;
};

export type CustomObjectRecordsType = {
  data: Array<CustomObjectRecord>;
  total: number;
  isLoadMore?: boolean;
};

export type DataActions =
  | {
      type: DataActionTypes.GET_CONTACTS;
      payload: ContactsType;
    }
  | {
      type: DataActionTypes.CREATE_CONTACT;
      payload: Contact;
    }
  | {
      type: DataActionTypes.UPDATE_CONTACT_FILTERS;
      payload: { filters: SearchFilters; is_load_more: boolean };
    }
  | { type: DataActionTypes.BLOCK_CONTACT; payload: Contact }
  | { type: DataActionTypes.DELETE_CONTACT; payload: { id: string } }
  | {
      type: DataActionTypes.UPDATE_OBJECT_RECORD_FILTERS;
      payload: { filters: SearchFilters; is_load_more: boolean };
    }
  | {
      type: DataActionTypes.GET_CUSTOM_OBJECT_RECORDS;
      payload: CustomObjectRecordsType;
    };

export type SearchFilters = {
  filter: Array<FilterItem>;
  searchFilter: Array<FilterItem>;
  sort: Array<Sort>;
  limit: number;
  offset: number;
  defaultFilter?: FilterItem;
  defaultFilters?: Array<FilterItem>;
  extended_filter?: ExtendedFilterItem[];
};

export type DataStateType = {
  contacts: Array<Contact>;
  customObjectRecords: Array<CustomObjectRecord>;
  totalCount: number;
  loading: boolean;
  loadingCustomObjects: boolean;
  loadingMore: boolean;
  error: Error | null;
  contactsFilters: SearchFilters;
  customObjectsFilters: SearchFilters;
};
