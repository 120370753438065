import { Agent } from './agents';
import { LLM } from './llms';

export enum AgentActionTypes {
  GET_LLMS = 'GET_LLMS',
  GET_AGENTS = 'GET_AGENTS',
  GET_LLM = 'GET_LLM',
  GET_AGENT = 'GET_AGENT',
  CREATE_LLM = 'CREATE_LLM',
  UPDATE_LLM = 'UPDATE_LLM',
  DELETE_LLM = 'DELETE_LLM',
  CREATE_AGENT = 'CREATE_AGENT',
  UPDATE_AGENT = 'UPDATE_AGENT',
  DELETE_AGENT = 'DELETE_AGENT',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
}

export type AgentStateType = {
  llms: { data: LLM[]; total: number };
  agents: { data: Agent[]; total: number };
  loading: boolean;
};

export type AgentActions =
  | { type: AgentActionTypes.GET_LLMS; payload: { data: LLM[]; total: number } }
  | {
      type: AgentActionTypes.GET_AGENTS;
      payload: { data: Agent[]; total: number };
    }
  | { type: AgentActionTypes.GET_LLM; payload: LLM }
  | { type: AgentActionTypes.GET_AGENT; payload: Agent }
  | { type: AgentActionTypes.CREATE_LLM; payload: LLM }
  | { type: AgentActionTypes.UPDATE_LLM; payload: LLM }
  | { type: AgentActionTypes.DELETE_LLM; payload: string }
  | { type: AgentActionTypes.CREATE_AGENT; payload: Agent }
  | { type: AgentActionTypes.UPDATE_AGENT; payload: Agent }
  | { type: AgentActionTypes.DELETE_AGENT; payload: string }
  | { type: AgentActionTypes.SET_LOADING; payload: boolean };
