import { Template } from '../types/templates';
import { api } from './api';

export type CreateMessageTemplateParams = {
  title: string;
  message: string;
  location_id?: string;
  attachment_urls?: Array<string>;
  location_ids?: Array<string>;
};

export const getMessageTemplatesV1 = async (params?: {
  offset?: number;
  limit?: number;
}) => {
  const { data } = await api.get(`/message_templates`, { params }).then((res) => {
    return res;
  });

  return data;
};

export const getMessageTemplates = async (params: any) =>
  api.post(`/v2/message_templates/search`, { ...params }).then((res) => {
    return res.data;
  });

export const createMessageTemplate = (params: CreateMessageTemplateParams) =>
  api
    .post(`/v2/message_templates`, {
      message_template: params,
    })
    .then((res) => {
      return res.data.data;
    });

export const editMessageTemplate = (template: Template) =>
  api
    .put(`/v2/message_templates/${template.id}`, { message_template: { ...template } })
    .then((res) => {
      return res.data.data;
    });

export const deleteMessageTemplate = (template: Template) =>
  api.delete(`/v2/message_templates/${template.id}`).then((res) => {
    return res.data.data;
  });

export const bulkImportTemplates = (message_templates: Array<any>) =>
  api.post(`/message_templates/upload`, { message_templates }).then((res) => {
    return res.data.data;
  });
