/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { Virtuoso } from 'react-virtuoso';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Location } from '@/shared/types/locations';
import { Sequence } from '@/shared/types/sequences';
import { User } from '@/shared/types/users';
import { Box, Button, Flex, HStack, Input, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useSequences } from '../context/SequenceContext';
import { SequenceEditor } from '../sequence/SequenceEditor';
import { SequenceCard, SequenceLoadingCard } from './SequenceCard';

type SequenceFilters = {
  /* array of location ids */
  locations: string[];
  /* array of user ids */
  users: number[];
};

const LIMIT = 20;

export const SequenceList = () => {
  const sequencesContext = useSequences();
  const { getSequences, sequencesState } = sequencesContext;
  const { sequences, loading, totalCount } = sequencesState;

  const isLargeScreen = useMedia('(min-width: 1600px)');

  const isListComplete = sequences.length >= totalCount;

  const locationContext = useLocations();
  const { locations } = locationContext.locationsState;

  const userContext = useUsers();
  const { users } = userContext.userState;

  const [query, setQuery] = useState<{ title: string; filters: SequenceFilters }>({
    title: '',
    filters: { locations: [], users: [] },
  });
  const [offset, setOffset] = useState(0);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  const getSequencesDebounced = useCallback(
    debounce((query) => {
      getSequences({
        title: query.title || '',
        location_ids: query.filters.locations,
        user_ids: query.filters.users,
        limit: LIMIT,
        offset: 0,
      });
    }, 500), // 500ms debounce time
    []
  );

  useEffect(() => {
    setOffset(0);
    getSequencesDebounced(query);
  }, [query]);

  // fetch next set of campaign templates to add the the list
  const fetchNextSequences = async (offset: number) => {
    setIsLoadingNext(true);
    await getSequences({
      title: query.title || '',
      location_ids: query.filters.locations,
      user_ids: query.filters.users,
      limit: LIMIT,
      offset,
    });
    setIsLoadingNext(false);
  };

  useEffect(() => {
    if (offset > 0 && !loading) {
      fetchNextSequences(offset);
    }
  }, [offset, loading]);

  const increaseOffset = () => {
    setOffset((prevCoefficient) => prevCoefficient + LIMIT);
  };

  const enabledLoadingNext =
    // If the list is not already complete
    !isListComplete &&
    // Is next sequences list loading
    !isLoadingNext;

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Sequences', path: '/sequences' },
        { title: 'All Sequences', path: '/sequences' },
      ]}
      actions={
        <SequenceEditor>
          <Button>Create Sequence</Button>
        </SequenceEditor>
      }
    >
      <ListContainer gap={1}>
        <SearchRow css={{ backgroundColor: 'rgb(250, 250, 250)', px: 30, mb: 20 }}>
          <HStack gap={1} css={{ width: '100%' }}>
            <Flex align="center" css={{ width: isLargeScreen ? '100%' : '40%' }}>
              <Input
                value={query.title}
                placeholder="Search Sequences"
                onChange={(e) => setQuery((prev) => ({ ...prev, title: e.target.value }))}
                css={{ width: '409px' }}
              />
            </Flex>
            {locations.length > 0 && (
              <Box css={{ width: '30%' }}>
                <MultiSelect
                  defaultPlaceholder="Locations"
                  defaultSelectedItems={[]}
                  isDropdown={true}
                  options={locations.map((location: Location) => ({
                    type: location?.name || '',
                    value: location.id,
                  }))}
                  parentSelectedItems={query.filters}
                  setParentSelectedItems={(filters: SequenceFilters) =>
                    setQuery((prev) => ({ ...prev, filters }))
                  }
                  isCampaigns={true}
                />
              </Box>
            )}
            {users.length > 0 && (
              <Box css={{ width: '30%' }}>
                <MultiSelect
                  defaultPlaceholder="Users"
                  defaultSelectedItems={[]}
                  isDropdown={true}
                  options={users.map((user: User) => ({
                    type: user.name || user.email || '',
                    value: user.id,
                  }))}
                  parentSelectedItems={query.filters}
                  setParentSelectedItems={(filters: SequenceFilters) =>
                    setQuery((prev) => ({ ...prev, filters }))
                  }
                  isCampaigns={true}
                />
              </Box>
            )}
          </HStack>
        </SearchRow>
        {loading &&
          Array.from({ length: 20 }, (_: never, k: React.Key | null | undefined) => (
            <ItemContainer key={k}>
              <SequenceLoadingCard key={k} />
            </ItemContainer>
          ))}

        {!loading && sequences.length > 0 && (
          <Virtuoso
            data={sequences as Sequence[]}
            atBottomStateChange={(isAtBottom) => {
              if (isAtBottom && enabledLoadingNext) {
                increaseOffset();
              }
            }}
            totalCount={totalCount}
            itemContent={(index: number, sequence: Sequence) => (
              <ItemContainer key={sequence.id}>
                <SequenceCard key={sequence.id} sequence={sequence} />
              </ItemContainer>
            )}
            components={{
              Footer: () =>
                enabledLoadingNext ? (
                  <ItemContainer>
                    <SequenceLoadingCard />
                  </ItemContainer>
                ) : (
                  <Box css={{ pb: 10 }} />
                ),
            }}
          />
        )}
        {/* this box below is specially for empty state layout */}
        {!loading && sequences.length === 0 && (
          <Box
            css={{
              p: 30,
              mb: 30,
              minHeight: 'calc(100% - 88px)',
              height: 'fit-content',
              overflow: 'auto',
            }}
          >
            {query.title === '' ? <SequenceEmptyState /> : <SearchedSequenceEmptyState />}
          </Box>
        )}
      </ListContainer>
    </PageLayout>
  );
};

export const SearchedSequenceEmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Sequence Matches Your Search</Text>
      <Text css={{ mt: 10 }}>Please try a different key word</Text>
    </EmptyStateContainer>
  );
};

export const SequenceEmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Sequences</Text>
      <Text css={{ mt: 10 }}>{`Click "Create Sequence" to get started`}</Text>
    </EmptyStateContainer>
  );
};

const EmptyStateContainer = styled(Flex, {
  p: 20,
  height: '100%',
  width: '100%',
});

export const ListContainer = styled(VStack, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minHeight: '100%',
  overflow: 'auto',
  maxWidth: '100%',
  pt: 30,
  pb: 0,
});

export const SearchRow = styled(Flex, {
  height: 35,
});

export const ItemContainer = styled(Box, {
  px: 30,
  pb: 10,
});
