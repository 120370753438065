import { Box, Button, HStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { BulkAction } from '../FiltersTable';

type BulkActionButtonProp = {
  bulkAction: BulkAction;
  handleClick: () => void;
};

export const BulkActionButton = ({ bulkAction, handleClick }: BulkActionButtonProp) => (
  <HStack
    css={{
      cursor: 'pointer',
      color: 'white',
      paddingLeft: '8px',
      paddingRight: '8px',
      marginLeft: '8px',
      borderWidth: '2px',
      borderRadius: '6px',
      borderColor: 'white',
    }}
    onClick={handleClick}
  >
    {bulkAction.icon && (
      <BulkActionIcon css={{ paddingLeft: '0px' }}>{bulkAction.icon}</BulkActionIcon>
    )}
    <Button
      ghost
      variant="gray"
      size="2"
      css={{
        color: 'White',
      }}
    >
      {' '}
      {bulkAction.label}
    </Button>
  </HStack>
);

const BulkActionIcon = styled(Box, {
  cursor: 'pointer',
  padding: 5,
  fontSize: 16,
});
