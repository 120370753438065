/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { VirtuosoHandle } from 'react-virtuoso';

import { QuickCampaignDialog } from '@/pages/campaigns/v2/quick';
import { createQuickCampaign } from '@/pages/campaigns/v2/quick/api';
import { useGroups } from '@/pages/contacts/groups/context/GroupContext';
import { CreateUpload } from '@/pages/contacts/uploads/CreateUpload';
import {
  initialCustomDataState,
  useCustomData,
} from '@/pages/settings/organization/data/context/CustomDataContext';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import {
  ConfirmationDialogDescription,
  CustomDropdownMenuItem,
  CustomDropdownMenuItemWarning,
} from '@/pages/settings/organization/users/UsersTable';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { DownloadContacts, getContactList } from '@/shared/components/DownloadContacts';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { BulkTagModal } from '@/shared/components/filters/bulkAction/modals/BulkTagModal';
import { BulkAction, FilteredTable } from '@/shared/components/filters/FiltersTable';
import { prepareFilter } from '@/shared/components/filters/utils';
import { AddToSequenceDialog } from '@/shared/components/modals/AddToSequenceDialog/AddToSequenceDialog';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { ScheduleOptions } from '@/shared/types/campaigns';
import { SearchFilters } from '@/shared/types/contacts';
import { Group, SegmentV2Filters } from '@/shared/types/contacts/groups';
import { FilterParams, FilterType, Sort } from '@/shared/types/filter';
import { Location } from '@/shared/types/locations';
import { SequenceBulkActionFilter } from '@/shared/types/sequences';
import { Box, Button, HStack } from '@/shared/ui';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';

import { areFiltersValid } from '../../../shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '../../../shared/components/filterBuilder/utils/cleanFilters';
import { rebuildFilters } from '../../../shared/components/filterBuilder/utils/rebuildFilters';
import { ITEMS_COUNT } from '../contacts';
import { CreateContact } from '../contacts/CreateContact';
import { initialState, useData } from '../context/DataContext';
import { applySort } from '../utils/applySort';
import { ContactsTableColumns } from '../utils/ContactsTable';
import {
  defaultSort,
  handleFilterChange,
  handleInfiniteScroll,
  handleQuickSearch,
  handleSortChange,
} from '../utils/filterActions';
import { sortConfig } from '../utils/filterConfig';
import { prepareFilters } from '../utils/prepareFilters';
import { EditAction } from './EditAction';
import { RenameDialog } from './RenameDialog';

export const Segment = (): JSX.Element => {
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const contacts = useData();

  const { updateContactFilters, createContact } = contacts;
  const loading = contacts.dataState.loading || false;
  const loadingMore = contacts.dataState.loadingMore || false;

  const data = useMemo(() => {
    const sortField = contacts.dataState.contactsFilters.sort[0]?.column || 'updated_at';
    const sortOrder = contacts.dataState.contactsFilters.sort[0]?.order || 'desc';
    return applySort(contacts.dataState.contacts, sortField, sortOrder);
  }, [contacts.dataState.contacts]);

  const [quickSearchValue, setQuickSearchValue] = useState<string>('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  // the state needed for Quick Campaign
  const location = useLocations();
  const { locationsState } = location;
  const { locations } = locationsState;
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState([] as string[]);
  const [totalContactsSelected, setTotalContactsSelected] = useState(0);
  const [isQuickCampaignOpen, setQuickCampaignOpen] = useState(false);
  const [isAddToSequenceModalOpen, setAddToSequenceModalOpen] = useState(false);
  const [isBulkTagModalOpen, setBulkTagModalOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const custom = useCustomData();
  const { getCustomObjects, customDataState } = custom;
  const { customObjects } = customDataState;

  useEffect(() => {
    const fetchCustomObjects = async () => {
      await getCustomObjects({
        ...initialCustomDataState.filterParams,
        limit: 100,
        offset: 0,
      });
    };

    if (customObjects.length === 0) fetchCustomObjects();

    // on unmount set the current group to null
    return () => {
      setCurrentGroup(null);
    };
  }, []);

  const segments = useGroups();
  const { getGroup, updateGroup, deleteGroup, setCurrentGroup, groupsState } = segments;
  const { current } = groupsState;

  const getSegment = async () => {
    try {
      const segment = await getGroup(params.id);
      if (segment?.filters_version === 'v2' && 'filter' in segment.filters) {
        const filters = rebuildFilters(customObjects, segment.filters.filter || []);
        updateContactFilters({
          ...initialState.contactsFilters,
          defaultFilters: cleanFilters(filters),
          sort: defaultSort,
        });
      }
    } catch (error) {
      history.push('/data/segments');
    }
  };

  useEffect(() => {
    // Reset filters and contacts data when navigating to a different segment
    setActiveFilters([]);
    // Then, fetch the new segment data
    if (!current || current.id !== params.id) {
      getSegment();
    }
  }, [params.id, current]);

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => updateContactFilters(props), 1000),
    []
  );

  const tableRef = useRef<VirtuosoHandle>(null);
  const columns = ContactsTableColumns();

  const handleUpdateSegmentFilters = (segment: Group) => {
    try {
      const new_filters = cleanFilters([
        ...contacts.dataState.contactsFilters.filter,
        ...(contacts.dataState.contactsFilters.defaultFilters ?? []),
      ]);
      segment.filters = {
        filter: new_filters,
      };
      updateGroup(segment);
    } catch (error) {
      console.error(error);
    }
  };

  const areFiltersEqual = (filtersA: FilterType[], filtersB: FilterType[]): boolean => {
    if (filtersA.length !== filtersB.length) return false;
    for (let i = 0; i < filtersA.length; i++) {
      if (JSON.stringify(filtersA[i]) !== JSON.stringify(filtersB[i])) return false;
    }
    return true;
  };

  function handleConfirm(
    message: string,
    attachments: Attachments,
    location: Location,
    scheduleOptions: ScheduleOptions | null
  ) {
    if (!current || !current.id) return;
    const filter = combineFilter(
      selectedContactIds,
      contacts.dataState.contactsFilters,
      isAllSelected
    );
    createQuickCampaign({ message, attachments, location, scheduleOptions, filter });
    setQuickCampaignOpen(false);
  }

  function combineFilter(
    selectedContactIds: string[],
    searchParam: SearchFilters,
    isAllSelected: boolean
  ): FilterParams {
    const filter = prepareFilter(
      searchParam,
      selectedContactIds,
      isAllSelected,
      'contact'
    );
    return { filter: filter };
  }

  const bulkActions: BulkAction[] = [
    {
      label: 'Add to Sequence',
      action: 'addToSequenceV2',
      title: 'Add to Sequence',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add ${selected} contact(s) to an existing sequence?`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setAddToSequenceModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setAddToSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      action: 'createQuickCampaignV2',
      label: 'Create Campaign',
      title: 'Create Quick Campaign',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add ${selected} contact(s) to a Quick Campaign?`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setQuickCampaignOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setQuickCampaignOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Unassign Tags',
      action: 'unassignTagsV2',
      title: 'Unassign Tags',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to unassign tags from ${selected} contact(s)`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
  ];

  const getContacts = async (isDownloadAll: boolean) => {
    const params = isDownloadAll
      ? prepareFilters({
          ...initialState.contactsFilters,
          defaultFilters: contacts.dataState.contactsFilters.defaultFilters,
          sort: defaultSort,
        })
      : prepareFilters(contacts.dataState.contactsFilters);

    return getContactList(params.filter, params.sort, ITEMS_COUNT);
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Segment</title>
      </Helmet>
      <PageLayout
        breadcrumbs={[
          { title: 'Data', path: '/data/contacts' },
          { title: 'Segments', path: '/data/segments' },
          { title: `${current?.name || 'Segment'}`, path: `/data/segments/${params.id}` },
        ]}
        actions={
          <HStack>
            <TableActionMenu width={200}>
              <>
                {current && current.id && (
                  <>
                    <DownloadContacts
                      isSubmenu={true}
                      getData={getContacts}
                      setDownloading={setDownloading}
                      fileName="contacts"
                      title={downloading ? 'Downloading Data...' : 'Download CSV'}
                    />
                    <RenameDialog
                      title="Rename segment"
                      description="Rename this segment"
                      value={current}
                      action={updateGroup}
                    />
                    <EditAction segment={current} updateSegment={updateGroup}>
                      <CustomDropdownMenuItem
                        data-testid="edit-action-option"
                        onClick={(e) => e.preventDefault()}
                      >
                        Edit Action
                      </CustomDropdownMenuItem>
                    </EditAction>
                  </>
                )}
                <CreateUpload navigateTo="/data/lists/">
                  <CustomDropdownMenuItem
                    data-testid="create-contacts-list-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Create Contacts List
                  </CustomDropdownMenuItem>
                </CreateUpload>
                <CreateContact handleCreateContact={createContact}>
                  <CustomDropdownMenuItem
                    data-testid="add-contact-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Add Contact
                  </CustomDropdownMenuItem>
                </CreateContact>
                {current && current.id && (
                  <ConfirmationDialog
                    width="432px"
                    title="Delete this segment?"
                    description={
                      <ConfirmationDialogDescription
                        value={current.name}
                        description="segment will be permanently deleted."
                      />
                    }
                    onConfirm={() => deleteGroup(current.id)}
                    confirmButtonTitle="Confirm"
                    cancelButtonTitle="Cancel"
                    confirmButtonVariant="redBackground"
                    cancelButtonVariant="grayBackground"
                  >
                    <CustomDropdownMenuItemWarning
                      data-testid="delete-segment-option"
                      onClick={(e) => e.preventDefault()}
                    >
                      Delete segment
                    </CustomDropdownMenuItemWarning>
                  </ConfirmationDialog>
                )}
              </>
            </TableActionMenu>
          </HStack>
        }
      >
        <Box css={{ backgroundColor: 'white', height: '100%', overflowY: 'hidden' }}>
          <Box css={{ padding: '$space$3 $space$6' }}>
            <CombinedFilters
              quickSearchPlaceholder="Search Contacts"
              quickSearchValue={quickSearchValue}
              setQuickSearchValue={(value: string) => {
                setQuickSearchValue(value);
                handleQuickSearch(
                  debouncedUpdate,
                  contacts.dataState.contactsFilters,
                  value
                );
              }}
              customObjects={customObjects}
              activeFilters={activeFilters}
              setFilters={(value: Array<FilterType>) => {
                setActiveFilters(value);
                handleFilterChange(
                  debouncedUpdate,
                  contacts.dataState.contactsFilters,
                  value
                );
              }}
              sortConfig={sortConfig}
              activeSort={contacts.dataState.contactsFilters.sort}
              onSortUpdate={(value: Array<Sort>) =>
                handleSortChange(
                  updateContactFilters,
                  contacts.dataState.contactsFilters,
                  value
                )
              }
            >
              <Box>
                <Button
                  css={{ width: 120 }}
                  variant="gray"
                  size="2"
                  onClick={() => handleUpdateSegmentFilters(current as Group)}
                  disabled={
                    activeFilters.length === 0 ||
                    !areFiltersValid(activeFilters) ||
                    cleanFilters(activeFilters).length === 0 ||
                    areFiltersEqual(
                      cleanFilters(activeFilters),
                      (current?.filters as SegmentV2Filters)?.filter || []
                    )
                  }
                >
                  Save Segment
                </Button>
              </Box>
            </CombinedFilters>
          </Box>
          <Box>
            <FilteredTable
              columns={columns}
              bulkActions={maybeGetBulkActions(current, bulkActions)}
              data={data}
              isLoading={loading}
              totalCount={contacts.dataState.totalCount}
              onEndReached={() =>
                handleInfiniteScroll(
                  updateContactFilters,
                  contacts.dataState.contactsFilters,
                  data,
                  contacts.dataState.totalCount,
                  loadingMore
                )
              }
              emptyStateElement={<Box>No records found</Box>}
              tableRef={tableRef}
              heightOffset={0}
              showTotalCount
            />
          </Box>
        </Box>
      </PageLayout>
      {isQuickCampaignOpen && (
        <QuickCampaignDialog
          currentLocation={currentLocation}
          setLocation={setCurrentLocation}
          locations={locations}
          isOpen={isQuickCampaignOpen}
          showTimeZonePicker={false}
          setIsOpen={setQuickCampaignOpen}
          handleConfirm={handleConfirm}
          totalContacts={totalContactsSelected}
        />
      )}
      {current && current.id && (
        <AddToSequenceDialog
          isOpen={isAddToSequenceModalOpen}
          showTimeZonePicker={false}
          locations={locations}
          totalContacts={totalContactsSelected}
          setIsOpen={setAddToSequenceModalOpen}
          filter={
            combineFilter(
              selectedContactIds,
              contacts.dataState.contactsFilters,
              isAllSelected
            ) as SequenceBulkActionFilter
          }
        />
      )}
      <BulkTagModal
        open={isBulkTagModalOpen}
        totalContacts={totalContactsSelected}
        onOpenChange={setBulkTagModalOpen}
        filter={
          combineFilter(
            selectedContactIds,
            contacts.dataState.contactsFilters,
            isAllSelected
          ).filter ?? []
        }
      />
    </>
  );
};

function maybeGetBulkActions(segment: Group | null, bulkActions: BulkAction[]) {
  if (segment && segment.filters_version === 'v2' && 'filter' in segment.filters) {
    return bulkActions;
  }
  return [];
}
