/* eslint-disable react-hooks/exhaustive-deps */
import { AsYouType } from 'libphonenumber-js';
import { useEffect } from 'react';
import { ChangeEvent, useState } from 'react';

import {
  BandwidthAuthParams,
  MailgunAuthParams,
  ProviderTypes,
  SinchAuthParams,
  TelnyxAuthParams,
  TwilioAuthParams,
  VonageAuthParams,
} from '@/shared/types/locations';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Button,
  Divider,
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio as Radio,
  Text,
  VStack,
} from '@/shared/ui';
import { toE164 } from '@/shared/utils/validations/validations';

import { useLocations } from '../context/LocationContext';
import { SelectProvider } from './SelectProvider';

export const PhoneNumberDetails = () => {
  const { updateLocation, locationsState } = useLocations();
  const { current } = locationsState;

  useEffect(() => {
    if (current && current.provider && current.phone) {
      setProvider(current.provider as ProviderTypes);
      setPhone(current.phone);
      setMessagesPerPeriod(current.delivery_options?.messages_per_period || 100);
      setPeriodInSeconds(current.delivery_options?.period || 30);

      if (current.provider_auth) {
        setProviderAuthType('custom');

        switch (current.provider) {
          case ProviderTypes.TELNYX:
            setTelnyxApiKey((current.provider_auth as TelnyxAuthParams)?.api_key);
            setTelnyxPublicKey((current.provider_auth as TelnyxAuthParams)?.public_key);
            return;
          case ProviderTypes.SINCH:
            setSinchAppId((current.provider_auth as SinchAuthParams)?.app_id);
            setSinchProjectId((current.provider_auth as SinchAuthParams)?.project_id);
            setSinchRegion((current.provider_auth as SinchAuthParams)?.region);
            setSinchUsername((current.provider_auth as SinchAuthParams)?.username);
            setSinchPassword((current.provider_auth as SinchAuthParams)?.password);
            setSinchWebhookSecret(
              (current.provider_auth as SinchAuthParams)?.webhook_secret
            );
            return;
          case ProviderTypes.TWILIO:
            setTwilioAccountSID((current.provider_auth as TwilioAuthParams)?.account_sid);
            setTwilioAuthToken((current.provider_auth as TwilioAuthParams)?.auth_token);
            return;
          case ProviderTypes.VONAGE:
            setVonageApplicationId(
              (current.provider_auth as VonageAuthParams)?.application_id
            );
            setVonagePrivateKey((current.provider_auth as VonageAuthParams)?.private_key);
            return;
          case ProviderTypes.BANDWIDTH:
            setBandwidthUsername(
              (current.provider_auth as BandwidthAuthParams)?.username
            );
            setBandwidthUserPassword(
              (current.provider_auth as BandwidthAuthParams)?.user_password
            );
            setBandwidthAccountId(
              (current.provider_auth as BandwidthAuthParams)?.account_id
            );
            setBandwidthApplicationId(
              (current.provider_auth as BandwidthAuthParams)?.application_id
            );
            setBandwidthSubAccountNumber(
              (current.provider_auth as BandwidthAuthParams)?.sub_account_number
            );
            setBandwidthLocationNumber(
              (current.provider_auth as BandwidthAuthParams)?.location_number
            );
            setBandwidthCallbackID(
              (current.provider_auth as BandwidthAuthParams)?.callback_id
            );
            setBandwidthCallbackPassword(
              (current.provider_auth as BandwidthAuthParams)?.callback_password
            );
            return;
          case ProviderTypes.MAILGUN:
            setMailgunBaseDomain(
              (current.provider_auth as MailgunAuthParams)?.base_domain
            );
            setMailgunApiKey((current.provider_auth as MailgunAuthParams)?.api_key);
            setMailgunWebhookSigningKey(
              (current.provider_auth as MailgunAuthParams)?.webhook_signing_key
            );
            return;
          default:
            return;
        }
      }
    }

    // on unmount
    return () => {
      setProvider(ProviderTypes.TELNYX);
      setPhone('');
      setProviderAuthType('default');
      setTelnyxApiKey('');
      setTwilioAccountSID('');
      setTwilioAuthToken('');
      setVonageApplicationId('');
      setVonagePrivateKey('');
      setMailgunBaseDomain('');
      setMailgunApiKey('');
      setMailgunWebhookSigningKey('');
    };
  }, [current]);

  // set auth provider telnyx, twilio, vonage
  const [provider, setProvider] = useState<ProviderTypes>(ProviderTypes.TELNYX);

  const [phone, setPhone] = useState('');

  // auth type set state default or custom
  const [providerAuthType, setProviderAuthType] = useState('default');

  /* Telnyx */

  // set Telnyx API key
  const [telnyxApiKey, setTelnyxApiKey] = useState('');
  const [telnyxPublicKey, setTelnyxPublicKey] = useState('');

  /* Sinch */

  // set Sinch APP ID
  const [sinchAppId, setSinchAppId] = useState('');
  // set Sinch PROJECT ID
  const [sinchProjectId, setSinchProjectId] = useState('');
  // set Sinch Region
  const [sinchRegion, setSinchRegion] = useState('');
  // set Sinch USERNAME
  const [sinchUsername, setSinchUsername] = useState('');
  // set Sinch PASSWORD
  const [sinchPassword, setSinchPassword] = useState('');
  // set Sinch WEBHOOK SECRET
  const [sinchWebhookSecret, setSinchWebhookSecret] = useState('');

  /* Twilio */

  // set Twilio Account SID
  const [twilioAccountSID, setTwilioAccountSID] = useState('');
  // set Twilio Auth Token
  const [twilioAuthToken, setTwilioAuthToken] = useState('');

  /* Vonage */

  // set Vonage API key
  const [vonageApplicationId, setVonageApplicationId] = useState('');
  // set Vonage API secret
  const [vonagePrivateKey, setVonagePrivateKey] = useState('');

  /* bandwidth */

  // set Bandwidth Username
  const [bandwidthUsername, setBandwidthUsername] = useState('');
  // set Bandwidth User Password
  const [bandwidthUserPassword, setBandwidthUserPassword] = useState('');
  // set Bandwidth Account Id
  const [bandwidthAccountId, setBandwidthAccountId] = useState('');
  // set Bandwidth Application Id
  const [bandwidthApplicationId, setBandwidthApplicationId] = useState('');
  // set Bandwidth Sub Account Number
  const [bandwidthSubAccountNumber, setBandwidthSubAccountNumber] = useState('');
  // set Bandwidth Location Number
  const [bandwidthLocationNumber, setBandwidthLocationNumber] = useState('');
  // set Bandwidth Callback user id
  const [bandwidthCallbackID, setBandwidthCallbackID] = useState('');
  // set Bandwidth Callback password
  const [bandwidthCallbackPassword, setBandwidthCallbackPassword] = useState('');

  // set Mailgun Base Domain
  const [mailgunBaseDomain, setMailgunBaseDomain] = useState('');
  // set Mailgun API key
  const [mailgunApiKey, setMailgunApiKey] = useState('');
  // set Mailgun Webhook signing key
  const [mailgunWebhookSigningKey, setMailgunWebhookSigningKey] = useState('');

  const isCustomProvider = providerAuthType === 'custom';

  // message per period
  const [messagesPerPeriod, setMessagesPerPeriod] = useState(
    current?.delivery_options?.messages_per_period || 100
  );
  // period in seconds
  const [periodInSeconds, setPeriodInSeconds] = useState(
    current?.delivery_options?.period || 30
  );

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    if (phone?.endsWith(')') && e.target.value.length === phone?.length - 1) {
      e.target.value = e.target.value.slice(0, -1);
    }
    setPhone(new AsYouType('US').input(e.target.value));
  };

  const getCustomAuthObject = (provider: ProviderTypes) => {
    switch (provider) {
      case ProviderTypes.TWILIO:
        return {
          account_sid: twilioAccountSID,
          auth_token: twilioAuthToken,
        };
      case ProviderTypes.VONAGE:
        return {
          application_id: vonageApplicationId,
          private_key: vonagePrivateKey,
        };
      case ProviderTypes.TELNYX:
        return {
          api_key: telnyxApiKey,
          public_key: telnyxPublicKey,
        };
      case ProviderTypes.SINCH:
        return {
          app_id: sinchAppId,
          project_id: sinchProjectId,
          region: sinchRegion,
          username: sinchUsername,
          password: sinchPassword,
          webhook_secret: sinchWebhookSecret,
        };
      case ProviderTypes.BANDWIDTH:
        return {
          username: bandwidthUsername,
          user_password: bandwidthUserPassword,
          account_id: bandwidthAccountId,
          application_id: bandwidthApplicationId,
          sub_account_number: bandwidthSubAccountNumber,
          location_number: bandwidthLocationNumber,
          callback_id: bandwidthCallbackID,
          callback_password: bandwidthCallbackPassword,
        };
      case ProviderTypes.MAILGUN:
        return {
          base_domain: mailgunBaseDomain,
          api_key: mailgunApiKey,
          webhook_signing_key: mailgunWebhookSigningKey,
        };
      default:
        return {
          api_key: telnyxApiKey,
          public_key: telnyxPublicKey,
        };
    }
  };

  const onSubmit = () => {
    if (current && current.id) {
      // if we are using a custom provider auth
      // then we need to send the auth object
      if (isCustomProvider) {
        updateLocation({
          ...current,
          phone: toE164(phone),
          provider,
          provider_auth: getCustomAuthObject(provider),
          delivery_options: {
            messages_per_period: messagesPerPeriod,
            period: periodInSeconds,
          },
        });
      } else {
        // if we are not using the default provider auth
        // then set the auth object to null
        updateLocation({
          ...current,
          phone: toE164(phone),
          provider,
          provider_auth: null,
          delivery_options: {
            messages_per_period: messagesPerPeriod,
            period: periodInSeconds,
          },
        });
      }
    }
  };

  return (
    <Accordion
      css={{ background: 'white' }}
      type="single"
      collapsible
      data-testid="phoneNumber-details"
    >
      <AccordionItem value="basic-information" variant="neumorphic">
        <Flex justify="between" align="center">
          <VStack gap={2} css={{ lineHeight: 1.5 }}>
            <Text css={{ fontWeight: 600 }}>Phone Number Configuration</Text>
            <Text>{'Control how this location sends SMS Messages'}</Text>
          </VStack>
          <AccordionTrigger />
        </Flex>
        <AccordionContent variant="neumorphic">
          <Divider css={{ mt: -20, mb: 20 }} />
          <VStack gap={2}>
            <Fieldset>
              <Label htmlFor="phone">Phone Number</Label>
              <Input
                id="phone"
                placeholder="(000) 000-0000"
                name="phone"
                value={phone}
                onChange={(e) => handleChangePhone(e)}
              />
            </Fieldset>
            <SelectProvider provider={provider} setProvider={setProvider} />
            <Fieldset>
              <Label>Select Authentication Type</Label>
              <RadioGroup value={providerAuthType} onValueChange={setProviderAuthType}>
                <Flex align="center" css={{ mt: 10 }}>
                  <Flex justify="center" align="center" css={{ mr: 10 }}>
                    <Radio value="default" data-testid="default-radio">
                      <RadioGroupIndicator />
                    </Radio>
                    <Label css={{ ml: 5, mb: 0 }}>Managed by Whippy</Label>
                  </Flex>
                  <HStack align="center">
                    <Radio value="custom" data-testid="custom-radio">
                      <RadioGroupIndicator />
                    </Radio>
                    <Label css={{ ml: 5, mb: 0 }}>Custom</Label>
                  </HStack>
                </Flex>
              </RadioGroup>
            </Fieldset>
            {isCustomProvider && provider === ProviderTypes.TELNYX && (
              <Fieldset>
                <Label htmlFor="api_key_telnyx">Telnyx API Key</Label>
                <Input
                  id="api_key_telnyx"
                  placeholder="Enter Telnyx API Key"
                  name="api_key"
                  value={telnyxApiKey}
                  onChange={(e) => setTelnyxApiKey(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.TELNYX && (
              <Fieldset>
                <Label htmlFor="public_key_telnyx">Telnyx Public Key</Label>
                <Input
                  id="public_key_telnyx"
                  placeholder="Enter Telnyx Public Key"
                  name="public_key"
                  value={telnyxPublicKey}
                  onChange={(e) => setTelnyxPublicKey(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.SINCH && (
              <Fieldset>
                <Label htmlFor="app_id_sinch">Sinch App Id</Label>
                <Input
                  id="app_id_sinch"
                  placeholder="Enter Sinch App Id"
                  name="app_id"
                  value={sinchAppId}
                  onChange={(e) => setSinchAppId(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.SINCH && (
              <Fieldset>
                <Label htmlFor="project_id_sinch">Sinch Project Id</Label>
                <Input
                  id="project_id_sinch"
                  placeholder="Enter Sinch Project Id"
                  name="project_id"
                  value={sinchProjectId}
                  onChange={(e) => setSinchProjectId(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.SINCH && (
              <Fieldset>
                <Label htmlFor="region_sinch">Sinch Region</Label>
                <Input
                  id="region_sinch"
                  placeholder="Enter Sinch Region"
                  name="region"
                  value={sinchRegion}
                  onChange={(e) => setSinchRegion(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.SINCH && (
              <Fieldset>
                <Label htmlFor="username_sinch">Sinch Username</Label>
                <Input
                  id="username_sinch"
                  placeholder="Enter Sinch Username"
                  name="username"
                  value={sinchUsername}
                  onChange={(e) => setSinchUsername(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.SINCH && (
              <Fieldset>
                <Label htmlFor="password_sinch">Sinch Password</Label>
                <Input
                  id="password_sinch"
                  placeholder="Enter Sinch Password"
                  name="password"
                  value={sinchPassword}
                  onChange={(e) => setSinchPassword(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.SINCH && (
              <Fieldset>
                <Label htmlFor="webhook_secret_sinch">Sinch Webhook Secret</Label>
                <Input
                  id="webhook_secret_sinch"
                  placeholder="Enter Sinch Webhook Secret"
                  name="webhook_secret"
                  value={sinchWebhookSecret}
                  onChange={(e) => setSinchWebhookSecret(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.TWILIO && (
              <Fieldset>
                <Label htmlFor="account_sid">Twilio Account SID</Label>
                <Input
                  id="account_sid"
                  placeholder="Enter Twilio Account SID"
                  name="account_sid"
                  value={twilioAccountSID}
                  onChange={(e) => setTwilioAccountSID(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.TWILIO && (
              <Fieldset>
                <Label htmlFor="tokens">Twilio Auth Token</Label>
                <Input
                  id="tokens"
                  placeholder="Enter Twilio Auth Token"
                  name="auth?.tokens"
                  value={twilioAuthToken}
                  onChange={(e) => setTwilioAuthToken(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.VONAGE && (
              <Fieldset>
                <Label htmlFor="api_key_vonage">Vonage Application ID</Label>
                <Input
                  id="api_key_vonage"
                  placeholder="Enter Vonage Application ID"
                  name="api_key"
                  value={vonageApplicationId}
                  onChange={(e) => setVonageApplicationId(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.VONAGE && (
              <Fieldset>
                <Label htmlFor="api_secret">Vonage Private Key</Label>
                <Input
                  id="api_secret"
                  placeholder="Enter Vonage Private Key"
                  name="api_secret"
                  value={vonagePrivateKey}
                  onChange={(e) => setVonagePrivateKey(e.target.value)}
                />
              </Fieldset>
            )}

            {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
              <Fieldset>
                <Label htmlFor="username">Bandwidth Username</Label>
                <Input
                  id="username"
                  placeholder="Enter Bandwidth Username"
                  name="username"
                  value={bandwidthUsername}
                  onChange={(e) => setBandwidthUsername(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
              <Fieldset>
                <Label htmlFor="user_password">Bandwidth Password</Label>
                <Input
                  id="user_password"
                  placeholder="Enter Bandwidth Password"
                  name="user_password"
                  value={bandwidthUserPassword}
                  onChange={(e) => setBandwidthUserPassword(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
              <Fieldset>
                <Label htmlFor="account_id">Bandwidth Account Id</Label>
                <Input
                  id="account_id"
                  placeholder="Enter Bandwidth Account Id"
                  name="account_id"
                  value={bandwidthAccountId}
                  onChange={(e) => setBandwidthAccountId(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
              <Fieldset>
                <Label htmlFor="application_id">Bandwidth Application Id</Label>
                <Input
                  id="application_id"
                  placeholder="Enter Bandwidth Application Id"
                  name="application_id"
                  value={bandwidthApplicationId}
                  onChange={(e) => setBandwidthApplicationId(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
              <Fieldset>
                <Label htmlFor="sub_account_number">Bandwidth Sub-Account Number</Label>
                <Input
                  id="sub_account_number"
                  placeholder="Enter Bandwidth Sub-Account Number"
                  name="sub_account_number"
                  value={bandwidthSubAccountNumber}
                  onChange={(e) => setBandwidthSubAccountNumber(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
              <Fieldset>
                <Label htmlFor="location_number">Bandwidth Location Number</Label>
                <Input
                  id="location_number"
                  placeholder="Enter Bandwidth Location Number"
                  name="location_number"
                  value={bandwidthLocationNumber}
                  onChange={(e) => setBandwidthLocationNumber(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
              <Fieldset>
                <Label htmlFor="callback_id">Bandwidth Callback ID</Label>
                <Input
                  id="callback_id"
                  placeholder="Enter Bandwidth Callback ID"
                  name="callback_id"
                  value={bandwidthCallbackID}
                  onChange={(e) => setBandwidthCallbackID(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
              <Fieldset>
                <Label htmlFor="callback_password">Bandwidth Callback Password</Label>
                <Input
                  id="callback_password"
                  placeholder="Enter Bandwidth Callback Password"
                  name="callback_password"
                  value={bandwidthCallbackPassword}
                  onChange={(e) => setBandwidthCallbackPassword(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.MAILGUN && (
              <Fieldset>
                <Label htmlFor="base_domain">Mailgun Base Domain</Label>
                <Input
                  id="base_domain"
                  placeholder="Enter Mailgun Base Domain"
                  name="base_domain"
                  value={mailgunBaseDomain}
                  onChange={(e) => setMailgunBaseDomain(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.MAILGUN && (
              <Fieldset>
                <Label htmlFor="api_key_mailgun">Mailgun API Key</Label>
                <Input
                  id="api_key_mailgun"
                  placeholder="Enter Mailgun API Key"
                  name="api_key"
                  value={mailgunApiKey}
                  onChange={(e) => setMailgunApiKey(e.target.value)}
                />
              </Fieldset>
            )}
            {isCustomProvider && provider === ProviderTypes.MAILGUN && (
              <Fieldset>
                <Label htmlFor="webhook_signing_key">Mailgun Webhook Signing Key</Label>
                <Input
                  id="webhook_signing_key"
                  placeholder="Enter Mailgun Webhook Signing Key"
                  name="webhook_signing_key"
                  value={mailgunWebhookSigningKey}
                  onChange={(e) => setMailgunWebhookSigningKey(e.target.value)}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Label htmlFor="messages_per_period">Messages Per Period</Label>
              <Input
                id="messages_per_period"
                placeholder="100"
                name="messages_per_period"
                type="number"
                value={messagesPerPeriod}
                onChange={(e) => setMessagesPerPeriod(Number(e.target.value))}
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="period_in_seconds">Period in Seconds</Label>
              <Input
                id="period_in_seconds"
                placeholder="30"
                name="period_in_seconds"
                type="number"
                value={periodInSeconds}
                onChange={(e) => setPeriodInSeconds(Number(e.target.value))}
              />
            </Fieldset>
            <Box>
              <Button onClick={onSubmit}>Update Location</Button>
            </Box>
          </VStack>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
