import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import * as API from '@/shared/api/ai';
import { DotsTyping } from '@/shared/components/Icons';
import { Box, Button, Flex, Text, VStack } from '@/shared/ui';
import { MONTH_DAY_YEAR } from '@/shared/utils/timestamps';
import { styled } from '@/stitches.config';

import { useReviews } from './context/ReviewContext';

type ReplyToReviewProps = {
  reviewId: string;
  googleLocationId: string;
  displayName: string;
  starRating: string;
  comment: string | undefined | null;
};

export const ReviewWriteResponseButton = (props: ReplyToReviewProps): JSX.Element => {
  const [showTextArea, setShowTextArea] = useState(false);
  const isToggledRef = useRef(showTextArea);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const reviewContext = useReviews();
  const { createResponse } = reviewContext;
  const { reviewId, googleLocationId, displayName, starRating, comment } = props;

  const toggle = useCallback(
    () => setShowTextArea(!isToggledRef.current),
    [isToggledRef, setShowTextArea]
  );

  useEffect(() => {
    isToggledRef.current = showTextArea;
  }, [showTextArea]);

  const toggleTextArea = async () => {
    setLoading(true);
    try {
      const params = {
        model: 'gpt-3.5-turbo',
        options: {
          frequency_penalty: 0.5,
          presence_penalty: 0.5,
          max_tokens: 700,
          temperature: 0.7,
          top_p: 1,
          n: 1,
        },
        messages: [
          {
            role: 'system',
            content:
              "You are an AI Google Review Response Writing assistant. You're here to help write a response to a review. Response to the review below, give a generic response if the reviewer left no comment. Don't start with Dear and dont use paceholders like [name] or [business name].",
          },
          {
            role: 'user',
            content:
              'From: ' +
              displayName +
              ', with star rating ' +
              starRating +
              ' stars' +
              ' and comment: ' +
              comment,
          },
        ],
      };

      const response = await API.createChatCompletion(params);

      setMessage(response.response);
      toggle();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toggle();
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const respondHandler = () => {
    toggle();
    createResponse({
      reviewId: reviewId,
      message,
      google_location_id: googleLocationId,
    });
    setMessage('');
  };

  return (
    <Box>
      {showTextArea ? (
        <VStack gap={2}>
          <Text variant="bold" size={3}>
            Response
          </Text>
          <Flex direction="column">
            <ReviewTextArea
              minRows={5}
              placeholder="Write a response..."
              value={message}
              onChange={onChangeHandler}
            />
            <ReviewTextAreaFooter align="center">
              Automated by Whippy
            </ReviewTextAreaFooter>
          </Flex>
          <Button onClick={respondHandler} disabled={message.length === 0}>
            Send Response
          </Button>
        </VStack>
      ) : (
        <VStack>
          <Button onClick={toggleTextArea}>
            {loading ? <DotsTyping /> : 'Draft Response'}
          </Button>
        </VStack>
      )}
    </Box>
  );
};

type ResponseProps = {
  comment: string;
  updateTime: string;
};

export function ReviewResponse(props: ResponseProps): JSX.Element {
  const { updateTime, comment } = props;

  return (
    <VStack gap={4}>
      <VStack gap={2}>
        <Flex justify="between">
          <Text variant="bold" size={3}>
            Response
          </Text>
          <Text variant="gray" size={2} css={{ fontWeight: '500' }}>
            {dayjs(updateTime).format(MONTH_DAY_YEAR)}
          </Text>
        </Flex>
        <Text variant="review">{comment}</Text>
      </VStack>
    </VStack>
  );
}

export const ReviewFooter = ({ children }: { children: React.ReactNode }) => {
  return <Box>{children}</Box>;
};

const ReviewTextArea = styled(TextareaAutosize, {
  // Reset
  borderBottomRightRadius: '0px !important',
  borderBottomLeftRadius: '0px !important',
  borderTopRightRadius: '$1',
  borderTopLeftRadius: '$1',
  resize: 'none',
  border: 'thin solid $colors$slate7',
  padding: '$2',
  lineHeight: 1.5,
  appearance: 'none',
  '-moz-appearance': 'none',
  '-webkit-appearance': 'none',
  marginBottom: 0,
  fontSize: 13,
  '@lg': {
    minHeight: 100,
  },
  '&:focus': {
    outline: 'none',
  },
});

const ReviewTextAreaFooter = styled(Flex, {
  borderBottomRightRadius: '$1',
  borderBottomLeftRadius: '$1',
  backgroundColor: '$gray2',
  fontSize: 10,
  fontWeight: 500,
  borderTopWidth: '0',
  border: 'thin solid $colors$slate7',
  margin: '0',
  outline: 'none',
  p: '$1',
  paddingBottom: '7px',
  width: '100%',
  color: '$gray11',
});
