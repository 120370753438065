/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { useDeveloperContext } from '@/pages/developer/context/DevelopersContext';
import * as Util from '@/pages/integrations/utils';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import * as API from '@/shared/api/integrations';
import {
  FormFieldWrapper,
  SelectCombobox,
  TextAreaInput,
  TextInput,
} from '@/shared/components/forms';
import {
  isSendToMessageParams,
  JobBoardAutomationData,
} from '@/shared/types/integrations';
import { Button, Flex, HStack } from '@/shared/ui';

import { useIntegrationConfigurations } from '../../context/IntegrationConfigurationContext';

type FormValues = {
  from_number: string;
  outbound_body: string;
  outbound_attachment_url?: string;
  delay_in_seconds: number;
  forwarding_email: string;
  api_key: string;
};

export const SendMessageForm = (props: {
  data: JobBoardAutomationData;
  onClose: () => void;
}) => {
  const { locationsState } = useLocations();
  const { locations } = locationsState;
  const { developerState, getApiKeys } = useDeveloperContext();
  const { apiKeys } = developerState;

  const { configurationsState, setCurrentConfiguration } = useIntegrationConfigurations();
  const { current } = configurationsState;

  const apiKeyOptions =
    apiKeys && apiKeys.length > 0
      ? apiKeys.map((apiKey) => ({
          // display the name
          type: apiKey?.name as string,
          // capture the key as the value
          value: apiKey?.key as string,
        }))
      : [];

  useEffect(() => {
    getApiKeys();
  }, []);

  // mapping the locations into the data structure that is needed for combobox
  const phoneNumberOptions =
    locations && locations.length > 0
      ? locations.map((location) => ({
          value: location.phone as string,
          type: location.phone as string,
        }))
      : [];

  const requiredFields = {
    forwarding_email: Yup.string().email().required('Required'),
    api_key: Yup.string().uuid().required('Required'),
  };

  const schema = Yup.object({
    from_number: Yup.string().required('Required'),
    outbound_body: Yup.string().required('Required'),
    delay_in_seconds: Yup.number().required('Required'),
    outbound_attachment_url: Yup.string().url(),
    ...requiredFields,
  });

  if (!isSendToMessageParams(props.data.action_params)) {
    return <div></div>;
  }

  const initialFormValues = {
    from_number: props.data.action_params.from_number,
    outbound_body: props.data.action_params.outbound_body,
    outbound_attachment_url: props.data.action_params.outbound_attachment_url,
    delay_in_seconds: props.data.action_params.delay_in_seconds,
    // required fields for all Job Board Automations
    forwarding_email: props.data.forwarding_email,
    api_key: props.data.api_key,
  } as FormValues;

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={schema}
      onSubmit={(values) => {
        const updatedConfig = Util.updatedConfiguration(current, values);
        if (!updatedConfig) return;
        API.updateConfiguration(updatedConfig)
          .then(() => {
            toast.success('Configuration Updated');
            setCurrentConfiguration(updatedConfig);
          })
          .catch(() => {
            toast.error('Error updating configuration');
          })
          .finally(() => {
            props.onClose();
          });
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Flex justify="between">
            <FormFieldWrapper
              name="from_number"
              tooltip="The number within your organization that you want the message to originate from"
              label="From Number"
            >
              <SelectCombobox
                key={`${phoneNumberOptions.length}+${formik.values.from_number}`}
                placeholder={formik.values.from_number}
                isDropdown={true}
                options={phoneNumberOptions}
                closeOnClick={true}
              />
            </FormFieldWrapper>
          </Flex>
          <Flex justify="between">
            <FormFieldWrapper
              name="forwarding_email"
              tooltip="The email address you will forward job board notifications from"
              label="Email Address"
            >
              <TextInput name="forwarding_email" placeholder="john.doe@example.com" />
            </FormFieldWrapper>
          </Flex>
          <Flex justify="between">
            <FormFieldWrapper
              name="outbound_body"
              tooltip="The message you would like to send to each recipient on "
              label="Message"
            >
              <TextAreaInput
                name="outbound_body"
                placeholder="Hello and thank you for taking the time to apply!"
              />
            </FormFieldWrapper>
          </Flex>
          <Flex justify="between">
            <FormFieldWrapper
              name="outbound_attachment_url"
              tooltip="An optional attachment you can include when creating a lead."
              label="Attachment (Optional)"
            >
              <TextInput
                name="outbound_attachment_url"
                placeholder="www.images.com/logo.jpg"
              />
            </FormFieldWrapper>
          </Flex>
          <Flex justify="between">
            <FormFieldWrapper
              name="delay_in_seconds"
              tooltip="The amount of delay you want to add to message before it sends"
              label="Delay in Seconds"
            >
              <TextInput name="delay_in_seconds" placeholder="180" />
            </FormFieldWrapper>
          </Flex>
          <Flex justify="between">
            <FormFieldWrapper
              name="api_key"
              tooltip="The api key to use."
              label="API Key"
            >
              <SelectCombobox
                key={`${apiKeyOptions.length}+${formik.values.api_key}`}
                placeholder={formik.values.api_key}
                isDropdown={true}
                options={apiKeyOptions}
                closeOnClick={true}
              />
            </FormFieldWrapper>
          </Flex>
          <Flex justify="end">
            <HStack>
              <Button type="submit" disabled={!formik.dirty}>
                Submit Changes
              </Button>
            </HStack>
          </Flex>
        </form>
      )}
    </Formik>
  );
};
