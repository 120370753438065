import { forwardRef, RefObject } from 'react';

import {
  Box,
  Button,
  IconButton,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';

import { USE_ICON_BUTTONS_AT } from './v2/index';

type EditorToolbarButtonProps = {
  /* The width of the editor/screen */
  width: number;
  /* The copy for the button */
  buttonCopy: string;
  /* The icon for the button */
  buttonIcon: JSX.Element;
  /* The intercom id for the button */
  intercomId?: string;
  /* The callback for when the button is clicked */
  onClick?: () => void;
  /* The ref for the button */
  ref?: RefObject<HTMLDivElement>;
};

const EditorToolbarButton = forwardRef<HTMLDivElement, EditorToolbarButtonProps>(
  (props, ref) => {
    const handleClick = () => {
      if (props.onClick) {
        props.onClick();
      }
    };

    return (
      <Box ref={ref}>
        {props.width < USE_ICON_BUTTONS_AT ? (
          <Tooltip>
            <TooltipTrigger asChild>
              <IconButton
                size={2}
                onClick={() => handleClick()}
                type="button"
                data-intercom-target={props.intercomId}
              >
                {props.buttonIcon}
              </IconButton>
            </TooltipTrigger>
            <TooltipContent side="top">
              {props.buttonCopy}
              <TooltipArrow />
            </TooltipContent>
          </Tooltip>
        ) : (
          <Button
            size={2}
            variant="gray"
            ghost={true}
            onClick={() => handleClick()}
            type="button"
            data-intercom-target={props.intercomId}
          >
            {props.buttonIcon}
            {props.buttonCopy}
          </Button>
        )}
      </Box>
    );
  }
);

export { EditorToolbarButton };
