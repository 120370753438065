/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { useEffect, useState } from 'react';
import React, { useMemo } from 'react';

import { Table } from '@/shared/components/Table';
import { Team } from '@/shared/types/team';
import { Box, Flex, Skeleton, Td, Tr, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useTeams } from './context/TeamsContext';
import { TeamMembersAvatars } from './TeamMembersAvatars';
import { TeamNavigationButton } from './TeamNavigationButton';

const TeamsTable = () => {
  const {
    getTeams,
    teamsState: { teams, totalCount, loading },
  } = useTeams();

  const limit = 50;
  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    getTeams({ offset, limit }, searchValue);
  }, [offset, searchValue]);

  const data = useMemo(() => (loading ? Array(5).fill({}) : teams), [loading, teams]);

  const columns = useMemo(
    () =>
      loading
        ? [
            {
              Header: 'Teams',
              colWidth: '55%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', my: 0 }} />
                </>
              ),
            },
            {
              Header: 'Members',
              colWidth: '40%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', my: 0 }} />
                </>
              ),
            },
            {
              Header: (): null => null,
              disableSortBy: true,
              id: 'expander',
              Cell: <Skeleton variant="icon" css={{ my: 0 }} />,
            },
          ]
        : [
            {
              Header: 'Teams',
              colWidth: '55%',
              accessor: 'name',
              Cell: (props: { row: { original: Team } }) => (
                <Flex direction="column">
                  <TeamTitle>{props.row.original.name}</TeamTitle>
                </Flex>
              ),
            },
            {
              Header: 'Members',
              colWidth: '40%',
              accessor: 'team_members',
              Cell: (props: { row: { original: Team } }) => (
                <TeamMembers direction="row">
                  <TeamMembersAvatars
                    members={props.row.original.team_members || []}
                    limit={4}
                  />
                </TeamMembers>
              ),
            },
            {
              Header: '',
              colWidth: '5%',
              accessor: 'action',
              Cell: (props: { row: { original: Team } }) => (
                <Box css={{ position: 'relative' }}>
                  <TeamNavigationButton team={props.row.original} />
                </Box>
              ),
            },
          ],
    [loading]
  );

  return (
    <Table
      data={data}
      columns={columns}
      sortable={true}
      caption="Teams Table"
      searchFilter={true}
      searchValue={searchValue}
      setSearchValue={handleSearch}
      limit={limit}
      offset={offset}
      setOffset={setOffset}
      totalCount={totalCount}
      empty={
        <Tr>
          <Td
            colSpan={columns.length}
            css={{ textAlign: 'center', backgroundColor: 'white' }}
          >
            <VStack gap={2}>
              <Box css={{ padding: '6.5px 0' }}>No teams were found.</Box>
            </VStack>
          </Td>
        </Tr>
      }
    />
  );
};

const TeamTitle = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 200,
  textOverflow: 'ellipsis',
});

const TeamMembers = styled(Flex, {
  maxWidth: 200,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  ml: '10px',
});

export default TeamsTable;
