import React from 'react';
import {
  HiEye,
  // HiLightBulb,
  HiQuestionMarkCircle,
  HiUserGroup,
  HiUserRemove,
} from 'react-icons/hi';

import {
  Toolbar,
  ToolbarButton,
} from '@/pages/inbox/conversation/panels/ConversationToolbar';
import { PanelType } from '@/shared/types/campaigns';
import { VStack } from '@/shared/ui';

type CampaignToolbarProps = {
  /* handle campaign side panel toolbar click */
  handlePanelClick: (panel: PanelType) => void;
  /* hide view audience button */
  hideViewAudience?: boolean;
  /* hide view excluded audience button */
  hideViewExcludedAudience?: boolean;
};

export const CampaignToolbar = (props: CampaignToolbarProps) => {
  return (
    <Toolbar>
      <VStack gap={4}>
        {!props.hideViewAudience && (
          <ToolbarButton
            description="View Audience"
            onClick={() => props.handlePanelClick(PanelType.VIEW_AUDIENCE)}
          >
            <HiUserGroup size={18} />
          </ToolbarButton>
        )}
        {!props.hideViewExcludedAudience && (
          <ToolbarButton
            description="View Excluded Audience"
            onClick={() => props.handlePanelClick(PanelType.VIEW_EXCLUDED_AUDIENCE)}
          >
            <HiUserRemove size={18} />
          </ToolbarButton>
        )}
        <ToolbarButton
          description="View Message Preview"
          onClick={() => props.handlePanelClick(PanelType.VIEW_PREVIEW)}
        >
          <HiEye size={18} />
        </ToolbarButton>
        <ToolbarButton
          description="View Help"
          onClick={() => props.handlePanelClick(PanelType.VIEW_HELP)}
        >
          <HiQuestionMarkCircle size={18} />
        </ToolbarButton>
      </VStack>
    </Toolbar>
  );
};
