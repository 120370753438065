/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { createContext, useContext, useReducer } from 'react';

import * as API from '@/shared/api/auth';
import * as APIOrganization from '@/shared/api/organization';
import { Organization } from '@/shared/types';
import { getOrganizationId } from '@/shared/utils/storage';

import SettingsReducer from './SettingsReducer';
import { OptOutOptions } from './types';
import { SettingsActionType } from './types';

export type SettingsState = {
  organizationName: string;
  organizationAttachment: string;
  selectedIndustries: [];
  settings: {
    contact_opt_out_default: OptOutOptions;
    default_template_id?: string | null;
    link_tracking: {
      default_domain_id: string | null;
      enabled: boolean;
      track_click_metadata: boolean;
    };
  };
  loading: boolean;
  error: Error | null;
};

export const initialSettingsState: SettingsState = {
  organizationName: '',
  organizationAttachment: '',
  selectedIndustries: [],
  settings: {
    contact_opt_out_default: OptOutOptions.LOCATION,
    default_template_id: null,
    link_tracking: {
      default_domain_id: null,
      enabled: true,
      track_click_metadata: true,
    },
  },
  error: null,
  loading: true,
};

export const SettingsContext = createContext<{
  settingsState: SettingsState;
  getOrganizationInfo: () => void;
  updateSettings: (settings: any) => Promise<void | Organization>;
}>({
  settingsState: initialSettingsState,
  getOrganizationInfo: () => Promise.resolve(),
  updateSettings: () => Promise.resolve(),
});

export const useSettings = () => useContext(SettingsContext);

const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const [settingsState, dispatch] = useReducer(SettingsReducer, initialSettingsState);

  const getOrganizationInfo = async () => {
    try {
      const data = await API.fetchOrganizationsInfo();

      dispatch({
        type: SettingsActionType.GET_ORGANIZATION_INFO,
        payload: data.organization,
      });
      return data;
    } catch (error) {
      dispatch({ type: SettingsActionType.SETTINGS_ERROR, payload: error });
    }
  };

  const updateSettings = async (settings: any) => {
    try {
      const organizationId = getOrganizationId();
      const { data } = await APIOrganization.updateOrganization(organizationId, settings);

      dispatch({
        type: SettingsActionType.UPDATE_SETTINGS,
        payload: data,
      });
      return data;
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: SettingsActionType.SETTINGS_ERROR,
          payload: err.response.msg,
        });
      }
      throw err;
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        settingsState,
        getOrganizationInfo,
        updateSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
