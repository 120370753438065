import dayjs from 'dayjs';
import React from 'react'; // for unit test
import { toast } from 'sonner';

import { pauseLocations, pauseLocationsParams } from '@/shared/api/contacts/v1';
import { Location } from '@/shared/types/locations';
import {
  Button,
  Dialog,
  DialogContent,
  DialogPortal,
  DialogTitle,
  Flex,
  HStack,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio as Radio,
  StyledRadioIndicator,
  Text,
  VStack,
} from '@/shared/ui';

export type PauseConfirmationDialogPropsType = {
  /** Whether the Pause Confirmation dialog is open or not **/
  isPauseDialogOpen: boolean;
  /** Function to set whether the Pause Confirmation dialog is open or not **/
  setIsPauseDialogOpen: (value: boolean) => void;
  /** The selected pause duration **/
  selectedPauseDuration: string;
  /** The selected customized date **/
  selectedCustomizedDate: string;
  /** Whether pause -> "custom" is selected **/
  isCustomSelected: boolean;
  /** Whether the date picker dialog is open or not */
  isDatePickerDialogOpen: boolean;
  /** Function to set whether the Date Picker dialog is open or not **/
  setIsDatePickerDialogOpen: (value: boolean) => void;
  /** The selected active locations **/
  selectedActiveLocations: Array<Location>;
  /** The contact id of the current contact **/
  contactId: string;
  /** Function to close the entire dialogs **/
  onClose: () => void;
  /** Function to set the selected pause duration **/
  setSelectedPauseDuration: (value: string) => void;
  /** Function to set whether pause -> "custom" is selected **/
  setIsCustomSelected: (value: boolean) => void;
};

const PauseConfirmationDialog = (
  props: PauseConfirmationDialogPropsType
): JSX.Element => {
  const {
    isPauseDialogOpen,
    setIsPauseDialogOpen,
    selectedPauseDuration,
    selectedCustomizedDate,
    isCustomSelected,
    isDatePickerDialogOpen,
    setIsDatePickerDialogOpen,
    selectedActiveLocations,
    contactId,
    onClose,
    setSelectedPauseDuration,
    setIsCustomSelected,
  } = props;

  const pauseTimeOptions = ['1 week', '1 month', 'Forever'];

  // handler when users select a pause duration
  const handleSelectPauseDuration = (selection: string) => {
    if (selection !== 'Custom') {
      setSelectedPauseDuration(selection);
      setIsCustomSelected(false);
    } else {
      setSelectedPauseDuration(selection);
      setIsCustomSelected(true);
    }
  };

  // when user hit the "Confirm" button in the Pause Confirmation dialog
  const handlePauseConfirm = async () => {
    if (selectedActiveLocations && selectedActiveLocations.length > 0) {
      let calculatedPauseDurationString = '';
      if (selectedPauseDuration === '1 week') {
        calculatedPauseDurationString = dayjs().add(1, 'week').format('YYYY-MM-DD');
      } else if (selectedPauseDuration === '1 month') {
        calculatedPauseDurationString = dayjs().add(1, 'month').format('YYYY-MM-DD');
      } else if (selectedPauseDuration === 'Forever') {
        // if the user select "Forever", then set the preference.pause-until date to be 2099-12-31
        calculatedPauseDurationString = dayjs('2099-12-31').format('YYYY-MM-DD');
      } else if (selectedPauseDuration === 'Custom') {
        // if the user select "Custom", then set the preference.pause-until date to be the selected date
        calculatedPauseDurationString = dayjs(
          selectedCustomizedDate,
          'MM/DD/YYYY'
        ).format('YYYY-MM-DD');
      }
      try {
        const apiCallParams: pauseLocationsParams = {
          // audience.contacts is an array of contact ids (here there's only 1 contact)
          audience: {
            contacts: [contactId],
          },
          // date is the calculated pause-until date
          date: calculatedPauseDurationString,
          // all active AND selected locations
          location_ids: selectedActiveLocations.map((location) => location.id),
        };
        await pauseLocations(apiCallParams);
        toast.success('Pause locations successful.');
      } catch (error) {
        toast.error('Pause locations unsuccessful.');
      }
    } else {
      toast.error('Please select active locations to pause.');
    }
    setIsPauseDialogOpen(false);
    onClose();
  };

  // when user hit the "Cancel" button in the Pause Confirmation dialog
  const handlePauseCancel = () => {
    setIsPauseDialogOpen(false);
  };

  return (
    <Dialog open={isPauseDialogOpen} modal={false}>
      <DialogPortal>
        <DialogContent
          onEscapeKeyDown={() => setIsPauseDialogOpen(false)}
          css={{ zIndex: 100000, filter: isDatePickerDialogOpen ? 'blur(1px)' : 'none' }}
        >
          <DialogTitle>Pause Confirmation</DialogTitle>
          <Text css={{ pb: '20px' }}>
            Select the duration for which you want to pause the selected active locations:
          </Text>
          <RadioGroup
            value={selectedPauseDuration}
            onValueChange={handleSelectPauseDuration}
          >
            <VStack gap={2}>
              {pauseTimeOptions.map((option) => (
                <StyledRadioIndicator align="center" key={option}>
                  <Radio value={option}>
                    <RadioGroupIndicator />
                  </Radio>
                  <Label css={{ mb: 0, ml: '8px', fontSize: 12 }}>{option}</Label>
                </StyledRadioIndicator>
              ))}
              <StyledRadioIndicator align="center" key="Custom">
                <Radio value="Custom">
                  <RadioGroupIndicator />
                </Radio>
                <Label css={{ mb: 0, ml: '8px', flexGrow: 1, fontSize: 12 }}>
                  Custom (Pause Until {selectedCustomizedDate})
                </Label>
                <Button
                  disabled={!isCustomSelected}
                  onClick={() => setIsDatePickerDialogOpen(true)}
                  variant="gray"
                >
                  Select Date
                </Button>
              </StyledRadioIndicator>
            </VStack>
          </RadioGroup>

          <Flex justify="end" css={{ pt: 20 }}>
            <HStack>
              <Button variant="gray" onClick={handlePauseCancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handlePauseConfirm}>
                Confirm
              </Button>
            </HStack>
          </Flex>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default PauseConfirmationDialog;
