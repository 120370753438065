import * as SelectPrimitive from '@radix-ui/react-select';
import { Formik } from 'formik';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { HiChevronDown, HiChevronUp, HiX } from 'react-icons/hi';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import {
  FormFieldWrapper,
  FormMessageEditorV2,
  TextInput,
} from '@/shared/components/forms';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useDisclosure } from '@/shared/hooks';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';

import { useLocations } from '../locations/context/LocationContext';
import { messageTemplatePermissions } from './constants';
import { useTemplates } from './context/TemplatesContext';

type AddTemplateProps = {
  isInbox?: boolean;
  /* is the template editor open  in the inbox */
  setShowTemplateEditor?: Dispatch<SetStateAction<boolean>>;
};

export const AddTemplate = (props: AddTemplateProps): JSX.Element => {
  const { isInbox, setShowTemplateEditor } = props;
  const templatesContext = useTemplates();
  const { createTemplate } = templatesContext;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const authContext = useAuth();
  const { isAdmin } = authContext;

  const locationContext = useLocations();
  const { locations, allLocations } = locationContext.locationsState;
  const [permissionValue, setPermissionValue] = useState('organization');
  const [selectedLocations, setSelectedLocations] = useState({ locations: [] });

  // disable submit btn if location permission is selected without picking location/s
  const shouldDisableSubmitBtn =
    permissionValue === 'location' && selectedLocations.locations.length < 1;

  const handleOpen = () => {
    onOpen();
    // we need this because if an attachment is added to the editor in the template editor
    // and we are in the inbox, the attachment gets added to the inbox editor and not the template editor
    setShowTemplateEditor ? setShowTemplateEditor(true) : null;
  };

  const handleClose = () => {
    setPermissionValue('organization');
    setShowTemplateEditor ? setShowTemplateEditor(false) : null;
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        <Button onClick={() => handleOpen()} data-intercom-target="add-template-button">
          Add Template
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay css={{ zIndex: isInbox ? 9999 : undefined }}>
          <DialogContent
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
            onPointerDownOutside={() => handleClose()}
            onEscapeKeyDown={() => handleClose()}
            css={{ zIndex: isInbox ? 999998 : 99998 }}
          >
            <DialogHeader title="Create a Template" />
            <Formik
              initialValues={{
                title: '',
                message: { body: '', attachment_urls: [] },
              }}
              validationSchema={Yup.object({
                title: Yup.string().test(
                  'len',
                  'Must be at least 3 characters',
                  (val) => val !== undefined && val.length > 2
                ),
              })}
              onSubmit={async (values: {
                title: string;
                message: {
                  body: string;
                  attachment_urls: string[];
                };
              }) => {
                const params = {
                  title: values.title,
                  message: values.message.body,
                  attachments: values.message.attachment_urls,
                  access_level: permissionValue,
                };
                if (permissionValue === 'location') {
                  Object.assign(params, {
                    location_ids: selectedLocations.locations,
                  });
                }
                try {
                  if (params.message.length < 1 && params.attachments.length < 1) {
                    return toast.error(
                      `You must add at least one attachment or a message`
                    );
                  }
                  createTemplate(params);

                  handleClose();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <FormFieldWrapper
                    label="Template Name"
                    tooltip="The name of your template."
                    name="title"
                  >
                    <TextInput placeholder="Example: New Customer Question" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    label="Template Message"
                    tooltip="The message of your template."
                    name="message"
                  >
                    <FormMessageEditorV2
                      field="message"
                      placeholder="E.g. We are open from..."
                      showAddAttachment={true}
                      enableAttachments={true}
                      showCharacterCount={true}
                      showAddEmoji={true}
                      showAddReviewLink={true}
                      showAddVariable={true}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Permission" name="permissions">
                    <Select
                      value={permissionValue}
                      onValueChange={(e) => setPermissionValue(e)}
                    >
                      <SelectTrigger aria-label="option-select-trigger">
                        <SelectValue />
                        <SelectIcon>
                          <HiChevronDown />
                        </SelectIcon>
                      </SelectTrigger>
                      <SelectPrimitive.Portal>
                        <SelectContent css={{ zIndex: 9999999 }}>
                          <SelectScrollUpButton>
                            <HiChevronUp />
                          </SelectScrollUpButton>
                          <SelectViewport>
                            <SelectGroup>
                              <SelectLabel>Permission</SelectLabel>
                              {messageTemplatePermissions.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  <SelectItemIndicator />
                                  <SelectItemText>{option.label}</SelectItemText>
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectViewport>
                        </SelectContent>
                      </SelectPrimitive.Portal>
                    </Select>
                  </FormFieldWrapper>
                  {permissionValue === 'location' && (
                    <FormFieldWrapper label="Locations" name="locations">
                      {locations.length > 0 && (
                        <MultiSelect
                          defaultPlaceholder="Locations"
                          defaultSelectedItems={[]}
                          isDropdown={true}
                          options={
                            isAdmin
                              ? allLocations.map((location: any) => ({
                                  type: location?.name || '',
                                  value: location.id,
                                }))
                              : locations.map((location: any) => ({
                                  type: location?.name || '',
                                  value: location.id,
                                }))
                          }
                          parentSelectedItems={selectedLocations}
                          setParentSelectedItems={setSelectedLocations}
                          isCampaigns={true}
                        />
                      )}
                    </FormFieldWrapper>
                  )}
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button
                        variant="gray"
                        css={{ mr: '$1' }}
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button type="submit" disabled={shouldDisableSubmitBtn}>
                        Save Template
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={() => handleClose()} size="2">
                <HiX style={{ color: 'white' }} />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
