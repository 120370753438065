import { ErrorBoundary } from '@sentry/react';
import { styled } from '@stitches/react';
import i18next from 'i18next';
import React from 'react';
import { HiStatusOffline } from 'react-icons/hi';
import { toast } from 'sonner';

import { Text } from '@/shared/ui';

// Fallback Component to be rendered in case of an error
const LocationsTableFallback = () => (
  <ErrorTableContainer>
    <HiStatusOffline color="red" />
    <Text css={{ pt: 10 }}>There is an error with showing the locations table.</Text>
  </ErrorTableContainer>
);

type ErrorBoundaryProps = {
  feature: string;
  children: React.ReactNode;
};

export const LocationsTableErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  feature,
  children,
}) => {
  // need to have a feature signal
  if (!feature || feature.length < 1) return null;
  // need to have children to render
  if (!children) return null;

  const localizationError = `${feature}_error`;
  const errorTag = `unsubscribePreference.${feature}`;

  return (
    <ErrorBoundary
      fallback={<LocationsTableFallback />}
      beforeCapture={(scope) => {
        toast.error(
          (i18next.t(localizationError) as string) ||
            'An error occurred in showing the locations table.'
        );
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};

const ErrorTableContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  textAlign: 'center',
  color: '$gray5',
});
