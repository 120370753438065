import { useHistory } from 'react-router-dom';

import { Integration, IntegrationApplicationKeys } from '@/shared/types/integrations';
import { Button } from '@/shared/ui';

import { IntegrationProvider } from '../IntegrationProvider';
import { IntegrationConfigurationEditor } from './ConfigurationEditor';
import { IntegrationConfigurations as ConfigurationList } from './IntegrationConfigurations';

const Configurations = () => {
  return (
    <IntegrationProvider>
      <ConfigurationList />
    </IntegrationProvider>
  );
};

type RenderConfigurationProps = {
  integration: Integration;
};

const RenderConfiguration = (props: RenderConfigurationProps) => {
  const { integration } = props;
  const history = useHistory();

  // Opens Intercom for integrations that are not yet supported
  // Or that require additional configuration before installation
  const openIntercom = () => {
    window.Intercom('show');
  };

  switch (integration.key) {
    case IntegrationApplicationKeys.PANDO:
    case IntegrationApplicationKeys.INDEED:
    case IntegrationApplicationKeys.DATA_FRENZY:
    case IntegrationApplicationKeys.ACQUIRE_ROI:
    case IntegrationApplicationKeys.CAREER_BUILDER:
    case IntegrationApplicationKeys.JOB_ADDER:
    case IntegrationApplicationKeys.JOB_SPARX:
    case IntegrationApplicationKeys.ZIP_RECRUITER:
    case IntegrationApplicationKeys.JAZZ_HR:
    case IntegrationApplicationKeys.ZOOM_VOICEMAIL:
      return (
        <>
          {' '}
          <Button
            onClick={() => {
              history.push(`/integrations/${integration.id}/configurations`);
            }}
            variant="gray"
          >
            Edit
          </Button>
          <IntegrationConfigurationEditor applicationId={integration.id}>
            <Button>Install</Button>
          </IntegrationConfigurationEditor>
        </>
      );
    case IntegrationApplicationKeys.CHROME:
      return (
        <LinkToConfiguration url="https://chrome.google.com/webstore/detail/whippy-all-in-one-communi/fdniocipdjcdaedhpbjbpjjpbbhijmde" />
      );
    case IntegrationApplicationKeys.EDGE:
      return (
        <LinkToConfiguration url="https://chrome.google.com/webstore/detail/whippy-all-in-one-communi/fdniocipdjcdaedhpbjbpjjpbbhijmde" />
      );
    case IntegrationApplicationKeys.ZAPIER:
      return <LinkToConfiguration url="https://zapier.com/apps/whippy/integrations" />;
    case IntegrationApplicationKeys.AIRTABLE:
      return (
        <LinkToConfiguration url="https://airtable.com/marketplace/blkvsUTFN9QtWcOvm/whippy-ai" />
      );
    default:
      return <Button onClick={openIntercom}>Contact Support to Install</Button>;
  }
};

const LinkToConfiguration = ({ url }: { url: string }) => {
  return (
    <Button
      onClick={() => {
        window.open(url, '_blank');
      }}
    >
      Install
    </Button>
  );
};

export { Configurations, RenderConfiguration };
