import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { ConversationMessageType, ConversationType } from '@/shared/types/conversations';
import { Location } from '@/shared/types/locations';
import { User } from '@/shared/types/users';

export const onPdfExport = (
  current: ConversationType,
  messages: ConversationMessageType[],
  users: Array<User>,
  locations: Array<Location>
) => {
  const doc = new jsPDF({
    orientation: 'l',
    unit: 'px',
  });

  // filter to get only messages
  const conversation =
    messages
      .map((message) => (message.body ? message : null))
      .filter((msg) => msg !== null) || [];

  const getUserEmailById = (userId: number | undefined) => {
    if (!userId) {
      return '';
    }
    const user = users.find((user) => user.id === userId);
    if (user) {
      return user.email;
    }

    return '';
  };

  const getLocationPhoneById = (locationId: string | null) => {
    if (!locationId) {
      return '';
    }
    const location = locations.find((l) => l.id === locationId);
    if (location) {
      return location.phone;
    }

    return '';
  };

  autoTable(doc, { html: '#pdf-table' });

  autoTable(doc, {
    // configure columns names
    styles: {
      cellPadding: 3,
      fontSize: 10,
      minCellWidth: 70,
    },
    head: [
      [
        'Direction',
        'Message',
        'Contact Phone',
        'Contact Name',
        'Outbound Phone',
        'From User Email',
        'Date',
      ],
    ],
    body: conversation.map((msg) => {
      // configure each row
      const direction = `${msg?.source_type}`;
      const message = `${msg?.body}`;
      const contact_name = `${current.contact.name || ''}`;
      const contact_phone = `${current.contact.phone}`;
      const outbound_phone = `${getLocationPhoneById(current.location_id)}`;
      const from_email = `${getUserEmailById(Number(msg?.user_id))}`;
      const date = `${dayjs(msg?.inserted_at).format('M/D/YYYY HH:mm')}`;

      return [
        direction,
        message,
        contact_phone,
        contact_name,
        outbound_phone,
        from_email,
        date,
      ];
    }),
  });
  doc.save(`conversation-${current.id}.pdf`);
};

export const onCsvExport = (
  current: ConversationType,
  messages: ConversationMessageType[],
  users: Array<User>,
  locations: Array<Location>
) => {
  // filter to get only messages
  const conversation =
    messages
      .map((message) => (message.body ? message : null))
      .filter((msg) => msg !== null) || [];

  const getUserEmailById = (userId: number | undefined) => {
    if (!userId) {
      return '';
    }
    const user = users.find((user) => user.id === userId);
    if (user) {
      return user.email;
    }

    return '';
  };

  const getLocationPhoneById = (locationId: string | null) => {
    if (!locationId) {
      return '';
    }
    const location = locations.find((l) => l.id === locationId);
    if (location) {
      return location.phone;
    }

    return '';
  };

  const exportedData = conversation.map((msg) => {
    const direction = `${msg?.source_type}`;
    const message = `${msg?.body}`;
    const contact_name = `${current.contact.name || ''}`;
    const contact_phone = `${current.contact.phone}`;
    const outbound_phone = `${getLocationPhoneById(current.location_id)}`;
    const from_email = `${getUserEmailById(Number(msg?.user_id))}`;
    const date = `${dayjs(msg?.inserted_at).format('M/D/YYYY HH:mm')}`;
    // const campaign_id = `${msg?.event?.metadata?.campaign_id || ''}`;
    const error = `${msg?.error || ''}`;

    return {
      direction,
      message,
      contact_phone,
      contact_name,
      outbound_phone,
      from_email,
      date,
      // campaign_id,
      error,
    };
  });

  return exportedData;
};
