import { FilterParams } from '@/shared/types';

import { api } from '../api';
import { CreateTeamParams, CreateWorkSchedulesParams, UpdateTeamParams } from './types';

/*
  GET - get team list
*/
export const getTeams = async (
  params?: { offset?: number; limit?: number },
  filter?: Array<FilterParams>
) => {
  const { data } = await api.post('v2/teams/search', { ...params, filter });
  return data;
};

/*
  POST - create team
*/
export const createTeam = async (team: CreateTeamParams) => {
  const { data } = await api.post('v2/teams', { team });
  return data;
};

/*
  GET - get team
*/
export const getTeam = async (id: string) => {
  const { data } = await api.get(`v2/teams/${id}`);
  return data;
};

/*
  PUT - update team
*/
export const updateTeam = async (team_id: string, params: UpdateTeamParams) => {
  const { data } = await api.put(`v2/teams/${team_id}`, { team: params });
  return data;
};

/*
  DELETE - delete team
*/
export const deleteTeam = async (id: string) => {
  const data = await api.delete(`v2/teams/${id}`);
  return data;
};

/*
  GET - get team members
*/
export const getTeamMembers = async (
  team_id: string,
  params: { offset: number; limit: number }
) => {
  const { data } = await api.get(`v2/teams/${team_id}/team_members`, { params });
  return data;
};

/*
  GET - get team member
*/
export const getTeamMember = async (team_id: string, id: string) => {
  const { data } = await api.get(`v2/teams/${team_id}/team_members/${id}`);
  return data;
};

/*
  POST - create team member
*/
export const createTeamMember = async (team_id: string, user_id: number | string) => {
  const { data } = await api.post(`v2/teams/${team_id}/team_members`, {
    team_member: { user_id },
  });
  return data;
};

/*
  DELETE - create team member
*/
export const deleteTeamMember = async (team_id: string, team_member_id: string) => {
  const data = await api.delete(`v2/teams/${team_id}/team_members/${team_member_id}`);
  return data;
};

/*
  GET - get list work schedules
*/
export const getWorkSchedules = async (team_id: string) => {
  const { data } = await api.get(`v2/teams/${team_id}/work_schedules`);
  return data;
};

/*
  POST - create team work schedules
*/
export const createWorkSchedules = async (
  team_id: string,
  params: CreateWorkSchedulesParams
) => {
  const { data } = await api.post(`v2/teams/${team_id}/work_schedules`, {
    work_schedule: params,
  });
  return data;
};

/*
  PUT - update team work schedules
*/
export const updateWorkSchedules = async (
  team_id: string,
  work_schedule_id: string,
  params: CreateWorkSchedulesParams
) => {
  const { data } = await api.put(
    `v2/teams/${team_id}/work_schedules/${work_schedule_id}`,
    {
      work_schedule: params,
    }
  );
  return data;
};

/*
  DELETE - delete team work schedules
*/
export const deleteWorkSchedules = async (team_id: string, work_schedule_id: string) => {
  const data = await api.delete(`v2/teams/${team_id}/work_schedules/${work_schedule_id}`);
  return data;
};

/*
  POST - create team member work schedules
*/
export const createTeamMemberWorkSchedules = async (
  team_id: string,
  team_member_id: string,
  params: CreateWorkSchedulesParams
) => {
  const { data } = await api.post(
    `v2/teams/${team_id}/team_members/${team_member_id}/work_schedules`,
    {
      work_schedule: params,
    }
  );
  return data;
};

/*
  PUT - update team member work schedules
*/
export const updateTeamMemberWorkSchedules = async (
  team_id: string,
  team_member_id: string,
  work_schedule_id: string,
  params: CreateWorkSchedulesParams
) => {
  const { data } = await api.put(
    `v2/teams/${team_id}/team_members/${team_member_id}/work_schedules/${work_schedule_id}`,
    {
      work_schedule: params,
    }
  );
  return data;
};

/*
  DELETE - delete team member work schedules
*/
export const deleteTeamMemberWorkSchedules = async (
  team_id: string,
  team_member_id: string,
  work_schedule_id: string
) => {
  const data = await api.delete(
    `v2/teams/${team_id}/team_members/${team_member_id}/work_schedules/${work_schedule_id}`
  );
  return data;
};
