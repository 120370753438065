import React, { useEffect, useState } from 'react';
import { HiPencil, HiTrash, HiX } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { createPreviewCall } from '@/shared/api/calls';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { useDisclosure } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Agent, AgentStatus } from '@/shared/types/ai/agents';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  Text,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useLLMAgent } from '../context/AgentsContext';

type AgentContainerProps = {
  children: React.ReactNode;
};

export const AgentContainer = ({ children }: AgentContainerProps) => {
  const agents = useLLMAgent();
  const { updateAgent, getAgent, deleteAgent } = agents;

  const history = useHistory();

  const params = useParams<{ id: string }>();
  const { id } = params;

  const [currentAgent, setCurrentAgent] = useState<Agent>();

  useEffect(() => {
    fetchAndSetAgent(id);
  }, [id]);

  const fetchAndSetAgent = async (id: string) => {
    const agent: Agent = await getAgent(id);
    setCurrentAgent(agent);
  };

  const [agentName, setAgentName] = useState('');
  const [agentDescription, setAgentDescription] = useState('');

  useEffect(() => {
    if (currentAgent) {
      setAgentName(currentAgent?.current_version?.name || '');
      setAgentDescription(currentAgent?.current_version?.description || '');
    }
  }, [currentAgent]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const saveAgent = async () => {
    if (!currentAgent) return;

    await updateAgent(currentAgent.id, {
      agent: {
        status: AgentStatus.Active,
        name: agentName,
        description: agentDescription,
        llm_id: currentAgent.current_version.llm_id,
        provider: currentAgent.current_version.provider,
        provider_settings: {
          language: 'en-US',
          responsiveness: 0.95,
          voice_id: '11labs-Emily',
          voice_temperature: 0.4,
          voice_speed: 1.04,
          interruption_sensitivity: 0.95,
        },
      },
    });

    onClose();
  };

  const handleDeleteAgent = async () => {
    if (!currentAgent) return;
    await deleteAgent(currentAgent.id, currentAgent);
    history.push('/ai/agents');
  };

  const {
    isOpen: previewCallIsOpen,
    onOpen: previewCallOnOpen,
    onClose: previewCallOnClose,
  } = useDisclosure();

  const [previewCallTo, setPreviewCallTo] = useState('');
  const [previewCallChannelId, setPreviewCallChannelId] = useState('');

  const locationsContext = useLocations();
  const { locations } = locationsContext.locationsState;

  const handlePreviewCall = async () => {
    if (!currentAgent) return;
    await createPreviewCall({
      agent_id: currentAgent.id,
      channel_id: previewCallChannelId,
      to: previewCallTo,
    });
    previewCallOnClose();
  };

  const getLocationName = (id: string) =>
    locations.find((location) => location.id === id)?.name;

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'AI', path: '/ai/agents' },
        { title: 'Agents', path: '/ai/agents' },
        { title: `${agentName || 'Editor'}`, path: '/ai/agents/create' },
      ]}
      actions={
        <HStack css={{ mr: 0 }}>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <IconButton size="2" variant="outline">
                <HiTrash />
              </IconButton>
            </AlertDialogTrigger>
            <AlertDialogPortal>
              <AlertDialogOverlay />
              <AlertDialogContent>
                <AlertDialogTitle>Delete Agent</AlertDialogTitle>
                <Text>
                  Are you sure you want to delete this agent? You cannot undo this action.
                </Text>
                <HStack css={{ marginTop: 20, justifyContent: 'flex-end' }}>
                  <AlertDialogCancel>
                    <Button variant={'gray'}>Cancel</Button>
                  </AlertDialogCancel>
                  <Button variant={'red'} onClick={handleDeleteAgent}>
                    Confirm
                  </Button>
                </HStack>
              </AlertDialogContent>
            </AlertDialogPortal>
          </AlertDialog>
          <Dialog open={isOpen}>
            <DialogTrigger asChild onClick={onOpen}>
              <IconButton size="2" variant="outline" css={{ pr: 0 }}>
                <HiPencil />
              </IconButton>
            </DialogTrigger>
            <DialogPortal>
              <DialogOverlay />
              <DialogContent
                onEscapeKeyDown={onClose}
                onPointerDownOutside={onClose}
                css={{ overflow: 'auto' }}
              >
                <DialogHeader title="Agent Info" />
                <VStack css={{ width: '100%' }} gap="1">
                  <Fieldset>
                    <Label>Agent Name</Label>
                    <Input
                      placeholder="A name for your agent"
                      value={agentName}
                      onChange={(e) => setAgentName(e.target.value)}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Description</Label>
                    <Input
                      placeholder="What does this agent do?"
                      value={agentDescription}
                      onChange={(e) => setAgentDescription(e.target.value)}
                    />
                  </Fieldset>
                  <Button onClick={saveAgent}>Save Agent</Button>
                </VStack>
                <DialogCloseIcon onClick={onClose} size="2">
                  <HiX size="15px" style={{ color: 'white' }} />
                </DialogCloseIcon>
              </DialogContent>
            </DialogPortal>
          </Dialog>
        </HStack>
      }
    >
      <TabsContainer align="center" justify="between">
        <HStack>
          <AgentSubNavigationItem
            selected={history.location.pathname.includes('editor')}
            onClick={() => history.push(`/ai/agents/${id}/editor`)}
          >
            Editor
          </AgentSubNavigationItem>
          <AgentSubNavigationItem
            selected={history.location.pathname === `/ai/agents/${id}/versions`}
            onClick={() => history.push(`/ai/agents/${id}/versions`)}
          >
            Versions
          </AgentSubNavigationItem>
          <AgentSubNavigationItem
            selected={history.location.pathname === `/ai/agents/${id}/calls`}
            onClick={() => history.push(`/ai/agents/${id}/calls`)}
          >
            Calls
          </AgentSubNavigationItem>
        </HStack>
        <Dialog open={previewCallIsOpen}>
          <DialogTrigger asChild onClick={previewCallOnOpen}>
            <Button variant="outline" onClick={previewCallOnOpen}>
              Preview Call
            </Button>
          </DialogTrigger>
          <DialogPortal>
            <DialogOverlay />
            <DialogContent
              onEscapeKeyDown={previewCallOnClose}
              onPointerDownOutside={previewCallOnClose}
              css={{ overflow: 'auto' }}
            >
              <DialogHeader title="Agent Info" />
              <VStack css={{ width: '100%' }} gap="1">
                <Fieldset>
                  <Label>To</Label>
                  <Input
                    placeholder="Phone number"
                    value={previewCallTo}
                    onChange={(e) => setPreviewCallTo(e.target.value)}
                  />
                </Fieldset>
                <Fieldset>
                  <Label>Select Channel</Label>
                  <SingleSelect
                    defaultPlaceholder={
                      getLocationName(previewCallChannelId || '') || 'Select a location'
                    }
                    isDropdown={true}
                    selectItem={previewCallChannelId || ''}
                    setSelectItem={setPreviewCallChannelId}
                    options={locations.map((location) => ({
                      type: location.name || location.address || '',
                      value: location?.id,
                    }))}
                  />
                </Fieldset>
                <Button onClick={handlePreviewCall}>Trigger Call</Button>
              </VStack>
              <DialogCloseIcon onClick={previewCallOnClose} size="2">
                <HiX size="15px" style={{ color: 'white' }} />
              </DialogCloseIcon>
            </DialogContent>
          </DialogPortal>
        </Dialog>
      </TabsContainer>
      <Container direction="column">{children}</Container>
    </PageLayout>
  );
};

export const AgentSubNavigationItem = styled(Flex, {
  px: 10,
  py: 8,
  borderRadius: 4,
  fontWeight: 500,
  alignContent: 'center',
  cursor: 'pointer',
  fontSize: 13,
  position: 'relative',
  '&:hover': {
    backgroundColor: '$slate3',
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '$slate3',
      },
    },
  },
});

export const TabsContainer = styled(Flex, {
  width: '100%',
  height: 60,
  px: 20,
  borderBottom: 'thin solid $gray4',
  backgroundColor: 'white',
});

export const Container = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
  backgroundColor: '#fafafa',
});
