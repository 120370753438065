import { api } from '../api';

export type LocationParams = {
  id?: string;
  name?: string;
  address?: string;
  widget_setting_id?: string | null;
  phone?: string;
  state?: string;
  review_link?: string;
  google_place_id?: string | null;
  automatic_response_open?: string | null;
  automatic_response_closed?: string | null;
};
/*
  GET - the organizations's locations
*/

export const fetchLocations = (queryParams?: string) =>
  api.get(`/locations?${queryParams || ''}`).then((res) => {
    return res.data.data;
  });

/*
  GET - part of organizations's locations
*/

export const fetchPartOfLocations = (params: { offset: number; limit: number }) =>
  api.get(`/locations?limit=${params.limit}&offset=${params.offset}`).then((res) => {
    return res.data.data;
  });

/*
  POST - search for a location by name
*/

export const searchLocation = (name: string) =>
  api.post('/locations/search', { name }).then((res) => {
    return res.data.data;
  });

/*
  POST - add a new location to an organization
*/

export const createLocation = (locationParams: LocationParams) =>
  api.post('/locations', { location: locationParams }).then((res) => {
    return res.data.data;
  });

/*
  PUT - update a location in an organization
*/

export const updateLocation = (locationParams: LocationParams) =>
  api.put(`/locations/${locationParams.id}`, { location: locationParams }).then((res) => {
    return res.data.data;
  });

/*
  PUT - disable a location in an organization
*/

export const disableLocation = (locationParams: LocationParams) =>
  api.put(`/locations/disable/${locationParams.id}`).then((res) => {
    return res.data.data;
  });

/*
  DELETE - remove a location from an organization
*/

export const deleteLocation = (id: string) =>
  api.delete(`/locations/${id}`).then((res) => {
    return res.data.data;
  });

/*
  GET - get a location by id
  */

export const getLocation = (id: string) => {
  return api.get(`/locations/${id}`).then((res) => {
    return res.data.data;
  });
};
