/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { HiDotsHorizontal, HiDuplicate, HiEye, HiEyeOff } from 'react-icons/hi';
import { toast } from 'sonner';

import DeleteConfirmationDialog from '@/pages/developer/keys/DeleteConfirmationDialog';
import DisableConfirmationDialog from '@/pages/developer/keys/DisableConfirmationDialog';
import RenameConfirmationDialog from '@/pages/developer/keys/RenameConfirmationDialog';
import { Table } from '@/shared/components/Table';
import { ApiKey } from '@/shared/types/developers';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Td,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Tr,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';

import { AddApiKeyErrorBoundaries } from '../boundaries/AddApiKeyErrorBoundaries';
import { useDeveloperContext } from '../context/DevelopersContext';
import { AddApiKey } from './AddApiKey';

export const ApiKeysList = () => {
  const star = '*';
  const apiKeysContext = useDeveloperContext();
  const {
    developerState,
    getApiKeys,
    enableApiKey,
    disableApiKey,
    deleteApiKey,
    renameApiKey,
  } = apiKeysContext;
  const { apiKeys, loading } = developerState;

  // Use an object for the showKeys state
  const [showKeys, setShowKeys] = useState<Record<string, boolean>>({});

  // state variables for confirmation dialogs
  const [isRenameConfirmationDialogOpen, setIsRenameConfirmationDialogOpen] =
    useState(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false);
  const [isDisableConfirmationDialogOpen, setIsDisableConfirmationDialogOpen] =
    useState(false);

  // state variable for current api key id, name and status (for dialog operations)
  const [currentApiKeyId, setCurrentApiKeyId] = useState<string | null>(null);
  const [currentApiKeyName, setCurrentApiKeyName] = useState<string | null>(null);
  const [currentApiKeyStatus, setCurrentApiKeyStatus] = useState<boolean | null>(null);

  // state variable for search value
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getApiKeys();
  }, []);

  // Filter api keys based on search value and reverse the array to show latest first
  const filteredApiKeys = apiKeys
    .filter((apiKey) => {
      return (
        apiKey?.name === undefined ||
        apiKey?.name === null ||
        apiKey.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    })
    .reverse(); // need to reverse the array to show latest first

  // offset is the number of keywords to skip
  const [offset, setOffset] = useState(0);

  const data = useMemo(
    () => (loading ? Array(5).fill({}) : filteredApiKeys),
    [loading, filteredApiKeys]
  );

  // Toggle individual key visibility
  const toggleKeyVisibility = (id: string) => {
    setShowKeys((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleRenameButtonClick = (currentApiKeyId: string, name: string) => {
    setCurrentApiKeyId(currentApiKeyId);
    setCurrentApiKeyName(name);
    setIsRenameConfirmationDialogOpen(true);
  };

  const handleDisableButtonClick = (id: string, currentStatus: boolean) => {
    setCurrentApiKeyId(id);
    setCurrentApiKeyStatus(currentStatus);
    setIsDisableConfirmationDialogOpen(true);
  };

  const handleDeleteButtonClick = (currentApiKeyId: string) => {
    setCurrentApiKeyId(currentApiKeyId);
    setIsDeleteConfirmationDialogOpen(true);
  };

  const columns = useMemo(
    () =>
      loading
        ? [
            // loading state UI
            // Name : API Key : Status : Edit = 30% : 50% : 15% : 5%
            {
              Header: 'Name',
              colWidth: '30%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', m: 0 }} />
                </>
              ),
            },
            {
              Header: 'API Key',
              colWidth: '50%',
              disableSortBy: true,
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', m: 0 }} />
                </>
              ),
            },
            {
              Header: 'Status',
              colWidth: '15%',
              disableSortBy: true,
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', m: 0 }} />
                </>
              ),
            },
            {
              Header: 'Edit',
              colWidth: '5%',
              disableSortBy: true,
              id: 'expander',
              Cell: <Skeleton variant="icon" />,
            },
          ]
        : [
            {
              Header: 'Name',
              colWidth: '30%',
              Cell: (props: { row: { original: ApiKey } }) => (
                <HStack gap="2">
                  <Text>{props.row.original?.name || 'No API Key Name'}</Text>
                </HStack>
              ),
            },
            {
              Header: 'API Key',
              colWidth: '50%',
              disableSortBy: true,
              id: 'api_key',
              Cell: (props: { row: { original: ApiKey } }) => (
                <HStack gap="2">
                  {/* For api key string */}
                  <Text>
                    {props.row.original.id && showKeys[props.row.original.id]
                      ? props.row.original.key
                      : `${star.repeat(
                          props.row.original.key
                            ? props.row.original.key?.length * 1.25
                            : 10
                        )}`}
                  </Text>
                  {/* For hide / show key */}
                  <IconButton
                    onClick={() => {
                      if (props.row.original.id) {
                        toggleKeyVisibility(props.row.original.id);
                      }
                    }}
                  >
                    {props.row.original.id && showKeys[props.row.original.id] ? (
                      <Tooltip>
                        <TooltipTrigger>
                          <HiEyeOff />
                        </TooltipTrigger>
                        <TooltipContent css={{ marginBottom: 12 }}>
                          Hide API Key
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      <Tooltip>
                        <TooltipTrigger>
                          <HiEye />
                        </TooltipTrigger>
                        <TooltipContent css={{ marginBottom: 12 }}>
                          Show API Key
                        </TooltipContent>
                      </Tooltip>
                    )}
                  </IconButton>
                  {/* For copy key */}
                  <Tooltip>
                    <TooltipTrigger>
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(props.row.original.key || '');
                          toast.success(i18next.t('api_key_copied') as string);
                        }}
                      >
                        <HiDuplicate />
                      </IconButton>
                    </TooltipTrigger>
                    <TooltipContent css={{ marginBottom: 8 }}>
                      Copy API Key
                    </TooltipContent>
                  </Tooltip>
                </HStack>
              ),
            },
            {
              Header: 'Status',
              colWidth: '15%',
              disableSortBy: true,
              id: 'status',
              Cell: (props: { row: { original: ApiKey } }) => (
                <HStack gap="2">
                  <Text>{props.row.original.active ? 'Active' : 'Disabled'}</Text>
                </HStack>
              ),
            },
            {
              Header: 'Edit',
              colWidth: '5%',
              disableSortBy: true,
              id: 'expander',
              Cell: (props: { row: { original: ApiKey } }) => (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild style={{ alignContent: 'end' }}>
                    <IconButton aria-label="open dropdown" variant="outline" size="2">
                      <HiDotsHorizontal size="15px" />
                    </IconButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent sideOffset={10}>
                    <DropdownMenuItem
                      onClick={() =>
                        handleRenameButtonClick(
                          props.row.original.id || '',
                          props.row.original.name || ''
                        )
                      }
                    >
                      Rename
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        if (props.row.original.id) {
                          handleDisableButtonClick(
                            props.row.original.id,
                            props.row.original.active ? true : false
                          );
                        }
                      }}
                    >
                      {props.row.original.active ? 'Disable' : 'Enable'}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        if (props.row.original?.id) {
                          handleDeleteButtonClick(props.row.original?.id);
                        }
                      }}
                    >
                      Delete
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              ),
            },
          ],
    [loading, filteredApiKeys]
  );

  return (
    <>
      {isRenameConfirmationDialogOpen && (
        <RenameConfirmationDialog
          isRenameConfirmationDialogOpen={isRenameConfirmationDialogOpen}
          setIsRenameConfirmationDialogOpen={setIsRenameConfirmationDialogOpen}
          oldName={currentApiKeyName}
          renameApiKey={renameApiKey}
          currentApiKeyId={currentApiKeyId}
        />
      )}
      {isDeleteConfirmationDialogOpen && (
        <DeleteConfirmationDialog
          isDeleteConfirmationDialogOpen={isDeleteConfirmationDialogOpen}
          setIsDeleteConfirmationDialogOpen={setIsDeleteConfirmationDialogOpen}
          currentApiKeyId={currentApiKeyId}
          deleteApiKey={deleteApiKey}
        />
      )}
      {isDisableConfirmationDialogOpen && (
        <DisableConfirmationDialog
          isDisableConfirmationDialogOpen={isDisableConfirmationDialogOpen}
          setIsDisableConfirmationDialogOpen={setIsDisableConfirmationDialogOpen}
          currentApiKeyId={currentApiKeyId}
          currentApiKeyStatus={currentApiKeyStatus}
          disableApiKey={disableApiKey}
          enableApiKey={enableApiKey}
        />
      )}

      <Box css={{ p: 30 }}>
        <Table
          data={data}
          columns={columns}
          sortable={true}
          caption="API Keys Table"
          searchFilter={true}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          limit={50}
          offset={offset}
          setOffset={setOffset}
          totalCount={filteredApiKeys.length}
          empty={
            <Tr>
              <Td
                colSpan={columns.length}
                css={{ textAlign: 'center', backgroundColor: 'white' }}
              >
                <Flex css={{ width: '100%' }} direction="column">
                  <VStack gap={3}>
                    <Box>Create your first API Key.</Box>
                    <Box>
                      <AddApiKeyErrorBoundaries feature="AddApiKey">
                        <AddApiKey />
                      </AddApiKeyErrorBoundaries>
                    </Box>
                  </VStack>
                </Flex>
              </Td>
            </Tr>
          }
        />
      </Box>
    </>
  );
};
