import {
  Agent,
  AgentStatus,
  AgentVersion,
  CreateAgentParams,
  UpdateAgentParams,
} from '@/shared/types/ai/agents';
import { FilterItem, Sort } from '@/shared/types/filter';

import { api } from '../../api';

export const searchAgents = async (
  filter: Array<FilterItem> = [
    {
      resource: 'agent',
      column: 'status',
      comparison: '!=',
      value: AgentStatus.Archived,
    },
  ],
  sort: Array<Sort> = [],
  limit = 100,
  offset = 0
): Promise<{ data: Agent[]; total: number }> => {
  const { data } = await api.post('v2/agents/search', {
    filter,
    sort,
    limit,
    offset,
  });
  return data;
};

export const getAgent = async (id: string): Promise<{ data: Agent }> => {
  const { data } = await api.get(`v2/agents/${id}`);
  return data;
};

export const createAgent = async (
  params: CreateAgentParams
): Promise<{ data: Agent }> => {
  const { data } = await api.post('v2/agents', params);
  return data;
};

export const updateAgent = async (
  id: string,
  params: UpdateAgentParams
): Promise<{ data: Agent }> => {
  const { data } = await api.put(`v2/agents/${id}`, params);
  return data;
};

export const searchAgentsVersions = async (
  filter: Array<FilterItem> = [],
  sort: Array<Sort> = [],
  limit = 100,
  offset = 0
): Promise<{ data: AgentVersion[]; total: number }> => {
  const { data } = await api.post('v2/agents/versions/search', {
    filter,
    sort,
    limit,
    offset,
  });
  return data;
};

export const getAgentVersion = async (id: string): Promise<{ data: AgentVersion }> => {
  const { data } = await api.get(`v2/agents/versions/${id}`);
  return data;
};
