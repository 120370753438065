import { VStack } from '@/shared/ui';

import TeamsTable from './TeamsTable';

export const TeamsView = () => {
  return (
    <VStack gap={7} css={{ width: '100%', alignItems: 'center' }}>
      <TeamsTable />
    </VStack>
  );
};
