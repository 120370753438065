import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from '@/shared/ui';

import { useDomainsContext } from '../context/DomainsContext';

export const AddDomain = () => {
  const { addDomain } = useDomainsContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  // handle create domain
  const handleSubmit = useCallback(
    async (
      values: {
        name: string;
      },
      actions: any
    ) => {
      try {
        const params = {
          name: values.name,
        };
        const data = await addDomain(params);
        data && history.push(`/campaigns/domains/${data.id}`);
        actions.resetForm({
          values: {
            name: '',
          },
        });
        onClose();
      } catch (e) {
        toast.error(e?.response?.data?.errors?.[0]?.description as string);
      }
    },
    []
  );

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        <Button onClick={onOpen} data-intercom-target="add-domain-button">
          Add Domain
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent onEscapeKeyDown={onClose} onPointerDownOutside={onClose}>
            <DialogHeader title="Add Domain" />
            <Formik
              initialValues={{
                name: '',
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .test(
                    'len',
                    'Must be at least 3 characters',
                    (val) => val !== undefined && val.length > 2
                  )
                  .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    'Enter correct domain name'
                  )
                  .required('Please enter domain name'),
              })}
              onSubmit={handleSubmit}
              validateOnChange
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} data-testid="add-domain-form">
                  <FormFieldWrapper label="Domain" name="name">
                    <Box css={{ position: 'relative' }}>
                      <TextInput placeholder="e.g. domain.com" name="name" />
                    </Box>
                  </FormFieldWrapper>
                  <Box css={{ pt: 15 }} />
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={onClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        type="submit"
                        disabled={
                          !formik.isValid || formik.isSubmitting || !formik.values.name
                        }
                      >
                        Add Domain
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={onClose} size="2">
                <HiX style={{ color: 'white' }} />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
