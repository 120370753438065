/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useCampaignsContext } from '@/campaigns/context/CampaignsContext';
import { DEFAULT_CAMPAIGNS_LIMIT } from '@/campaigns/context/constants';

type UseCampaignsProps = {
  /* filter by status of campaigns - currently filtering by tabs */
  status: string;
};

type FetchCampaignsParams = {
  /* filter parameter by locations */
  location_ids?: string[];
  /* filter by users */
  user_ids?: number[];
  /* number of items to skip */
  offset: number;
  /* filter by status of campaigns */
  status: string;
  /* maximum number of items to include in a query result */
  limit: number;
  /* search for campaigns by title using an input string. */
  title: string;
  // Set isLoading
  isLoading?: boolean;
};

export type CurrentFilters = {
  /* filter parameter by title */
  title: string;
  /* filter parameter by locations */
  locations: string[] | [];
  /* filter by users */
  users: number[] | [];
};

export default function useFilterCampaigns({ status }: UseCampaignsProps) {
  const campaign = useCampaignsContext();
  const { isIdle, getCampaigns, campaignsState, updateCampaigns, setLoading } = campaign;

  const [filters, setFilters] = useState<CurrentFilters>({
    title: '',
    locations: [],
    users: [],
  });

  const campaignsRef = useRef<HTMLDivElement | null>(null);

  // Increment the "page" number every time the user reaches the end of the list,
  // triggering the loading of the next "page" of data.
  const [offsetCoefficient, setOffsetCoefficient] = useState(0);

  const changeFilters = (newFilters: CurrentFilters) => {
    setOffsetCoefficient(0);
    setFilters(newFilters);
  };

  const increaseOffsetCoefficient = () => {
    setOffsetCoefficient((prevCoefficient) => prevCoefficient + 1);
  };

  const fetchCampaigns = (params?: { [key: string]: string }) => {
    getCampaigns({
      offset: offsetCoefficient * DEFAULT_CAMPAIGNS_LIMIT,
      limit: DEFAULT_CAMPAIGNS_LIMIT,
      location_ids: filters.locations,
      user_ids: filters.users,
      status,
      ...params,
    });
  };

  // Debounced fetching campaigns list
  const debouncedFetchCampaigns = useCallback(
    debounce((params: FetchCampaignsParams) => {
      getCampaigns(params);
    }, 200),
    []
  );

  // initial fetch
  useEffect(() => {
    setLoading(true);
    debouncedFetchCampaigns({
      offset: offsetCoefficient * DEFAULT_CAMPAIGNS_LIMIT,
      limit: DEFAULT_CAMPAIGNS_LIMIT,
      location_ids: filters.locations,
      user_ids: filters.users,
      status,
      title: filters.title,
      isLoading: true,
    });
  }, []);

  // listener on campaigns parameters changes
  useEffect(() => {
    if (campaignsState.isCampaignsInitialized) {
      setOffsetCoefficient(0);
      debouncedFetchCampaigns({
        // it always will fetch from the first element
        offset: 0,
        limit: DEFAULT_CAMPAIGNS_LIMIT,
        location_ids: filters.locations,
        user_ids: filters.users,
        status,
        title: filters.title,
        isLoading: true,
      });

      if (campaignsRef.current) {
        campaignsRef.current?.scrollTo(0, 0);
      }
    }
  }, [filters.locations, filters.users, filters.title]);

  // listener on campaigns parameters changes
  useEffect(() => {
    if (campaignsState.isCampaignsInitialized && !isIdle) {
      setOffsetCoefficient(0);
      getCampaigns({
        // it always will fetch from the first element
        offset: 0,
        limit: DEFAULT_CAMPAIGNS_LIMIT,
        location_ids: filters.locations,
        user_ids: filters.users,
        status,
        title: filters.title,
      });

      if (campaignsRef.current) {
        campaignsRef.current?.scrollTo(0, 0);
      }
    }
  }, [isIdle]);

  // infinite scroll listener
  useEffect(() => {
    if (
      // is initialized
      campaignsState.isCampaignsInitialized &&
      // campaigns is not loading
      !campaignsState?.loading &&
      // Don't update on the first part of items
      offsetCoefficient !== 0
    ) {
      updateCampaigns({
        offset: offsetCoefficient * DEFAULT_CAMPAIGNS_LIMIT,
        limit: DEFAULT_CAMPAIGNS_LIMIT,
        location_ids: filters.locations,
        user_ids: filters.users,
        status,
        title: filters.title,
      });
    }
  }, [offsetCoefficient]);

  return {
    filters,
    changeFilters,
    fetchCampaigns,
    increaseOffsetCoefficient,
    offsetCoefficient,
    campaignsRef,
  };
}
