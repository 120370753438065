import React from 'react';
import { toast } from 'sonner';

import { updateContactCommunicationPreference } from '@/shared/api/contacts/v1';
import { Location } from '@/shared/types/locations';
import {
  Button,
  Dialog,
  DialogContent,
  DialogPortal,
  DialogTitle,
  Flex,
  HStack,
  Text,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';

type UnsubscribeConfirmationDialogPropsType = {
  /** Whether the Unsubscribe Confirmation dialog is open or not **/
  isUnsubscribeConfirmationDialogOpen: boolean;
  /** Function to set whether the Unsubscribe Confirmation dialog is open or not **/
  setIsUnsubscribeConfirmationDialogOpen: (value: boolean) => void;
  /** Any selected locations **/
  selectedLocations: Array<Location>;
  /** The contact id of the current contact **/
  contactId: string;
  /** Function to close the entire dialogs **/
  onClose: () => void;
};

const UnsubscribeConfirmationDialog = (
  props: UnsubscribeConfirmationDialogPropsType
): JSX.Element => {
  const {
    isUnsubscribeConfirmationDialogOpen,
    setIsUnsubscribeConfirmationDialogOpen,
    selectedLocations,
    contactId,
    onClose,
  } = props;

  const handleUnsubscribeConfirm = () => {
    try {
      // update the contact's communication preferences
      updateContactCommunicationPreference(contactId, {
        opt_in: false,
        paused_until: null,
        location_ids: selectedLocations
          ? selectedLocations.map((location) => location.id)
          : [],
      });
      toast.success(i18next.t('contact_opted_out_success') as string);
    } catch (error) {
      toast.error(i18next.t('contact_opted_out_failure') as string);
    }
    setIsUnsubscribeConfirmationDialogOpen(false);
    onClose();
  };

  return (
    <Dialog open={isUnsubscribeConfirmationDialogOpen} modal={false}>
      <DialogPortal>
        <DialogContent
          onEscapeKeyDown={() => setIsUnsubscribeConfirmationDialogOpen(false)}
          css={{ zIndex: 100000, width: '400px' }}
        >
          <DialogTitle>Unsubscribe Confirmation</DialogTitle>
          <Text>
            Are you sure to un-subscribe the selected locations? Please note that you
            won&apos;t be able to re-subscribe them unless contacts themselves reply START
            via SMS. For more information, please refer to{' '}
            <a
              href="https://intercom.help/whippy/en/articles/8302056-how-to-re-subscribe-opt-in-contact"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              this link
            </a>
            .
          </Text>
          <Flex justify="end" css={{ pt: 20 }}>
            <HStack>
              <Button
                variant="gray"
                onClick={() => {
                  setIsUnsubscribeConfirmationDialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleUnsubscribeConfirm}>
                Yes, I am sure
              </Button>
            </HStack>
          </Flex>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default UnsubscribeConfirmationDialog;
