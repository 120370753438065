import { User } from '../../users';
import { FilterType } from '../../filter';
import { SearchFilters } from '..';

export type GroupsState = {
  groups: Array<Group>;
  recentGroups: Array<Group>;
  searchedGroups: Array<Group>;
  totalCount: number;
  loading: boolean;
  current: Group | null;
  error: Error | null;
  filterParams: SearchFilters;
};

export type Group = {
  id: string;
  organization_id: string;
  name: string;
  color: string;
  filters: SegmentV1Filters | SegmentV2Filters;
  filters_version: 'v1' | 'v2';
  actions:
    | {
        enabled: boolean;
        type: string;
        params: {
          sequence_id: string;
          location_id: string;
          schedule_options: {
            days: string;
            hour: string;
            minute: string;
            timezone: string;
          };
        };
      }[]
    | [];
  inserted_at: string;
  updated_at: string;
  created_by?: User;
  updated_by?: User;
};

export type SegmentV1Filters = {
  search: Array<Record<string, any>>; // Specify the type instead of any if
};

export type SegmentV2Filters = {
  filter: Array<FilterType>;
};

export enum GroupsActionTypes {
  GET_GROUPS = 'GET_GROUPS',
  GET_RECENT_GROUPS = 'GET_RECENT_GROUPS',
  GET_GROUP = 'GET_GROUP',
  SEARCH_GROUP = 'SEARCH_GROUP',
  CREATE_GROUP = 'CREATE_GROUP',
  UPDATE_GROUP = 'UPDATE_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  SET_CURRENT = 'SET_CURRENT',
  SET_LOADING = 'SET_LOADING',
  GET_GROUPS_V2 = 'GET_GROUPS_V2',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
}

export type GroupsType = {
  groups: Array<Group>;
  total: number;
};

export type GroupsTypeV2 = {
  data: Array<Group>;
  total: number;
};

export type GroupsAction =
  | {
      type: GroupsActionTypes.GET_GROUPS;
      payload: GroupsType;
    }
  | {
      type: GroupsActionTypes.GET_RECENT_GROUPS;
      payload: GroupsType;
    }
  | { type: GroupsActionTypes.GET_GROUP; payload: Group | null }
  | { type: GroupsActionTypes.SEARCH_GROUP; payload: Array<Group> }
  | { type: GroupsActionTypes.CREATE_GROUP; payload: Group }
  | { type: GroupsActionTypes.UPDATE_GROUP; payload: Group }
  | { type: GroupsActionTypes.DELETE_GROUP; payload: string }
  | { type: GroupsActionTypes.SET_CURRENT; payload: Group | null }
  | { type: GroupsActionTypes.SET_LOADING; payload: boolean }
  | { type: GroupsActionTypes.GET_GROUPS_V2; payload: GroupsTypeV2 }
  | { type: GroupsActionTypes.UPDATE_FILTER_PARAMS; payload: SearchFilters };
