export type ApiKey = {
  id?: string | null;
  active?: boolean | null;
  key?: string | null;
  name?: string | null;
  description?: string | null;
  created_at?: string | null;
  created_by?: {
    id: number;
    name: string;
    email: string;
    attachment: {
      url: string;
    };
  };
};

export type DeveloperApplication = {
  id?: string | null;
  active: boolean;
  name: string | null;
  description: string | null;
  created_at: string | null;
  rate_limit: number | null;
  api_key: ApiKey;
};

export type CreateDeveloperApplication = {
  api_key_id: string;
  name: string;
  description: string;
  active: boolean;
};

export type DeveloperEndpoint = {
  id: string;
  active: boolean;
  event_types: string[];
  method: string;
  rate_limit: number;
  url: string;
  created_at?: string;
};

export type CreateDeveloperEndpoint = {
  developer_application_id: string;
  event_types: string[];
  method: string;
  url: string;
};

export enum DeveloperActionTypes {
  GET_API_KEYS = 'GET_API_KEYS',
  CREATE_API_KEY = 'CREATE_API_KEY',
  ENABLE_API_KEY = 'ENABLE_API_KEY',
  DISABLE_API_KEY = 'DISABLE_API_KEY',
  DELETE_API_KEY = 'DELETE_API_KEY',
  RENAME_API_KEY = 'RENAME_API_KEY',
  SET_CURRENT = 'SET_CURRENT',
  SET_LOADING = 'SET_LOADING',
  GET_DEVELOPER_APPLICATIONS = 'GET_DEVELOPER_APPLICATIONS',
  CREATE_DEVELOPER_APPLICATION = 'CREATE_DEVELOPER_APPLICATION',
  UPDATE_DEVELOPER_APPLICATION = 'UPDATE_DEVELOPER_APPLICATION',
  GET_DEVELOPER_ENDPOINTS = 'GET_DEVELOPER_ENDPOINTS',
  CREATE_DEVELOPER_ENDPOINT = 'CREATE_DEVELOPER_ENDPOINT',
  UPDATE_DEVELOPER_ENDPOINT = 'UPDATE_DEVELOPER_ENDPOINT',
  DELETE_DEVELOPER_ENDPOINT = 'DELETE_DEVELOPER_ENDPOINT',
}

export type DeveloperActions =
  | {
      type: DeveloperActionTypes.GET_API_KEYS;
      payload: ApiKey[] | Array<null>;
    }
  | {
      type: DeveloperActionTypes.CREATE_API_KEY;
      payload: ApiKey | null;
    }
  | {
      type: DeveloperActionTypes.ENABLE_API_KEY;
      payload: string | null;
    }
  | {
      type: DeveloperActionTypes.DISABLE_API_KEY;
      payload: string | null;
    }
  | {
      type: DeveloperActionTypes.DELETE_API_KEY;
      payload: string | null;
    }
  | {
      type: DeveloperActionTypes.RENAME_API_KEY;
      payload: ApiKey | null;
    }
  | {
      type: DeveloperActionTypes.SET_CURRENT;
      payload: ApiKey | null;
    }
  | {
      type: DeveloperActionTypes.SET_LOADING;
      payload: boolean;
    }
  | {
      type: DeveloperActionTypes.GET_DEVELOPER_APPLICATIONS;
      payload: DeveloperApplication[] | Array<null>;
    }
  | {
      type: DeveloperActionTypes.CREATE_DEVELOPER_APPLICATION;
      payload: DeveloperApplication | null;
    }
  | {
      type: DeveloperActionTypes.UPDATE_DEVELOPER_APPLICATION;
      payload: DeveloperApplication | null;
    }
  | {
      type: DeveloperActionTypes.GET_DEVELOPER_ENDPOINTS;
      payload: DeveloperEndpoint[] | Array<null>;
    }
  | {
      type: DeveloperActionTypes.CREATE_DEVELOPER_ENDPOINT;
      payload: DeveloperEndpoint | null;
    }
  | {
      type: DeveloperActionTypes.UPDATE_DEVELOPER_ENDPOINT;
      payload: DeveloperEndpoint | null;
    }
  | {
      type: DeveloperActionTypes.DELETE_DEVELOPER_ENDPOINT;
      payload: string | null;
    };

export enum Resources {
  CONTACT = 'contact',
  MESSAGE = 'message',
  CAMPAIGN = 'campaign',
  MESSAGE_TEMPLATE = 'message_template',
  LEAD = 'lead',
  CUSTOM_OBJECT = 'custom_object',
  CUSTOM_PROPERTY = 'custom_property',
  CUSTOM_OBJECT_RECORD = 'custom_object_record',
  CUSTOM_PROPERTY_VALUE = 'custom_property_value',
}

export const webhook_events = [
  {
    resource: 'message',
    title: 'Message Events',
    description: 'Events triggered by message changes',
    events: [
      {
        event: 'message.created',
        description: 'A new message was created',
      },
      {
        event: 'message.updated',
        description: 'A message was updated',
      },
    ],
  },
  {
    resource: 'conversation',
    title: 'Conversation Events',
    description: 'Events triggered by conversation changes',
    events: [
      {
        event: 'conversation.created',
        description: 'A new conversation was created',
      },
      {
        event: 'conversation.updated',
        description: 'A conversation was updated',
      },
    ],
  },
  {
    resource: 'contact',
    title: 'Contact Events',
    description: 'Events triggered by contact changes',
    events: [
      {
        event: 'contact.created',
        description: 'A new contact is created in Whippy',
      },
      {
        resource: 'contact',
        event: 'contact.updated',
        description: 'A contact was updated',
      },
    ],
  },
  {
    resource: 'lead',
    title: 'Lead Events',
    description: 'Events triggered by new Leads',
    events: [
      {
        event: 'lead.new',
        description: 'A new lead was created',
      },
    ],
  },
  {
    resource: 'campaign',
    title: 'Campaign Events',
    description: 'Events triggered by Campaign changes',
    events: [
      {
        event: 'campaign.created',
        description: 'A new campaign was created',
      },
      {
        event: 'campaign.updated',
        description: 'A campaign was updated',
      },
    ],
  },
  {
    resource: 'sequence',
    title: 'Sequence Events',
    description: 'Events triggered by Sequence changes',
    events: [
      {
        event: 'sequence.created',
        description: 'A new sequence was created',
      },
      {
        event: 'sequence.updated',
        description: 'A sequence was updated',
      },
    ],
  },
  {
    resource: 'step',
    title: 'Step Events',
    description: 'Events triggered by Step changes',
    events: [
      {
        event: 'step.created',
        description: 'A new step was created',
      },
      {
        event: 'step.updated',
        description: 'A step was updated',
      },
    ],
  },
  {
    resource: 'step_contact',
    title: 'Step Contact Events',
    description: 'Events triggered by Step Contact changes',
    events: [
      {
        event: 'step_contact.created',
        description: 'A new step contact was created',
      },
      {
        event: 'step_contact.updated',
        description: 'A step contact was updated',
      },
    ],
  },
  {
    resource: 'sequence_run',
    title: 'Sequence Run Events',
    description: 'Events triggered by Sequence Run changes',
    events: [
      {
        event: 'sequence_run.created',
        description: 'A new sequence run was created',
      },
      {
        event: 'sequence_run.updated',
        description: 'A sequence run was updated',
      },
    ],
  },
  {
    resource: 'custom_object',
    title: 'Custom Object Events',
    description: 'Events triggered by Custom Object changes',
    events: [
      {
        event: 'custom_object.created',
        description: 'A new custom object was created',
      },
      {
        event: 'custom_object.updated',
        description: 'A custom object was updated',
      },
      {
        event: 'custom_object.deleted',
        description: 'A custom object was deleted',
      },
    ],
  },
  {
    resource: 'custom_object_record',
    title: 'Custom Object Record Events',
    description: 'Events triggered by Custom Object Record changes',
    events: [
      {
        event: 'custom_object_record.created',
        description: 'A new custom object record was created',
      },
      {
        event: 'custom_object_record.updated',
        description: 'A custom object record was updated',
      },
      {
        event: 'custom_object_record.deleted',
        description: 'A custom object record was deleted',
      },
    ],
  },
  {
    resource: 'custom_property',
    title: 'Custom Property Events',
    description: 'Events triggered by Custom Property changes',
    events: [
      {
        event: 'custom_property.created',
        description: 'A new custom property was created',
      },
      {
        event: 'custom_property.updated',
        description: 'A custom property was updated',
      },
      {
        event: 'custom_property.deleted',
        description: 'A custom property was deleted',
      },
    ],
  },
  {
    resource: 'custom_property_value',
    title: 'Custom Property Value Events',
    description: 'Events triggered by Custom Property Value changes',
    events: [
      {
        event: 'custom_property_value.created',
        description: 'A new custom property value was created',
      },
      {
        event: 'custom_property_value.updated',
        description: 'A custom property value was updated',
      },
      {
        event: 'custom_property_value.deleted',
        description: 'A custom property value was deleted',
      },
    ],
  },
  {
    resource: 'message_template',
    title: 'Message Template Events',
    description: 'Events triggered by Message Template changes',
    events: [
      {
        event: 'message_template.created',
        description: 'A new message template was created',
      },
      {
        event: 'message_template.updated',
        description: 'A message template was updated',
      },
      {
        event: 'message_template.deleted',
        description: 'A message template was deleted',
      },
    ],
  },
  {
    resource: 'link',
    title: 'Link Events',
    description: 'Events triggered by Link clicks',
    events: [
      {
        event: 'link.click',
        description: 'A link was clicked',
      },
    ],
  },
  {
    resource: 'call',
    title: 'Voice Call Events',
    description: 'Events triggered by voice call changes',
    events: [
      {
        event: 'call.started',
        description: 'A voice call has started',
      },
      {
        event: 'call.ended',
        description: 'A voice call has ended',
      },
      {
        event: 'call.analyzed',
        description: 'A voice call was analyzed',
      },
    ],
  },
];
