import React from 'react';
import { createContext, useContext, useState } from 'react';

import { useAuth } from '@/auth/context/AuthProvider';
import * as API from '@/shared/api/organization';
import { Organization } from '@/shared/types';

type CreateNewAccountParams = {
  organization_name: string;
  website: string;
};

export const OnboardingContext = createContext<{
  currentStep: string;
  setCurrentStep: (currentStep: string) => void;
  organizationDetails: Record<string, any> | null;
  setOrganizationDetails: (organizationDetails: Record<string, any>) => void;
  phone: string;
  setPhone: (phone: string) => void;
  areaCode: string;
  setAreaCode: (area: string) => void;
  createNewAccount: (params: CreateNewAccountParams) => Promise<Organization>;
}>({
  currentStep: 'find_address',
  setCurrentStep: () => Promise.resolve(),
  organizationDetails: null,
  setOrganizationDetails: () => Promise.resolve(),
  phone: '',
  setPhone: () => Promise.resolve(),
  areaCode: '',
  setAreaCode: () => Promise.resolve(),
  createNewAccount: () => Promise.resolve({} as Organization),
});

export const useOnboarding = () => useContext(OnboardingContext);

const OnboardingProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const [currentStep, setCurrentStep] = useState('find_address');
  const [organizationDetails, setOrganizationDetails] = useState<any>({
    website: auth?.organizationInfo?.organization?.website_url || '',
    formatted_address: '',
    formatted_phone: '',
    name: '',
    place_id: '',
  });
  const [phone, setPhone] = useState<any>();
  const [areaCode, setAreaCode] = useState('');

  const createNewAccount = async (params: {
    organization_name?: string;
    website: string;
  }) => {
    try {
      const data = await API.createOrganization(params);

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        organizationDetails,
        setOrganizationDetails,
        phone,
        setPhone,
        areaCode,
        setAreaCode,
        createNewAccount,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
