import { Dispatch, SetStateAction } from 'react';
import { HiExclamationCircle } from 'react-icons/hi';

import { Setter, useCaplocks, useFocus } from '@/shared/hooks';
import { LocationTypes } from '@/shared/types';
import { Box, ControlGroup } from '@/shared/ui';
import {
  isValidPhoneNumber,
  phoneRegex,
  toE164,
} from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

type CreateConversationFormProps = {
  selectedLocationType?: LocationTypes;
  generalInput: string;
  setGeneralInput: Setter;
  nameInput: string;
  setNameInput: Dispatch<SetStateAction<string>>;
  phoneInput: string;
  setPhoneInput: Setter;
  showNameInput: boolean;
  setShowNameInput: Dispatch<SetStateAction<boolean>>;
  showPhoneInput: boolean;
  setShowPhoneInput: Dispatch<SetStateAction<boolean>>;
  emailInput: string;
  setEmailInput: Dispatch<SetStateAction<string>>;
  subjectInput: string;
  setSubjectInput: Dispatch<SetStateAction<string>>;
  inputValuePhoneError: boolean;
  setInputValuePhoneError: Dispatch<SetStateAction<boolean>>;
};

export const CreateConversationForm = (
  props: CreateConversationFormProps
): JSX.Element => {
  const {
    setNameInput,
    setPhoneInput,
    inputValuePhoneError,
    setInputValuePhoneError,
    showNameInput,
    showPhoneInput,
    setShowNameInput,
    setShowPhoneInput,
    generalInput,
    nameInput,
    setGeneralInput,
    phoneInput,
    selectedLocationType,
    emailInput,
    setEmailInput,
    subjectInput,
    setSubjectInput,
  } = props;

  // Input Focus manages the focus state of the input for the general input box
  // which accepts both name and phone inputs
  const [inputFocus, setInputFocus] = useFocus();
  // Phone Focus manages the focus state of the phone input
  const [phoneFocus, setPhoneFocus] = useFocus();
  // Name Focus manages the focus state of the name input
  const [nameFocus, setNameFocus] = useFocus();
  // Caps lock Focus manages the focus state of the caps lock
  const isCaplocksOn = useCaplocks();

  const clearSearchedContacts = () => {
    setShowPhoneInput(false);
    setShowNameInput(false);
  };

  const sanitizedInputValue = generalInput.toLowerCase().replace(/\s/g, '');
  const isPhoneNumber =
    phoneRegex.onlyNumbers.test(sanitizedInputValue) ||
    phoneRegex.containsNumber.test(sanitizedInputValue);

  const isContactName =
    phoneRegex.onlyLetters.test(sanitizedInputValue) ||
    phoneRegex.containsAlphabet.test(sanitizedInputValue);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget?.value || '';
    setGeneralInput(inputValue);
    if (inputValue.length < 1) {
      return resetInputFields();
    }

    const phoneStripped = inputValue.replace('(', '').replace('-', '').replace(')', '');
    const isPhoneValid = isValidPhoneNumber(toE164(inputValue));
    if (!isPhoneValid && phoneStripped.length > 10) {
      setInputValuePhoneError(true);
    } else {
      setInputValuePhoneError(false);
    }

    if (isContactName) {
      setShowPhoneInput(true);
      setShowNameInput(false);
      setNameInput(inputValue);
      setInputValuePhoneError(false);
    } else if (isPhoneNumber && isPhoneValid) {
      setShowNameInput(true);
      setShowPhoneInput(false);
      setPhoneInput(inputValue);
    } else {
      setShowPhoneInput(false);
    }
  };

  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value || '';
    setNameInput(value);
  };

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value || '';
    setEmailInput(value);
  };

  const handleSubjectInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value || '';
    setSubjectInput(value);
  };

  const handlePhoneInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | null,
    pastedInput?: string
  ) => {
    const value = pastedInput ? pastedInput : e?.currentTarget?.value || '';
    setPhoneInput(value);
    setInputValuePhoneError(false);

    const sanitizedInputValue = value.toLowerCase().replace(/\s/g, '');
    const hasLetters = phoneRegex.containsAlphabet.test(sanitizedInputValue);

    if (value.length < 1) {
      setInputValuePhoneError(false);
    }

    if (hasLetters && value.length > 0) {
      setInputValuePhoneError(true);
    }
  };

  const resetInputFields = () => {
    setShowNameInput(false);
    setShowPhoneInput(false);
    setInputValuePhoneError(false);
    setPhoneInput('');
    setNameInput('');
    clearSearchedContacts();
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    handlePasteName(e);
    handlePastePhone(e);
  };

  const handlePastePhone = (e: React.ClipboardEvent) => {
    e?.preventDefault();

    const clipboard = e.clipboardData;
    const value = clipboard ? clipboard.getData('Text') : '';

    const isValidPhone = isValidPhoneNumber(toE164(value));

    if (isValidPhone) {
      handlePhoneInputChange(null, value);
      setNameInput('');
      setShowNameInput(true);
      setShowPhoneInput(false);
    }
  };

  const handlePasteName = (e: React.ClipboardEvent) => {
    e?.preventDefault();

    const clipboard = e.clipboardData;
    const value = clipboard ? clipboard.getData('Text') : '';

    const isValidPhone = isValidPhoneNumber(toE164(value));

    setGeneralInput(value);

    const name = value.match(/[a-z]/i);

    if (!isValidPhone && name) {
      setShowPhoneInput(true);
      setNameInput(value);
      setShowNameInput(false);
    }

    if (value.length < 1) {
      clearSearchedContacts();
    }
  };

  return (
    <>
      {selectedLocationType === 'phone' && (
        <InputContainer>
          <NewConversationInputLabel>SEND TO:</NewConversationInputLabel>
          <Box css={{ position: 'relative', width: '100%' }}>
            <ControlGroup css={{ alignItems: 'center', mt: 8 }}>
              <NewConversationInput
                placeholder="Phone or name"
                data-intercom-target="phone-or-name-input"
                name="contactValue"
                value={generalInput}
                onChange={handleInputChange}
                onPaste={handlePaste}
                onKeyDown={(e: React.KeyboardEvent) => {
                  const val = e.key;
                  if (val === 'ArrowDown')
                    showPhoneInput ? setPhoneFocus() : setNameFocus();
                }}
                showError={inputValuePhoneError && isPhoneNumber}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
                autoComplete="off"
                ref={inputFocus}
              />
              {inputValuePhoneError && isPhoneNumber && (
                <HiExclamationCircle
                  size="20px"
                  color="#E53E3E"
                  style={{ marginTop: 5 }}
                />
              )}

              {isCaplocksOn && (
                <HiExclamationCircle
                  size="20px"
                  color="#DD6B20"
                  style={{ marginTop: 5 }}
                />
              )}
            </ControlGroup>
            <Box css={{ pt: 10, height: 15 }}>
              {inputValuePhoneError && isPhoneNumber && (
                <ErrorContainer color="red.500">
                  {' '}
                  Invalid Phone Number
                  <Box as="span" css={{ fontStyle: 'normal' }}>
                    {'  '}😱
                  </Box>
                </ErrorContainer>
              )}
              {isCaplocksOn && (
                <ErrorContainer color="orange.400">Caps Lock is on</ErrorContainer>
              )}
            </Box>
          </Box>
        </InputContainer>
      )}

      {selectedLocationType === 'phone' && showPhoneInput && (
        <InputContainer>
          <NewConversationInputLabel>PHONE:</NewConversationInputLabel>
          <Box css={{ position: 'relative', width: '100%' }}>
            <ControlGroup css={{ alignItems: 'center' }}>
              <NewConversationInput
                ref={phoneFocus}
                placeholder="Phone"
                data-intercom-target="phone-input"
                autoComplete="off"
                name="phone"
                value={phoneInput}
                onChange={handlePhoneInputChange}
                showError={inputValuePhoneError}
                onKeyDown={(e: React.KeyboardEvent) => {
                  const val = e.key;
                  if (val === 'ArrowUp') setInputFocus();
                  if (val === 'Enter' && !isValidPhoneNumber(toE164(phoneInput)))
                    setInputValuePhoneError(true);
                }}
              />
              {inputValuePhoneError && (
                <HiExclamationCircle
                  size="20px"
                  color="#E53E3E"
                  style={{ marginTop: 5 }}
                />
              )}
            </ControlGroup>
            <Box css={{ pt: 10, height: 15 }}>
              {inputValuePhoneError && (
                <ErrorContainer color="red.500">
                  {' '}
                  Invalid Phone Number
                  <Box as="span" css={{ fontStyle: 'normal' }}>
                    {'  '}😱
                  </Box>{' '}
                </ErrorContainer>
              )}
            </Box>
          </Box>
        </InputContainer>
      )}

      {selectedLocationType === 'phone' && showNameInput && (
        <InputContainer>
          <NewConversationInputLabel>NAME:</NewConversationInputLabel>
          <ControlGroup css={{ alignItems: 'center' }}>
            <NewConversationInput
              ref={nameFocus}
              autoComplete="off"
              placeholder="Name"
              value={nameInput}
              name="name"
              onChange={handleNameInputChange}
              onKeyDown={(e: React.KeyboardEvent) => {
                const val = e.key;
                if (val === 'ArrowUp') setInputFocus();
              }}
            />
            {isCaplocksOn && (
              <HiExclamationCircle size="20px" color="#DD6B20" style={{ marginTop: 5 }} />
            )}
          </ControlGroup>
          <Box css={{ pt: 10, height: 15 }}>
            {isCaplocksOn && (
              <ErrorContainer color="orange.400">Caps lock is on</ErrorContainer>
            )}
          </Box>
        </InputContainer>
      )}

      {selectedLocationType === 'email' && (
        <InputContainer>
          <NewConversationInputLabel>SEND TO:</NewConversationInputLabel>
          <ControlGroup css={{ alignItems: 'center', mt: 8 }}>
            <NewConversationInput
              ref={nameFocus}
              autoComplete="off"
              placeholder="E-mail"
              value={emailInput}
              name="email"
              onChange={handleEmailInputChange}
              onKeyDown={(e: React.KeyboardEvent) => {
                const val = e.key;
                if (val === 'ArrowUp') setInputFocus();
              }}
            />
            {isCaplocksOn && (
              <HiExclamationCircle size="20px" color="#DD6B20" style={{ marginTop: 5 }} />
            )}
          </ControlGroup>
          <Box css={{ pt: 10, height: 15 }}>
            {isCaplocksOn && (
              <ErrorContainer color="orange.400">Caps lock is on</ErrorContainer>
            )}
          </Box>
        </InputContainer>
      )}

      {selectedLocationType === 'email' && (
        <InputContainer>
          <NewConversationInputLabel>SUBJECT:</NewConversationInputLabel>
          <ControlGroup css={{ alignItems: 'center' }}>
            <NewConversationInput
              ref={nameFocus}
              autoComplete="off"
              placeholder="Subject"
              value={subjectInput}
              name="subject"
              onChange={handleSubjectInputChange}
              onKeyDown={(e: React.KeyboardEvent) => {
                const val = e.key;
                if (val === 'ArrowUp') setInputFocus();
              }}
            />
            {isCaplocksOn && (
              <HiExclamationCircle size="20px" color="#DD6B20" style={{ marginTop: 5 }} />
            )}
          </ControlGroup>
          <Box css={{ pt: 10, height: 15 }}>
            {isCaplocksOn && (
              <ErrorContainer color="orange.400">Caps lock is on</ErrorContainer>
            )}
          </Box>
        </InputContainer>
      )}

      {selectedLocationType === 'whatsapp' && (
        <InputContainer>
          <NewConversationInputLabel>WHATSAPP:</NewConversationInputLabel>
          <Box css={{ position: 'relative', width: '100%' }}>
            <ControlGroup css={{ alignItems: 'center' }}>
              <NewConversationInput
                ref={phoneFocus}
                placeholder="Phone"
                data-intercom-target="phone-input"
                autoComplete="off"
                name="phone"
                value={phoneInput}
                onChange={handlePhoneInputChange}
                showError={inputValuePhoneError}
                onKeyDown={(e: React.KeyboardEvent) => {
                  const val = e.key;
                  if (val === 'ArrowUp') setInputFocus();
                  if (val === 'Enter' && !isValidPhoneNumber(toE164(phoneInput)))
                    setInputValuePhoneError(true);
                }}
              />
              {inputValuePhoneError && (
                <HiExclamationCircle
                  size="20px"
                  color="#E53E3E"
                  style={{ marginTop: 5 }}
                />
              )}
            </ControlGroup>
            <Box css={{ pt: 10, height: 15 }}>
              {inputValuePhoneError && (
                <ErrorContainer color="red.500">
                  {' '}
                  Invalid Phone Number
                  <Box as="span" css={{ fontStyle: 'normal' }}>
                    {'  '}😱
                  </Box>{' '}
                </ErrorContainer>
              )}
            </Box>
          </Box>
        </InputContainer>
      )}
    </>
  );
};

export const ErrorContainer = styled(Box, {
  textAlign: 'right',
  fontWeight: 500,
  fontSize: 15,
  fontStyle: 'italic',
});

export const InputContainer = styled(Box, {
  pt: 15,
  px: 15,
  '@lg': { px: 30 },
});

export const NewConversationInput = styled('input', {
  background: 'none',
  width: '100%',
  fontSize: 32,
  py: 8,
  fontWeight: 700,
  fontStyle: 'bold',
  borderBottom: '2px solid $gray4',
  '&:focus': { outline: 'none' },
  variants: {
    showError: {
      true: {
        borderBottomColor: '$red10',
      },
    },
  },
});

export const NewConversationInputLabel = styled('label', {
  fontSize: 12,
  textTransform: 'uppercase',
  fontWeight: 700,
});
