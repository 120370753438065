export const default_contact_object = {
  id: 'unique-id-for-contact',
  label: 'Contact',
  key: 'contact',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'contact-name',
      label: 'Name',
      type: 'text',
      key: 'name',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-phone',
      label: 'Phone',
      type: 'text',
      key: 'phone',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-email',
      label: 'Email',
      type: 'text',
      key: 'email',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: false,
      id: 'contact-blocked',
      label: 'Blocked',
      type: 'boolean',
      key: 'blocked',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-source',
      label: 'Source',
      type: 'text',
      key: 'source',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-default-channel',
      label: 'Default Channel',
      type: 'channel',
      key: 'default_channel_id',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-external-id',
      label: 'External ID',
      type: 'text',
      key: 'external_id',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-language',
      label: 'Language',
      type: 'language',
      key: 'language',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-default-address-address-line-1',
      label: 'Address Line One',
      type: 'text',
      key: 'address.address_line_one',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-default-address-address-line-2',
      label: 'Address Line Two',
      type: 'text',
      key: 'address.address_line_two',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-default-address-city',
      label: 'City',
      type: 'text',
      key: 'address.city',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-default-address-state',
      label: 'State',
      type: 'text',
      key: 'address.state',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-default-address-postal-code',
      label: 'Post/Zip Code',
      type: 'text',
      key: 'address.post_code',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-default-address-country',
      label: 'Country',
      type: 'text',
      key: 'address.country',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-opt-in-sms',
      label: 'Opt in to SMS',
      type: 'boolean',
      key: 'opt_in_sms',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-opt-in-email',
      label: 'Opt in to Email',
      type: 'boolean',
      key: 'opt_in_email',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'contact-opt-out-of-all',
      label: 'Opt out of all',
      type: 'boolean',
      key: 'opt_out_of_all',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: 'current-timestamp',
      id: 'contact-inserted_at',
      label: 'Inserted At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: 'current-timestamp',
      id: 'contact-updated_at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
  ],
};
