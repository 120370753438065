import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Table } from '@/shared/components/Table';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Domain } from '@/shared/types/domains';
import { DomainStatusType } from '@/shared/types/domains';
import { Badge, Box, Flex, Link, Skeleton, Td, Text, Tr, VStack } from '@/shared/ui';
import { MONTH_DAY_YEAR } from '@/shared/utils/timestamps';
import { styled } from '@/stitches.config';

import { useDomainsContext } from '../context/DomainsContext';
import { getBadgeVariant } from '../utils/utils';
import { AddDomain } from './AddDomain';
import { BuyDomain } from './BuyDomain';
import { CreatedByUser } from './CreatedByUser';
import { DomainNavigationButton } from './DomainNavigationButton';

const LIMIT = 30;

export const DomainsList = () => {
  const {
    domainsState: { loading, domains, totalCount },
    getDomains,
  } = useDomainsContext();

  const [search, setSearch] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);

  const loadingDomains = (offset: number, search?: string) => {
    getDomains({
      filter: [
        {
          column: 'name',
          comparison: 'ilike',
          resource: 'domain',
          value: `%${search}%`,
        },
      ],
      offset,
      limit: LIMIT,
    });
  };

  useEffect(() => {
    loadingDomains(offset, search);
  }, [offset]);

  const data = useMemo(
    () => (loading ? Array(10).fill({}) : domains),
    [loading, domains]
  );

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
    setOffset(0);
    loadingDomains(0, value);
  }, []);

  const columns = useMemo(
    () =>
      loading
        ? [
            {
              Header: 'Domains',
              colWidth: '30%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '80%', my: 0 }} />
                </>
              ),
            },
            {
              Header: 'Setup Type',
              colWidth: '10%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', my: 0 }} />
                </>
              ),
            },
            {
              Header: 'Expired Date',
              colWidth: '15%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '70%', my: 0 }} />
                </>
              ),
            },
            {
              Header: 'Status',
              colWidth: '15%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '90%', my: 0 }} />
                </>
              ),
            },
            {
              Header: 'Created by',
              colWidth: '20%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '90%', my: 0 }} />
                </>
              ),
            },
            {
              Header: '',
              disableSortBy: true,
              id: 'expander',
              Cell: (
                <Flex direction="row" justify="end">
                  <Skeleton variant="icon" css={{ my: 0, height: 35, width: 35 }} />
                </Flex>
              ),
            },
          ]
        : [
            {
              Header: 'Domains',
              colWidth: '25%',
              accessor: 'name',
              Cell: (props: { row: { original: Domain } }) => (
                <Flex direction="column">
                  {props.row.original?.status === DomainStatusType.CANCELLED ? (
                    <Label>{props.row.original.name}</Label>
                  ) : (
                    <DomainLink
                      href={`https://${props.row.original.name}`}
                      target="_blank"
                    >
                      {props.row.original.name}
                    </DomainLink>
                  )}
                </Flex>
              ),
            },
            {
              Header: 'Setup Type',
              colWidth: '10%',
              accessor: 'setupType',
              Cell: (props: { row: { original: Domain } }) => (
                <Flex direction="column">
                  <CapitalizeText>{props.row.original.setup_type}</CapitalizeText>
                </Flex>
              ),
            },
            {
              Header: 'Expiration Date',
              colWidth: '15%',
              accessor: 'expires_at',
              Cell: (props: { row: { original: Domain } }) => (
                <Flex direction="column">
                  <Label>
                    {props.row.original.expires_at
                      ? dayjs(props.row.original.expires_at).format(MONTH_DAY_YEAR)
                      : '-'}
                  </Label>
                </Flex>
              ),
            },
            {
              Header: 'Status',
              colWidth: '15%',
              accessor: 'status',
              Cell: (props: { row: { original: Domain } }) => (
                <Badge variant={getBadgeVariant(props.row.original.status)}>
                  <CapitalizeText>{props.row.original.status}</CapitalizeText>
                </Badge>
              ),
            },
            {
              Header: 'Created by',
              colWidth: '20%',
              accessor: 'created',
              Cell: (props: { row: { original: Domain } }) => (
                <CreatedByUser
                  userId={
                    props.row.original?.created_by_id ||
                    props.row.original?.created_by?.id ||
                    0
                  }
                />
              ),
            },
            {
              Header: '',
              colWidth: '5%',
              accessor: 'action',
              Cell: (props: { row: { original: Domain } }) => (
                <Flex direction="row" justify="end">
                  <DomainNavigationButton domain={props.row.original} />
                </Flex>
              ),
            },
          ],
    [loading]
  );

  return (
    <PageLayout
      background="#fafafa"
      breadcrumbs={[
        { title: 'Links', path: '/campaigns/domains' },
        { title: 'Domains', path: '/campaigns/domains' },
      ]}
      actions={
        <>
          <BuyDomain key="buy" />
          <AddDomain key="add" />
        </>
      }
    >
      <Box
        css={{ p: 30, pb: 0, minHeight: '100%', height: 'fit-content', overflow: 'auto' }}
      >
        <Table
          data={data}
          columns={columns}
          sortable={true}
          caption="Domains"
          searchFilter={true}
          searchValue={search}
          setSearchValue={handleSearch}
          limit={LIMIT}
          offset={offset}
          setOffset={setOffset}
          totalCount={totalCount}
          empty={
            <Tr>
              <Td
                colSpan={columns.length}
                css={{ textAlign: 'center', backgroundColor: 'white' }}
              >
                <VStack gap={2}>
                  {search ? <SearchedEmptyState /> : <EmptyState />}
                </VStack>
              </Td>
            </Tr>
          }
        />
      </Box>
    </PageLayout>
  );
};

export const SearchedEmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Domain Matches Your Search</Text>
      <Text css={{ mt: 10 }}>Please try a different filter</Text>
    </EmptyStateContainer>
  );
};

export const EmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Domains</Text>
      <Text css={{ mt: 10 }}>Click the Buy or Add Domain button to get started</Text>
    </EmptyStateContainer>
  );
};

const EmptyStateContainer = styled(Flex, {
  p: 20,
  height: '100%',
  width: '100%',
});

const Label = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 200,
  textOverflow: 'ellipsis',
  fontWeight: '500',
  color: '$slate12',
});

const CapitalizeText = styled(Box, {
  textTransform: 'capitalize',
  fontSize: 14,
});

const DomainLink = styled(Link, {
  fontSize: 14,
  color: '$slate12 !important',
  textDecoration: 'underline',
  textDecorationColor: '$slate12 !important',
  fontWeight: '500',
  '&:hover': {
    textDecoration: 'none',
  },
});
