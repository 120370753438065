import {
  CreateDeveloperApplication,
  CreateDeveloperEndpoint,
  DeveloperApplication,
  DeveloperEndpoint,
} from '@/shared/types/developers';

import { api } from '../api';

export const getApiKeys = () =>
  api.get(`/developers/api_keys`).then((res) => {
    return res.data.data;
  });

export const createApiKey = (name?: string) =>
  api
    .post(`/developers/api_keys`, {
      api_key: { active: true, name: name || 'Default API Key' },
    })
    .then((res) => {
      return res.data.data;
    });

export const enableApiKey = (id: string) =>
  api.put(`/developers/api_keys/${id}`, { api_key: { active: true } }).then((res) => {
    return res.data.data;
  });

export const disableApiKey = (id: string) =>
  api.put(`/developers/api_keys/${id}`, { api_key: { active: false } }).then((res) => {
    return res.data.data;
  });

export const deleteApiKey = (id: string) =>
  api.delete(`/developers/api_keys/${id}`).then((res) => {
    return res.data.data;
  });

export const renameApiKey = (id: string, newName: string) =>
  api.put(`/developers/api_keys/${id}`, { api_key: { name: newName } }).then((res) => {
    return res.data.data;
  });

export const getDeveloperApplications = () =>
  api.get(`/developers/applications`).then((res) => {
    return res.data.data;
  });

export const createDeveloperApplication = (app: CreateDeveloperApplication) =>
  api.post(`/developers/applications`, { application: app }).then((res) => {
    return res.data.data;
  });

export const deleteDeveloperApplication = (id: string) =>
  api.delete(`/developers/applications/${id}`).then((res) => {
    return res.data.data;
  });

export const getDeveloperApplication = (id: string) =>
  api.get(`/developers/applications/${id}`).then((res) => {
    return res.data.data;
  });

export const updateDeveloperApplication = (app: DeveloperApplication) =>
  api.put(`/developers/applications/${app.id}`, { application: app }).then((res) => {
    return res.data.data;
  });

export const getDeveloperEndpoints = (application_id: string) =>
  api.get(`/developers/applications/${application_id}/endpoints`).then((res) => {
    return res.data.data;
  });

export const createDeveloperEndpoint = (endpoint: CreateDeveloperEndpoint) =>
  api.post(`/developers/endpoints`, { endpoint }).then((res) => {
    return res.data.data;
  });

export const deleteDeveloperEndpoint = (id: string) =>
  api.delete(`/developers/endpoints/${id}`).then((res) => {
    return res.data.data;
  });

export const getDeveloperEndpoint = (id: string) =>
  api.get(`/developers/endpoints/${id}`).then((res) => {
    return res.data.data;
  });

export const updateDeveloperEndpoint = (endpoint: DeveloperEndpoint) =>
  api.put(`/developers/endpoints/${endpoint.id}`, { endpoint }).then((res) => {
    return res.data.data;
  });
