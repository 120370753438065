import * as SelectPrimitive from '@radix-ui/react-select';
import React from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { ProviderTypes } from '@/shared/types/locations';
import {
  Fieldset,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';

type SelectProviderProps = {
  /* location api provider */
  provider: ProviderTypes;
  /* set location api provider */
  setProvider: (provider: ProviderTypes) => void;
};

export const SelectProvider = (props: SelectProviderProps) => {
  const { provider, setProvider } = props;
  return (
    <Fieldset>
      <Label>Communication Provider</Label>
      <Select
        defaultValue={ProviderTypes.TELNYX}
        value={provider as string}
        onValueChange={setProvider as React.Dispatch<React.SetStateAction<string>>}
      >
        <SelectTrigger aria-label="action-select-trigger">
          <SelectValue />
          <SelectIcon>
            <HiChevronDown />
          </SelectIcon>
        </SelectTrigger>
        <SelectPrimitive.Portal>
          <SelectContent css={{ zIndex: '9999' }}>
            <SelectScrollUpButton>
              <HiChevronUp />
            </SelectScrollUpButton>
            <SelectViewport>
              <SelectGroup>
                <SelectLabel>Communication Providers</SelectLabel>
                {PROVIDERS.map((provider) => (
                  <SelectItem key={provider.value} value={provider.value}>
                    <SelectItemIndicator />
                    <SelectItemText>{provider.label}</SelectItemText>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectViewport>
          </SelectContent>
        </SelectPrimitive.Portal>
      </Select>
    </Fieldset>
  );
};

export const PROVIDERS = [
  { value: ProviderTypes.TELNYX, label: 'Telnyx' },
  { value: ProviderTypes.TWILIO, label: 'Twilio' },
  { value: ProviderTypes.VONAGE, label: 'Vonage' },
  { value: ProviderTypes.BANDWIDTH, label: 'Bandwidth' },
  { value: ProviderTypes.MAILGUN, label: 'Mailgun' },
  { value: ProviderTypes.SINCH, label: 'Sinch' },
];
