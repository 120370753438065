import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  HiDotsVertical,
  HiExternalLink,
  HiPhoneIncoming,
  HiPhoneOutgoing,
} from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { getAgentVersion } from '@/shared/api/ai/agents';
import { searchCalls } from '@/shared/api/calls';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { AgentVersion } from '@/shared/types/ai/agents';
import { Call } from '@/shared/types/calls';
import { FilterType, Sort } from '@/shared/types/filter';
import { Avatar, Box, Button, Flex, HStack, IconButton, Link, Text } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';

import { default_call_object } from './config/filterConfig';
import { sortConfig } from './config/sortConfig';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import { TranscriptData } from './AICall';

export const AICalls = () => {
  const [calls, setCalls] = useState<{ data: Call[]; total: number }>({
    data: [],
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [activeSort, setActiveSort] = useState<Sort[]>([
    {
      label: 'Updated At',
      column: 'updated_at',
      resource: 'message_attachment',
      order: 'desc',
      id: 1,
    },
  ]);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  useEffect(() => {
    resetFilters();
  }, []);

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    fetchCalls([], activeSort, limit, offset);
  };

  const fetchCalls = async (
    filter: Array<FilterType>,
    sort: Array<Sort>,
    limit: number,
    offset: number
  ) => {
    try {
      setLoading(true);
      const data = await searchCalls(filter, sort, limit, offset);
      setCalls(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const updateFilters = useCallback((filter: Array<FilterType>) => {
    setActiveFilters(filter);
  }, []);

  const debouncedFetchAgents = useCallback(
    debounce(async (filter: Array<FilterType>) => {
      await fetchCalls(cleanFilters(filter), [], limit, offset);
    }, 500),
    []
  );

  const handleFilterChange = useCallback(
    (filter: Array<FilterType>) => {
      updateFilters(filter);
      if (areFiltersValid(cleanFilters(filter))) {
        debouncedFetchAgents(filter);
      }
    },
    [updateFilters, debouncedFetchAgents]
  );

  const handleSortChange = useCallback((sort: Array<Sort>) => {
    setActiveSort(sort);
    fetchCalls(cleanFilters(activeFilters), sort, limit, offset);
  }, []);

  const handleOffsetChange = useCallback((offset: number) => {
    setOffset(offset);
    fetchCalls(cleanFilters(activeFilters), activeSort, limit, offset);
  }, []);

  const handleQuickSearchChange = useCallback((value: string) => {
    setQuickSearchValue(value);
    const filter = {
      resource: 'contact',
      column: 'name',
      comparison: 'ilike',
      value: `%${value}%`,
    };
    fetchCalls(cleanFilters([filter]), activeSort, limit, offset);
  }, []);

  const history = useHistory();

  const columns: Array<TableColumn<Call>> = useMemo(
    () => [
      {
        Header: 'Date & Time',
        colWidth: '',
        accessor: 'call.time',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 140 }}>
            <Box css={{ fontSize: 13 }}>
              {dayjs(props.row.original.updated_at).format('MMM D YYYY')}
            </Box>
            <Box css={{ fontSize: 10 }}>
              {dayjs(props.row.original.updated_at).format('h:mm A')}
            </Box>
          </Flex>
        ),
      },
      {
        Header: 'Contact',
        colWidth: '100%',
        accessor: 'contact.name',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 200 }}>
            <HStack>
              <Avatar size="3" fallback={initials(props.row.original.contact.name)} />
              <Flex direction="column">
                <Box css={{ fontSize: 13 }}>{props.row.original.contact.name}</Box>
                <Box css={{ fontSize: 10 }}>
                  {formatPhoneNumber(props.row.original.contact.phone || '')}
                </Box>
              </Flex>
            </HStack>
          </Flex>
        ),
      },
      {
        Header: 'Agent Version',
        colWidth: '100%',
        accessor: 'call.agent_version_id',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 160 }}>
            <AgentVersionCell id={props.row.original.attachments[0].agent_version_id} />
          </Flex>
        ),
      },
      {
        Header: 'Call Duration',
        colWidth: '100%',
        accessor: 'call.duration',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 110 }}>
            <CallDuration
              url={
                props.row.original.attachments.filter((attachment) =>
                  attachment.url.endsWith('/transcript.json')
                )[0].url
              }
            />
          </Flex>
        ),
      },
      {
        Header: 'Call Direction',
        colWidth: '100%',
        accessor: 'call.updated_at',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 135 }}>
            <HStack align="center">
              {props.row.original.source_type === 'INBOUND' ? (
                <HiPhoneIncoming />
              ) : (
                <HiPhoneOutgoing />
              )}
              <Text>{props.row.original.source_type}</Text>
            </HStack>
          </Flex>
        ),
      },
      {
        Header: 'Actions',
        colWidth: '100%',
        accessor: 'call.actions',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 135 }}>
            <HStack>
              <IconButton size="2" variant="outline">
                <HiDotsVertical />
              </IconButton>
              <Button
                variant="gray"
                onClick={() => history.push(`/ai/calls/${props.row.original.id}`)}
              >
                View Call
              </Button>
            </HStack>
          </Flex>
        ),
      },
    ],
    [loading, calls]
  );

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'AI', path: '/ai/agents' },
        { title: 'Calls', path: '/ai/calls' },
      ]}
    >
      <Flex direction="column" css={{ flex: '1 1 auto', p: 30 }}>
        <Box css={{ paddingBottom: '$space$3' }}>
          <CombinedFilters
            quickSearchPlaceholder="Search Calls"
            quickSearchValue={quickSearchValue}
            setQuickSearchValue={handleQuickSearchChange}
            defaultObjects={[default_call_object, default_contact_object]}
            customObjects={[]}
            activeFilters={activeFilters}
            setFilters={handleFilterChange}
            sortConfig={sortConfig}
            activeSort={activeSort}
            onSortUpdate={handleSortChange}
          />
        </Box>
        <Table
          data={calls.data}
          columns={columns}
          caption="Calls Table"
          emptyTitle="No Calls Match Search"
          totalCount={calls.total}
          pageSize={10}
          isLoading={loading}
          setOffset={handleOffsetChange}
        />
      </Flex>
    </PageLayout>
  );
};

// Take a transcript url and calculate the duration of the call
// Take a transcript url and calculate the duration of the call
function CallDuration({ url }: { url: string }) {
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setDuration(calculateDuration(data));
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [url]);

  const calculateDuration = (data: TranscriptData) => {
    let minStart = Infinity;
    let maxEnd = -Infinity;

    data.forEach((entry) => {
      if ('words' in entry) {
        // Check if entry has words. Remove tool invocations
        entry.words.forEach((word) => {
          if (word.start < minStart) {
            minStart = word.start;
          }
          if (word.end > maxEnd) {
            maxEnd = word.end;
          }
        });
      }
    });

    return Number((maxEnd - minStart).toFixed(0));
  };

  const formatDuration = (duration: number) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return minutes > 0 ? `${minutes} Min ${seconds} Sec` : `${seconds} Sec`;
  };

  return <Box>{duration > 0 ? formatDuration(duration) : 'Unknown'}</Box>;
}

// Takes an agent version id and render a link to the agents page
function AgentVersionCell({ id }: { id: string }) {
  const [agentVersion, setAgentVersion] = useState<AgentVersion | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAgentVersion(id);
        setAgentVersion(response.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [id]);

  const history = useHistory();

  return (
    <Box>
      {loading ? (
        'Loading...'
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          variant="contrast"
          href="#"
          onClick={() =>
            history.push(
              `/ai/agents/${agentVersion?.agent.id}/versions/${agentVersion?.id}`
            )
          }
        >
          <HStack>
            <Box>{agentVersion?.name}</Box>
            <HiExternalLink />
          </HStack>
        </Link>
      )}
    </Box>
  );
}
