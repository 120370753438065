import React from 'react';

import { useOnboarding } from './context/OnboardingProvider';
import ConfirmPhone from './steps/ConfirmPhone';
import FindAddress from './steps/FindAddress';
import FindWebsite from './steps/FindWebsite';
import InstallWebchat from './steps/InstallWebchat';
import SearchPhone from './steps/SearchPhone';

export const renderOnboardingStep = (current: string) => {
  switch (current) {
    case 'find_address':
      return <FindAddress />;
    case 'find_website':
      return <FindWebsite />;
    case 'install_webchat':
      return <InstallWebchat />;
    case 'confirm_phone':
      return <ConfirmPhone />;
    case 'search_phone':
      return <SearchPhone />;
    default:
      return null;
  }
};

export function Onboarding() {
  const onboarding = useOnboarding();

  return <>{renderOnboardingStep(onboarding.currentStep)}</>;
}
