/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { toast } from 'sonner';

import { useAuth } from '@/auth/context/AuthProvider';
import { Organization } from '@/shared/types';
import { Box, Button, Fieldset, Input, Label } from '@/shared/ui';
import { handleScriptLoad, loadScript } from '@/shared/utils/setup/google';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

import { useOnboarding } from './context/OnboardingProvider';
import { OnboardingContainer } from './OnboardingContainer';
import { OnboardingHeading } from './OnboardingContainer';

export function AddOrganization() {
  const auth = useAuth();
  const onboarding = useOnboarding();
  const history = useHistory();
  const { organizationDetails, setOrganizationDetails } = onboarding;
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvZkCD8dE8AHfGya8Z_l8coxbhcsKiDvM&libraries=places`,
      () => handleScriptLoad(setOrganizationDetails, autoCompleteRef)
    );
  }, []);

  const getValidationError = () => {
    if (!organizationDetails?.name) {
      return toast.error(i18next.t('enter_valid_organization_name') as string);
    } else {
      return null;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const error = getValidationError();

    if (error) {
      return;
    }

    const organization_name = organizationDetails?.name;
    const website = organizationDetails?.website || '';

    try {
      const newOrganization: Organization = await onboarding.createNewAccount({
        organization_name,
        website,
      });
      await auth.changeOrganization(newOrganization?.id);
      history.go(0);
    } catch (e) {
      toast.error(i18next.t('account_creation_failure') as string);
    }
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Add Organization</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <OnboardingContainer>
          <OnboardingHeading>Create New Organization</OnboardingHeading>
          <Fieldset>
            <LabelContainer>
              <Label css={{ mr: 11 }}>Organization Name</Label>
              <Label css={{ fontSize: 11 }}>(The name of your Business in Whippy)</Label>
            </LabelContainer>
            <Input
              ref={autoCompleteRef}
              id="organizationName"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOrganizationDetails({ name: e.target.value })
              }
              placeholder="Example: Law offices of Mauro Fiore"
              value={organizationDetails?.name || ''}
            />
          </Fieldset>
          <Button type="submit" css={{ width: '100%' }}>
            Create Organization
          </Button>
        </OnboardingContainer>
      </form>
    </>
  );
}

export const LabelContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  align: 'left',

  '@md': {
    flexDirection: 'row',
    align: 'center',
  },
});

export const TopContainer = styled(Box, {
  top: '10px',
  position: 'absolute',
  fontSize: 13,
});
