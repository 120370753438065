/* eslint-disable @typescript-eslint/ban-ts-comment */
import { styled } from '@stitches/react';
import {
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarSearch,
  useKBar,
  useMatches,
} from 'kbar';
import { useMemo } from 'react';

import { Spinner } from '../components/Icons';
import { RenderSearchResults } from './results/searchResults';
import { useSearch } from './SearchContext';
import { SearchFooter } from './searchFooter';
import { SearchTabs } from './searchTabs';

// TODO: Add a filter bar for users, campaigns, etc.
export const GlobalSearch = () => {
  const {
    selectedTab,
    setSelectedTab,
    tabs,
    searchInput,
    loadings,
    setActiveItemIndex,
    activeItemIndex,
    searchResults,
    paginatings,
  } = useSearch();
  const { results: kbarActions } = useMatches();
  const { query } = useKBar();

  // Handles keyboard navigation between tabs and search results
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Navigate between tabs using left and right arrow keys
    if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
      e.preventDefault();
      const currentIndex = tabs.indexOf(selectedTab);
      const direction = e.key === 'ArrowRight' ? 1 : -1;
      const nextIndex = (currentIndex + direction + tabs.length) % tabs.length;
      setSelectedTab(tabs[nextIndex]);
    }
    // Navigate search results using up and down arrow keys
    else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      const tabId = selectedTab;
      const searchedResults =
        tabId === 'All' ? Object.values(searchResults).flat() : searchResults[tabId];

      const results = [...kbarActions.slice(1), ...searchedResults];
      const direction = e.key === 'ArrowDown' ? 1 : -1;
      const nextIndex = Math.max(
        0,
        Math.min(activeItemIndex + direction, results.length - 1)
      );
      setActiveItemIndex(nextIndex);
    }
    // Perform action on selected search result when Enter key is pressed
    else if (e.key === 'Enter') {
      e.preventDefault();
      const items =
        selectedTab === 'All'
          ? Object.values(searchResults).flat()
          : searchResults[selectedTab];
      const itemIsKbarAction = activeItemIndex < kbarActions.length;

      // Filter out only the non-string elements from kbarActions
      const kbarActionsFiltered = kbarActions.filter(
        (action) => typeof action !== 'string'
      ) as ActionImpl[];

      const selectedItem = itemIsKbarAction
        ? kbarActionsFiltered[activeItemIndex]
        : items[activeItemIndex - kbarActions.length];

      console.log({ selectedItem, activeItemIndex, kbarActionsFiltered, items });

      if (selectedItem) {
        // Execute the perform method on the selected search result item
        // @ts-ignore
        selectedItem?.perform();
        query?.toggle();
      }
    }
  };

  // Determine if any search-related process is loading
  const isLoadingActive = useMemo(() => {
    return Object.values(loadings).some((loading) => loading);
  }, [loadings]);

  // Determine if any search-related process is paginating
  const isPaginatingActive = useMemo(() => {
    return Object.values(paginatings).some((pagination) => pagination);
  }, [paginatings]);

  return (
    <KBarPortal>
      {/* @ts-ignore */}
      <KBarPositioner onKeyDown={handleKeyDown}>
        <KBarAnimator style={animatorStyle}>
          <SearchContainerStyle textActive={searchInput.length > 0}>
            <KBarSearch style={{ ...searchStyle, flex: 1 }} />
            {(isLoadingActive || isPaginatingActive) && (
              <Spinner size={2} color="primary" style={{ marginRight: 16 }} />
            )}
          </SearchContainerStyle>
          <SearchTabs />
          <RenderSearchResults />
          <SearchFooter />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

const SearchContainerStyle = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box' as React.CSSProperties['boxSizing'],
  outline: 'none',
  border: 'none',
  background: 'rgb(252 252 252)',
  color: 'rgb(28 28 29)',
  borderBottom: '1px solid #E5E5E5',
  variants: {
    textActive: {
      true: {
        borderBottom: '1px solid var(--colors-primaryColor)',
      },
    },
  },
});

const searchStyle = {
  padding: '12px 16px',
  fontSize: '16px',
  width: '100%',
  boxSizing: 'border-box' as React.CSSProperties['boxSizing'],
  outline: 'none',
  border: 'none',
  background: 'rgb(252 252 252)',
  color: 'rgb(28 28 29)',
};

const animatorStyle = {
  maxWidth: '600px',
  minHeight: 'calc(100vh - 250px)',
  maxHeight: 'calc(100vh - 250px)',
  width: '100%',
  background: 'rgb(252 252 252)',
  color: 'rgb(28 28 29)',
  overflow: 'hidden',
  boxShadow: '0px 6px 20px rgb(0 0 0 / 20%)',
  border: '1px solid #E5E5E5',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column' as const,
};
