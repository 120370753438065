import i18next from 'i18next';
import React, { Dispatch } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import * as AutomationTemplatesAPI from '@/shared/api/automations/templates';
import {
  AutomationTemplate,
  AutomationTemplatesActions,
  AutomationTemplatesActionTypes,
  GetAutomationTemplateParams,
} from '@/shared/types/automations';

import { AutomationTemplatesReducer } from './AutomationsTemplatesReducer';

export type AutomationTemplatesState = {
  templates: AutomationTemplate[];
  loading: boolean;
  current: AutomationTemplate | null;
};

export const initialAutomationTemplatesState: AutomationTemplatesState = {
  templates: [],
  loading: true,
  current: null,
};

export const AutomationTemplatesContext = createContext<{
  automationTemplatesState: AutomationTemplatesState;
  getAutomationTemplates: (
    params: GetAutomationTemplateParams
  ) => Promise<AutomationTemplate[]>;
  getAutomationTemplate: (id: string) => Promise<AutomationTemplate>;
  createAutomationTemplate: (template: AutomationTemplate) => Promise<AutomationTemplate>;
  updateAutomationTemplate: (template: AutomationTemplate) => Promise<AutomationTemplate>;
  deleteAutomationTemplate: (id: string) => Promise<void>;
  setCurrentAutomationTemplate: (template: AutomationTemplate | null) => void;
}>({
  automationTemplatesState: initialAutomationTemplatesState,
  getAutomationTemplates: () => Promise.resolve([]),
  getAutomationTemplate: () => Promise.resolve({} as AutomationTemplate),
  createAutomationTemplate: () => Promise.resolve({} as AutomationTemplate),
  updateAutomationTemplate: () => Promise.resolve({} as AutomationTemplate),
  deleteAutomationTemplate: () => Promise.resolve(),
  setCurrentAutomationTemplate: () => null,
});

export const useAutomationTemplates = () => useContext(AutomationTemplatesContext);

const AutomationTemplatesState = ({ children }: { children: React.ReactNode }) => {
  const [automationTemplatesState, dispatch]: [
    AutomationTemplatesState,
    Dispatch<AutomationTemplatesActions>,
  ] = useReducer(AutomationTemplatesReducer, initialAutomationTemplatesState);

  const getAutomationTemplates = async (params: GetAutomationTemplateParams) => {
    try {
      const data = await AutomationTemplatesAPI.getAutomationTemplates(params);

      dispatch({
        type: AutomationTemplatesActionTypes.GET_AUTOMATION_TEMPLATES,
        payload: data,
      });

      return data;
    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  };

  const getAutomationTemplate = async (id: string) => {
    try {
      const data = await AutomationTemplatesAPI.getAutomationTemplate(id);

      dispatch({
        type: AutomationTemplatesActionTypes.GET_AUTOMATION_TEMPLATE,
        payload: data,
      });

      return data;
    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  };

  const createAutomationTemplate = async (template: AutomationTemplate) => {
    try {
      const data = await AutomationTemplatesAPI.createAutomationTemplate(template);

      dispatch({
        type: AutomationTemplatesActionTypes.CREATE_AUTOMATION_TEMPLATE,
        payload: data,
      });

      toast.success(i18next.t('automation_template_created_success') as string);

      return data;
    } catch (error) {
      console.error(error);

      toast.error(i18next.t('automation_template_created_failure') as string);

      return Promise.reject(error);
    }
  };

  const updateAutomationTemplate = async (template: AutomationTemplate) => {
    try {
      const data = await AutomationTemplatesAPI.updateAutomationTemplate(template);

      dispatch({
        type: AutomationTemplatesActionTypes.UPDATE_AUTOMATION_TEMPLATE,
        payload: data,
      });

      toast.success(i18next.t('automation_template_updated_success') as string);

      return data;
    } catch (error) {
      console.error(error);

      toast.error(i18next.t('automation_template_updated_failure') as string);

      return Promise.reject(error);
    }
  };

  const deleteAutomationTemplate = async (id: string) => {
    try {
      await AutomationTemplatesAPI.deleteAutomationTemplate(id);

      dispatch({
        type: AutomationTemplatesActionTypes.DELETE_AUTOMATION_TEMPLATE,
        payload: id,
      });

      toast.success(i18next.t('automation_template_deleted_success') as string);
    } catch (error) {
      console.error(error);

      toast.error(i18next.t('automation_template_deleted_failure') as string);

      return Promise.reject(error);
    }
  };

  const setCurrentAutomationTemplate = (template: AutomationTemplate | null) => {
    dispatch({
      type: AutomationTemplatesActionTypes.SET_CURRENT_AUTOMATION_TEMPLATE,
      payload: template,
    });
  };

  return (
    <AutomationTemplatesContext.Provider
      value={{
        automationTemplatesState,
        getAutomationTemplates,
        getAutomationTemplate,
        createAutomationTemplate,
        updateAutomationTemplate,
        deleteAutomationTemplate,
        setCurrentAutomationTemplate,
      }}
    >
      {children}
    </AutomationTemplatesContext.Provider>
  );
};

export default AutomationTemplatesState;
