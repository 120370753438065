import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { BulkAction } from '../FiltersTable';

type BulkActionDropdownProps = {
  bulkActions: BulkAction[];
  handleClick: (bulkAction: BulkAction) => void;
};

export const BulkActionDropdown = ({
  handleClick,
  bulkActions,
}: BulkActionDropdownProps) => {
  // if we have no bulk actions don't display a dropdown
  if (bulkActions.length == 0) return <></>;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Flex
          css={{
            cursor: 'pointer',
            color: 'white',
            paddingLeft: '8px',
            paddingRight: '8px',
            marginLeft: '8px',
            borderWidth: '2px',
            borderRadius: '6px',
            borderColor: 'white',
          }}
        >
          <Button
            ghost
            variant="gray"
            size="2"
            css={{
              color: 'White',
            }}
          >
            More
          </Button>
        </Flex>
      </DropdownMenuTrigger>
      <StyledDropdownMenuContent side="top" sideOffset={10}>
        <Flex direction="column">
          {bulkActions?.map((bulkAction) => {
            return (
              <DropdownMenuItem
                key={bulkAction.action}
                onClick={() => handleClick(bulkAction)}
                asChild
                style={{
                  textAlign: 'center',
                }}
              >
                <Button
                  ghost
                  variant="gray"
                  size="2"
                  css={{
                    color: 'White',
                  }}
                >
                  {' '}
                  {bulkAction.label}
                </Button>
              </DropdownMenuItem>
            );
          })}
        </Flex>
      </StyledDropdownMenuContent>
    </DropdownMenu>
  );
};

const StyledDropdownMenuContent = styled(DropdownMenuContent, {
  cursor: 'pointer',
  backgroundColor: 'black',
  color: 'white',
});
