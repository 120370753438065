import React, { useEffect } from 'react';

import { useAuth } from '@/auth/context/AuthProvider';
import { buyPhoneNumber, getPhone } from '@/shared/api/phone';
import { Button, Fieldset, Input, ProgressBar, VStack } from '@/shared/ui';
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from '@/shared/utils/validations/validations';

import { useOnboarding } from '../context/OnboardingProvider';
import {
  OnboardingContainer,
  OnboardingDescription,
  OnboardingHeading,
  SkipOnboarding,
} from '../OnboardingContainer';

const ConfirmPhone = () => {
  const onboarding = useOnboarding();
  const auth = useAuth();
  const { setCurrentStep, phone, setPhone } = onboarding;

  useEffect(() => {
    async function getPhoneNumber() {
      const data = await getPhone('323');
      setPhone(data.phone);
    }

    if (!isValidPhoneNumber(phone)) {
      getPhoneNumber();
    }
    // eslint-disable-next-line
  }, []);

  const finishOnboarding = () => {
    async function buyNumber() {
      await buyPhoneNumber({ phone_number: phone });
    }

    async function completeOnboarding() {
      await auth.updateOnboardingStatus();
    }

    buyNumber();
    completeOnboarding();
  };

  return (
    <>
      <ProgressBar value={(5 / 5) * 100} variant="purple" />
      <SkipOnboarding />
      <OnboardingContainer>
        <OnboardingHeading>{"You're ready to rock"}</OnboardingHeading>
        <OnboardingDescription>
          This is your new Whippy phone number. You can send and receive SMS, request
          reviews and collaborate with your team.
        </OnboardingDescription>
        <Fieldset>
          <Input
            css={{
              height: 80,
              fontSize: 22,
              fontWeight: 600,
              textAlign: 'center',
            }}
            placeholder="(323) 424-9459"
            value={formatPhoneNumber(phone)}
          />
        </Fieldset>
        <VStack gap={2} css={{ width: '100%' }}>
          <Button onClick={() => finishOnboarding()}>Open Whippy</Button>
          <Button
            onClick={() => setCurrentStep('search_phone')}
            variant="gray"
            ghost="true"
          >
            I want to change my number
          </Button>
        </VStack>
      </OnboardingContainer>
    </>
  );
};

export default ConfirmPhone;
