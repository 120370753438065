import React from 'react';
import { toast } from 'sonner';

import { unpauseLocations, unpauseLocationsParams } from '@/shared/api/contacts/v1';
import { Location } from '@/shared/types/locations';
import {
  Button,
  Dialog,
  DialogContent,
  DialogPortal,
  DialogTitle,
  Flex,
  HStack,
  Text,
} from '@/shared/ui';

type UnsubscribePreferenceType = {
  /** Whether the Unpause Confirmation dialog is open or not **/
  isUnpauseDialogOpen: boolean;
  /** Function to set whether the Unpause Confirmation dialog is open or not **/
  setIsUnpauseDialogOpen: (value: boolean) => void;
  /** The selected paused locations **/
  selectedPausedLocations: Array<Location>;
  /** The contact id of the current contact **/
  contactId: string;
  /** Function to close the entire dialogs **/
  onClose: () => void;
};

const UnpauseConfirmationDialog = (props: UnsubscribePreferenceType): JSX.Element => {
  const {
    isUnpauseDialogOpen,
    setIsUnpauseDialogOpen,
    selectedPausedLocations,
    contactId,
    onClose,
  } = props;

  // handler when users hit "Yes, I'm sure" button in the unpause dialog
  const handleUnpauseConfirm = () => {
    // make api call to unpause the selected locations
    if (selectedPausedLocations && selectedPausedLocations.length > 0) {
      try {
        const apiCallParams: unpauseLocationsParams = {
          // audience.contacts is an array of contact ids (here there's only 1 contact)
          audience: {
            contacts: [contactId],
          },
          // all paused AND selected locations
          location_ids: selectedPausedLocations.map((location) => location.id),
        };
        unpauseLocations(apiCallParams);
        toast.success('Unpause locations successful.');
      } catch (error) {
        toast.error('Unpause locations unsuccessful.');
      }
    } else {
      toast.error('Please select paused locations to unpause.');
    }
    setIsUnpauseDialogOpen(false);
    onClose();
  };

  const handleUnpauseCancel = () => {
    setIsUnpauseDialogOpen(false);
  };

  return (
    <Dialog open={isUnpauseDialogOpen} modal={false}>
      <DialogPortal>
        <DialogContent
          onEscapeKeyDown={() => setIsUnpauseDialogOpen(false)}
          css={{ zIndex: 100000, width: '400px' }}
        >
          <DialogTitle>Unpause Confirmation</DialogTitle>
          <Text>Are you sure to unpause the selected active locations?</Text>
          <Flex justify="end" css={{ pt: 20 }}>
            <HStack>
              <Button variant="gray" onClick={handleUnpauseCancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleUnpauseConfirm}>
                Yes, I am sure
              </Button>
            </HStack>
          </Flex>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default UnpauseConfirmationDialog;
