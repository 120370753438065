/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React from 'react';
import { createContext, useContext, useEffect, useReducer } from 'react';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import {
  createDraftMessage,
  deleteDraftMessage,
  getDraftsMessagesByConversation,
  getFilteredDraftMessages,
  GetFilteredDraftMessagesParams,
} from '@/shared/api/message_drafts';
import { DraftMessage } from '@/shared/types';

import DraftMessagesReducer from './DraftMessagesReducer';
import {
  CREATE_DRAFT_MESSAGE,
  DELETE_DRAFT_MESSAGE,
  GET_PART_OF_DRAFT_MESSAGES,
  SET_CURRENT,
} from './types';

export type DraftMessagesState = {
  draftMessages: Array<DraftMessage>;
  loading: boolean;
  current: DraftMessage | null | undefined;
  error: Error | null;
};

export const initialDraftMessagesState: DraftMessagesState = {
  draftMessages: [],
  current: null,
  error: null,
  loading: true,
};

export const DraftMessagesContext = createContext<{
  draftMessagesState: DraftMessagesState;
  getAllDraftMessagesByConversationId: (conversationId: string) => any;
  getPartOfDraftMessages: (params: GetFilteredDraftMessagesParams) => void;
  createOneDraftMessage: (
    body: string,
    conversationId: string,
    attachments: Array<{ url: string; content_type?: string | null }>
  ) => void;
  deleteOneDraftMessage: (id: string) => void;
  setCurrentDraftMessage: (draftMessage: DraftMessage | null | undefined) => void;
  getDraftFromState: (id: string) => void;
}>({
  draftMessagesState: initialDraftMessagesState,
  getAllDraftMessagesByConversationId: () => Promise.resolve(),
  getPartOfDraftMessages: () => Promise.resolve(),
  createOneDraftMessage: () => Promise.resolve(),
  deleteOneDraftMessage: () => Promise.resolve(),
  setCurrentDraftMessage: () => Promise.resolve(),
  getDraftFromState: () => Promise.resolve(),
});

export const useDraftMessages = () => useContext(DraftMessagesContext);

const DraftMessageState = ({ children }: { children: React.ReactNode }) => {
  const [draftMessagesState, dispatch] = useReducer(
    DraftMessagesReducer,
    initialDraftMessagesState
  );

  const { locationsState } = useLocations();
  const locations = locationsState.locations.map((location) => location.id);

  const startDate = moment().add(-60, 'day');
  const endDate = moment().add(1, 'day');

  useEffect(() => {
    // get all draft messages on mount
    if (draftMessagesState.draftMessages.length < 1) {
      getPartOfDraftMessages({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        location_ids: locations,
      });
    }
  }, []);

  const getAllDraftMessagesByConversationId = async (conversationId: string) => {
    try {
      const data = await getDraftsMessagesByConversation(conversationId);
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const getPartOfDraftMessages = async (params: GetFilteredDraftMessagesParams) => {
    try {
      const data = await getFilteredDraftMessages(params);

      if (data) {
        dispatch({
          type: GET_PART_OF_DRAFT_MESSAGES,
          payload: data,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const deleteOneDraftMessage = async (id: string) => {
    try {
      await deleteDraftMessage(id);

      dispatch({
        type: DELETE_DRAFT_MESSAGE,
        payload: id,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const createOneDraftMessage = async (
    body: string,
    conversationId: string,
    attachments: Array<{ url: string; content_type?: string | null }>
  ) => {
    try {
      const data = await createDraftMessage(body, conversationId, attachments);

      if (data) {
        dispatch({
          type: CREATE_DRAFT_MESSAGE,
          payload: data,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const setCurrentDraftMessage = (draftMessage: DraftMessage | null | undefined) => {
    try {
      dispatch({
        type: SET_CURRENT,
        payload: draftMessage,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getDraftFromState = async (id: string) => {
    const draft = await draftMessagesState.draftMessages.find(
      (draft: { conversation_id: string }) => draft.conversation_id === id
    );

    return draft as DraftMessage;
  };

  return (
    <DraftMessagesContext.Provider
      value={{
        draftMessagesState,
        getAllDraftMessagesByConversationId,
        getPartOfDraftMessages,
        createOneDraftMessage,
        deleteOneDraftMessage,
        setCurrentDraftMessage,
        getDraftFromState,
      }}
    >
      {children}
    </DraftMessagesContext.Provider>
  );
};

export default DraftMessageState;
