/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { FormikValues, useFormikContext } from 'formik';
import _ from 'lodash';
import { useState } from 'react';

import { ComboboxMultiselect } from '../../comboboxMultiselect/ComboboxMultiselect';
import {
  ComboboxItemProps,
  ComboboxMultiselectTriggerProps,
  OptionType,
} from '../../comboboxMultiselect/types';

export type ComboboxProps<T> = {
  options: Array<OptionType<T>>;
  Trigger: React.FC<ComboboxMultiselectTriggerProps<T>>;
  Option: React.FC<ComboboxItemProps<T>>;
  selectLabel?: string;
  searchLabel?: string;
  /* Start with all the options selected */
  selectAll?: boolean;
  disabled?: boolean;
  name?: string;
  invalid?: boolean;
};

export const ComboboxMultiselectInput = <T extends unknown>({
  options,
  searchLabel,
  selectLabel,
  Trigger,
  Option,
  selectAll,
  disabled,
  name,
  invalid,
}: ComboboxProps<T>) => {
  const formik = useFormikContext<FormikValues>();

  const value = _.get(formik.values, name as string);
  const [selectedItems, setSelectedItems] = useState<string[]>([...value]);

  const setSelectedValues = (locations: Array<string>) => {
    setSelectedItems(locations);
    formik.setFieldTouched(name as string, true);
    formik.setFieldValue(name as string, locations);
  };

  return (
    <div>
      <ComboboxMultiselect<T>
        options={options}
        selected={selectedItems}
        onSelect={setSelectedValues}
        searchLabel={searchLabel}
        selectLabel={selectLabel}
        Trigger={Trigger}
        Option={Option}
        disabled={disabled}
        selectAll={selectAll}
        invalid={invalid}
      />
    </div>
  );
};
