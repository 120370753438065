/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { conversationSearch } from '@/shared/api/conversations';
import {
  ConversationSearchTypeV2,
  SearchV2Type,
  WhippyQueryLanguage,
  WhippyQueryLanguageFilterSchema,
} from '@/shared/types/conversations';

import { TabOptions, useSearch } from '../SearchContext';
import { MessageSkeleton } from '../skeletons/message';
import { GroupName, KeepResultsFocused } from '../utils';
import { EmptyResults } from './emptyResults';
import { ErrorResults } from './errorResults';

export function RenderMessageSearch({
  limit = 50,
  showEmptyResults = false,
  showResults = true,
}: {
  limit?: number;
  showEmptyResults?: boolean;
  showResults?: boolean;
}) {
  // Get search state and functions from SearchContext
  const {
    searchInput,
    setSearchInput,
    loadings,
    setLoadings,
    cancelPreviousRequest,
    setControllers,
    searchResults,
    setSearchResults,
    errors,
    setErrors,
    offsets,
    setOffsets,
    setPaginatings,
  } = useSearch();

  const history = useHistory();

  useEffect(() => {
    // Reset search results and trigger search when searchInput changes
    setSearchResults((prev) => ({ ...prev, [TabOptions.Messages]: [] }));
    if (searchInput) {
      setLoadings((prev) => ({ ...prev, [TabOptions.Messages]: true }));
      debounceConversationSearch({ inputValue: searchInput });
    }
  }, [searchInput]);

  // Debounce search to reduce API calls during typing
  const debounceConversationSearch = useCallback(
    debounce((searchV2: SearchV2Type) => {
      searchConversations(searchV2);
    }, 350),
    []
  );

  // Perform the actual search using the provided input value
  const searchConversations = async ({
    inputValue,
    offset = 0,
    shouldAppendResults = false,
  }: SearchV2Type) => {
    try {
      cancelPreviousRequest('Messages'); // Cancel any ongoing search to prevent race conditions

      const controller = new AbortController(); // For cancelable fetch requests
      setControllers((prev) => ({ ...prev, Messages: controller }));

      // Prepare search query payload
      const searchFilter: WhippyQueryLanguageFilterSchema = {
        resource: 'message',
        column: 'body',
        comparison: 'ilike',
        value: `${inputValue}%`,
        or: [
          {
            resource: 'message',
            column: 'body',
            comparison: 'ilike',
            value: `%${inputValue}%`,
          },
        ],
      };

      const searchPayload: WhippyQueryLanguage = {
        filter: [searchFilter],
        limit,
        offset,
      };

      // Call API to search conversations
      const searchResults = await conversationSearch(searchPayload, controller);

      const results = searchResults.data.map((conversation) => {
        const item = {
          id: conversation.id,
          name: inputValue,
          perform: () => handleClick(conversation),
          shortcut: [],
          section: 'Message Search',
          subtitle: '',
          keywords: `search messageSearch ${inputValue}`,
          conversation: conversation,
        };
        return item;
      });

      // If end reached, prevent further pagination
      if (results.length === 0) setOffsets((prev) => ({ ...prev, Messages: -1 }));

      setSearchResults((prev) => ({
        ...prev,
        Messages: shouldAppendResults ? [...prev.Messages, ...results] : results,
      }));
      setLoadings((prev) => ({ ...prev, [TabOptions.Messages]: false }));
    } catch (error) {
      console.error(error);

      if (error.name === 'AbortError' || error.name === 'CanceledError') {
        return console.log('AbortError: The operation was aborted');
      }

      // Display error toast and reset search state on error
      const errorMessage =
        error?.response?.data?.errors?.[0]?.description ?? 'Failed to search messages';
      setLoadings((prev) => ({ ...prev, [TabOptions.Messages]: false }));
      setSearchResults((prev) => ({ ...prev, [TabOptions.Messages]: [] }));
      setErrors((prev) => ({
        ...prev,
        [TabOptions.Messages]: { hasError: true, error: errorMessage },
      }));
      captureException(error, {
        tags: {
          area: 'globalSearch',
          feature: 'messages',
        },
      });
    }
  };

  const hasNoResults = useMemo(
    () => searchResults?.Messages?.length === 0 && searchInput !== '',
    [searchResults]
  );

  const handleMoreResults = () => {
    const offset = offsets[TabOptions.Messages];
    const isLoading = loadings[TabOptions.Messages];
    if (offset > 0 && !hasNoResults && !isLoading) {
      console.log(`handle more results: ${offset}, hasNoResults: ${hasNoResults}`);
      searchConversations({
        inputValue: searchInput,
        offset,
        shouldAppendResults: true,
      }).finally(() => {
        setPaginatings((prev) => ({ ...prev, [TabOptions.Messages]: false }));
      });
    }
  };

  useEffect(() => {
    handleMoreResults();
  }, [offsets[TabOptions.Messages]]);

  // Handle click on a search result item
  const handleClick = (conversation: ConversationSearchTypeV2) => {
    console.log(`handle click`);
    history.push(
      `/inbox/all/open/${conversation?.id}?message_id=${conversation?.conversationItemsPage?.conversationItems[0]?.id}`
    );

    setSearchResults((prev) => ({ ...prev, [TabOptions.Messages]: [] }));
    setSearchInput('');
  };

  // Display loading message while searching
  if (loadings[TabOptions.Messages]) {
    return (
      <>
        <GroupName>Searching messages...</GroupName>
        <MessageSkeleton />
      </>
    );
  }

  // Display error message if search request fails
  if (showResults && errors?.Messages?.hasError) {
    return <ErrorResults errorMessage={errors?.Messages?.error} />;
  }

  // Display "No results found" message if search input is not empty and no results are found
  if (hasNoResults && !loadings.Messages && showEmptyResults) {
    return <EmptyResults />;
  }

  // Display search results
  if (!hasNoResults && showResults) {
    return (
      <>
        <KeepResultsFocused results={searchResults?.Messages} label="Messages" />
      </>
    );
  }
}
