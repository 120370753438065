import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Import Day.js plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export function getHours(
  startDate: string,
  timezone: string | undefined,
  timeFormat = 'h:mm A',
  incrementSize = 15
): string[] {
  // Parse the startDate string using Day.js and validate that it is a valid date
  const startDay = dayjs(startDate, 'MM/DD/YYYY');

  if (!startDay.isValid()) {
    throw new Error('Invalid startDate');
  }

  // Determine the current day based on the specified timezone (if applicable) or local time
  const currentDay = timezone ? dayjs().tz(timezone) : dayjs();

  // If the startDay is after the currentDay, return all the hours in the day
  if (startDay.isAfter(currentDay, 'day')) {
    // Calculate the start of the day and the start minute for generating time strings
    const startOfDay = startDay.startOf('day');
    const startMinute = 0;

    // Generate an array of time strings by looping through each increment of the specified size
    const hours = [];
    for (let minute = startMinute; minute < 1440; minute += incrementSize) {
      // Use Day.js to add the minute offset to the start of the day and format the resulting time as a string
      const time = startOfDay.add(minute, 'minute').format(timeFormat);
      hours.push(time);
    }

    // Return the array of generated time strings
    return hours;
  }

  // If the startDay is the same as the currentDay, return only the remaining hours in the day
  if (startDay.isSame(currentDay, 'day')) {
    // Calculate the start of the day and the start minute for generating time strings
    const startOfDay = currentDay.startOf('day');
    const startMinute =
      Math.ceil(currentDay.diff(startOfDay, 'minute') / incrementSize) * incrementSize;

    // Generate an array of time strings by looping through each increment of the specified size
    const hours = [];
    for (let minute = startMinute; minute < 1440; minute += incrementSize) {
      // Use Day.js to add the minute offset to the start of the day and format the resulting time as a string
      const time = startOfDay.add(minute, 'minute').format(timeFormat);
      hours.push(time);
    }

    // Return the array of generated time strings
    return hours;
  }

  // If the startDay is before the currentDay, return an empty array
  return [];
}

/*
convenience method to convert the time that is represented as a string into it's extracted hour and minute.
the time that is provided will look like:
- 3:00 PM 
- 11:00 AM
*/
export function convertTimeString(time: string): { hour: number; minute: number } {
  let hour = time?.split(':')[0];
  const minute = time?.split(':')[1].split(' ')[0];
  const ampm = time?.split(' ')[1];

  if (ampm === 'PM') {
    if (hour === '12') {
      hour = '12';
    } else {
      hour = (Number(hour) + 12).toString();
    }
  } else {
    if (hour === '12') {
      hour = '00';
    }
  }
  return {
    hour: parseInt(hour),
    minute: parseInt(minute),
  };
}
