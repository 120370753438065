import * as DialogPrimitive from '@radix-ui/react-dialog';
import React from 'react';

import { CSS, keyframes, styled, VariantProps } from '@/stitches.config';

import { Flex } from './Flex';
import { IconButton } from './IconButton';
import { overlayStyles } from './Overlay';
import { panelStyles } from './Panel';

type DialogProps = React.ComponentProps<typeof DialogPrimitive.Root> & {
  children: React.ReactNode;
};

const fadeIn = keyframes({
  from: { opacity: '0' },
  to: { opacity: '1' },
});

const fadeOut = keyframes({
  from: { opacity: '1' },
  to: { opacity: '0' },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  ...overlayStyles,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',

  '&[data-state="open"]': {
    animation: `${fadeIn} 205ms cubic-bezier(0.22, 1, 0.36, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${fadeOut} 205ms cubic-bezier(0.22, 1, 0.36, 1)`,
  },
});

export function Dialog({ children, ...props }: DialogProps): JSX.Element {
  return <DialogPrimitive.Root {...props}>{children}</DialogPrimitive.Root>;
}

const StyledContent = styled(DialogPrimitive.Content, {
  ...panelStyles,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  py: '$5',
  px: '$5',
  marginTop: '-5vh',
  willChange: 'transform',
  zIndex: 9999,
  '@md': {
    minWidth: 380,
    width: '500px',
  },
  '&:focus': {
    outline: 'none',
  },
  variants: {
    variant: {
      combobox: {
        padding: 0,
      },
    },
  },
});

export const DialogCloseIcon = styled(IconButton, {
  position: 'absolute',
  top: '$2',
  right: '$4',
  minWidth: '16px',
});

const StyledTitle = styled(DialogPrimitive.Title, {
  fontWeight: 600,
  fontSize: 16,
  marginBottom: 20,
});

export const StyledFooter = styled(Flex, {
  marginTop: '20px',
});

type DialogContentPrimitiveProps = React.ComponentProps<typeof DialogPrimitive.Content>;
type DialogContentProps = DialogContentPrimitiveProps & { css?: CSS } & VariantProps<
    typeof StyledContent
  >;

export const DialogContent = React.forwardRef<
  React.ElementRef<typeof StyledContent>,
  DialogContentProps
>(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    {children}
  </StyledContent>
));

export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogClose = DialogPrimitive.Close;
export const DialogTitle = StyledTitle;
export const DialogFooter = StyledFooter;
export const DialogOverlay = StyledOverlay;
export const DialogPortal = DialogPrimitive.Portal;

type DialogHeaderProps = {
  title: string;
  description?: string;
};

const DialogDescription = styled('div', {
  fontSize: 13,
  color: '$gray600',
});

export const DialogHeader = (props: DialogHeaderProps) => (
  <Flex
    css={{
      backgroundColor: '#323E4A',
      marginInline: -25,
      marginTop: -25,
      color: '#FFFFFF',
      fontSize: 18,
      fontWeight: 600,
      padding: '15px 24px',
      borderTopLeftRadius: '$3',
      borderTopRightRadius: '$3',
      mb: 25,
    }}
    direction="column"
  >
    <DialogTitle css={{ color: '#FFFFFF', width: '100%', m: 0 }}>
      {props.title}
    </DialogTitle>
    {props.description && <DialogDescription>{props.description}</DialogDescription>}
  </Flex>
);
