import dayjs from 'dayjs';
import i18next from 'i18next';
import {
  HiCheck,
  HiHashtag,
  HiOfficeBuilding,
  HiOutlineCursorClick,
  HiOutlineMailOpen,
  HiTag,
  HiTranslate,
  HiUser,
  HiUserAdd,
  HiUsers,
} from 'react-icons/hi';

import { languages } from '@/shared/components/editor/utils';
import { EventType } from '@/shared/types/conversations';
import { Box, Flex } from '@/shared/ui';
import { TIME_STAMP } from '@/shared/utils/timestamps';

import { StyledEvent, StyledEventContainer } from '.';

type ConversationEventProps = {
  /* the event object */
  event: EventType;
  /* the date of the event */
  date?: string;
  /* the index of the event */
  index?: number;
};

export const ConversationEvent = (props: ConversationEventProps) => {
  const { event, date } = props;

  // return null if no translation is found for that key
  if (!i18next.t(event.target + '_' + event.type)) return null;

  const errorMessage2 = i18next.t(event.target + '_' + event.type, event.metadata) || '';

  return (
    <StyledEventContainer>
      <StyledEvent>
        <Flex align="center" css={{ backgroundColor: '#F9F9F9', p: 5, borderRadius: 4 }}>
          <Box css={{ mr: 5 }}>{renderEventIcon(event.target + '_' + event.type)}</Box>

          {event.type === 'update_conversation_languages' && (
            <Box css={{ mr: 3 }}>{languageChangeMessage(event.metadata)}</Box>
          )}

          {event.type !== 'update_conversation_languages' && (
            <Box css={{ mr: 3 }}>{String(errorMessage2)}</Box>
          )}
          <Box css={{ mr: 5 }}>{` at ${dayjs(date).format(TIME_STAMP)}`}</Box>
        </Flex>
      </StyledEvent>
    </StyledEventContainer>
  );
};

const languageChangeMessage = (metadata: EventType['metadata']) => {
  const event_metadata = parseEventMetadata(metadata);
  let message;

  if (
    metadata.current_language !== metadata.previous_language &&
    metadata.current_contact_language === metadata.previous_contact_language
  ) {
    message = i18next.t(
      'conversation_update_conversation_languages_conversation',
      event_metadata
    ) as string;
  } else if (
    metadata.current_contact_language !== metadata.previous_contact_language &&
    metadata.current_language === metadata.previous_language
  ) {
    message = i18next.t(
      'conversation_update_conversation_languages_contact',
      event_metadata
    ) as string;
  } else {
    message =
      i18next.t('conversation_update_conversation_languages', event_metadata) || '';
  }

  // Ensure message is a string
  if (typeof message === 'string') {
    return message;
  } else {
    // Handle other cases here, for now we just convert to string
    return JSON.stringify(message);
  }
};

const parseEventMetadata = (metadata: EventType['metadata']) => {
  if (
    Object.prototype.hasOwnProperty.call(metadata, 'current_language') ||
    Object.prototype.hasOwnProperty.call(metadata, 'current_contact_language')
  ) {
    const language = metadata.current_language || metadata.previous_language;
    const contact_language =
      metadata.current_contact_language || metadata.previous_contact_language;

    return {
      ...metadata,
      current_language: findLanguageLabel(language as string),
      current_contact_language: findLanguageLabel(contact_language as string),
    };
  } else {
    return metadata;
  }
};

const findLanguageLabel = (mapping_language: string) => {
  return languages.find((language) => language.value === mapping_language)?.label || '';
};

const renderEventIcon = (eventTargetAndType: string) => {
  switch (eventTargetAndType) {
    case 'conversation_open':
      return <HiOutlineMailOpen />;
    case 'conversation_closed':
    case 'conversation_auto_closed':
      return <HiCheck />;
    case 'conversation_transfer_location':
      return <HiOfficeBuilding />;
    case 'conversation_link_click':
      return <HiOutlineCursorClick />;
    case 'conversation_assign_user':
      return <HiUserAdd />;
    case 'conversation_assign_team':
      return <HiUsers />;
    case 'contact_tagging':
    case 'contact_untagging':
      return <HiTag />;
    case 'contact_webchat_lead':
    case 'contact_webflow_lead':
    case 'contact_zapier_lead':
    case 'contact_api_lead':
      return <HiUser />;
    case 'conversation_keyword_trigger':
      return <HiHashtag />;
    case 'conversation_update_conversation_languages':
      return <HiTranslate />;
    default:
      return null;
  }
};
