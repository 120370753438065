/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import { useEffect } from 'react';
import { HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { FormSelect } from '@/shared/components/forms/Select';
import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  HStack,
} from '@/shared/ui';

import { useDeveloperContext } from '../context/DevelopersContext';

export const AddDeveloperApplication = () => {
  const developerContext = useDeveloperContext();
  const { createDeveloperApplication, getApiKeys } = developerContext;
  const { apiKeys } = developerContext.developerState;

  const { isOpen, onOpen, onClose } = useDisclosure();

  // list active api keys
  const activeApiKeys = apiKeys?.filter((key) => key?.active);

  // get api keys on mount
  useEffect(() => {
    // if there ate no api keys, then get them
    if (apiKeys?.length === 0) {
      getApiKeys();
    }
  }, []);

  // if the user clicks open and they have no api key, then show an alert
  const handleOpen = () => {
    if (activeApiKeys?.length === 0) {
      alert('You must create an active API key before you can create an application.');
    } else {
      onOpen();
    }
  };

  const history = useHistory();

  return (
    <Dialog open={isOpen} modal={false}>
      <DialogTrigger onClick={handleOpen}>
        <Button>Add Endpoint</Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" />
        <DialogContent onEscapeKeyDown={onClose} onPointerDownOutside={onClose}>
          <DialogHeader title="Add Application" />
          <Formik
            initialValues={{
              name: '',
              description: '',
              api_key_id: '',
              active: true,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Required'),
              description: Yup.string(),
              api_key_id: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const data = await createDeveloperApplication(values);
              if (data) {
                history.push(`/developer/webhooks/${data?.id}`);
              }
              setSubmitting(false);
              onClose();
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <FormFieldWrapper
                    name="name"
                    label="Name"
                    tooltip="The name of the application."
                  >
                    <TextInput name="name" placeholder="Name" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    name="description"
                    label="Description"
                    tooltip="The description of the application."
                  >
                    <TextInput name="description" placeholder="Description" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    name="api_key_id"
                    label="API Key"
                    tooltip="The API key to associate with this application."
                  >
                    <FormSelect
                      name="api_key_id"
                      placeholder="Select an API key"
                      options={
                        activeApiKeys
                          ? apiKeys.map((key) => ({
                              label: key?.name || '',
                              value: key?.id || '',
                            }))
                          : []
                      }
                    />
                  </FormFieldWrapper>
                  <DialogFooter justify="end">
                    <HStack>
                      <Button variant="gray" type="button" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button type="submit">Create Application</Button>
                    </HStack>
                  </DialogFooter>
                </Box>
              </form>
            )}
          </Formik>
          <DialogCloseIcon onClick={onClose} size="2">
            <HiX size="15px" style={{ color: '#FFFFFF' }} />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
