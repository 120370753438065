import React from 'react';
import { useParams } from 'react-router-dom';

export const AgentVersion = () => {
  const { id, version_id } = useParams<{ id: string; version_id: string }>();

  console.log(id, version_id);

  return (
    <div>
      <div>Agent Version</div>
    </div>
  );
};
