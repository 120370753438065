/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import TextareaAutosize from 'react-textarea-autosize';

import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { NoteType } from '@/shared/types';
import { User } from '@/shared/types/users';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Avatar,
  Box,
  Flex,
  IconButton,
  VStack,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { DATE_TIME_STAMP } from '@/shared/utils/timestamps';
import { styled } from '@/stitches.config';

import { useContacts } from '../context/ContactContext';

type NoteEditorProps = {
  /** note text */
  newNote: string;
  /** execute function when note text changes */
  handleNoteChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  children: React.ReactNode;
};

type NoteProps = {
  /** note object */
  note: NoteType;
  /** contact id */
  contactId: string;
};

export function ContactNotes(): JSX.Element {
  const { contactState, deleteNote, getUserNotes, addNote } = useContacts();
  const { current } = contactState;

  const [newNote, setNewNote] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    setNewNote(inputValue);
  };

  const addNewNote = () => {
    addNote({ contact_id: current?.id, body: newNote });
    setNewNote('');
  };

  useEffect(() => {
    getUserNotes(current?.id);
  }, [current?.id]);

  return (
    <Accordion type="single" collapsible defaultValue="notes">
      <AccordionItem value="notes" variant="notes">
        <Flex justify="between" align="center">
          <Flex css={{ fontSize: 13, fontWeight: 500 }} align="center">
            <Box css={{ mr: 10 }}>Notes</Box>
            <Box>{current?.notes?.length}</Box>
          </Flex>
          <AccordionTrigger />
        </Flex>
        <AccordionContent variant="notes">
          <VStack gap="3">
            <Box />
            <VStack gap="5">
              {current?.notes?.map((note: NoteType) => (
                <Flex justify="between" key={note.id}>
                  <ContactNote note={note} contactId={current?.id} />
                  <IconButton
                    onClick={() => deleteNote(note.id, current?.id)}
                    css={{ mt: '-4px' }}
                  >
                    <HiTrash size="12px" style={{ marginLeft: '-1px' }} />
                  </IconButton>
                </Flex>
              ))}
            </VStack>
            <ContactNoteEditor newNote={newNote} handleNoteChange={handleInputChange}>
              <IconButton
                aria-label="Add note"
                variant="send"
                onClick={() => addNewNote()}
              >
                <HiPencil color="white" />
              </IconButton>
            </ContactNoteEditor>
          </VStack>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

export function ContactNote(props: NoteProps): JSX.Element {
  const { userState } = useUsers();
  const { users } = userState;
  const [userPhoto, setUserPhoto] = useState<string | null | undefined>(null);

  useEffect(() => {
    //set the profile picture of the current user
    if (props.note?.user?.id) {
      try {
        const userProfilePicture = users.filter(
          (user: User) => user.id === props.note?.user?.id
        )[0].attachment?.url;
        setUserPhoto(userProfilePicture);
      } catch {
        setUserPhoto('');
      }
    }
  }, [props.note.user.id]);

  return (
    <Box css={{ width: '100%' }}>
      <Flex>
        <Flex css={{ pt: 2, mr: 10 }}>
          <Avatar
            variant="pink"
            size="assign"
            src={userPhoto || ''}
            fallback={initials(props.note?.user?.name || props.note?.user?.email || '')}
          />
        </Flex>
        <Flex direction="column" css={{ verticalAlign: 'top', width: '100%' }}>
          <Flex align="center" justify="between">
            <NotesUser>{props.note?.user?.name || props.note?.user?.email}</NotesUser>
            <Box css={{ fontSize: 11, mr: 5 }}>
              {dayjs(props.note.updated_at).format(DATE_TIME_STAMP)}
            </Box>
          </Flex>
          <NoteText>{renderText(props.note.body)}</NoteText>
        </Flex>
      </Flex>
    </Box>
  );
}

export function ContactNoteEditor(props: NoteEditorProps): JSX.Element {
  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <NotesTextareaContainer direction="column" focused={focused}>
      <NotesTextarea
        minRows={2.5}
        placeholder="Add note"
        value={props.newNote}
        onChange={props.handleNoteChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Flex justify="between">
        <Box></Box>
        {props.children}
      </Flex>
    </NotesTextareaContainer>
  );
}

const NotesUser = styled(Box, {
  pr: '5px',
  fontWeight: 600,
  fontSize: 12,
  mr: '3px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 100,
  wordBreak: 'break-all',
  wordWrap: 'break-word',
});

const NotesTextarea = styled(TextareaAutosize, {
  background: 'none',
  border: 'none',
  outline: 'none',
  resize: 'none',
  width: '100%',
  pt: 5,
  fontSize: 13,
  fontWeight: 500,
});

const NotesTextareaContainer = styled(Flex, {
  width: '100%',
  boxShadow: 'inset 0px 0px 0px 1px $colors$gray4, 0px 0px 0px 1px $colors$gray4',
  br: '4px',
  px: '10px',
  py: '7px',
  mb: '$2',
  variants: {
    focused: {
      true: {
        boxShadow:
          'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
        '&:-webkit-autofill': {
          boxShadow:
            'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
        },
      },
    },
  },
});

const NoteText = styled(Box, {
  fontSize: 13,
  position: 'relative',
  display: 'inline-block',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  mt: 3,
});
