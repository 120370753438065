import { Contact, ContactEvent } from '@/shared/types';

import { ContactsState } from './ContactContext';
import {
  ADD_CONTACT,
  ARCHIVE_CONTACT,
  ARCHIVE_MULTIPLE_CONTACTS,
  BLOCK_CONTACT,
  CLEAR_CURRENT,
  CLEAR_SEARCHED_CONTACTS,
  CONTACT_ERROR,
  FILTER_CONTACTS,
  FILTER_PART_OF_CONTACTS,
  GET_CONTACTS_IN_GROUP,
  GET_PART_OF_CONTACTS_IN_GROUP,
  GET_PORTION_OF_CONTACTS,
  NEW_EVENT,
  SEARCH_CONTACTS,
  SET_CONTACTS_CHECKED,
  SET_CURRENT,
  SET_IS_SEARCHED,
  SET_LAST_ADDED_CONTACT,
  SET_LOADING_CONTACT,
  SET_LOADING_TAGS,
  SET_SEARCH_RESULT_CHECKED,
  SET_VIEW,
  UPDATE_CONTACT,
  UPDATE_CONTACTS_NOTES,
  UPDATE_CONTACTS_TAGS,
} from './types';

type ContactsAction =
  | { type: 'GET_CONTACTS_IN_GROUP'; payload: any }
  | { type: 'GET_PART_OF_CONTACTS_IN_GROUP'; payload: any }
  | { type: 'SEARCH_CONTACTS'; payload: any }
  | { type: 'FILTER_CONTACTS'; payload: any }
  | { type: 'FILTER_PART_OF_CONTACTS'; payload: any }
  | { type: 'CLEAR_SEARCHED_CONTACTS'; payload: any }
  | { type: 'GET_PORTION_OF_CONTACTS'; payload: any }
  | { type: 'GET_CONTACTS_TAGS'; payload: any }
  | { type: 'UPDATE_CONTACTS_TAGS'; payload: any }
  | { type: 'DELETE_CONTACT_TAG'; payload: any }
  | { type: 'ADD_CONTACT'; payload: any }
  | { type: 'BLOCK_CONTACT'; payload: any }
  | { type: 'ARCHIVE_CONTACT'; payload: any }
  | { type: 'ARCHIVE_MULTIPLE_CONTACTS'; payload: any }
  | { type: 'UPDATE_CONTACT'; payload: any }
  | { type: 'CONTACT_ERROR'; payload: any }
  | { type: 'UPDATE_CONTACTS_NOTES'; payload: any }
  | { type: 'SET_VIEW'; payload: any }
  | { type: 'SET_CURRENT'; payload: any }
  | { type: 'SET_LAST_ADDED_CONTACT'; payload: any }
  | { type: 'SET_CONTACTS_CHECKED'; payload: any }
  | { type: 'SET_SEARCH_RESULT_CHECKED'; payload: any }
  | { type: 'SET_IS_SEARCHED'; payload: any }
  | { type: 'SET_LOADING_TAGS'; payload: any }
  | { type: 'NEW_EVENT'; payload: any }
  | { type: 'CONTACT_ERROR'; payload: any | null }
  | { type: 'SET_LOADING_CONTACT'; payload: boolean }
  | { type: 'CLEAR_CURRENT' };

const ContactReducer = (state: ContactsState, action: ContactsAction): ContactsState => {
  switch (action.type) {
    case GET_CONTACTS_IN_GROUP:
      return {
        ...state,
        groupContacts: mergeContacts(state.groupContacts, action.payload.contacts),
        groupContactsTotal: action.payload.total,
        loading: false,
        isFiltered: false,
      };
    case SEARCH_CONTACTS:
      return {
        ...state,
        searchedContacts: action.payload,
        loading: false,
        isFiltered: false,
      };
    case FILTER_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
        filteredContacts: action.payload,
        loading: false,
        isFiltered: true,
      };
    case FILTER_PART_OF_CONTACTS:
      return {
        ...state,
        currentDynamicGroupId: action.payload.dynamicGroupId,
        dynamicGroupContactsTotal: action.payload.data.total,
        filteredContacts:
          action.payload.dynamicGroupId === state.currentDynamicGroupId
            ? mergeContacts(state.filteredContacts, action.payload.data.contacts)
            : action.payload.data.contacts,
        loading: false,
        isFiltered: true,
      };
    case CLEAR_SEARCHED_CONTACTS:
      return {
        ...state,
        searchedContacts: action.payload,
        loading: false,
      };
    case GET_PORTION_OF_CONTACTS:
      return {
        ...state,
        contacts: mergeContacts(state.contacts, action.payload.contacts),
        contactsTotal: action.payload.total,
        loading: false,
        isFiltered: false,
      };
    case GET_PART_OF_CONTACTS_IN_GROUP:
      return {
        ...state,
        groupContacts:
          action.payload.uploadId === state.currentUploadId
            ? mergeContacts(state.groupContacts, action.payload.data.contacts)
            : action.payload.data.contacts,
        groupContactsTotal: action.payload.data.total,
        currentUploadId: action.payload.uploadId,
        loading: false,
        isFiltered: false,
      };
    case SET_LOADING_TAGS:
      return {
        ...state,
        loadingTags: action.payload,
      };
    case ADD_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts, action.payload],
        lastAddedContact: action.payload,
        loading: false,
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        contacts: addOrUpdate(state.contacts, action.payload),
        groupContacts: addOrUpdate(state.groupContacts, action.payload),
        current: state.current?.id === action.payload.id ? action.payload : state.current,
        error: null,
        loading: false,
      };
    case ARCHIVE_CONTACT:
      return {
        ...state,
        groupContacts: state.groupContacts.filter(
          (contact) => contact.id !== action.payload.id
        ),
        contacts: state.contacts.filter((contact) => contact.id !== action.payload.id),
        loading: false,
      };
    case ARCHIVE_MULTIPLE_CONTACTS:
      return {
        ...state,
        groupContacts: state.groupContacts.filter(
          (el) => -1 === action.payload.indexOf(el.id)
        ),
        contacts: state.contacts.filter((el) => -1 === action.payload.indexOf(el.id)),
        loading: false,
      };
    case UPDATE_CONTACTS_TAGS:
    case UPDATE_CONTACTS_NOTES:
      return {
        ...state,
        current:
          state.current?.id === action.payload.id
            ? { ...state.current, ...action.payload }
            : state.current,
        contacts: state.contacts.map((c: Contact) =>
          c.id === action.payload.id ? { ...c, ...action.payload } : c
        ),
        groupContacts: state.groupContacts.map((c: Contact) =>
          c.id === action.payload.id ? { ...c, ...action.payload } : c
        ),
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_LAST_ADDED_CONTACT:
      return {
        ...state,
        lastAddedContact: action.payload,
      };
    case SET_CONTACTS_CHECKED:
      return {
        ...state,
        allContactsChecked: action.payload,
      };
    case SET_SEARCH_RESULT_CHECKED:
      return {
        ...state,
        setSearchResultChecked: action.payload,
      };
    case SET_IS_SEARCHED:
      return {
        ...state,
        isSearched: action.payload,
      };
    case NEW_EVENT:
      return {
        ...state,
        current: updateContactEvents(state.current as Contact, action.payload),
        contacts: state.contacts.map((c: Contact) =>
          updateContactEvents(c, action.payload)
        ),
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case CONTACT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_VIEW:
      return {
        ...state,
        viewSettings: action.payload,
      };
    case BLOCK_CONTACT:
      return {
        ...state,
        current: action.payload,
        contacts: addOrUpdate(state.contacts, action.payload),
      };
    case SET_LOADING_CONTACT:
      return {
        ...state,
        loadingContact: action.payload,
      };
    default:
      return state;
  }
};

export const mergeContacts = (
  stateContacts: Array<Contact>,
  newContacts: Array<Contact>
) => {
  const currentContacts = stateContacts || [];
  const ids = new Set(currentContacts.map((c) => c.id));
  return [...currentContacts, ...newContacts.filter((c) => !ids.has(c.id))];
};

const addOrUpdate = (contacts: Array<Contact>, contact: Contact) => {
  const inState = contacts.find((c) => c.id === contact.id);
  if (inState) {
    return contacts.map((c) => (c.id === contact.id ? contact : c));
  }
  return [...contacts, contact];
};

const updateContactEvents = (contact: Contact, contactEvent: ContactEvent) => {
  if (contact?.id !== contactEvent.contact_id) {
    return contact;
  }

  const currentContactEvents = contact.contact_events || [];

  return {
    ...contact,
    ...{
      contact_events: [...currentContactEvents, contactEvent],
    },
  };
};

export default ContactReducer;
