import { Formik } from 'formik';
import React from 'react';
import { HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from '@/shared/ui';
import { generateKey } from '@/shared/utils/generateKey/generateKey';

import { useCustomData } from './context/CustomDataContext';

export const AddObject = () => {
  const { createCustomObject } = useCustomData();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // handle create custom object
  const handleSubmit = async (
    values: {
      label: string;
      custom_properties: Array<any>;
    },
    actions: any
  ) => {
    try {
      const params = {
        key: generateKey(values.label),
        label: values.label,
      };
      await createCustomObject(params);
      actions.resetForm({
        values: {
          label: '',
          custom_properties: [],
        },
      });
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        <Button onClick={onOpen} data-intercom-target="add-object-button">
          Add Object
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent onEscapeKeyDown={onClose} onPointerDownOutside={onClose}>
            <DialogHeader title="Create New Object" />
            <Formik
              initialValues={{
                label: '',
                custom_properties: [],
              }}
              validationSchema={Yup.object({
                label: Yup.string().test(
                  'len',
                  'Must be at least 3 characters',
                  (val) => val !== undefined && val.length > 2
                ),
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} data-testid="create-object-form">
                  <FormFieldWrapper label="Object Name" name="label">
                    <TextInput placeholder="Enter name" />
                  </FormFieldWrapper>
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={onClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button type="submit" disabled={!formik.isValid}>
                        Create
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={onClose} size="2">
                <HiX style={{ color: 'white' }} />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
