import React, { useRef } from 'react';

import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Flex,
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverTrigger,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

type EditorPopoverProps = {
  anchorDimensions: {
    height: number;
    width: number;
  };
  updateAnchorDimensions: () => void;
  children: React.ReactElement[];
  intercomId?: string;
  textareaRef?: React.RefObject<HTMLTextAreaElement>;
};

const EditorPopoverAnchor = styled(PopoverAnchor, {
  position: 'absolute',
  left: 0,

  '@md': {
    left: '50%',
  },
});

// message editor popover
export const EditorPopover = (props: EditorPopoverProps) => {
  const { isOpen, onClose, toggle } = useDisclosure();
  const { textareaRef } = props;

  const triggerRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  // adjusts popover dimensions when side-drawer is toggled
  const handlePopoverTrigger = () => {
    props.updateAnchorDimensions();
    toggle();
  };

  // only close dialog when pointer outside is not on top of template icon
  // otherwise the dialog will keep reopening
  const handlePointerDownOutside = (event: any) => {
    if (!triggerRef.current.contains(event.target)) {
      if (textareaRef?.current) {
        // Focus cursor at the end of the textarea
        textareaRef.current.setSelectionRange(
          textareaRef.current.value.length,
          textareaRef.current.value.length
        );
      }

      onClose();
    }
  };

  //only close dialog when emoji is clicked
  const handleOnEmojiClick = () => {
    if (props.children[1].props.onEmojiClick) {
      // TODO: Decide on how to handle this, for now emoji picker does not close dialog when an
      // emoji is clicked unless ESC is pressed or the user clicks outside the dialog
      // onClose();
      console.log('emoji clicked');
    }
  };

  // child component for popover trigger icon
  const trigger = props.children[0];

  // child component for popover content needs access to onClose function
  const content = React.cloneElement(props.children[1], { onClose });

  return (
    <Popover open={isOpen} onOpenChange={() => !isOpen} modal={false}>
      <EditorPopoverAnchor css={{ bottom: props.anchorDimensions.height }} />
      <PopoverTrigger onClick={() => handlePopoverTrigger()} ref={triggerRef} asChild>
        <Box>{trigger}</Box>
      </PopoverTrigger>
      <PopoverContent
        onPointerDownOutside={(event: any) => handlePointerDownOutside(event)}
        onEscapeKeyDown={(event: any) => handlePointerDownOutside(event)}
        onClick={handleOnEmojiClick}
        sideOffset={5}
        side="top"
        css={{
          width: props.anchorDimensions.width - 10,
          '@media (min-width: 912px)': { width: props.anchorDimensions.width },
          zIndex: 9999,
        }}
      >
        <Flex direction="column">{content}</Flex>
      </PopoverContent>
    </Popover>
  );
};
