import React, { useState } from 'react';
import { HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { useDisclosure } from '@/shared/hooks';
import { Model, Provider } from '@/shared/types/ai/llms';
import {
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Fieldset,
  Input,
  Label,
  VStack,
} from '@/shared/ui';

import { useLLMAgent } from '../context/AgentsContext';

export const CreateAgent = () => {
  const agents = useLLMAgent();
  const { createAgent, createLLM } = agents;

  const history = useHistory();

  const [agentName, setAgentName] = useState('');
  const [agentDescription, setAgentDescription] = useState('');

  const createNewAgent = async () => {
    const llm = await createLLM({
      llm: {
        description: '',
        model: Model.Gpt35Turbo,
        provider: Provider.Retell,
        prompt:
          'You are an AI phone agent, fields inbound calls and answer the callers questions as best as possible',
        provider_settings: { begin_message: 'Hello, how can I help you?' },
      },
    });

    const agent = await createAgent({
      agent: {
        name: agentName,
        description: agentDescription,
        llm_id: llm.id,
        provider: Provider.Retell,
        provider_settings: {
          language: 'en-US',
          responsiveness: 0.95,
          voice_id: '11labs-Emily',
          voice_temperature: 0.4,
          voice_speed: 1.04,
          interruption_sensitivity: 0.95,
        },
      },
    });

    history.push(`/ai/agents/${agent.id}/editor`);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={onOpen}>
        <Button>Create Agent</Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          onEscapeKeyDown={onClose}
          onPointerDownOutside={onClose}
          css={{ overflow: 'auto' }}
        >
          <DialogHeader title="Agent Info" />
          <VStack css={{ width: '100%' }} gap="1">
            <Fieldset>
              <Label>Agent Name</Label>
              <Input
                placeholder="A name for your agent"
                value={agentName}
                onChange={(e) => setAgentName(e.target.value)}
              />
            </Fieldset>
            <Fieldset>
              <Label>Description</Label>
              <Input
                placeholder="What does this agent do?"
                value={agentDescription}
                onChange={(e) => setAgentDescription(e.target.value)}
              />
            </Fieldset>
            <Button onClick={createNewAgent}>Save Agent</Button>
          </VStack>
          <DialogCloseIcon onClick={onClose} size="2">
            <HiX size="15px" style={{ color: 'white' }} />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
