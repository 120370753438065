import { debounce, DebouncedFunc } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_custom_property_object } from '@/shared/components/filterBuilder/objects/custom_property';
import { usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';

import { DataErrorBoundary } from '../';
import { useCustomData } from '../context/CustomDataContext';
import { DeleteObject } from '../DeleteObject';
import { LayoutContainer } from '../layout/LayoutContainer';
import { AddProperty } from './AddProperty';
import CustomPropertiesTable from './CustomPropertiesTable';
import { sortConfig } from './filterConfig';
import { UpdateObject } from './UpdateObject';

export const CustomObject = () => {
  usePageView();
  const isDesktop = useMedia('(min-width: 912px)');
  const [searchValue, setSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const {
    customDataState: { current, customPropertiesFilterParams },
    getCustomObject,
    deleteCustomObject,
    clearCurrent,
    clearProperties,
    updateCustomObject,
    updateCustomPropertiesFilterParams,
  } = useCustomData();

  const params = useParams<{ id: string }>();

  // on mount get the id from the url and fetch the data object if the id does not match the current data object
  useEffect(() => {
    if (params?.id && current?.id !== params?.id) {
      getCustomObject(params?.id);
    }
  }, [params?.id, current?.id]);

  // clear current when unmount
  useEffect(() => {
    return () => {
      clearCurrent();
      clearProperties();
    };
  }, []);

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();

    const quickFilters = [
      {
        column: 'label',
        comparison: 'ilike',
        resource: 'custom_property',
        value: `%${formattedValue}%`,
      },
    ];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateCustomPropertiesFilterParams(props);
    }, 1000),
    []
  );

  return (
    <>
      <Helmet>
        <title>Whippy | Custom data object</title>
      </Helmet>
      <LayoutContainer>
        <PageLayout
          breadcrumbs={[
            { title: 'Data', path: '/settings/data' },
            { title: 'Custom Objects', path: `/settings/data` },
            { title: current?.label || '', path: `/settings/data/${current?.label}` },
          ]}
          actions={
            <Flex align="center">
              <DeleteObject onDelete={deleteCustomObject} id={params?.id} />
              {current && <UpdateObject data={current} onUpdate={updateCustomObject} />}
              <AddProperty currentObjectId={params?.id} />
            </Flex>
          }
        >
          <DataErrorBoundary>
            <Flex
              css={{ padding: isDesktop ? '30px' : '40px 20px', flex: '1 1 auto' }}
              direction="column"
            >
              <Box css={{ paddingBottom: '$space$3' }}>
                <CombinedFilters
                  quickSearchPlaceholder="Search..."
                  quickSearchValue={searchValue}
                  setQuickSearchValue={(value) => {
                    setSearchValue(value);
                    handleQuickSearch(
                      debouncedUpdate,
                      customPropertiesFilterParams,
                      value
                    );
                  }}
                  defaultObjects={[default_custom_property_object]}
                  customObjects={[]}
                  activeFilters={activeFilters}
                  setFilters={(value) => {
                    setActiveFilters(value);
                    handleFilterChange(
                      debouncedUpdate,
                      customPropertiesFilterParams,
                      value
                    );
                  }}
                  sortConfig={sortConfig}
                  activeSort={customPropertiesFilterParams.sort}
                  onSortUpdate={(value) =>
                    handleSortChange(
                      updateCustomPropertiesFilterParams,
                      customPropertiesFilterParams,
                      value
                    )
                  }
                />
              </Box>
              <CustomPropertiesTable />
            </Flex>
          </DataErrorBoundary>
        </PageLayout>
      </LayoutContainer>
    </>
  );
};
