import { Channel, Socket } from 'phoenix';
import React from 'react';
import { useContext } from 'react';

import { getAccessToken } from '@/shared/api/api';
import { WEBSOCKET_BASE_URL, isDev } from '@/shared/utils/config';

export const SocketContext = React.createContext<{
  /* Socket object for the connection */
  socket: Socket | null;
  /* Boolean flag to indicate if there's a connection error */
  hasConnectionError?: boolean;
  /* Function to handle the connection */
  handleConnect: () => void;
  /* Function to join a channel, returns Channel object or null */
  joinChannel: (channel: string) => Channel | null;
  /* Number of reconnection attempts */
  reconnectAttempts: number;
  /* reconnecting in time */
  reconnectingIn?: number;
}>({
  /* Initialize socket with the base URL */
  socket: null,
  /* Initialize hasConnectionError as false */
  hasConnectionError: false,
  /* Initialize handleConnect as a function that returns a resolved promise */
  handleConnect: () => Promise.resolve(),
  /* Initialize joinChannel as a function that returns null */
  joinChannel: () => null,
  /* Initialize reconnectAttempts as 0 */
  reconnectAttempts: 0,
  /* Initialize reconnectingIn as undefined */
  reconnectingIn: undefined,
});

export const useSocket = () => useContext(SocketContext);

type Props = {
  /* Optional URL for the socket connection */
  url?: string;
  /* Function to refresh the connection with a new token */
  refresh: (token: string) => Promise<void>;
} & React.PropsWithChildren<unknown>;

type State = {
  /* Socket object for the connection */
  socket: Socket | null;
  /* History of socket objects for reconnection attempts */
  history: Array<Socket>;
  /* Number of reconnection attempts */
  reconnectAttempts: number;
  /* Time until the next reconnect attempt */
  reconnectingIn: number;
};
export class SocketProvider extends React.Component<Props, State> {
  timeoutId: NodeJS.Timeout | null = null;
  connecting: boolean;

  constructor(props: Props) {
    super(props);

    const { url = WEBSOCKET_BASE_URL } = props;

    const socket: Socket | null = this.createSocket(url);

    this.state = {
      socket,
      history: socket ? [socket] : [],
      reconnectAttempts: 0,
      reconnectingIn: 0,
    };

    this.connecting = false;
  }

  componentDidMount() {
    this.handleConnect();

    // Add event listeners for online and offline events
    window.addEventListener('online', this.handleNetworkChange);
    window.addEventListener('offline', this.handleNetworkChange);

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    // Clear timeout if it exists
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Disconnect socket connection
    this.disconnect(() => console.log('Socket disconnected'));

    // Remove event listeners for online and offline events
    window.removeEventListener('online', this.handleNetworkChange);
    window.removeEventListener('offline', this.handleNetworkChange);

    // Remove event listener for visibility change
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  handleNetworkChange = () => {
    if (navigator.onLine) {
      console.log('Network is back online, attempting to reconnect');
      this.reconnect();
    } else {
      // in dev we do not need an internet connection
      // in order to be able to connect to the backend
      if (isDev) {
        this.reconnect();
      } else {
        console.log('Network is offline');
      }
    }
  };

  handleVisibilityChange = () => {
    if (document.hidden) {
      console.log('Tab is hidden');
    } else {
      console.log('Tab is visible');
      this.reconnect();
    }
  };

  createNewSocket = () => {
    console.log('Creating new socket');

    const { url = WEBSOCKET_BASE_URL } = this.props;

    return this.createSocket(url);
  };

  createSocket = (url: string = WEBSOCKET_BASE_URL): Socket | null => {
    const token = getAccessToken();

    // Check if token is a string
    if (typeof token !== 'string') {
      console.error('Access token is not a string. Cannot create a new socket.');
      return null;
    }

    return new Socket(url, {
      heartbeatIntervalMs: 10000,
      params: { token },
      logger: function (kind, msg, data) {
        filterLogs(kind, msg, data);
      },
    });
  };

  handleConnect = () => {
    // If the browser is offline or the tab is hidden, don't try to connect
    if ((!navigator.onLine || document.hidden) && !isDev) {
      console.log('Browser is offline or tab is hidden, not attempting to connect');
      return;
    }

    // Set connecting flag to true
    this.connecting = true;
    console.log(`Connecting is set to ${this.connecting}`);

    const { socket } = this.state;

    // If socket is not defined, don't try to connect
    if (!socket) {
      console.error('Socket is not defined');
      return;
    }

    // if socket is connected, don't try to connect
    if (socket.isConnected()) {
      console.log('Socket is already connected, not attempting to connect');
      return;
    }

    // Connect to socket
    socket.connect();

    // Set connecting flag to false
    this.connecting = false;
    console.log(`Connecting is set to ${this.connecting}`);

    // Add event listeners for socket events
    socket.onOpen(() => {
      console.log('Socket on open called', socket.connectionState());
      // Reset reconnect attempts on successful connection
      const isConnected = socket.isConnected();

      console.log('Socket is connected inside on open called', isConnected);

      if (isConnected) {
        this.setState({ reconnectAttempts: 0 });

        console.log(
          'Re-setting websocket connection attempts:',
          this.state.reconnectAttempts
        );
      }
    });

    socket.onClose(() => {
      console.warn('Socket closing:', socket.connectionState());
      this.reconnect();
    });

    socket.onError((error, transport, establishedConnections) => {
      console.error('Socket error:', error, transport, establishedConnections);
      this.reconnect();
    });
  };

  reconnect = () => {
    // if reconnecting, don't try to reconnect
    if (this.connecting) {
      console.log('Already reconnecting, not attempting to reconnect');
      return;
    }

    // If the browser is offline or the tab is hidden, don't try to reconnect
    if (!navigator.onLine || document.hidden) {
      console.log('Browser is offline or tab is hidden, not attempting to reconnect');
      return;
    }

    // If the socket is connected, don't try to reconnect
    const { socket } = this.state;

    if (socket && socket.isConnected()) {
      console.log('Socket is already connected, not attempting to reconnect');
      return;
    }

    // Set reconnecting flag to true
    this.connecting = true;
    console.log(`Connecting is set to ${this.connecting}`);

    this.disconnect(async () => {
      // Calculate backoff time
      const backoffTime = Math.min(1000 * 2 ** this.state.reconnectAttempts, 30000);

      /* 
        Add jitter: random factor between 0.5 and 1.5:
        This value is multiplied with the backoffTime to add some randomness to the reconnect attempts. 
        This way, not all clients will try to reconnect at the exact same intervals, reducing the risk of 
        overwhelming the server. 
      */
      const jitterFactor = 0.5 + Math.random();
      const backoffTimeWithJitter = backoffTime * jitterFactor;

      // Increment reconnect attempts
      this.setState(
        (prevState) => ({
          reconnectAttempts: prevState.reconnectAttempts + 1,
          reconnectingIn: backoffTimeWithJitter,
        }),
        () => {
          console.log(
            `Reconnect attempt #${this.state.reconnectAttempts + 1}. Next attempt in ${
              backoffTimeWithJitter / 1000
            } seconds.`
          );
        }
      );

      // Wait for backoff time then reconnect
      this.timeoutId = setTimeout(() => {
        const socket = this.createNewSocket();

        if (!socket) {
          console.error('Socket is not defined');
          return;
        }

        this.setState({ socket, history: [socket, ...this.state.history] }, () =>
          this.handleConnect()
        );

        // Set reconnecting flag to false
        this.connecting = false;
        console.log(`Connecting is set to ${this.connecting}`);

        console.warn('Socket History', this.state.history);
      }, backoffTimeWithJitter);
    });
  };

  disconnect = (
    cb = () => {
      console.log('Socket disconnected');
    }
  ) => {
    const { socket } = this.state;

    if (!socket) {
      console.error('Socket is not defined');
      return;
    }

    socket.disconnect(cb);
  };

  joinChannel = (channel_name: string) => {
    const { socket } = this.state;

    if (socket) {
      // join a channel if socket exists
      const channel = socket.channel(channel_name);

      channel.join();

      return channel;
    } else {
      return null;
    }
  };

  render() {
    return (
      <SocketContext.Provider
        value={{
          socket: this.state.socket,
          handleConnect: this.handleConnect,
          joinChannel: this.joinChannel,
          reconnectAttempts: this.state.reconnectAttempts,
          reconnectingIn: this.state.reconnectingIn,
        }}
      >
        {this.props.children}
      </SocketContext.Provider>
    );
  }
}

// Filter logs to only show errors in the console from the socket
const filterLogs = (kind: string, msg: any, data: any) => {
  const lowerCaseKind = kind.toLowerCase();
  let lowerCaseMsg = '';

  if (typeof msg === 'string') {
    lowerCaseMsg = msg.toLowerCase();
  }

  if (lowerCaseKind.includes('error') || lowerCaseMsg.includes('error')) {
    console.error(`${kind}: ${msg}`, data);
  }
};

export default SocketProvider;
