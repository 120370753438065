import { memo, useMemo } from 'react';

import { Domain, DomainStatusType } from '@/shared/types/domains';
import { Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { getProgressVariant, getStep } from '../utils/utils';
import { StepProgressBar } from './StepProgressBar';

type SetupStepsProps = {
  domain: Domain | null;
};

export const SetupSteps = memo(({ domain }: SetupStepsProps) => {
  const step = useMemo(() => {
    return getStep(domain?.setup_state);
  }, [domain?.setup_state]);

  return domain?.status === DomainStatusType.PENDING ||
    domain?.status === DomainStatusType.CANCELLED ? (
    <SetupContainer gap={3}>
      <Text size="3" variant="fieldTitle">
        Domain Setup
      </Text>
      <StepProgressBar
        step={step?.value}
        color={getProgressVariant(domain?.status)}
        status={domain?.status}
      />
      {step && (
        <Text
          css={{ fontSize: 14, fontWeight: '500' }}
        >{`Step ${step?.value} of 7: ${step?.label}`}</Text>
      )}
    </SetupContainer>
  ) : null;
});

const SetupContainer = styled(VStack, {
  pt: 10,
  mt: 0,
});
