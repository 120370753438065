import { Tag } from '../types/tags';
import { api } from './api';

export const getTag = (tagId: string) =>
  api.get(`/tags/${tagId}`).then((res) => {
    return res.data.data;
  });

export const getTags = () =>
  api.get(`/tags`).then((res) => {
    return res.data.data;
  });

export const getTagsV2 = async (params: any) =>
  api.post(`/v2/tags/search`, { ...params }).then((res) => {
    return res.data;
  });

export const getPartTags = (params: { offset: number; limit: number }) =>
  api.get(`/tags?offset=${params.offset}&limit=${params.limit}`).then((res) => {
    return res.data.data;
  });

export const searchTag = (name: string) =>
  api.post(`/tags/search`, { name }).then((res) => {
    return res.data.data;
  });

export const createTag = (tagName: string, tagColor: string, tagType: string) =>
  api
    .post(`/tags`, { tag: { name: tagName, color: tagColor, type: tagType } })
    .then((res) => {
      return res.data.data;
    });

export const editTag = (tag: Tag) =>
  api.put(`/tags/${tag.id}`, { tag: { ...tag } }).then((res) => {
    return res.data.data;
  });

export const deleteTag = (tag: Tag) =>
  api.delete(`/tags/${tag.id}`).then((res) => {
    return res.data.data;
  });
