/* eslint-disable react-hooks/exhaustive-deps */
import * as SelectPrimitive from '@radix-ui/react-select';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { HiChevronDown, HiChevronUp, HiX } from 'react-icons/hi';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import {
  FormFieldWrapper,
  FormMessageEditorV2,
  TextInput,
} from '@/shared/components/forms';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useDisclosure } from '@/shared/hooks';
import { Template } from '@/shared/types/templates';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';

import { useLocations } from '../locations/context/LocationContext';
import { messageTemplatePermissions } from './constants';
import { useTemplates } from './context/TemplatesContext';

type UpdateTemplateProps = {
  template: Template;
  children: React.ReactNode;
};

export const UpdateTemplate = (props: UpdateTemplateProps): JSX.Element => {
  const templatesContext = useTemplates();
  const { editTemplate, deleteTemplate } = templatesContext;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const authContext = useAuth();
  const { isAdmin } = authContext;

  const locationContext = useLocations();
  const { locations, allLocations } = locationContext.locationsState;
  const [permissionValue, setPermissionValue] = useState(props.template.access_level);
  const [selectedLocations, setSelectedLocations] = useState({
    locations: props.template.locations?.map((location) => location.id) || [],
  });

  // disable submit btn if location permission is selected without picking location/s
  const shouldDisableSubmitBtn =
    permissionValue === 'location' && selectedLocations.locations.length < 1;

  const handleDelete = (template: Template) => {
    deleteTemplate(template);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild onClick={() => onOpen()}>
        {props.children}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay css={{ zIndex: 999999 }}>
          <DialogContent
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
            onPointerDownOutside={onClose}
            onEscapeKeyDown={onClose}
            css={{ zIndex: 99999999 }}
          >
            <DialogHeader title="Update Template" />
            <Formik
              enableReinitialize
              initialValues={{
                title: props.template.title || '',
                message: {
                  body: props.template.message,
                  attachment_urls: props.template.attachments?.map((att) => att.url),
                },
              }}
              validationSchema={Yup.object({
                title: Yup.string().test(
                  'len',
                  'Must be at least 3 characters',
                  (val) => val !== undefined && val.length > 2
                ),
              })}
              onSubmit={async (values: { title: string; message: any }) => {
                const newTemplate = {
                  id: props.template.id,
                  title: values.title,
                  message: values.message.body,
                  attachments: values.message.attachment_urls,
                  access_level: permissionValue,
                };

                if (permissionValue === 'location') {
                  Object.assign(newTemplate, {
                    location_ids: selectedLocations.locations,
                  });
                }

                try {
                  if (
                    values.message.body.length < 1 &&
                    newTemplate.attachments.length < 1
                  ) {
                    return toast.error('Please add at least one attachment or message');
                  }
                  editTemplate(newTemplate as unknown as Template);
                  onClose();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <FormFieldWrapper
                    label="Template Name"
                    tooltip="The name of your template."
                    name="title"
                  >
                    <TextInput placeholder="Example: New Customer Question" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    label="Template Message"
                    tooltip="The message of your template."
                    name="message"
                  >
                    <FormMessageEditorV2
                      source="update-template" // Required for attachments to ensure they are uploaded to the correct Message Editor
                      placeholder="Type your message here..."
                      field="message"
                      showAddAttachment={true}
                      enableAttachments={true}
                      showCharacterCount={true}
                      showAddEmoji={true}
                      showAddReviewLink={true}
                      showAddVariable={true}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Permission" name="permission">
                    <Select
                      value={permissionValue}
                      onValueChange={(e) => setPermissionValue(e)}
                    >
                      <SelectTrigger aria-label="option-select-trigger">
                        <SelectValue />
                        <SelectIcon>
                          <HiChevronDown />
                        </SelectIcon>
                      </SelectTrigger>
                      <SelectPrimitive.Portal>
                        <SelectContent css={{ zIndex: 9999999 }}>
                          <SelectScrollUpButton>
                            <HiChevronUp />
                          </SelectScrollUpButton>
                          <SelectViewport>
                            <SelectGroup>
                              <SelectLabel>Permission</SelectLabel>
                              {messageTemplatePermissions.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  <SelectItemIndicator />
                                  <SelectItemText>{option.label}</SelectItemText>
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectViewport>
                        </SelectContent>
                      </SelectPrimitive.Portal>
                    </Select>
                  </FormFieldWrapper>
                  {permissionValue === 'location' && (
                    <FormFieldWrapper label="Locations" name="locations">
                      {locations.length > 0 && (
                        <MultiSelect
                          defaultPlaceholder="Locations"
                          defaultSelectedItems={props.template.locations?.map(
                            (location) => location.id
                          )}
                          isDropdown={true}
                          options={
                            isAdmin
                              ? allLocations.map((location: any) => ({
                                  type: location?.name || '',
                                  value: location.id,
                                }))
                              : locations.map((location: any) => ({
                                  type: location?.name || '',
                                  value: location.id,
                                }))
                          }
                          parentSelectedItems={selectedLocations}
                          setParentSelectedItems={setSelectedLocations}
                          isCampaigns={true}
                        />
                      )}
                    </FormFieldWrapper>
                  )}
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button
                        variant="red"
                        css={{ marginRight: '$1' }}
                        onClick={() => handleDelete(props.template)}
                      >
                        Delete Template
                      </Button>
                    </DialogClose>
                    <DialogClose>
                      <Button type="submit" disabled={shouldDisableSubmitBtn}>
                        Update Template
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={onClose} size="2">
                <HiX style={{ color: 'white' }} />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
