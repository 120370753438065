import React, { useState } from 'react';
import { HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { useDisclosure } from '@/shared/hooks';
import { DeveloperApplication } from '@/shared/types/developers';
import {
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  HStack,
  Input,
  Text,
} from '@/shared/ui';

import { useDeveloperContext } from '../context/DevelopersContext';

type RenameConfirmationDialogProps = {
  /* the focused api key id */
  application: DeveloperApplication | null;
  /* children */
  children?: React.ReactNode;
};

const RenameConfirmationDialog = (props: RenameConfirmationDialogProps): JSX.Element => {
  const apiKeysContext = useDeveloperContext();
  const { updateDeveloperApplication } = apiKeysContext;

  const { children, application } = props;

  const MAX_LENGTH = 30; // Maximum allowed length for rename
  const [newName, setNewName] = useState(application?.name || '');
  const [nameError, setNameError] = useState<string | null>(null); // To store validation error

  const handleRenameConfirm = async (newName: string) => {
    if (validateNewName(newName)) {
      // Only continue if name is valid
      try {
        application && updateDeveloperApplication({ ...application, name: newName });
        toast.success('API Key renamed successfully');
      } catch (error) {
        toast.error('API Key rename error');
      }
    }
  };

  const handleChangeNewName = (e: any) => {
    setNewName(e.target.value);
    setNameError(null); // Reset error when user types
  };

  const validateNewName = (name: string): boolean => {
    if (!name.trim()) {
      setNameError("Name can't be empty");
      return false;
    }
    if (name === application?.name) {
      setNameError('New name should be different from the old name');
      return false;
    }
    if (name.length > MAX_LENGTH) {
      setNameError(`Name can't be longer than ${MAX_LENGTH} characters`);
      return false;
    }
    return true;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={() => onOpen()}>
        {children}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" css={{ zIndex: 999999 }} />
        <DialogContent onEscapeKeyDown={onClose} css={{ zIndex: 9999999 }}>
          <DialogHeader title="Rename Application" />
          <DialogCloseIcon onClick={() => onClose()} size="2">
            <HiX size="15px" style={{ color: 'white' }} />
          </DialogCloseIcon>
          <Text>Please input a new name for the application.</Text>
          <HStack css={{ marginTop: 20, width: '100%' }}>
            <Input
              placeholder={'Type the new name here'}
              value={newName || ''}
              onChange={handleChangeNewName}
            />
          </HStack>
          {/* Display validation error */}
          <HStack css={{ marginTop: 10, width: '100%' }}>
            {nameError && <Text css={{ color: 'red' }}>{nameError}</Text>}
          </HStack>
          <HStack css={{ marginTop: 20, justifyContent: 'flex-end' }}>
            <Button
              variant={'gray'}
              onClick={() => {
                onClose();
                setNewName(application?.name || '');
              }}
            >
              Cancel
            </Button>
            <Button
              variant={'primary'}
              onClick={() => handleRenameConfirm(newName || '')}
            >
              Save
            </Button>
          </HStack>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default RenameConfirmationDialog;
