import { FilterItem, SortItem } from '@/shared/types/filter';

import { api } from '../api';

export type DomainsParams = {
  offset?: number;
  limit?: number;
  filter?: Array<FilterItem>;
  sort?: Array<SortItem>;
};

export type DomainParams = {
  name: string;
  price?: number;
  setup_type?: SetupType;
};

export enum SetupType {
  BUY = 'buy',
  ADD = 'add',
}

export const domains = {
  /*
    POST - Applies searching on the Domains and returns a list of them.
  */
  searchDomains: async (domainsParams: DomainsParams) => {
    const { data } = await api.post('/v2/domains/search', domainsParams);
    return data;
  },
  /*
    POST - Create Domain
  */
  createDomain: async (domainParams: DomainParams) => {
    const { data } = await api.post('/v2/domains', { domain: domainParams });
    return data;
  },
  /*
    GET - Get Domain by id
  */
  getDomain: async (id: string) => {
    const { data } = await api.get(`/v2/domains/${id}`);
    return data;
  },
  /*
    DELETE - Delete Domain by id
  */
  deleteDomain: async (id: string) => {
    const { data } = await api.delete(`/v2/domains/${id}`);
    return data;
  },
  /*
    GET - Checks the Domains availability.
  */
  getDomainAvailability: async (name: string) => {
    const { data } = await api.get(`/v2/domains/${name}/availability`);
    return data;
  },
};
