import * as TabsPrimitive from '@radix-ui/react-tabs';
import React from 'react';

import { TabFilter } from '@/shared/types/filter';
import { Badge, Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

type FilterTabsProps = {
  // List of tabs to be rendered
  tabs: TabFilter[];
  // Callback function when a tab is selected
  onTabChange: (tab: TabFilter) => void;
  // The currently active tab
  activeTab: TabFilter;
  // An object which maps a key to a function which returns the badge value
  tabValueMappings?: {
    [key: string]: () => number | null;
  };
};

/**
 * The Filter Tabs component renders a horizontal list of tabs.
 *
 * @param tabs - The list of tabs which are rendered.
 * @param onTabChange - A callback function which is called when a tab is selected.
 * @param activeTab - The currently active tab.
 * @param tabValueMappings - An object which maps a key to a function which returns the badge value.
 */

export const FilterTabs: React.FC<FilterTabsProps> = ({
  tabs,
  onTabChange,
  activeTab,
  tabValueMappings,
}) => {
  const handleTabChange = (value: string) => {
    const tab = tabs.find((tab) => tab.key === value);
    if (tab) {
      onTabChange(tab);
    }
  };

  return (
    <div>
      <FilterTabsContainer defaultValue={tabs[0].key} onValueChange={handleTabChange}>
        <FiltersTabList>
          {tabs.map((tab: TabFilter) => (
            <Flex css={{ position: 'relative' }} key={tab.key}>
              <RoundedCornerLeft active={tab.key === activeTab.key} />
              <FilterTabTrigger data-testid="filter-tab-trigger" value={tab.key}>
                <TriggerLabel>{tab.label}</TriggerLabel>
                {tabValueMappings && tabValueMappings[tab.key]() !== null && (
                  <Badge
                    css={{ zIndex: 1, marginLeft: 8 }}
                    variant={tab.key === activeTab.key ? 'blue' : 'darkgray'}
                  >
                    {tabValueMappings[tab.key]()}
                  </Badge>
                )}
              </FilterTabTrigger>
              <RoundedCornerRight active={tab.key === activeTab.key} />
            </Flex>
          ))}
        </FiltersTabList>
      </FilterTabsContainer>
    </div>
  );
};

const FilterTabsContainer = styled(TabsPrimitive.Root, {
  backgroundColor: '$slate3',
  padding: '5px 30px 0 30px',
  overflowY: 'hidden',
  fontSize: 14,

  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const FiltersTabList = styled(TabsPrimitive.List, {
  display: 'flex',
  position: 'relative',
});

const FilterTabTrigger = styled(TabsPrimitive.Trigger, {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  padding: '6px 18px 8px',
  whiteSpace: 'nowrap',
  color: '#879096',
  fontSize: 13,
  '&[data-state="active"]': {
    background: 'white',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    color: 'black',
  },
});

const TriggerLabel = styled(Box, {
  position: 'relative',
  zIndex: 1,
});

const RoundedCornerLeft = styled(Box, {
  display: 'none',
  height: 10,
  width: 10,
  backgroundColor: 'white',
  position: 'absolute',
  bottom: 0,
  left: -10,

  variants: {
    active: {
      true: {
        display: 'block',
      },
    },
  },

  '&::after': {
    content: '',
    position: 'absolute',
    borderRadius: '100%',
    backgroundColor: '$slate3',
    height: 20,
    width: 20,
    left: -10,
    bottom: -1,
  },
});

const RoundedCornerRight = styled(Box, {
  display: 'none',
  height: 10,
  width: 10,
  backgroundColor: 'white',
  position: 'absolute',
  bottom: 0,
  right: -10,

  variants: {
    active: {
      true: {
        display: 'block',
      },
    },
  },

  '&::after': {
    content: '',
    position: 'absolute',
    borderRadius: '100%',
    backgroundColor: '$slate3',
    height: 20,
    width: 20,
    left: 0,
    bottom: 0,
  },
});
