import { memo } from 'react';
import { HiExclamationCircle } from 'react-icons/hi';

import { DomainStatusType } from '@/shared/types/domains';
import { Box, HStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

type StepProgressBarProps = {
  color: string;
  status: DomainStatusType;
  step?: number;
};

export const StepProgressBar = memo(({ step, color, status }: StepProgressBarProps) => {
  return step ? (
    <ProgressContainer>
      {[...Array(7)].map((_, index) => (
        <Step key={index}>
          <StepLine css={{ backgroundColor: step >= index + 1 ? color : '#E5E7EB' }} />
          {step === index + 1 && status === DomainStatusType.CANCELLED && (
            <StepIcon>
              <HiExclamationCircle color={color} size={29} />
            </StepIcon>
          )}
        </Step>
      ))}
    </ProgressContainer>
  ) : null;
});

const ProgressContainer = styled(HStack, {
  mt: 16,
  mx: -4,
});

const Step = styled(Box, {
  mx: 4,
  my: 'auto',
  flex: 1,
  position: 'relative',
  alignItems: 'center',
});

const StepLine = styled(Box, {
  width: '100%',
  height: 8,
  borderRadius: 9,
  backgroundColor: '#E5E7EB',
  zIndex: 1,
});

const StepIcon = styled(Box, {
  position: 'absolute',
  top: -10,
  left: 'calc(50% - 15px)',
  width: 29,
  height: 29,
  zIndex: 9999,
  borderRadius: 29,
  '&::before': {
    boxSizing: 'border-box',
    content: '""',
    position: 'absolute',
    top: 2.8,
    right: 2.8,
    bottom: 2.8,
    left: 2.8,
    width: 23.2,
    height: 23.2,
    borderRadius: 23.2,
    backgroundColor: 'white',
    zIndex: 1,
  },
  svg: {
    position: 'relative',
    zIndex: 2,
  },
});
