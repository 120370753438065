import React, { useState } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import { Divider, Flex, HStack, IconButton, Skeleton, Text, VStack } from '@/shared/ui';

import { useTenDlc } from '../context/TenDlcContext';

const CampaignsList = () => {
  const tenDlcContext = useTenDlc();
  const { tenDlcState } = tenDlcContext;
  const { campaigns, loading } = tenDlcState;

  const [page, setPage] = useState(1);

  const campaignsRendered = campaigns.slice(page * 10 - 10, page * 10);

  const onPageDown = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const onPageUp = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <Flex css={{ width: '100%', mt: 20 }} direction="column">
      <VStack gap="2">
        <Flex css={{ width: '100%' }} justify="between">
          <Text size="1" variant="bold">
            Campaign Description
          </Text>
          <Text size="1" variant="bold">
            Use Case
          </Text>
        </Flex>
        <Divider />
        {/* loading state */}
        {loading &&
          Array.from({ length: 5 }, (_, k) => (
            <Flex css={{ width: '100%' }} justify="between" key={k}>
              <Skeleton variant="heading" css={{ width: '100%' }} />
            </Flex>
          ))}
        {/* empty state */}
        {campaigns.length < 1 && !loading && (
          <Flex css={{ width: '100%' }} justify="center">
            <Text>No campaigns have been created.</Text>
          </Flex>
        )}
        {/* normal state */}
        {campaigns.length > 0 &&
          !loading &&
          campaignsRendered.map((campaign: any) => {
            return (
              <Flex css={{ width: '100%' }} justify="between" key={campaign?.id}>
                <Text>{campaign.description}</Text>
                <Text css={{ textTransform: 'capitalize' }}>
                  {campaign.use_case.toLowerCase()}
                </Text>
              </Flex>
            );
          })}
      </VStack>
      <Flex css={{ width: '100%', mt: 20 }} justify="end">
        <HStack>
          <IconButton disabled={page <= 1 ? true : false} onClick={onPageDown}>
            <HiChevronLeft />
          </IconButton>
          <Text>{page}</Text>
          <IconButton disabled={page * 10 >= campaigns.length} onClick={onPageUp}>
            <HiChevronRight />
          </IconButton>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default CampaignsList;
