import { Invite } from '@/shared/types';
import { Location } from '@/shared/types/locations';

import { SearchFilters } from '../contacts';

export type UsersType = {
  data: Array<User>;
  total: number;
};

export type InvitesType = {
  data: Array<Invite>;
  total: number;
};

export type UserV2 = User & { channels: Array<Location> };

export type User = {
  /** user id - int */
  id: number;
  /** user name */
  name: string;
  /** user email */
  email: string;
  /** user's phone */
  phone: string;
  /** user's organization id */
  organization_id?: string;
  /** user's state e.g. disabled */
  state?: UserStates;
  /** user's role e.g. admin */
  role?: UserRoles;
  /** user's location for current organization */
  locations?: Array<Location>;
  /** user's profile picture */
  attachment: UserAttachment | null;
  /** user's default signature id */
  default_signature_id: string | null;
};

export type UserAttachment = {
  url: string;
  content_type: string;
};

export enum UserStates {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  INVITED = 'invited',
  ARCHIVED = 'archived',
}

export enum UserRoles {
  USER = 'user',
  ADMIN = 'admin',
}

export enum UsersActionTypes {
  UPDATE_USER = 'UPDATE_USER',
  GET_USERS = 'GET_USERS',
  GET_INVITES = 'GET_INVITES',
  ADD_INVITES = 'ADD_INVITES',
  DELETE_INVITE = 'DELETE_INVITE',
  SET_CURRENT = 'SET_CURRENT',
  DISABLE_USER = 'DISABLE_USER',
  ENABLE_USER = 'ENABLE_USER',
  ARCHIVE_USER = 'ARCHIVE_USER',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
  UPDATE_INVITES_FILTER_PARAMS = 'UPDATE_INVITES_FILTER_PARAMS',
  SEARCH_USERS = 'SEARCH_USERS',
}

export type UserParams = {
  id: string | number;
  role: string;
  attachments:
    | {
        content_type: string;
        url: string;
      }[]
    | null;
  phone: string | null;
  name: string | null;
  state: UserStates;
  locations?: Array<string>;
};

export type InviteParams = {
  email: string;
  locations: Array<string>;
};

export type UsersAction =
  | { type: UsersActionTypes.GET_USERS; payload: Array<User> }
  | { type: UsersActionTypes.GET_INVITES; payload: InvitesType }
  | { type: UsersActionTypes.ADD_INVITES; payload: Array<Invite> }
  | { type: UsersActionTypes.DELETE_INVITE; payload: string | number }
  | { type: UsersActionTypes.UPDATE_USER; payload: User }
  | { type: UsersActionTypes.DISABLE_USER; payload: User }
  | { type: UsersActionTypes.ENABLE_USER; payload: User }
  | { type: UsersActionTypes.SET_CURRENT; payload: User | null }
  | { type: UsersActionTypes.ARCHIVE_USER; payload: Partial<UserParams> }
  | { type: UsersActionTypes.UPDATE_FILTER_PARAMS; payload: SearchFilters }
  | { type: UsersActionTypes.UPDATE_INVITES_FILTER_PARAMS; payload: SearchFilters }
  | { type: UsersActionTypes.SEARCH_USERS; payload: UsersType };
