import { PopoverPortal } from '@radix-ui/react-popover';
import { Dispatch, SetStateAction } from 'react';

import { useDisclosure } from '@/shared/hooks';
import { Box, Button, Popover, PopoverContent, PopoverTrigger, Text } from '@/shared/ui';
import { SegmentedMessage } from '@/shared/utils/segments';

import { ToolTipIconButton } from '../../attachments/previewer';

type MessageSegmentPopoverProps = {
  message: string;
  setMessage?: Dispatch<SetStateAction<string>>;
  isNote?: boolean;
};

// This component shows the character count in the toolbar
// When the user hovers over the character count, it will show the credit count
// The button if we can save credits by removing non-GSM7 characters
export const MessageSegmentPopover = (props: MessageSegmentPopoverProps) => {
  const { message, setMessage, isNote } = props;
  const segmentedMessage = new SegmentedMessage(message);
  const characterCount = segmentedMessage.numberOfCharacters;
  const segmentCount = segmentedMessage.segments.length;

  // Track if we can save the user credits by removing non-GSM7 characters
  const fixedSegmentedMessage = new SegmentedMessage(removeNonGSM7Characters(message));
  const fixedSegmentCount = fixedSegmentedMessage.segments.length;
  const saveCredits = segmentCount - fixedSegmentCount;

  const nonGSM7Characters = hasNonGSM7Characters(message);

  const {
    isOpen: isPopoverOpen,
    onClose: onPopoverClose,
    toggle: togglePopover,
  } = useDisclosure();

  // This function removes all non-GSM7 characters from the message
  // and returns the message with only GSM7 characters
  // Which is then updated in the message editor
  const handleFixMessage = () => {
    if (!setMessage) return;
    setMessage(removeNonGSM7Characters(message));
    onPopoverClose();
  };

  // This function checks if the message has any non-GSM7 characters
  // Only if there are non-GSM7 characters, we will show the popover
  // Otherwise, we will just show the character count in the toolbar
  const handleTogglePopover = () => {
    if (saveCredits > 0) {
      togglePopover();
    }
  };

  // This function generates the description for the tooltip
  // It will show the number of credits the message will cost
  // If there are no characters, it will show 0 credits, even though all
  // SMS messages cost 1 credit, this is because we block messages with no characters
  const generateToolTipDescription = () => {
    const segmentText = segmentCount === 1 ? 'Credit' : 'Credits';

    return characterCount > 0 ? `${segmentCount} ${segmentText}` : '0 Credits';
  };

  return (
    <Popover open={isPopoverOpen}>
      <PopoverTrigger asChild>
        {isNote ? (
          <Text
            css={{
              color: '$slate12',
              fontWeight: 400,
            }}
          >
            {characterCount}
          </Text>
        ) : (
          <ToolTipIconButton
            type="button"
            description={generateToolTipDescription()}
            icon={
              <Text
                css={{
                  color:
                    characterCount > 800 || nonGSM7Characters ? '$red10' : '$slate12',
                  fontWeight: characterCount > 800 || nonGSM7Characters ? 600 : 400,
                }}
              >
                {characterCount}
              </Text>
            }
            onClick={handleTogglePopover}
          />
        )}
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          side="top"
          sideOffset={10}
          css={{
            width: 400,
            zIndex: 999999,
            boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.15)',
          }}
          onPointerDownOutside={onPopoverClose}
        >
          <Box
            css={{
              px: 15,
              py: 15,
            }}
          >
            <Box css={{ mb: 5 }}>Total Credits: {segmentCount}</Box>
            <Box>
              <MessageSegmentVisualizer message={message} />
            </Box>
            {saveCredits > 0 && (
              <Button
                onClick={() => handleFixMessage()}
                css={{ mt: 10, width: '100%' }}
                variant="outline"
              >
                Click and Save {saveCredits} Credits
              </Button>
            )}
          </Box>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

// This function removes all non-GSM7 characters from the message
// and returns the message with only GSM7 characters
const removeNonGSM7Characters = (message: string): string => {
  const segmentedMessage = new SegmentedMessage(message);
  const filteredChars = segmentedMessage.encodedChars
    .filter((char) => char.isGSM7)
    .map((char) => char.raw);
  return filteredChars.join('');
};

// This function checks if the message has any non-GSM7 characters
const hasNonGSM7Characters = (message: string): boolean => {
  const segmentedMessage = new SegmentedMessage(message);
  return segmentedMessage.encodedChars.some((char) => !char.isGSM7);
};

// This component visualizes the message with non-GSM7 characters
// This is so the user can see which characters are non-GSM7 and that will
// Cost them more credits
export const MessageSegmentVisualizer = ({ message }: { message: string }) => {
  const segmentedMessage = new SegmentedMessage(message);

  return (
    <div>
      {segmentedMessage.encodedChars.map((char, index) => {
        switch (char.isGSM7) {
          case true:
            return (
              <span
                style={{
                  color: 'gray',
                }}
                key={index}
              >
                {char.raw}
              </span>
            );
          case false:
            return (
              <span
                style={{
                  color: 'red',
                  border: '1px solid red',
                }}
                key={index}
              >
                {char.raw}
              </span>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
