/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { useSequences } from '@/pages/sequences/context/SequenceContext';
import { Sequence, SequenceStep } from '@/shared/types/sequences';
import { VStack } from '@/shared/ui';

import { FormFieldWrapper, helpers } from '../../../forms';
import { SelectCombobox } from '../../../forms';
import { ActionProps } from '.';

export const MoveToStepAction = (props: ActionProps) => {
  const { index } = props;

  const formik = useFormikContext();
  // @ts-ignore
  const sequenceId = formik?.values?.actions[index]?.params.sequence_id;

  const sequence = useSequences();
  const { sequencesState, getSequences, getSequenceTemplates, getSequenceSteps } =
    sequence;
  const { sequences: allSequences, sequenceTemplates: allTemplates } = sequencesState;

  useEffect(() => {
    // if the sequences are not loaded, load them
    if (!allSequences || allSequences.length === 0) {
      getSequences();
    }
  }, []);

  useEffect(() => {
    if (!allTemplates || allTemplates.length === 0) {
      getSequenceTemplates();
    }
  }, []);

  // combine sequences and templates
  const allSequencesAndTemplates = [...allSequences, ...allTemplates];

  const options =
    allSequencesAndTemplates && allSequencesAndTemplates.length > 0
      ? allSequencesAndTemplates
          // filter out null values
          .filter((sequence): sequence is Sequence => sequence !== null)
          // filter out duplicate sequences by id
          .filter(
            (sequence, index, self) =>
              index === self.findIndex((t) => t.id === sequence.id)
          )
          // map the sequences to the options and add the template tag to the title
          .map((sequence: Sequence) => ({
            value: `${sequence?.id || ''}` as string,
            type:
              sequence?.status === 'template'
                ? `${sequence?.title} [TEMPLATE]`
                : `${sequence?.title}`,
          }))
      : [];

  // the selected sequence steps based on the selected sequence id
  const [selectedSequenceSteps, setSelectedSequenceSteps] = useState<SequenceStep[]>([]);

  // show the step options only when the sequence id is selected
  const [showStepOptions, setShowStepOptions] = useState(false);

  useEffect(() => {
    const fetchSequenceSteps = async () => {
      // reset the step options when the sequence id is changed
      setShowStepOptions(false);
      if (sequenceId) {
        // get the sequence steps based on the selected sequence id
        const data = await getSequenceSteps(sequenceId);
        // set the sequence steps
        setSelectedSequenceSteps(data);
        // show the step options
        setShowStepOptions(true);
      }
    };

    fetchSequenceSteps();
  }, [sequenceId]);

  const step_options = selectedSequenceSteps?.map((step) => ({
    value: `${step?.id || ''}` as string,
    type:
      `Step ${step.position ? step?.position + 1 : ''} - ${step?.title}` || 'No title',
  }));

  return (
    <VStack gap={2}>
      <FormFieldWrapper
        label="Add to Sequence"
        tooltip="Select the sequence to add the contact to"
        name={`actions[${index}].params.sequence_id`}
      >
        <SelectCombobox
          placeholder={helpers.displaySelectedItem}
          isDropdown={true}
          options={options}
        />
      </FormFieldWrapper>
      {showStepOptions && step_options && (
        <FormFieldWrapper
          label="Add to Step"
          tooltip="Select the sequence step to add the contact to"
          name={`actions[${index}].params.step_id`}
        >
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={step_options}
          />
        </FormFieldWrapper>
      )}
    </VStack>
  );
};
