/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { useAuth } from '@/auth/context/AuthProvider';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import {
  FormFieldWrapper,
  helpers,
  MultiSelectCombobox,
  PhoneNumberInput,
  TextInput,
} from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks/useDisclosure';
import {
  Button,
  Checkbox,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Flex,
  Text,
  VStack,
} from '@/shared/ui';
import { toE164 } from '@/shared/utils/validations/validations';

import { useContacts } from './context/ContactContext';
import { formatContactName } from './utils';

type AddContactProps = {
  /**
   * Whether the modal is open or not
   */
  open?: boolean;
  /**
   * Function to open the modal
   */
  setOpen?: (value: boolean) => void;
  /**
   * The content of the modal
   */
  children?: React.ReactNode;
  /**
   * Whether to hide the overlay or not
   */
  hideOverlay?: boolean;
  /**
   * The phone number to be added
   */
  phone?: string;
};

export function AddContact(props: AddContactProps): JSX.Element {
  const { children, hideOverlay, phone, open, setOpen } = props;

  const contactContext = useContacts();
  const { addContactHttp, clearCurrent } = contactContext;

  const auth = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocations();
  const { locations } = location.locationsState;

  const [checked, setChecked] = useState(true);

  // This is a function that is used to create a new contact.
  const newContact = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    clearCurrent();
    onOpen();
  };

  // if props.open is true open the dialog
  useEffect(() => {
    if (open !== undefined) {
      if (open) {
        // if open is not undefined, and if it is true, then clear the current
        // and trigger the onOpen function
        clearCurrent();
        onOpen();
      }
    }
  }, [open]);

  // This function closes the dialog by calling the onClose function and
  // setting the open variable to false if it exists.
  const closeDialog = () => {
    onClose();
    setOpen ? setOpen(false) : null;
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={newContact}>
        {children}
      </DialogTrigger>
      <DialogPortal>
        {!hideOverlay && <DialogOverlay />}
        <DialogContent
          onEscapeKeyDown={() => closeDialog()}
          onPointerDownOutside={() => closeDialog()}
        >
          <DialogHeader title="Create Contact" />
          <Formik
            initialValues={{
              name: '',
              phone: phone || '',
              email: '',
              locations: locations.map((location) => location.id),
            }}
            validationSchema={Yup.object({
              name: Yup.string(),
              phone: Yup.string().test('phone', 'Required', function (value) {
                const { email } = this.parent;
                return value || email ? true : this.createError({ message: 'Required' });
              }),
              email: Yup.string()
                .email('Invalid email')
                .test('email', 'Required', function (value) {
                  const { phone } = this.parent;
                  return value || phone
                    ? true
                    : this.createError({ message: 'Required' });
                }),
              locations: Yup.array().test(
                'len',
                'Required',
                (arr) => Array.isArray(arr) && arr.length > 0
              ),
            })}
            onSubmit={async (values) => {
              const params = {
                name: formatContactName(values.name),
                phone: toE164(values.phone),
                email: values.email,
                organization_id: auth.organizationId,
                locations:
                  locations.length > 1
                    ? values.locations
                    : checked
                      ? [locations[0].id]
                      : [],
              };
              try {
                addContactHttp(params);
                closeDialog();
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <VStack gap={1}>
                  <FormFieldWrapper
                    label="Full Name"
                    tooltip="Contact's Full Name"
                    name="name"
                  >
                    <TextInput placeholder="Jane Doe" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    label="Mobile Phone Number"
                    tooltip="Contact's Mobile Phone Number"
                    name="phone"
                  >
                    <PhoneNumberInput placeholder="(000) 000-0000" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    label="Email Address"
                    tooltip="Contact's Email Address"
                    name="email"
                  >
                    <TextInput placeholder="jane@example.com" />
                  </FormFieldWrapper>
                  {locations.length > 1 ? (
                    <FormFieldWrapper
                      label="Opt-in"
                      tooltip="Can receive automated messages?"
                      name="locations"
                    >
                      <MultiSelectCombobox
                        placeholder={helpers.displaySelectedItems}
                        selectAll={true}
                        isDropdown={true}
                        options={locations.map((location) => ({
                          type: location.name as string,
                          value: location.id as string,
                        }))}
                      />
                    </FormFieldWrapper>
                  ) : (
                    <FormFieldWrapper
                      label="Opt-in"
                      tooltip="Can receive automated messages?"
                      name="location"
                    >
                      <Flex align="center">
                        <Checkbox
                          checked={checked}
                          onCheckedChange={(e: boolean) => setChecked(e)}
                          css={{ mr: 5 }}
                        />
                        <Text>Opt-in</Text>
                      </Flex>
                    </FormFieldWrapper>
                  )}
                  <DialogFooter justify="end" css={{ mt: 0, pt: 10 }}>
                    <DialogClose asChild>
                      <Button
                        aria-label="Close"
                        variant="gray"
                        css={{ mr: 5 }}
                        type="button"
                        onClick={() => closeDialog()}
                      >
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild type="submit">
                      <Button aria-label="Close">Save Contact</Button>
                    </DialogClose>
                  </DialogFooter>
                </VStack>
              </form>
            )}
          </Formik>
          <DialogCloseIcon onClick={() => closeDialog()} size="2">
            <HiX size="15px" style={{ color: 'white' }} />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
