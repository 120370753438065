import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import { Invite } from '@/shared/types';
import { User } from '@/shared/types/users';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Box,
  Button,
  Flex,
  VStack,
} from '@/shared/ui';
import { isValidEmail } from '@/shared/utils/validations/validations';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxMultiselectTrigger } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';
import { ComboboxMultiselectInput } from '@/shared/v2/components/forms/comboboxMultiselect/ComboboxMultiselectInput';

import { useLocations } from '../locations/context/LocationContext';
import { useUsers } from './context/UserContext';

export const AddUser = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { addUsers, userState } = useUsers();

  const location = useLocations();
  const { locations } = location.locationsState;

  const allUserEmails = [
    ...userState.users.map((user: User) => user.email),
    ...userState.invites.map((invite: Invite) => invite.email),
  ];

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogTrigger asChild>
        <Button onClick={onOpen} data-intercom-target="invite-users-button">
          Invite Users
        </Button>
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay as="div">
          <AlertDialogContent onEscapeKeyDown={onClose}>
            <AlertDialogTitle css={{ fontSize: '20px' }}>Invite Users</AlertDialogTitle>
            <Box css={{ fontSize: '14px', fontWeight: 400 }}>
              Enter the User&apos;s email address and select locations you want the User
              to have access to.
            </Box>
            <Formik
              initialValues={{
                email: '',
                locations: locations.map((location) => location.id),
              }}
              validationSchema={Yup.object({
                locations: Yup.array().test({
                  name: 'locationsRequiredError',
                  message: 'Locations is required',
                  test: (arr) => Array.isArray(arr) && arr.length > 0,
                }),
                email: Yup.string()
                  .required('Email is required')
                  .test({
                    name: 'emailInvalidError',
                    message: 'Email is invalid',
                    test: (value) => {
                      if (value) {
                        return isValidEmail(value);
                      } else {
                        return true;
                      }
                    },
                  })
                  .test({
                    name: 'emailAlreadyExists',
                    message: 'Email is already in the organization',
                    test: (value) => {
                      if (value) {
                        return !allUserEmails.includes(value);
                      } else {
                        return true;
                      }
                    },
                  }),
              })}
              onSubmit={async (values) => {
                const params = {
                  email: values.email.toLowerCase(),
                  locations: values.locations,
                };
                try {
                  addUsers(params);
                  onClose();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <VStack gap={0} css={{ marginTop: '16px' }}>
                    <Box css={{ position: 'relative' }}>
                      <FormFieldWrapper name="email">
                        <TextInput
                          css={{ height: '32px', fontSize: 14, fontWeight: 400 }}
                          placeholder="Enter email"
                          applyInvalidState
                        />
                      </FormFieldWrapper>
                    </Box>
                    {locations.length > 1 ? (
                      <>
                        <FormFieldWrapper name="locations">
                          <ComboboxMultiselectInput
                            options={locations.map((location) => ({
                              label: location.name || location.address || '',
                              value: location.id,
                            }))}
                            searchLabel="Search"
                            selectLabel="Choose locations"
                            Trigger={ComboboxMultiselectTrigger}
                            Option={ComboboxMultiselectItem}
                            invalid={
                              !!(formik.touched.locations && formik.errors.locations)
                            }
                            selectAll
                          />
                        </FormFieldWrapper>
                      </>
                    ) : null}
                  </VStack>
                  <Flex justify="end">
                    <AlertDialogCancel asChild>
                      <Button variant="grayBackground" css={{ mr: 10 }} onClick={onClose}>
                        Cancel
                      </Button>
                    </AlertDialogCancel>
                    <AlertDialogAction asChild>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={!formik.isValid}
                        data-intercom-target="invite-users-button"
                      >
                        Invite User
                      </Button>
                    </AlertDialogAction>
                  </Flex>
                </form>
              )}
            </Formik>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialogPortal>
    </AlertDialog>
  );
};
