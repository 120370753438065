/* eslint-disable react-hooks/exhaustive-deps */
import { useCombobox } from 'downshift';
import React, { useEffect, useRef, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

import { sources } from '@/contacts/ContactsFilters';
import { useGroups } from '@/contacts/groups/context/GroupContext';

import { useDisclosure } from '../hooks';
import {
  Box,
  Checkbox,
  ComboboxItem,
  ComboboxItemText,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  HStack,
  Input,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../ui';
import { ConditionalComponent } from './forms/MultiSelectCombobox';

type SelectOption = {
  /** name of option */
  type: string;
  /** id of option */
  value: string | number;
};

type MultiSelectProps = {
  /** list of options in the [{type: string, value: string}] format */
  options: Array<SelectOption>;
  /** list of options that you want to be selected by default [value1: string, value2: string] format */
  defaultSelectedItems?: Array<string | number>;
  /** placeholder for the input field */
  defaultPlaceholder: string;
  /** will the component be a dropdown */
  isDropdown: boolean;
  /** we use this for groups filters, that is the filter values of the parent */
  parentSelectedItems?: any;
  /** we use this for groups filters, that is a function that can change the filter values of the parent */
  setParentSelectedItems?: any;
  /** if this is true all items will be unselected */
  clearSelectedItems?: boolean;
  /** true if on campaigns page */
  isCampaigns?: boolean;
  /** when multi-select changes, will set this signal to be true */
  setDidFiltersChange?: (newDidFiltersChange: boolean) => void;
};

export const MultiSelect = (props: MultiSelectProps): JSX.Element => {
  const enhancedOptions = [{ type: 'Select All', value: null }, ...props.options];
  const [inputItems, setInputItems] = useState(enhancedOptions);
  const [selectedItems, setSelectedItems] = useState(
    props.defaultSelectedItems
      ? props.defaultSelectedItems
      : props.options.map((e: SelectOption) => e.value)
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [anchorWidth, setAnchorWidth] = useState(0);
  const [placeholder, setPlaceholder] = useState(props.defaultPlaceholder || '');
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const location = useLocation();
  const isInbox = location.pathname.startsWith('/inbox');

  const { groupsState } = useGroups();
  const currentGroup = groupsState.current;

  const updateAnchorWidth = () => {
    setAnchorWidth(inputRef.current.offsetWidth);
  };

  const handleClick = () => {
    setPlaceholder('');
    if (!isOpen) {
      onOpen();
      updateAnchorWidth();
    } else {
      displaySelectedItems(selectedItems as any);
      onClose();
    }
  };

  const handlePointerDownOutside = (e: Event) => {
    if (e.currentTarget !== inputRef.current) {
      onClose();
      handleClick();
    }
  };

  const displaySelectedItems = (selectedItems: Array<string>): void => {
    const selectedItemsWithTypes = props.options
      .map((option) => (selectedItems.includes(option?.value as string) ? option : null))
      .filter((item) => item !== null);

    const firstSelectedItem = selectedItemsWithTypes[0];
    if (firstSelectedItem) {
      if (selectedItems.length === 1) {
        return setPlaceholder(firstSelectedItem.type);
      } else {
        return setPlaceholder(
          `${firstSelectedItem.type} & ${selectedItems.length - 1} more`
        );
      }
    } else {
      return setPlaceholder(props.defaultPlaceholder);
    }
  };

  useEffect(() => {
    if (props.parentSelectedItems && !props.isCampaigns) {
      if (
        props.parentSelectedItems &&
        props.parentSelectedItems.sources &&
        props.options
      ) {
        const isFilteredBySource =
          props.options.map((item: any) => item.value)[0] === sources[0];
        const isFilteredByLocation = props?.defaultPlaceholder === 'Locations';
        if (isFilteredBySource) {
          selectedItems.length === 0
            ? props.setParentSelectedItems({ ...props.parentSelectedItems, sources: [] })
            : props.setParentSelectedItems({
                ...props.parentSelectedItems,
                sources: selectedItems,
              });
        } else if (isFilteredByLocation) {
          selectedItems.length === 0
            ? props.setParentSelectedItems({
                ...props.parentSelectedItems,
                locations: [],
              })
            : props.setParentSelectedItems({
                ...props.parentSelectedItems,
                locations: selectedItems,
              });
        } else {
          selectedItems.length === 0
            ? props.setParentSelectedItems({ ...props.parentSelectedItems, tags: [] })
            : props.setParentSelectedItems({
                ...props.parentSelectedItems,
                tags: selectedItems,
              });
        }
      }
    } else {
      const isFilteredByLocation = props.defaultPlaceholder === 'Locations';
      const isFilteredByTypes = props.defaultPlaceholder === 'Select Types';
      if (isFilteredByLocation) {
        selectedItems.length === 0
          ? props.setParentSelectedItems({ ...props.parentSelectedItems, locations: [] })
          : props.setParentSelectedItems({
              ...props.parentSelectedItems,
              locations: selectedItems,
            });
      } else if (isFilteredByTypes) {
        selectedItems.length === 0
          ? props.setParentSelectedItems({ ...props.parentSelectedItems, types: [] })
          : props.setParentSelectedItems({
              ...props.parentSelectedItems,
              types: selectedItems,
            });
      } else {
        selectedItems.length === 0
          ? props.setParentSelectedItems({ ...props.parentSelectedItems, users: [] })
          : props.setParentSelectedItems({
              ...props.parentSelectedItems,
              users: selectedItems,
            });
      }
    }
  }, [selectedItems]);

  useEffect(() => {
    if (
      isInbox &&
      JSON.stringify(props.defaultSelectedItems) != JSON.stringify(selectedItems) &&
      props?.parentSelectedItems?.length < 1
    ) {
      setSelectedItems(props.defaultSelectedItems as string[]);
    }
  }, [props.defaultSelectedItems]);

  useEffect(() => {
    if (props.clearSelectedItems && !currentGroup) {
      setSelectedItems([]);
    }
  }, [props.clearSelectedItems]);

  useEffect(() => {
    displaySelectedItems(selectedItems as string[]);
  }, [selectedItems]);

  useEffect(() => {
    const enhancedOptions = [{ type: 'Select All', value: null }, ...props.options];
    !inputRef?.current?.value && setInputItems(enhancedOptions);
  }, [props.options]);

  const { getMenuProps, getInputProps, getItemProps, highlightedIndex } = useCombobox({
    items: inputItems,
    onSelectedItemChange: ({ selectedItem }) => {
      // if multiselect get changed, will set the signal to be true
      props.setDidFiltersChange && props.setDidFiltersChange(true);
      if (!selectedItem?.value) {
        if (selectedItems.length === props.options.length) {
          return setSelectedItems([]);
        } else {
          return setSelectedItems(props.options.map((item) => item.value));
        }
      }
      const index = selectedItems.indexOf(selectedItem?.value);
      if (index >= 0) {
        return setSelectedItems(
          selectedItems.filter((item: string | number) => item !== selectedItem?.value)
        );
      } else {
        return setSelectedItems([...selectedItems, selectedItem?.value]);
      }
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '',
          };
        default:
          return {
            ...changes,
          };
      }
    },
    onInputValueChange: ({ inputValue }) => {
      if (inputValue) {
        const filteredOptions = props.options.filter((item) =>
          item.type.toLowerCase().includes(inputValue.toLowerCase())
        );
        setInputItems(filteredOptions);
      } else {
        setInputItems(enhancedOptions);
      }
    },
  });

  return (
    <ConditionalComponent
      condition={!!props.isDropdown}
      wrapper={{
        base: (children) => (children ? children : null),
        custom: (children) => (
          <DropdownMenu open={isOpen} onOpenChange={() => !isOpen} modal={false}>
            {children}
          </DropdownMenu>
        ),
      }}
    >
      <>
        <Box>
          <ConditionalComponent
            condition={!!props.isDropdown}
            wrapper={{
              base: (children) => (
                <Box
                  css={{
                    position: 'relative',
                  }}
                >
                  {children}
                </Box>
              ),
              custom: (children) => (
                <DropdownMenuTrigger asChild={true} onClick={handleClick}>
                  <Box css={{ position: 'relative' }}>
                    <HiChevronDown
                      style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '10px',
                        pointerEvents: 'none',
                        fontSize: '16px',
                      }}
                    />
                    {children}
                  </Box>
                </DropdownMenuTrigger>
              ),
            }}
          >
            <ConditionalComponent
              condition={false}
              wrapper={{
                base: () => (
                  <>
                    <Input
                      {...getInputProps(
                        {
                          placeholder: placeholder,
                          autoComplete: 'chrome-off',
                          type: 'text',
                          ref: inputRef,
                        },
                        { suppressRefError: true }
                      )}
                      variant={props.isDropdown ? 'selectDropdown' : null}
                    />
                  </>
                ),
                custom: () => null,
              }}
            />
          </ConditionalComponent>
        </Box>
        <Box {...getMenuProps({}, { suppressRefError: true })}>
          <ConditionalComponent
            condition={!!props.isDropdown}
            wrapper={{
              base: (children) => (children ? children : null),
              custom: (children) => (
                <DropdownMenuContent
                  side="bottom"
                  sideOffset={5}
                  variant="combobox"
                  onPointerDownOutside={(e) => handlePointerDownOutside(e)}
                  onFocus={() => inputRef.current.focus()}
                >
                  {children}
                </DropdownMenuContent>
              ),
            }}
          >
            <ConditionalComponent
              condition={false}
              wrapper={{
                base: (children) => (
                  <ScrollArea variant="combobox">{children}</ScrollArea>
                ),
                custom: () => null,
              }}
            >
              <>
                <ScrollAreaViewport variant="combobox">
                  {inputItems.length > 0 ? (
                    inputItems.map((item, index) => (
                      <Box
                        key={`${item.value}${index}`}
                        {...getItemProps({ item, index })}
                        css={{ width: props.isDropdown ? anchorWidth : 'auto' }}
                      >
                        <ConditionalComponent
                          condition={false}
                          wrapper={{
                            base: () => (
                              <ComboboxItem
                                selected={highlightedIndex === index}
                                css={{
                                  borderBottom: !item.value
                                    ? '1px solid $slate7'
                                    : 'auto',
                                }}
                              >
                                <HStack gap={2} css={{ overflow: 'hidden' }}>
                                  <Checkbox
                                    checked={
                                      selectedItems.includes(item.value as string) ||
                                      selectedItems.length === props.options.length
                                    }
                                    css={{ pointerEvents: 'none', minWidth: 15 }}
                                  />
                                  <ComboboxItemText>{item.type}</ComboboxItemText>
                                </HStack>
                              </ComboboxItem>
                            ),
                            custom: () => null,
                          }}
                        />
                      </Box>
                    ))
                  ) : (
                    <Box css={{ width: props.isDropdown ? anchorWidth : 'auto' }}>
                      <ConditionalComponent
                        condition={false}
                        wrapper={{
                          base: () => (
                            <ComboboxItem
                              disabled="true"
                              justify="center"
                              align="center"
                              css={{ pointerEvent: 'none' }}
                            >
                              <ComboboxItemText>No Results Found</ComboboxItemText>
                            </ComboboxItem>
                          ),
                          custom: () => null,
                        }}
                      />
                    </Box>
                  )}
                </ScrollAreaViewport>
                <ScrollAreaScrollbar orientation="vertical" variant="combobox">
                  <ScrollAreaThumb />
                </ScrollAreaScrollbar>
                <ScrollAreaCorner />
              </>
            </ConditionalComponent>
          </ConditionalComponent>
        </Box>
      </>
    </ConditionalComponent>
  );
};
