/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { HiSearch, HiX } from 'react-icons/hi';

import { Box, Flex, IconButton, Input } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useConversation } from '../context/ConversationContext';
import { ConversationSearchSort } from './ConversationSearchSort';
import { ConversationsSort } from './ConversationsSort';

type ConversationFilterProps = {
  /** the current conversation tab from the URL - open, automated, closed */
  tab: string;
  /** the current conversation filter from the URL - all, me, unassigned, location_id */
  filter: string;
  /** the current conversation search from the search input */
  contactValue: string;
  /** function that sets the inputs search input */
  setContactValue: React.Dispatch<React.SetStateAction<string>>;
};

export const ConversationsFilter = (props: ConversationFilterProps) => {
  const { contactValue, setContactValue } = props;

  const { setShowTabs, changeTabIndex, setSearchV2, conversationState } =
    useConversation();

  const { tabIndex } = conversationState;

  useEffect(() => {
    // Update SearchV2 for every contactValue change when feature flag is enabled
    setSearchV2({ inputValue: contactValue });

    // Navigate to filtered view if there's a search term and not already in 'filtered' tab
    if (contactValue.length > 1 && !tabIndex.includes('filtered')) {
      changeTabIndex('filtered:contacts');
    }
    // Show or hide tabs based on search input presence
    setShowTabs(contactValue.length <= 1);
    // Navigate to filtered view if there's a search term and not already in 'filtered' tab
    if (contactValue.length > 1 && !tabIndex.includes('filtered')) {
      changeTabIndex('filtered:contacts');
    }
    // Show or hide tabs based on search input presence
    setShowTabs(contactValue.length <= 1);
  }, [contactValue, tabIndex]);

  // when the users pastes a value into the search input
  // update the search value
  const handlePaste = (e: React.ClipboardEvent) => {
    e?.preventDefault();

    const value = e.clipboardData.getData('Text');
    setContactValue(value);
  };

  // when a user clicks the X icon to clear the search input
  const handleRemoveContactValue = () => {
    setContactValue('');
    setShowTabs(true);
  };

  return (
    <SearchContainer align="center">
      <Box css={{ position: 'relative', width: '100%', mr: 5 }}>
        <SearchIconContainer css={{ left: 10 }}>
          <HiSearch />
        </SearchIconContainer>
        <Input
          type="text"
          placeholder="Search Inbox"
          value={contactValue}
          css={{ pl: 38 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setContactValue(e.target.value)
          }
          onPaste={handlePaste}
        />
        {contactValue && (
          <SearchControlsContainer css={{ right: 5 }}>
            <IconButton onClick={handleRemoveContactValue} css={{ mt: 2 }}>
              <HiX size={15} />
            </IconButton>
          </SearchControlsContainer>
        )}
      </Box>
      {/* 
        Conditionally render the Conversation Sort component based on the contactValue.
        If contactValue is present, render ConversationSearchSort.
        Otherwise, render the default ConversationsSort component.
      */}
      {!contactValue ? <ConversationsSort /> : <ConversationSearchSort />}
    </SearchContainer>
  );
};

const SearchControlsContainer = styled(Box, {
  position: 'absolute',
  top: '18px',
  transform: 'translateY(-50%)',
  right: '5px',
});

const SearchIconContainer = styled(Box, {
  position: 'absolute',
  top: '18px',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
});

const SearchContainer = styled(Flex, {
  px: 15,
  py: 18,
  borderBottom: '1px solid $gray4',
  mb: 8,
});

export const SideNavigationHeading = styled(Flex, {
  flex: 'initial 0 initial',
  fontSize: 17,
  fontWeight: 800,
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
