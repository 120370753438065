import React, { useState } from 'react';
import { HiAdjustments, HiX } from 'react-icons/hi';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { useDisclosure } from '@/shared/hooks';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Fieldset,
  Input,
  Label,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { isValidPhoneNumber, toE164 } from '@/shared/utils/validations/validations';

import { useCampaignsContext } from '../context/CampaignsContext';

type SendPreviewProps = {
  /* The message body, as a string, to be sent */
  body: string;
  /* The list of attachments to be sent */
  attachments: string[];
  /* The location id to send the the campaign */
  location_id: string;
  /* The campaign title */
  campaignTitle: string;
  /* The campaign preview name */
  previewName: string;
  /* Set campaign preview name */
  setPreviewName: (name: string) => void;
  /* fill demo message */
  fillDemoMessage: () => string;
};

export const CampaignPreview = (props: SendPreviewProps): JSX.Element => {
  const {
    body,
    location_id,
    attachments,
    campaignTitle,
    previewName,
    setPreviewName,
    fillDemoMessage,
  } = props;
  // manage send preview dialog state
  const { isOpen, onOpen, onClose } = useDisclosure();

  const campaign = useCampaignsContext();
  const { sendPreview } = campaign;

  const locations = useLocations();
  const { getLocationById } = locations;
  const location = getLocationById(location_id);

  // the number to send the preview message to
  const [previewPhoneNumber, setPreviewPhoneNumber] = useState('');

  // send preview message
  const sendPreviewMessage = () => {
    const isPhoneValid = isValidPhoneNumber(toE164(previewPhoneNumber));
    // if message and from number are valid
    if (location_id && (body.length > 1 || attachments.length > 1) && isPhoneValid) {
      // fill message pipe fields
      const filled_body = fillDemoMessage();

      // add [PREVIEW] prefix to filled message
      const preview_body = `[PREVIEW] ${filled_body}`;

      // send preview api request
      if (location.phone) {
        sendPreview(
          preview_body,
          attachments,
          location.phone,
          toE164(previewPhoneNumber)
        );

        toast.success(i18next.t('campaign_preview_sent') as string);

        onClose();
      } else {
        toast.error(i18next.t('campaign_preview_failure') as string);
      }
    }
  };

  const isMobile = !useMedia('(min-width: 968px)');

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        {!isMobile ? (
          <Button
            variant="gray"
            disabled={
              campaignTitle.length < 3 ||
              !location_id ||
              !(body.length > 1 || attachments.length > 1)
            }
            onClick={onOpen}
          >
            Test Message
          </Button>
        ) : (
          <ToolTipIconButton
            variant="outline"
            size={2}
            icon={<HiAdjustments />}
            description="Send Test Message"
            onClick={onOpen}
          />
        )}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay>
          <DialogContent>
            <DialogHeader title="Send Test Message" />
            <Fieldset>
              <Label>Phone Number</Label>
              <Input
                placeholder="Enter your phone number"
                value={previewPhoneNumber}
                onChange={(e) => setPreviewPhoneNumber(e.target.value)}
              />
            </Fieldset>
            <Fieldset>
              <Label>Recipient Name</Label>
              <Input
                placeholder="Enter a Name"
                value={previewName}
                onChange={(e) => setPreviewName(e.target.value)}
              />
            </Fieldset>
            <DialogFooter justify="end">
              <DialogClose asChild>
                <Button variant="gray" css={{ mr: '$1' }} onClick={onClose}>
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button
                  variant="send"
                  onClick={sendPreviewMessage}
                  disabled={!isValidPhoneNumber(toE164(previewPhoneNumber))}
                >
                  Send Preview
                </Button>
              </DialogClose>
            </DialogFooter>
            <DialogClose asChild>
              <DialogCloseIcon size="2" onClick={onClose}>
                <HiX size="15px" style={{ color: 'white' }} />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
