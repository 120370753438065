/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { HiDuplicate, HiMail } from 'react-icons/hi';
import { useCopyToClipboard } from 'react-use';
import { toast } from 'sonner';

import { useAuth } from '@/auth/context/AuthProvider';
import * as API from '@/shared/api/webchat';
import {
  Box,
  Button,
  ControlGroup,
  Fieldset,
  Flex,
  Image,
  Input,
  Label,
  ProgressBar,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { isValidEmail } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useOnboarding } from '../context/OnboardingProvider';
import {
  OnboardingContainer,
  OnboardingDescription,
  OnboardingHeading,
  SkipOnboarding,
} from '../OnboardingContainer';

const InstallWebchat = () => {
  const onboarding = useOnboarding();
  const auth = useAuth();
  const { setCurrentStep } = onboarding;
  const [developerEmail, setDeveloperEmail] = useState('');

  const sendDeveloperEmail = () => {
    if (isValidEmail(developerEmail)) {
      API.sendInstructions({
        organization_name: auth.organizationName,
        organization_id: auth.organizationId,
        user_name: auth?.tokens?.name || '',
        user_email: auth?.tokens?.email || '',
        to: developerEmail,
      });
      toast.success(i18next.t('install_webchat_email_success') as string);
    } else {
      toast.error(i18next.t('install_webchat_email_failure') as string);
    }
  };

  const generateHtmlCode = () => {
    const organizationId = auth.organizationId;

    return `<script>window.Whippy = { config: { organizationId: "${organizationId}" },};</script>
    <script type="text/javascript" async defer src="https://web.whippy.co/whippy.js"></script>`.trim();
  };

  const [state, copyToClipboard] = useCopyToClipboard();
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    setCopied(true);
    copyToClipboard(`${generateHtmlCode()}`);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      <ProgressBar value={(3 / 5) * 100} variant="purple" />
      <SkipOnboarding />
      <Flex
        css={{
          flexWrap: 'wrap',
          color: '#333333',
          backgroundImage:
            'url("https://uploads-ssl.webflow.com/5fc01aa2868fa56df3b5539f/6115add89ad8bda4c4e4cf52_fallback.jpg")',
          backgroundSize: 'cover',
        }}
      >
        <InstallContainer align="center" justify="center">
          <OnboardingContainer>
            <OnboardingHeading css={{ textAlign: 'start' }}>
              Install Webchat Snippet
            </OnboardingHeading>
            <OnboardingDescription>
              Webchat lets you quickly convert customers on your website and continue your
              conversations offline with SMS.
            </OnboardingDescription>
            <Fieldset>
              <Label>Copy this snippet to add to your website</Label>
              <ControlGroup>
                <Input value={generateHtmlCode()} />
                <Button onClick={() => handleClick()} variant="gray">
                  {copied ? (
                    '🎉'
                  ) : (
                    <Flex align="center">
                      <HiDuplicate />
                      Copy Code
                    </Flex>
                  )}
                </Button>
              </ControlGroup>
            </Fieldset>
            <Fieldset>
              <Label>{"Enter your web developer's email address"}</Label>
              <ControlGroup>
                <Input
                  value={developerEmail}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDeveloperEmail(e.target.value)
                  }
                />
                <Button onClick={() => sendDeveloperEmail()} variant="gray">
                  <HiMail />
                  Send Email
                </Button>
              </ControlGroup>
            </Fieldset>
            <VStack gap={2} css={{ width: '100%' }}>
              <Button
                onClick={() => setCurrentStep('search_phone')}
                css={{ minWidth: '100%' }}
              >
                Continue
              </Button>
              <Button
                onClick={() => setCurrentStep('search_phone')}
                variant="gray"
                ghost={true}
                css={{ width: '100%' }}
              >
                {"I'll do this later"}
              </Button>
            </VStack>
          </OnboardingContainer>
        </InstallContainer>
        <Box css={{ bottom: 20, right: 42, position: 'absolute' }}>
          <WebchatPopUpContainer>
            <WebchatPopUpImage src={'./profile.png'} />
            <WebchatPopUpGreeting>
              Hey, welcome to {auth.organizationName} Text us here and a real person will
              respond fast.
            </WebchatPopUpGreeting>
            <WebchatPointer />
          </WebchatPopUpContainer>
          <WebchatToggle>
            <ChatIcon />
          </WebchatToggle>
        </Box>
      </Flex>
    </>
  );
};

export const InstallContainer = styled(Flex, {
  zIndex: 10,
  backgroundColor: 'white',
  paddingRight: 40,
  paddingLeft: 40,
  width: '100%',
  height: '100vh',
  '@md': {
    width: '100vh',
    height: '60%',
  },
});

export const WebchatToggle = styled(Box, {
  outline: 'none !important',
  border: 'none !important',
  cursor: 'pointer',
  width: '55px',
  height: '55px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$blue10',
  color: 'white',
});

export const WebchatPointer = styled(Box, {
  width: 21,
  height: 20,
  position: 'absolute',
  bottom: '-6px',
  right: '18px',
  backgroundColor: 'white',
  borderRadius: '4px',
  zIndex: '-1',
  transform: 'rotate(45deg)',
});

export const ChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const WebchatPopUpGreeting = styled(Box, {
  marginLeft: '12px',
  maxWidth: '185px',
  fontSize: '13px',
  lineHeight: '1.3',
  fontFamily: 'system-ui',
  fontWeight: 400,
  color: '#333333 !important',
});

export const WebchatPopUpImage = styled(Image, {
  width: '45px',
  height: '45px',
  padding: '0',
  margin: '0',
});

export const WebchatPopUpContainer = styled(Box, {
  position: 'fixed',
  bottom: '90px',
  right: '40px',
  maxWidth: '292px',
  maxHeight: '116px',
  padding: '24px',
  backgroundColor: '#ffffff',
  border: '1px solid #e8e9eb',
  boxShadow: '0 7px 20px 0 rgb(0 0 0 / 10%)',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  lineHeight: '20px',
});

export default InstallWebchat;
