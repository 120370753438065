import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { HiDuplicate, HiRefresh } from 'react-icons/hi';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { DomainStatusType } from '@/shared/types/domains';
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  VStack,
} from '@/shared/ui';
import { MONTH_DAY_YEAR } from '@/shared/utils/timestamps';
import i18next from '@/shared/utils/translation';
import { keyframes, styled } from '@/stitches.config';

import { useDomainsContext } from '../context/DomainsContext';
import { CreatedByUser } from '../list/CreatedByUser';
import { getBadgeVariant } from '../utils/utils';
import { DNSRecords } from './DNSRecords';
import { SetupResults } from './SetupResults';
import { SetupSteps } from './SetupSteps';
import { VerificationStatusBadge } from './VerificationStatusBadge';

export const Domain = () => {
  const {
    domainsState: { current, loadingDomain },
    getDomain,
    deleteDomain,
  } = useDomainsContext();

  const params = useParams<{ id: string }>();
  const history = useHistory();
  const isDomainPage = useRouteMatch('/campaigns/domains/:id');
  const isMobile = useMedia('(max-width: 700px)');

  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (params?.id && current?.id !== params?.id) {
      getDomain(params?.id);
    }
  }, [params?.id, current?.id]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(current?.name || '');
    toast.success(i18next.t('domain_name_copied') as string);
  }, [current?.name]);

  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    await getDomain(params?.id);
    setRefreshing(false);
  }, [params?.id]);

  const handleDelete = useCallback(async () => {
    try {
      current?.id && (await deleteDomain(current?.id));
      isDomainPage && history.push(`/campaigns/domains`);
    } catch (error) {
      console.log('error', error);
    }
  }, [deleteDomain, current?.id]);

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Links', path: '/campaigns/domains' },
        { title: 'Domains', path: '/campaigns/domains' },
        {
          title: current?.name || '',
          path: `/campaigns/domains/${current?.id}`,
        },
      ]}
    >
      <PageContainer>
        <ContentWrapper gap={2}>
          <DomainHeading align="center" justify="between">
            {loadingDomain ? (
              <HStack>
                <Skeleton variant="title" css={{ width: 101, m: 0, borderRadius: 7 }} />
                <Skeleton
                  variant="title"
                  css={{ width: 57, m: 0, ml: 12, borderRadius: 7 }}
                />
              </HStack>
            ) : (
              <Flex direction={'row'} align={'center'}>
                <DomainName>{current?.name}</DomainName>
                <IconButton onClick={handleCopy}>
                  <HiDuplicate size={'20px'} color="gray" />
                </IconButton>
                {current?.status && (
                  <StatusBadge variant={getBadgeVariant(current?.status)}>
                    {current?.status}
                  </StatusBadge>
                )}
              </Flex>
            )}
            <Flex direction={'row'} gap={2}>
              <IconButton
                onClick={handleRefresh}
                variant={'outline'}
                size={2}
                data-testid="refresh-domain-button"
              >
                <RefreshIcon size={'16px'} spin={refreshing} />
              </IconButton>
              {current && (
                <ConfirmationDialog
                  title="Delete Domain"
                  description="Are you sure want to delete this domain? This action cannot be undone."
                  onConfirm={handleDelete}
                  confirmButtonTitle="Yes, Delete Domain"
                  testID="delete-domain-button"
                >
                  <Button
                    onClick={(e) => e.preventDefault()}
                    variant={'gray'}
                    disabled={current?.is_domain_shared || loadingDomain}
                  >
                    Delete
                  </Button>
                </ConfirmationDialog>
              )}
            </Flex>
          </DomainHeading>
          <CreatedRow isMobile={isMobile}>
            <CreatedText isMobile={isMobile}>
              Created at{' '}
              {loadingDomain ? (
                <Skeleton
                  variant="title"
                  css={{ width: 86, m: 0, ml: 4, borderRadius: 7 }}
                />
              ) : (
                <Black>{dayjs(current?.inserted_at).format(MONTH_DAY_YEAR)}</Black>
              )}
            </CreatedText>
            {current?.created_by && (
              <>
                <CreatedText isMobile={isMobile}>
                  <span>by </span>
                  {loadingDomain ? (
                    <Skeleton
                      variant="title"
                      css={{ width: 122, m: 0, ml: 4, borderRadius: 7 }}
                    />
                  ) : (
                    <CreatedByUser user={current?.created_by} />
                  )}
                </CreatedText>
              </>
            )}
          </CreatedRow>
          {loadingDomain ? (
            <HStack css={{ mt: 5 }}>
              <Skeleton variant="title" css={{ width: 157, m: 0, mr: 32 }} />
            </HStack>
          ) : (
            <>
              {current?.status !== DomainStatusType.DELETED &&
                current?.status !== DomainStatusType.EXPIRED && (
                  <VerificationStatusBadge domain={current} />
                )}
            </>
          )}
          {!loadingDomain && (
            <>
              <DNSRecords domain={current} />
              <SetupSteps domain={current} />
            </>
          )}
          <SetupResults domain={current} loading={loadingDomain} />
        </ContentWrapper>
      </PageContainer>
    </PageLayout>
  );
};

export const PageContainer = styled(Flex, {
  p: 30,
  width: '100%',
});

const CreatedRow = styled(HStack, {
  mb: 22,
  variants: {
    isMobile: {
      true: {
        fd: 'column',
      },
    },
  },
});

const CreatedText = styled(HStack, {
  color: '#687076',
  mr: 20,
  fontSize: '14px',
  fontWeight: '500',
  variants: {
    isMobile: {
      true: {
        mx: 0,
        mb: 10,
        width: '100%',
      },
    },
  },
});

const Black = styled('span', {
  color: '$blackA11',
  ml: 6,
});

export const ContentWrapper = styled(VStack, {
  backgroundColor: 'white',
  minHeight: '100%',
  overflow: 'auto',
  height: '100%',
  width: '100%',
  border: '1px solid #EDEDED',
  borderRadius: 8,
  p: 20,
});

export const DomainName = styled(Box, {
  fontSize: 16,
  fontWeight: 600,
  color: '$primaryColor',
  mr: 5,
});

export const DomainHeading = styled(Flex, {
  width: '100%',
});

export const Value = styled(Box, {
  fontWeight: '500',
  wordBreak: 'break-word',
});

export const VerificationTable = styled(HStack, {
  p: 20,
  backgroundColor: '$slate3',
  variants: {
    isMobile: {
      true: {
        fd: 'column',
      },
    },
  },
});

export const Column = styled(VStack, {
  mr: 60,
  variants: {
    isMobile: {
      true: {
        mr: 0,
        mb: 20,
        width: '100%',
      },
    },
    isLast: {
      true: {
        mr: 0,
        mb: 0,
      },
    },
  },
});

const spinner = keyframes({
  to: {
    transform: 'rotate(360deg)',
  },
});

const RefreshIcon = styled(HiRefresh, {
  variants: {
    spin: {
      true: {
        animation: `${spinner} 1.5s infinite`,
      },
    },
  },
});

const StatusBadge = styled(Badge, {
  textTransform: 'capitalize',
  ml: 15,
  fontSize: '$1',
  fontWeight: '500',
});
