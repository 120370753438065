import * as SelectPrimitive from '@radix-ui/react-select';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import * as Yup from 'yup';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import {
  FormFieldWrapper,
  FormMessageEditorV2,
  TextInput,
} from '@/shared/components/forms';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useDisclosure } from '@/shared/hooks';
import { Signature } from '@/shared/types';
import { UpdateSignatureParams } from '@/shared/types/signatures';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  VStack,
} from '@/shared/ui';

import { useLocations } from '../locations/context/LocationContext';
import { signaturePermissions } from './constants';
import { useSignatures } from './context/SignaturesContext';

type AddSignatureProps = {
  signature?: Signature;
  children: React.ReactNode;
};

export const AddSignature = (props: AddSignatureProps) => {
  const signaturesContext = useSignatures();
  const { createSignature, editSignature, setCurrentSignature } = signaturesContext;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const authContext = useAuth();
  const { isAdmin, organizationId } = authContext;

  const locationContext = useLocations();
  const { locations, allLocations } = locationContext.locationsState;

  const [permissionValue, setPermissionValue] = useState(
    props.signature?.access_level ?? 'organization'
  );
  const [selectedLocations, setSelectedLocations] = useState({
    locations: props.signature?.locations?.map((location) => location.id) || [],
  });

  // disable submit btn if location permission is selected without picking location/s
  const shouldDisableSubmitBtn =
    permissionValue === 'location' && selectedLocations.locations.length < 1;

  const handleClose = (formik: FormikProps<any>) => {
    formik.handleReset();
    setCurrentSignature(null);
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger onClick={() => onOpen()} asChild>
        {props.children}
      </DialogTrigger>
      <Formik
        initialValues={{
          name: props.signature?.name ?? '',
          message: { body: props.signature?.body ?? '' },
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
          message: Yup.object({
            body: Yup.string().required('Required'),
          }),
        })}
        onSubmit={async (values, actions) => {
          try {
            const params = {
              ...props.signature,
              name: values.name,
              body: values.message.body,
              access_level: permissionValue,
              location_ids: selectedLocations.locations,
            };

            props.signature
              ? editSignature(params as UpdateSignatureParams)
              : createSignature({ ...params, organization_id: organizationId || '' });
            setCurrentSignature(null);
            actions.resetForm({
              values: {
                name: values.name,
                message: { body: values.message.body },
              },
            });
            actions.resetForm({
              values: {
                name: '',
                message: {
                  body: '',
                },
              },
            });
            setPermissionValue('user');
            onClose();
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formik) => (
          <DialogPortal>
            <DialogOverlay css={{ zIndex: 9999 }}>
              <DialogContent
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                onEscapeKeyDown={() => handleClose(formik)}
                onPointerDownOutside={() => handleClose(formik)}
                css={{ overflow: 'hidden' }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <DialogTitle css={{ fontSize: 20, fontWeight: 700 }}>
                    {props.signature ? 'Update Signature' : 'Add New Signature'}
                  </DialogTitle>
                  <VStack gap={2}>
                    <FormFieldWrapper label="Signature Name" name="name">
                      <TextInput placeholder="Signature name" />
                    </FormFieldWrapper>
                    <FormFieldWrapper label="Signature Body" name="message">
                      <FormMessageEditorV2
                        field="message"
                        placeholder="E.g. We are open from..."
                        enableAttachments={false}
                      />
                    </FormFieldWrapper>
                    <FormFieldWrapper label="Permission" name="permission">
                      <Select
                        value={permissionValue}
                        onValueChange={(e) => setPermissionValue(e)}
                      >
                        <SelectTrigger aria-label="option-select-trigger">
                          <SelectValue />
                          <SelectIcon>
                            <HiChevronDown />
                          </SelectIcon>
                        </SelectTrigger>
                        <SelectPrimitive.Portal>
                          <SelectContent css={{ zIndex: 9999999 }}>
                            <SelectScrollUpButton>
                              <HiChevronUp />
                            </SelectScrollUpButton>
                            <SelectViewport>
                              <SelectGroup>
                                <SelectLabel>Permission</SelectLabel>
                                {signaturePermissions.map((option) => (
                                  <SelectItem key={option.value} value={option.value}>
                                    <SelectItemIndicator />
                                    <SelectItemText>{option.label}</SelectItemText>
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectViewport>
                          </SelectContent>
                        </SelectPrimitive.Portal>
                      </Select>
                    </FormFieldWrapper>
                    {permissionValue === 'location' && (
                      <FormFieldWrapper label="Locations" name="locations">
                        {locations.length > 0 && (
                          <MultiSelect
                            defaultPlaceholder="Locations"
                            defaultSelectedItems={props.signature?.locations?.map(
                              (location) => location.id
                            )}
                            isDropdown={true}
                            options={
                              isAdmin
                                ? allLocations.map((location: any) => ({
                                    type: location?.name || '',
                                    value: location.id,
                                  }))
                                : locations.map((location: any) => ({
                                    type: location?.name || '',
                                    value: location.id,
                                  }))
                            }
                            parentSelectedItems={selectedLocations}
                            setParentSelectedItems={setSelectedLocations}
                            isCampaigns={true}
                          />
                        )}
                      </FormFieldWrapper>
                    )}
                  </VStack>

                  <DialogFooter justify="end" css={{ mt: 10 }}>
                    <DialogClose asChild>
                      <Button
                        variant="grayBackground"
                        css={{ mr: '$1' }}
                        onClick={() => handleClose(formik)}
                      >
                        Cancel
                      </Button>
                    </DialogClose>
                    <Button type="submit" disabled={shouldDisableSubmitBtn}>
                      {props.signature ? 'Update Signature' : 'Save Signature'}
                    </Button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </DialogOverlay>
          </DialogPortal>
        )}
      </Formik>
    </Dialog>
  );
};
