/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

type FocusedContextType = {
  mentionActive: boolean;
  setMentionActive: Dispatch<SetStateAction<boolean>>;
  isNote: boolean;
  setIsNote: Dispatch<SetStateAction<boolean>>;
  shortcutActive: boolean;
  setShortcutActive: Dispatch<SetStateAction<boolean>>;
  emojiModalActive: boolean;
  setEmojiModalActive: Dispatch<SetStateAction<boolean>>;
  templatesModalActive: boolean;
  setTemplatesModalActive: Dispatch<SetStateAction<boolean>>;
  aiModalActive: boolean;
  setAiModalActive: Dispatch<SetStateAction<boolean>>;
  scheduledModalActive: boolean;
  setScheduledModalActive: Dispatch<SetStateAction<boolean>>;
  translateModalActive: boolean;
  setTranslateModalActive: Dispatch<SetStateAction<boolean>>;
};

const defaultContext: FocusedContextType = {
  mentionActive: false,
  setMentionActive: () => {},
  isNote: false,
  setIsNote: () => {},
  shortcutActive: false,
  setShortcutActive: () => {},
  emojiModalActive: false,
  setEmojiModalActive: () => {},
  templatesModalActive: false,
  setTemplatesModalActive: () => {},
  aiModalActive: false,
  setAiModalActive: () => {},
  scheduledModalActive: false,
  setScheduledModalActive: () => {},
  translateModalActive: false,
  setTranslateModalActive: () => {},
};

export const FocusedContext = createContext(defaultContext);

export const useFocusedContext = () => useContext(FocusedContext);

export const FocusedProvider = ({ children }: { children: React.ReactNode }) => {
  const [isNote, setIsNote] = useState(false);
  const [aiModalActive, setAiModalActive] = useState(false);
  const [mentionActive, setMentionActive] = useState(false);
  const [shortcutActive, setShortcutActive] = useState(false);
  const [emojiModalActive, setEmojiModalActive] = useState(false);
  const [scheduledModalActive, setScheduledModalActive] = useState(false);
  const [templatesModalActive, setTemplatesModalActive] = useState(false);
  const [translateModalActive, setTranslateModalActive] = useState(false);

  return (
    <FocusedContext.Provider
      value={{
        mentionActive,
        setMentionActive,
        isNote,
        setIsNote,
        shortcutActive,
        setShortcutActive,
        emojiModalActive,
        setEmojiModalActive,
        templatesModalActive,
        setTemplatesModalActive,
        aiModalActive,
        setAiModalActive,
        scheduledModalActive,
        setScheduledModalActive,
        translateModalActive,
        setTranslateModalActive,
      }}
    >
      {children}
    </FocusedContext.Provider>
  );
};
