/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiChevronDown } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { useAuth } from '@/auth/context/AuthProvider';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Table } from '@/shared/components/Table';
import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { Location } from '@/shared/types/locations';
import { LocationStates } from '@/shared/types/locations';
import {
  Badge,
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  HStack,
  Td,
  Tr,
  VStack,
} from '@/shared/ui';
import {
  formatPhoneNumber,
  phoneFormatting,
  toE164,
} from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { AddLocation } from './AddLocation';
import { useLocations } from './context/LocationContext';

export * from './location';

const channelType = (location: Location) => {
  switch (location.type) {
    case 'phone':
      return formatPhoneNumber(location.phone);
    case 'email':
      return 'Email Channel';
    case 'whatsapp':
      return 'WhatsApp';
  }
};

export const Locations = () => {
  usePageView();

  const auth = useAuth();
  const isWhippyAdmin =
    auth?.tokens?.email && auth?.tokens?.email.includes('whippy' || 'hellowhippy');

  const {
    locationsState,
    getUserLocations,
    updateLocation,
    disableLocation,
    setCurrent,
  } = useLocations();
  const { locations } = locationsState;

  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState({});

  const clickHandler = (location: Location) => {
    setCurrent(location);
    history.push(`/settings/locations/${location.id}`);
    return location;
  };

  useEffect(() => {
    if (auth.isAuthenticated && auth?.tokens && auth?.tokens?.user_id) {
      getUserLocations({ user_id: auth?.tokens?.user_id });
    }
  }, [auth?.organizationId, auth?.tokens?.user_id]);

  const sortedLocations = locations.sort((a, b) =>
    (a?.name || '') > (b?.name || '') ? 1 : -1
  );

  const orderedLocations = [
    ...sortedLocations.filter((location) => location.state === 'enabled'),
    ...sortedLocations.filter((location) => location.state === 'disabled'),
  ];

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const filter = locations.filter((location) => {
      return (
        location.name?.toLowerCase().includes(value.toLowerCase()) ||
        location.address?.toLowerCase().includes(value.toLowerCase()) ||
        location.phone?.includes(toE164(value))
      );
    });
    const filterByNumber = locations.filter((location) => {
      const formattedNumber = phoneFormatting(value);
      return location.phone?.includes(formattedNumber);
    });

    const filterByAddress = locations.filter((location) => {
      return location.address?.toLocaleLowerCase().includes(value);
    });

    if (filter.length > 0) {
      setFilteredData(filter);
    } else if (filterByNumber.length > 0) {
      setFilteredData(filterByNumber);
    } else if (filterByAddress.length > 0) {
      setFilteredData(filterByAddress);
    } else {
      setFilteredData([]);
    }
  };

  const data = useMemo(() => orderedLocations, [orderedLocations, searchValue]);

  const columns = useMemo(
    () => [
      {
        Header: 'Channel Name',
        colWidth: '100%',
        accessor: 'locations',
        Cell: (props: { row: { original: Location } }) => (
          <Flex direction="column">
            <LocationName>{props.row.original.name}</LocationName>
          </Flex>
        ),
      },
      {
        Header: 'Channel Type',
        accessor: 'channelType',
        Cell: (props: { row: { original: Location } }) => (
          <Box css={{ minWidth: 150 }}>{channelType(props.row.original)}</Box>
        ),
      },
      {
        Header: 'Status',
        accessor: 'state',
        Cell: (props: { row: { original: Location } }) => {
          const location = props.row.original;
          const state = location.state;
          const isLocationEnabled = state === 'enabled';
          const isLocationDisabled = state === 'disabled';

          return (
            <DropdownMenu>
              <DropdownMenuTrigger
                style={{
                  display: 'inline-block',
                }}
                disabled={!isWhippyAdmin}
              >
                <Tag gap={2} align="center">
                  {isLocationDisabled && (
                    <Badge variant="red" size="2">
                      Disabled
                    </Badge>
                  )}
                  {isLocationEnabled && (
                    <Badge variant="green" size="2">
                      Enabled
                    </Badge>
                  )}
                  {isWhippyAdmin && (
                    <HiChevronDown
                      color="#333333"
                      style={{ marginTop: 1, flexShrink: 0 }}
                    />
                  )}
                </Tag>
              </DropdownMenuTrigger>
              {true && (
                <DropdownMenuContent align="start" side="top" css={{ ml: -10 }}>
                  {isLocationEnabled && (
                    <DropdownMenuItem
                      onSelect={() => disableLocation({ id: location.id })}
                    >
                      Disable Location
                    </DropdownMenuItem>
                  )}
                  {isLocationDisabled && (
                    <>
                      <DropdownMenuItem
                        onSelect={() =>
                          updateLocation({
                            id: location.id,
                            state: LocationStates.ENABLED,
                          })
                        }
                      >
                        Enable Location
                      </DropdownMenuItem>
                      <ConfirmationDialog
                        title="Archive Location"
                        description="You can only delete steps when there are no active contacts in the sequence. Are you sure you want to delete this sequence step?"
                        onConfirm={() =>
                          updateLocation({
                            id: location.id,
                            state: LocationStates.ARCHIVED,
                          })
                        }
                        confirmButtonTitle="Yes, Archive Location"
                        testID="delete-archive-location-button"
                      >
                        <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                          Archive Location
                        </DropdownMenuItem>
                      </ConfirmationDialog>
                    </>
                  )}
                </DropdownMenuContent>
              )}
            </DropdownMenu>
          );
        },
      },
      {
        Header: ' ',
        accessor: 'action',
        Cell: (props: { row: { original: Location } }) => (
          <Button
            css={{ whiteSpace: 'nowrap' }}
            variant="gray"
            onClick={() => clickHandler(props.row.original)}
          >
            Edit Location
          </Button>
        ),
      },
    ],
    []
  );

  const dataToDisplay = searchValue.length ? filteredData : data;

  return (
    <>
      <Helmet>
        <title>Whippy | Locations</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        width="100%"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/locations' },
          { title: 'Locations', path: `/settings/locations` },
        ]}
        actions={isWhippyAdmin && <AddLocation />}
      >
        <VStack>
          <Table
            data={dataToDisplay}
            columns={columns}
            caption="Locations Table"
            searchFilter={true}
            sortable={true}
            searchValue={searchValue}
            setSearchValue={handleSearch}
            totalCount={locations.length}
            empty={
              <Tr>
                <Td
                  colSpan={columns.length}
                  css={{ textAlign: 'center', background: 'white' }}
                >
                  <VStack gap={2}>
                    <Box css={{ p: '6.5px 0' }}>No locations were found.</Box>
                    <Box>
                      <AddLocation />
                    </Box>
                  </VStack>
                </Td>
              </Tr>
            }
          />
        </VStack>
      </SettingsLayout>
    </>
  );
};

export const LocationAddress = styled(Box, {
  color: '#718196',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 250,
  textOverflow: 'ellipsis',
});

export const LocationName = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 250,
  textOverflow: 'ellipsis',
});

const Tag = styled(HStack, {
  p: '$2',
  borderRadius: 4,
  ml: -10,
  whiteSpace: 'nowrap',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: 'rgb(240, 243, 249)',
  },
});

export const EmptyStateDescription = () => (
  <>
    <Box css={{ mt: 12, mb: 18, fontSize: 15 }}>
      {
        "Locations are the unique physical addresses of your business, which customers interact with. Each location has it's own phone number for customers and employees to communicate via sms."
      }
    </Box>
    <Box css={{ mb: 18, fontSize: 15 }}>
      {"You don't have any locations yet. Add a new location to get started."}
    </Box>
  </>
);
