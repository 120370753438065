import { styled } from '@stitches/react';
import { useKBar } from 'kbar';
import { forwardRef } from 'react';

import { renderDateWithYear } from '@/pages/inbox/list/ConversationPreview';
import {
  HighlightText,
  PREVIEW_MESSAGE_POST_LENGTH,
  PREVIEW_MESSAGE_PRE_LENGTH,
  truncateTextAroundHighlight,
} from '@/pages/inbox/list/utils';
import { Box, Flex } from '@/shared/ui';

import { useSearch } from '../SearchContext';
import { SearchResults } from '../utils';

export const MessageItem = forwardRef(
  (
    {
      action,
      active,
    }: {
      action: SearchResults;
      active: boolean;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { searchInput, highlightWords } = useSearch();
    const { query } = useKBar();
    const name =
      action?.conversation?.contact?.name ??
      action?.conversation?.contact?.email ??
      action?.conversation?.contact?.phone;

    if (!action?.conversation) return;

    return (
      <StyledMessageItem
        active={active}
        ref={ref}
        onClick={() => {
          action?.perform();
          query.toggle();
        }}
      >
        <Flex direction="column" css={{ width: '100%' }}>
          <NameContainer justify="between" align="center" css={{ width: '100%' }}>
            <StyledName>{name}</StyledName>
            <StyledDate>{renderDateWithYear(action.conversation)}</StyledDate>
          </NameContainer>
          <Flex justify="between" css={{ fontSize: 13 }}>
            <Flex align="center" css={{ width: 'calc(100% - 47px)' }}>
              <Box
                css={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <HighlightText
                  sentence={truncateTextAroundHighlight(
                    action?.conversation?.conversationItemsPage?.conversationItems[0]
                      ?.body,
                    searchInput,
                    PREVIEW_MESSAGE_PRE_LENGTH,
                    PREVIEW_MESSAGE_POST_LENGTH
                  )}
                  highlightStyle={{ fontWeight: 500, backgroundColor: '#FFC106' }}
                  wordsToHighlight={highlightWords}
                  sensitivity={3}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </StyledMessageItem>
    );
  }
);

export const NameContainer = styled(Flex, {
  gap: 10,
  alignItems: 'center',
});

export const StyledName = styled(Box, {
  overflow: 'hidden',
  fontWeight: 500,
  fontSize: 13,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 200,
});

export const StyledDate = styled(Box, {
  fontWeight: 400,
  fontSize: 11,
});

export const StyledMessageItem = styled('div', {
  padding: '12px 16px',
  background: 'transparent',
  borderLeft: '2px solid transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    background: '$gray1',
    backgroundColor: '$gray3',
  },
  variants: {
    active: {
      true: {
        background: '$gray1',
        borderLeftColor: '$foreground',
        backgroundColor: '$gray3',
      },
    },
  },
});
