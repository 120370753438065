import { Formik } from 'formik';
import { useState } from 'react';
import { HiX } from 'react-icons/hi';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { OptOutOptions } from '@/pages/settings/organization/general/context/types';
import { campaignBulkActions } from '@/shared/api/campaigns';
import { FormFieldWrapper } from '@/shared/components/forms';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Campaign } from '@/shared/types/campaigns';
import { FilterParams } from '@/shared/types/filter';
import { Location } from '@/shared/types/locations';
import { Box, Button, HStack, Label, VStack } from '@/shared/ui';
import {
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from '@/shared/ui/Dialog';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxMultiselectTrigger } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';
import { ComboboxMultiselectInput } from '@/shared/v2/components/forms/comboboxMultiselect/ComboboxMultiselectInput';

type UnsubscribeFromCampaignModalProps = {
  defaultLocation?: Location;
  onOpenChange: (open: boolean) => void;
  locations: Location[];
  // we will either provide a campaign or campaigns
  // if we provide a campaign then we will use that campaign
  // to unsubscribe contacts from.
  // Otherwise if we provide campaigns, then we display that
  // in a select box
  campaign?: Campaign;
  campaigns?: Campaign[];
  open: boolean;
  totalContacts: number;
  optOutOption: OptOutOptions;
  filter: FilterParams;
};

export const UnsubscribeContactsFromCampaignModal = ({
  open,
  totalContacts,
  campaign,
  campaigns,
  filter,
  locations,
  onOpenChange,
  optOutOption: optOutSetting,
}: UnsubscribeFromCampaignModalProps) => {
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>(
    campaign?.id ?? ''
  );
  const resetModalState = (): void => {
    onOpenChange(false);
    setSelectedCampaignId('');
  };

  const handleSubmit = async (
    values: { campaignId: string; locationIds: string[] },
    actions: any
  ) => {
    const { campaignId, locationIds } = values;
    toast.promise(
      campaignBulkActions({
        action: 'campaigns.unsubscribe.contacts',
        campaignId,
        filter,
        locationIds,
        sourceCampaignId: campaignId,
      }),
      {
        loading: 'Attempting to Un-subscribe contacts',
        success: () => {
          actions.resetForm({
            values: {
              campaignId: '',
              locationIds: [] as string[],
            },
          });
          onOpenChange(false);
          return 'Un-subscribing contacts';
        },
        error: 'Failed to un-subscribe contacts',
      }
    );
  };

  return (
    <Dialog open={open} modal={false}>
      <DialogTrigger asChild onClick={resetModalState}></DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              locationIds: locations.map((location) => location.id) as string[],
              campaignId: campaign?.id ?? '',
            }}
            validationSchema={Yup.object({
              campaignId: Yup.string().required('Please select a campaign.').uuid(),
              locationIds: Yup.array().min(1, 'Please provide at least one location'),
            })}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <DialogContent
                  css={{ width: 700 }}
                  onPointerDownOutside={resetModalState}
                  onEscapeKeyDown={resetModalState}
                >
                  <DialogHeader title="Un-subscribe Contacts from Campaign" />
                  <VStack gap={2}>
                    {!campaign && campaigns && (
                      <FormFieldWrapper name="campaignId" label="Campaign">
                        {/* if we're provided a campaign then leave out the select input */}
                        <SingleSelect
                          closeOnClick
                          defaultPlaceholder={getCampaignPlaceholder(
                            selectedCampaignId,
                            campaigns,
                            formik.dirty,
                            campaign
                          )}
                          options={campaigns.map((campaign) => ({
                            type: campaign.title ?? '',
                            value: campaign.id ?? '',
                          }))}
                          setSelectItem={(selectedCampaignId) => {
                            setSelectedCampaignId(selectedCampaignId);
                            formik.setFieldTouched('campaignId', true);
                            formik.setFieldValue('campaignId', selectedCampaignId);
                          }}
                          selectItem={selectedCampaignId}
                          isDropdown={true}
                        />
                      </FormFieldWrapper>
                    )}
                    {optOutSetting == OptOutOptions.LOCATION && (
                      <FormFieldWrapper name="locationIds" label="Location(s)">
                        <ComboboxMultiselectInput
                          options={locations.map((location: Location) => ({
                            label: location.name || '',
                            value: location.id,
                          }))}
                          searchLabel="Search"
                          selectLabel="Select Location(s)"
                          Trigger={ComboboxMultiselectTrigger}
                          Option={ComboboxMultiselectItem}
                          disabled={false}
                          selectAll
                        />
                      </FormFieldWrapper>
                    )}
                  </VStack>
                  <DialogClose asChild>
                    <DialogCloseIcon onClick={resetModalState} size="2">
                      <Box css={{ minWidth: 16 }} onClick={resetModalState}>
                        <HiX
                          size="15px"
                          style={{
                            color: '#ffffff',
                          }}
                        />
                      </Box>
                    </DialogCloseIcon>
                  </DialogClose>
                  <DialogFooter justify="between" css={{ mb: 12, mt: 0 }}>
                    <HStack
                      gap={1}
                      css={{ width: '100%', justifyContent: 'space-between' }}
                    >
                      <Label css={{ mb: 0 }}>
                        {totalContacts > 0 ? `Total Contacts: ${totalContacts}` : ''}
                      </Label>
                      <HStack>
                        <DialogClose asChild>
                          <Button
                            variant="gray"
                            css={{ mr: '$1' }}
                            onClick={resetModalState}
                          >
                            Cancel
                          </Button>
                        </DialogClose>
                        <DialogClose asChild>
                          <Button
                            type="submit"
                            disabled={isDisabled(optOutSetting, formik.values)}
                          >
                            Un-subscribe Contacts
                          </Button>
                        </DialogClose>
                      </HStack>
                    </HStack>
                  </DialogFooter>
                </DialogContent>
              </form>
            )}
          </Formik>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

function isDisabled(optOutSetting: OptOutOptions, values: any) {
  if (optOutSetting == OptOutOptions.LOCATION) {
    return values.locationIds.length == 0 || values.campaignId == '';
  }
  return false;
}

function getCampaignPlaceholder(
  selectedId: string,
  campaigns: Campaign[],
  isDirty: boolean,
  defaultCampaign?: Campaign
): string {
  if (!isDirty) return defaultCampaign?.title ?? 'Select Campaign';
  const selected = campaigns.find((campaign) => campaign.id == selectedId);
  return selected?.title ?? 'Select Campaign';
}
