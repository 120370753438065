import dayjs from 'dayjs';
import { useState } from 'react';
import { HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { Attachments } from '@/shared/components/editor/v2/constants';
import {
  convertTimeString,
  getHours,
} from '@/shared/components/timepicker/utils/getHours';
import { ScheduleOptions } from '@/shared/types/campaigns';
import { Location } from '@/shared/types/locations';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  HStack,
  Label,
  VStack,
} from '@/shared/ui';

import { LocationDropdown } from './LocationDropdown';
import { MessageEditor } from './MessageEditor';
import { RadioGroupOptions, ScheduleRadioGroup } from './ScheduleRadioGroup';

type Props = {
  currentLocation: Location | null;
  locations: Location[];
  setLocation: (location: Location) => void;
  showTimeZonePicker: boolean;
  /* the boolean value controlling whether or not the dialog is open*/
  isOpen: boolean;
  /* the function to control whether or not the dialog is open*/
  setIsOpen: (isOpen: boolean) => void;
  /*
   * The total number of contacts that will be messaged. This could be determined by
   * either the bulk actions sending us this information.
   */
  totalContacts: number;
  /*
    Once the user submits the message we append the input they gave in the location and schedule options
    A selectedOption of null means that it is an immediate message
  */
  handleConfirm: (
    message: string,
    attachments: Attachments,
    selectedLocation: Location,
    selectedScheduleOption: ScheduleOptions | null
  ) => void;
};
export const QuickCampaignDialog = ({
  isOpen,
  setIsOpen,
  currentLocation,
  locations,
  setLocation,
  handleConfirm,
  totalContacts,
}: Props): JSX.Element => {
  // the campaign type we are creating which can be scheduled or default for an immediate message
  const [campaignType, setCampaignType] = useState<RadioGroupOptions>('default');
  // message text
  const [message, setMessage] = useState<string>('');
  // message attachments
  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: [],
  });
  const [isAttachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  const [scheduleTime, setScheduleTime] = useState<ScheduleOptions>(
    initializeScheduleTime(currentLocation, new Date())
  );

  const resetModalState = (): void => {
    setIsOpen(false);
    initializeScheduleTime(currentLocation, new Date());
    setCampaignType('default');
  };

  const handleSendMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // if the location is not in the users location given to us
    if (!currentLocation || !validLocation(currentLocation, locations)) {
      toast.error('Error in selecting channel.');
      return;
    }
    // if the message is invalid
    if (message.length < 2 && attachments?.attachment_urls?.length == 0) {
      toast.error('Missing message or attachment(s).');
      return;
    }
    if (campaignType == 'scheduled' && isScheduledTimeInPast(scheduleTime, new Date())) {
      toast.error('Cannot schedule campaigns in the past.');
      return;
    }
    let selectedSchedule: ScheduleOptions | null = scheduleTime;
    if (campaignType == 'default') {
      selectedSchedule = null;
    }
    handleConfirm(message, attachments, currentLocation, selectedSchedule);
  };

  const handleChangeLocation = (selectedLocation: Location) => {
    if (validLocation(selectedLocation, locations)) {
      setLocation(selectedLocation);
    } else {
      toast.error('Error in selecting channel.');
    }
  };

  return (
    <Dialog open={isOpen} modal={false}>
      <DialogTrigger asChild onClick={resetModalState}></DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent
            css={{ width: 700 }}
            onPointerDownOutside={resetModalState}
            onEscapeKeyDown={resetModalState}
          >
            <DialogHeader title="Quick Campaign" />
            <form
              onSubmit={handleSendMessage}
              data-testid="create-follow-up-campaign-form"
            >
              <VStack gap={2}>
                <LocationDropdown
                  selected={currentLocation}
                  showTimeZonePicker={false}
                  handleChangeLocation={handleChangeLocation}
                />
                <Label>Schedule:</Label>
                <ScheduleRadioGroup
                  initialState={campaignType}
                  onRadioClick={(campaignType) => {
                    setCampaignType(campaignType as RadioGroupOptions);
                  }}
                  scheduleOptions={scheduleTime}
                  onDateTimeUpdated={(dateTime) => {
                    setScheduleTime(dateTime);
                  }}
                />
                <Label>Message:</Label>
                <MessageEditor
                  message=""
                  attachments={attachments}
                  onInputUpdated={(message, attachments, isAttachmentLoading) => {
                    setMessage(message);
                    setAttachments(attachments);
                    setAttachmentLoading(isAttachmentLoading);
                  }}
                  preSelectedAttachments={[]}
                  isCampaignsEditPage={false}
                  showSchedule={campaignType == 'scheduled'}
                  location={currentLocation}
                  locations={locations}
                />
              </VStack>
              <DialogClose>
                <DialogCloseIcon onClick={resetModalState} size="2">
                  <Box css={{ minWidth: 16 }} onClick={resetModalState}>
                    <HiX
                      size="15px"
                      style={{
                        color: '#ffffff',
                      }}
                    />
                  </Box>
                </DialogCloseIcon>
              </DialogClose>
              <DialogFooter justify="between" css={{ mb: 12, mt: 0 }}>
                <HStack gap={1} css={{ width: '100%', justifyContent: 'space-between' }}>
                  <Label css={{ mb: 0 }}>
                    {totalContacts > 0 ? `Total Contacts: ${totalContacts}` : ''}
                  </Label>
                  <HStack>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={resetModalState}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        type="submit"
                        data-testid="create-follow-up-campaign-submit-button"
                        disabled={
                          !isValidMessage(
                            message,
                            locations,
                            isAttachmentLoading,
                            attachments,
                            currentLocation
                          )
                        }
                      >
                        {campaignType == 'scheduled'
                          ? 'Schedule Campaign'
                          : 'Create Campaign'}
                      </Button>
                    </DialogClose>
                  </HStack>
                </HStack>
              </DialogFooter>
            </form>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

function initializeScheduleTime(location: Location | null, currentDate: Date) {
  const timezone = location?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  const firstTimeInFuture = getHours(startDate, timezone)[0];
  const time = convertTimeString(firstTimeInFuture);
  return {
    year: currentDate.getFullYear().toString(),
    // date is zero indexed and schedule options is not
    // so we need to modify the date before passing it as a scheduleOption
    month: (currentDate.getMonth() + 1).toString(),
    day: currentDate.getDate().toString(),
    hour: time.hour.toString(),
    minute: time.minute.toString(),
    timezone: timezone,
  };
}
function validLocation(currentLocation: Location, locations: Location[]) {
  return locations.some((location) => location.id == currentLocation.id);
}

function isScheduledTimeInPast(scheduleTime: ScheduleOptions, now: Date): boolean {
  const { year, month, day, hour, minute } = scheduleTime;
  const dateToCheck = new Date(
    parseInt(year),
    // date is zero indexed and schedule options is not
    // so we need to modify the date before passing it as a scheduleOption
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hour),
    parseInt(minute)
  );

  return dayjs(dateToCheck).isBefore(dayjs(now));
}

function isValidMessage(
  message: string,
  channels: Location[],
  isAttachmentLoading?: boolean,
  attachments?: Attachments,
  channel?: Location | null
) {
  // if the location is not provided
  if (channel === undefined || channel == null) return false;
  // if the location does not belong to the locations (belonging to the user)
  if (!channels.some((location) => location.id == channel.id)) return false;
  if (attachments === undefined || channel == undefined) return false;
  const isValidInput = message.length >= 2 || attachments?.attachment_urls?.length > 0;
  return !isAttachmentLoading && isValidInput;
}
