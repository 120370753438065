let autoComplete: google.maps.places.Autocomplete;

// extend global window with google
//another solution is probably installing google maps types 'npm i -D @types/google.maps' but probably overkill
declare global {
  type Google = Window & {
    google: string;
    maps: any;
  };
}

/*
  Load external script with the URL passed in
*/

export const loadScript = (url: any, callback: any) => {
  const script: any = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
  editZIndex();
};

/*
  Set the up the Google Places API to return:

  1. formatted_address -> address of the company
  2. name -> official name of the business
  3. website -> the business website

  More places data fields:
  https://developers.google.com/maps/documentation/places/web-service/place-data-fields?hl=fi
*/

export function handleScriptLoad(updateQuery: any, autoCompleteRef: any) {
  autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
    componentRestrictions: { country: 'us' },
  });
  autoComplete.setFields([
    'formatted_address',
    'website',
    'name',
    'place_id',
    'formatted_phone_number',
    'rating',
  ]);
  autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery));
}

/*
  Query the Google Places API, get auto complete values
*/

async function handlePlaceSelect(updateQuery: any) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject;
  updateQuery(query);
}

/*
  Increases the z-index of google autoComplete
  suggestions container. Needed so predictions
  are shown in modal.
*/
const editZIndex = async () => {
  const htmlCollection = document.getElementsByClassName(
    'pac-container'
  ) as HTMLCollectionOf<HTMLElement>;

  while (!htmlCollection[0]) {
    await new Promise((r) => setTimeout(r, 500));
  }

  // Set z-index to max so that dropdown renders in front of dialog
  htmlCollection[0].style.zIndex = '9999999999';
  htmlCollection[0].style.pointerEvents = 'auto';
};

/*
 Removes the script with the given url as source.
*/
export const removeScript = (url: any) => {
  // Remove google from BOM (window object)
  window.google = undefined as unknown as Google;

  // Remove google script from DOM
  const tags = document.getElementsByTagName('script');
  for (let i = 0; i <= tags.length; i++) {
    if (tags[i]?.getAttribute('src') === url) {
      tags[i]?.parentNode?.removeChild(tags[i]);
    }
  }

  // Remove google suggestions container from DOM
  const htmlCollection = document.getElementsByClassName('pac-container');
  for (let i = 0; i <= htmlCollection.length; i++) {
    htmlCollection[i]?.parentNode?.removeChild(htmlCollection[i]);
  }
};
