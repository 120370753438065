/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction, useState } from 'react';
import * as Yup from 'yup';

import {
  ActionRenderer,
  defaultActions,
  sendMessageAction,
} from '@/shared/components/triggers';
import { SelectActionType } from '@/shared/components/triggers/actions/AddAction';
import { Trigger, TriggerTypes } from '@/shared/types/triggers';
import { Action, ActionTypes } from '@/shared/types/triggers/actions';
import { Button, DialogHeader, Fieldset, Flex, Label, VStack } from '@/shared/ui';

import { ReplyTriggerType } from './AddTrigger';

type ReplyFormProps = {
  /* The action to perform when the save button is clicked */
  onSubmit: (values: Trigger) => void;
};

export const AddReplyTrigger = (props: ReplyFormProps) => {
  const { onSubmit } = props;
  const [initialvalues, setInitialValues] = useState({
    actions: [sendMessageAction],
  });

  const [actionType, setActionType] = useState<ActionTypes>(ActionTypes.SEND_MESSAGE);

  return (
    <>
      <DialogHeader title="Add Reply Trigger" />
      <VStack gap={3} css={{ width: '100%' }}>
        <Formik
          enableReinitialize
          initialValues={initialvalues}
          validationSchema={Yup.object({
            actions: Yup.array(),
          })}
          onSubmit={async (values) => {
            const params: Trigger = {
              type: TriggerTypes.REPLY,
              actions: values.actions as unknown as Action[],
            };
            try {
              onSubmit(params);
            } catch (e) {
              console.log(e);
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <VStack gap={4} css={{ width: '100%' }}>
                <KeywordFormFields
                  setInitialValues={setInitialValues}
                  actionType={actionType}
                  setActionType={setActionType}
                />
                <Flex css={{ width: '100%' }} align="center" justify="end">
                  <Button type="submit">Save Trigger</Button>
                </Flex>
              </VStack>
            </form>
          )}
        </Formik>
      </VStack>
    </>
  );
};

type FromFieldsProps = {
  /* set the initial values for the form */
  setInitialValues?: (values: any) => void;
  /* the action type */
  actionType: ActionTypes;
  /* set the action type */
  setActionType: Dispatch<SetStateAction<ActionTypes>>;
};

export const KeywordFormFields = (props: FromFieldsProps) => {
  const { actionType, setActionType } = props;
  const formik = useFormikContext();

  const values = formik.values as ReplyTriggerType;

  const defaultAction = (type: ActionTypes) =>
    defaultActions.find((action) => action.type === type)?.default;

  const handleChange = (a: ActionTypes) => {
    setActionType(a);
    formik.setFieldValue('actions', [defaultAction(a)]);
  };

  return (
    <VStack gap={2}>
      <Fieldset>
        <Label>Select Action Type</Label>
        <SelectActionType action_type={actionType} setAction={handleChange} />
      </Fieldset>
      {values.actions.map((action: Action, index) => (
        <ActionRenderer key={index} index={index} action={action} />
      ))}
    </VStack>
  );
};
