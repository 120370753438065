import { FieldArray, Formik } from 'formik';
import React from 'react';
import { HiX } from 'react-icons/hi';
import { useMedia } from 'react-use';
import * as Yup from 'yup';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import {
  DemoMessages,
  StyledMessageInnerContainer,
  StyledMessageOuterContainer,
} from '@/shared/components/DemoMessages';
import {
  FormFieldWrapper,
  MultiSelectCombobox,
  SliderInput,
  TextInput,
} from '@/shared/components/forms';
import { FormMessageEditorV2 } from '@/shared/components/forms/FormMessageEditorV2';
import { helpers } from '@/shared/components/forms/helpers';
import { Box, Button, ControlGroup, Flex, HStack, VStack } from '@/shared/ui';

import { useQuestions } from './context/QuestionsContext';
import { DeleteQuestion } from './DeleteQuestion';

export function EditQuestion({ row }: any): JSX.Element {
  const automationsContext = useQuestions();
  const { editQuestion, deleteQuestion } = automationsContext;

  const location = useLocations();
  const { locations } = location.locationsState;

  const isDesktop = useMedia('(min-width: 912px)');

  const onDelete = () => {
    deleteQuestion(row);
  };
  return (
    <Formik
      initialValues={{
        questions: row.questions.map(
          (question: { question: string }) => question.question
        ),
        message: {
          body: row.answer,
          attachments: row.attachments
            ? row.attachments.map((att: { url: any }) => att.url)
            : [],
        },
        locations: row.locations,
        tolerance: row.tolerance,
        enabled: row.enabled,
      }}
      validationSchema={Yup.object({
        questions: Yup.array().test(
          'Input 1 Required',
          'Required',
          (arr: any) => Array.isArray(arr) && arr[0]
        ),
        message: Yup.object({
          body: Yup.string().required('Required'),
          attachments: Yup.array(),
        }),
        locations: Yup.array().test(
          'len',
          'Required',
          (arr: string | any) => Array.isArray(arr) && arr.length > 0
        ),
        tolerance: Yup.mixed()
          .transform(function (value: number) {
            return Array.isArray(value) ? value[0] : value;
          })
          .test('min/max', 'Value must be between 0.1-1', (val: any) => {
            if (typeof val === 'number') {
              return val >= 0.1 && val <= 1;
            }
            return false;
          }),
      })}
      onSubmit={async (values) => {
        const params = {
          questions: values.questions
            .filter((question: string) => question)
            .map((question: string) => ({ question: question })),
          answer: values.message.body,
          locations: values.locations,
          tolerance: Array.isArray(values.tolerance)
            ? values.tolerance[0]
            : parseFloat(values.tolerance),
          enabled: row.enabled,
          id: row.id,
          attachment_urls: values.message.attachments,
        };
        try {
          editQuestion(params);
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <VStack gap={2}>
            <Flex direction={isDesktop ? 'row' : 'column'}>
              <VStack gap={2} css={{ width: '100%', mr: 10 }}>
                <FieldArray
                  name="questions"
                  render={(arrayHelpers: {
                    remove: (arg0: number) => any;
                    insert: (arg0: any, arg1: string) => any;
                  }) => (
                    <>
                      {formik.values.questions.map((question: string, index: number) => (
                        <Box key={index} css={{ marginTop: 0 }}>
                          {index === 0 ? (
                            <FormFieldWrapper
                              label="Questions"
                              tooltip="FAQ you would like to automate."
                              name={`questions.${index}`}
                            >
                              <TextInput
                                ariaLabel="Base Question"
                                placeholder="When are you open until?"
                              />
                            </FormFieldWrapper>
                          ) : (
                            <FormFieldWrapper name={`questions.${index}`}>
                              <ControlGroup>
                                <TextInput
                                  name={`questions.${index}`}
                                  ariaLabel={`Question Variant ${index}`}
                                />
                                <Button
                                  type="button"
                                  aria-label={`Remove Question Variant${index}`}
                                  variant="gray"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <HiX style={{ marginRight: 1, boxShadow: 'none' }} />
                                </Button>
                              </ControlGroup>
                            </FormFieldWrapper>
                          )}
                        </Box>
                      ))}
                      <Box css={{ mt: 0, mb: 15 }}>
                        <Button
                          css={{ fontSize: '11px' }}
                          size={1}
                          variant="gray"
                          type="button"
                          onClick={() =>
                            arrayHelpers.insert(formik.values.questions.length, '')
                          }
                        >
                          Add Question Variant
                        </Button>
                      </Box>
                    </>
                  )}
                />
                <FormFieldWrapper
                  label="Answer"
                  tooltip="Answer shown when customers ask the question above."
                  name="message"
                >
                  <FormMessageEditorV2
                    enableAttachments={true}
                    showAddAttachment={true}
                    showAddEmoji={true}
                    showAddVariable={true}
                    field={'message'}
                  />
                </FormFieldWrapper>
              </VStack>
              <StyledMessageOuterContainer
                align="center"
                justify="center"
                css={{ ml: 10, mt: 22, mb: 15 }}
              >
                <StyledMessageInnerContainer>
                  <DemoMessages
                    inboundMessage={
                      formik.values['questions'][0] || 'Example: Hey, when do you open?'
                    }
                    outboundMessage={
                      formik.values.message['body'] ||
                      'This is what your answer will look like. So make it good. 😉'
                    }
                  />
                </StyledMessageInnerContainer>
              </StyledMessageOuterContainer>
            </Flex>
            {locations.length > 1 ? (
              <FormFieldWrapper
                label="Locations"
                tooltip="Locations you would like to automate this FAQ."
                name="locations"
              >
                <MultiSelectCombobox
                  placeholder={helpers.displaySelectedItems}
                  selectAll={true}
                  isDropdown={true}
                  options={locations.map((location) => ({
                    type: location.name as string,
                    value: location.id as string,
                  }))}
                />
              </FormFieldWrapper>
            ) : null}
            <FormFieldWrapper
              label="Tolerance"
              tooltip="Exact match will only trigger automations if a lead's question is identical to the one you wrote."
              name="tolerance"
            >
              <SliderInput />
            </FormFieldWrapper>
          </VStack>
          <HStack gap={2} css={{ mt: 35 }}>
            <Box>
              <Button type="submit">Save Automated Response</Button>
            </Box>
            <Box>
              <DeleteQuestion alertAction={onDelete} />
            </Box>
          </HStack>
        </form>
      )}
    </Formik>
  );
}
