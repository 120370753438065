import { api } from '@/shared/api/api';
import { TriggerType } from '@/shared/components/triggers/AddTrigger';
import { Audience } from '@/shared/types/audience';
import { ScheduleOptions } from '@/shared/types/campaigns';
import { Schedule } from '@/shared/types/schedule';
import { Trigger } from '@/shared/types/triggers';

import {
  AddContactsToSequenceResponse,
  GetSequencesParams,
  RemoveContactsFromSequenceResponse,
  Sequence,
  SequenceBulkActionFilter,
  SequenceContacts,
  SequenceStep,
  SequenceStepTrigger,
} from '../../types/sequences';

/** 

GET - /api/sequences

{
   "data":[
      {
         "access_level":"location",
         "created_by":{
            "attachment":null,
            "email":"testuser1@whippy.co",
            "id":2,
            "name":"Judy Morissette"
         },
         "description":null,
         "id":"bd9d65be-9a1d-42a4-a3d4-8e85e41fd282",
         "inserted_at":"2023-02-04T00:07:56",
         "locations":[
            {
               "address":"7 Melody Cape",
               "id":"3db013ff-c27e-4371-86eb-ec0fb181f013",
               "name":"South Eda 3",
               "phone":"+14244023931"
            },
            {
               "address":"35 Forges",
               "id":"f99bfc16-fe7d-4f88-b5c5-45c6921fd3a4",
               "name":"West 2",
               "phone":"+14244023929"
            }
         ],
         "settings":{
            "on_response":"remove",
            "on_re_add":"exclude"
         },
         "status":"active",
         "steps":[
            {
               "analytics":{
                  "campaign_progress":0,
                  "delivery_rate":0,
                  "failed_deliveries":0,
                  "link_clicks":0,
                  "link_clicks_rate":0,
                  "response_rate":0,
                  "responses":0,
                  "sent_messages":0,
                  "slated_messages":0,
                  "unsubscribe_rate":0,
                  "unsubscribes":0
               },
               "attachment_urls":[
                  
               ],
               "body":"DEMO",
               "id":"3c8208e4-89f4-4186-be27-5bedb019bd18",
               "position":0,
               "schedule_options":{
                  "days":"0",
                  "hours":"0",
                  "minutes":"0",
                  "timezone":"Pacific/Honolulu"
               }
            }
         ],
         "title":"new sequence",
         "updated_at":"2023-02-04T00:07:56"
      }
   ]
}
*/

export const getSequences = async (
  params?: GetSequencesParams,
  controller?: AbortController
): Promise<{ data: Array<Sequence> | Array<null>; total: number }> => {
  const options = controller ? { signal: controller.signal } : undefined;
  const { data } = await api.get(`/sequences`, { params, ...options });
  return data;
};

/* 

GET - /api/sequences/:id

{
   "data":{
      "access_level":"location",
      "contact_tabs":{
         "active":0,
         "clicked":0,
         "finished":0,
         "pending":0,
         "responded":0,
         "total":0,
         "unresponded":0,
         "unsubscribed":0
      },
      "created_by":{
         "attachment":null,
         "email":"testuser1@whippy.co",
         "id":2,
         "name":"Judy Morissette"
      },
      "description":null,
      "id":"bd9d65be-9a1d-42a4-a3d4-8e85e41fd282",
      "inserted_at":"2023-02-04T00:07:56",
      "locations":[
         {
            "address":"7 Melody Cape",
            "id":"3db013ff-c27e-4371-86eb-ec0fb181f013",
            "name":"South Eda 3",
            "phone":"+14244023931"
         },
         {
            "address":"35 Erdman Forges",
            "id":"f99bfc16-fe7d-4f88-b5c5-45c6921fd3a4",
            "name":"West Liliane 2",
            "phone":"+14244023929"
         }
      ],
      "settings":{
         "on_response":"remove",
         "on_re_add":"exclude"
      },
      "status":"active",
      "steps":[
         {
            "analytics":{
               "campaign_progress":0,
               "delivery_rate":0,
               "failed_deliveries":0,
               "link_clicks":0,
               "link_clicks_rate":0,
               "response_rate":0,
               "responses":0,
               "sent_messages":0,
               "slated_messages":0,
               "unsubscribe_rate":0,
               "unsubscribes":0
            },
            "attachment_urls":[
               
            ],
            "body":"DEMO!",
            "id":"3c8208e4-89f4-4186-be27-5bedb019bd18",
            "position":0,
            "schedule_options":{
               "days":"0",
               "hours":"0",
               "minutes":"0",
               "timezone":"Pacific/Honolulu"
            }
         }
      ],
      "title":"new sequence",
      "updated_at":"2023-02-04T00:07:56"
   }
}
*/

export const getSequence = (id: string): Promise<Sequence | null> =>
  api.get(`/sequences/${id}`).then((res) => {
    return res.data.data as Sequence | null;
  });

/*

POST - /api/sequences

{
  "data":{
     "access_level":"location",
     "created_by":{
        "attachment":null,
        "email":"testuser1@whippy.co",
        "id":2,
        "name":"Judy Morissette"
     },
     "description":null,
     "id":"bd9d65be-9a1d-42a4-a3d4-8e85e41fd282",
     "inserted_at":"2023-02-04T00:07:56",
     "locations":[
        {
           "address":"7 Melody Cape",
           "id":"3db013ff-c27e-4371-86eb-ec0fb181f013",
           "name":"South Eda 3",
           "phone":"+14244023931"
        },
        {
           "address":"35 Erdman Forges",
           "id":"f99bfc16-fe7d-4f88-b5c5-45c6921fd3a4",
           "name":"West Liliane 2",
           "phone":"+14244023929"
        }
     ],
     "settings":{
        "on_response":"remove",
        "on_re_add":"exclude"
     },
     "status":"active",
     "steps":[
        
     ],
     "title":"new sequence",
     "updated_at":"2023-02-04T00:07:56"
  }
}
*/

export const createSequence = (params: Sequence): Promise<Sequence> =>
  api.post(`/sequences`, { sequence: params }).then((res) => {
    return res.data.data as Sequence;
  });

/*

PUT - /api/sequences/:id

{
   "data":{
      "access_level":"organization",
      "created_by":{
         "attachment":null,
         "email":"testuser1@whippy.co",
         "id":2,
         "name":"Judy Morissette"
      },
      "description":null,
      "id":"bd9d65be-9a1d-42a4-a3d4-8e85e41fd282",
      "inserted_at":"2023-02-04T00:07:56",
      "locations":[
         
      ],
      "settings":{
         "on_response":"remove",
         "on_re_add":"exclude"
      },
      "status":"active",
      "steps":[
         {
            "analytics":{
               "campaign_progress":0,
               "delivery_rate":0,
               "failed_deliveries":0,
               "link_clicks":0,
               "link_clicks_rate":0,
               "response_rate":0,
               "responses":0,
               "sent_messages":0,
               "slated_messages":0,
               "unsubscribe_rate":0,
               "unsubscribes":0
            },
            "attachment_urls":[
               
            ],
            "body":"DEMO!",
            "id":"3c8208e4-89f4-4186-be27-5bedb019bd18",
            "position":0,
            "schedule_options":{
               "days":"0",
               "hours":"0",
               "minutes":"0",
               "timezone":"Pacific/Honolulu"
            }
         }
      ],
      "title":"update sequence",
      "updated_at":"2023-02-06T12:34:16"
   }
}
*/

export const updateSequence = (params: Sequence): Promise<Sequence> =>
  api.put(`/sequences/${params.id}`, { sequence: params }).then((res) => {
    return res.data.data as Sequence;
  });

/*

GET - /api/sequences/:id/steps

Response Example:

{
   "data":[
      {
         "analytics":{
            "campaign_progress":0,
            "delivery_rate":0,
            "failed_deliveries":0,
            "link_clicks":0,
            "link_clicks_rate":0,
            "response_rate":0,
            "responses":0,
            "sent_messages":0,
            "slated_messages":0,
            "unsubscribe_rate":0,
            "unsubscribes":0
         },
         "attachment_urls":[
            
         ],
         "body":"DEMO",
         "id":"3c8208e4-89f4-4186-be27-5bedb019bd18",
         "position":0,
         "schedule_options":{
            "days":"0",
            "hours":"0",
            "minutes":"0",
            "timezone":"Pacific/Honolulu"
         },
         "title":null
      }
   ]
}

*/

export const getSequenceSteps = async (
  sequence_id: string
): Promise<Array<SequenceStep>> => {
  const { data } = await api.get(`/sequences/${sequence_id}/steps`);
  return data.data as Array<SequenceStep>;
};

/*

GET - /api/sequences/:sequence_id/steps/:step_id

Response Example:

{
   "data": 
      {
         "analytics":{
            "campaign_progress":0,
            "delivery_rate":0,
            "failed_deliveries":0,
            "link_clicks":0,
            "link_clicks_rate":0,
            "response_rate":0,
            "responses":0,
            "sent_messages":0,
            "slated_messages":0,
            "unsubscribe_rate":0,
            "unsubscribes":0
         },
         "attachment_urls":[
            
         ],
         "body":"DEMO!",
         "id":"3c8208e4-89f4-4186-be27-5bedb019bd18",
         "position":0,
         "schedule_options":{
            "days":"0",
            "hours":"0",
            "minutes":"0",
            "timezone":"Pacific/Honolulu"
         },
         "title":null
    }
  }
*/

export const getSequenceStep = (
  sequence_id: string,
  step_id: string
): Promise<SequenceStep> =>
  api.get(`/sequences/${sequence_id}/steps/${step_id}`).then((res) => {
    return res.data.data as SequenceStep;
  });

/*

POST - /api/sequences/:sequence_id/steps

Example Request:

{
   "step":{
      "sequence_id":"bd9d65be-9a1d-42a4-a3d4-8e85e41fd282",
      "body":"This is a demo sequence step",
      "attachment_urls":[
         
      ],
      "position":1,
      "schedule_options":{
         "days":"1",
         "hours":"2",
         "minutes":"3",
         "timezone":"Pacific/Honolulu"
      }
   }
}

Example Response:

{
   "data":{
      "analytics":{
         "campaign_progress":0,
         "delivery_rate":0,
         "failed_deliveries":0,
         "link_clicks":0,
         "link_clicks_rate":0,
         "response_rate":0,
         "responses":0,
         "sent_messages":0,
         "slated_messages":0,
         "unsubscribe_rate":0,
         "unsubscribes":0
      },
      "attachment_urls":[
         
      ],
      "body":"This is a demo sequence step",
      "id":"bd5b8c96-238e-4399-802e-91c3f315213a",
      "position":1,
      "schedule_options":{
         "days":"1",
         "hours":"2",
         "minutes":"3",
         "timezone":"Pacific/Honolulu"
      },
      "title":null
   }
}

*/

export const createSequenceStep = (params: SequenceStep): Promise<SequenceStep> =>
  api.post(`/sequences/${params.sequence_id}/steps`, { step: params }).then((res) => {
    return res.data.data as SequenceStep;
  });

/** 
PUT - /api/sequences/:sequence_id/steps/:step_id

Example Request:

{
   "step":{
      "id":"5b18174b-cca6-4c8e-8081-9805417d2f73",
      "sequence_id":"30394e23-0c10-4237-be20-07b80971c295",
      "body":"Are you able to provide the your work history for at least the last 2 years; including company names, dates and duties? Respond YES or NO",
      "attachment_urls":[
         
      ],
      "position":0,
      "schedule_options":{
         "days":"0",
         "hours":"0",
         "minutes":"0",
         "timezone":"America/Los_Angeles"
      }
   }
}

Example Response:

{
   "data":{
      "analytics":{
         "campaign_progress":0,
         "delivery_rate":100.0,
         "failed_deliveries":0,
         "link_clicks":0,
         "link_clicks_rate":0,
         "response_rate":100.0,
         "responses":1,
         "sent_messages":1,
         "slated_messages":0,
         "unsubscribe_rate":0,
         "unsubscribes":0
      },
      "attachment_urls":[
         
      ],
      "body":"Are you able to provide the your work history for at least the last 2 years; including company names, dates and duties? Respond YES or NO",
      "id":"5b18174b-cca6-4c8e-8081-9805417d2f73",
      "position":0,
      "schedule_options":{
         "days":"0",
         "hours":"0",
         "minutes":"0",
         "timezone":"America/Los_Angeles"
      }
   }
}
*/

export const updateSequenceStep = (params: SequenceStep): Promise<SequenceStep> =>
  api
    .put(`/sequences/${params.sequence_id}/steps/${params.id}`, { step: params })
    .then((res) => {
      return res.data.data as SequenceStep;
    });

export const deleteSequenceStep = (sequence_id: string, step_id: string) =>
  api.delete(`/sequences/${sequence_id}/steps/${step_id}`);

/**
 POST - /api/sequences/:sequence_id/steps/:step_id/steps_contacts

  * @param contacts an array of contact uuids to add to the step
  * @param location_id the location uuid to add contacts to
  * @param step_id the step uuid to add contacts to
  * @param sequence_id the sequence uuid

 Example Request:

  {
    "step_contact":{
        "audience":{
          "contacts":[
              "7c5e1d9e-347d-42a1-bf68-4a75d11ccc35"
          ]
        },
        "location_id":"3db013ff-c27e-4371-86eb-ec0fb181f013"
    }
  }

  Example Response:

  {
    "data":{
        "message":"Adding contacts to step",
        "sequence_id":"a3f90e3e-c370-45f5-b6ae-6b3b9abb4e9d",
        "step_id":"755f4a3d-b4d4-4a2b-8ce1-b224defe11d0"
    }
  }
 */

export const addContactsToSequence = (
  contacts: Array<string> | string,
  location_id: string,
  step_id: string,
  sequence_id: string
): Promise<AddContactsToSequenceResponse> =>
  api
    .post(`/sequences/${sequence_id}/steps/${step_id}/steps_contacts`, {
      step_contact: { audience: { contacts }, location_id: location_id },
    })
    .then((res) => {
      return res.data.data as AddContactsToSequenceResponse;
    });

export const addAudienceToSequence = (
  audience: Audience,
  location_id: string,
  step_id: string,
  sequence_id: string,
  schedule?: Schedule | null
): Promise<AddContactsToSequenceResponse> =>
  api
    .post(`/sequences/${sequence_id}/steps/${step_id}/steps_contacts`, {
      step_contact: {
        audience: audience,
        location_id: location_id,
        schedule_options: schedule,
      },
    })
    .then((res) => {
      return res.data.data as AddContactsToSequenceResponse;
    });

/**
 * POST - /api/sequences/:sequence_id/steps_contacts/bulk_remove
 * 
 * @param contacts an array of contact uuids to remove from the step
 * @param sequence_id the sequence uuid
 * 
 * Example Request:
 * 
 * {
 *  "step_contact ": {
 *   "audience": {
 *    "contacts": [
 *    "7c5e1d9e-347d-42a1-bf68-4a75d11ccc35"
 *   ]
 *  }
 * }
 *
 * Example Response:
 * 
 *  {
    "data":{
        "message":"Removing contacts from sequence",
        "sequence_id":"43f20b66-5bb7-4cb5-82ff-d0be3812f0fe",
        "step_id":null
    }
  }
 *  */

export const removeContactsFromSequence = (
  contacts: Array<string> | string,
  sequence_id: string
): Promise<RemoveContactsFromSequenceResponse> =>
  api
    .post(`/sequences/${sequence_id}/steps_contacts/bulk_remove`, {
      step_contact: { audience: { contacts } },
    })
    .then((res) => {
      return res.data.data as RemoveContactsFromSequenceResponse;
    });

/**
 * 
 * POST - api/v2/sequences/:sequence_id/step_contacts/search
 * 
  * @param sequence_id the sequence uuid
  * @param params the query params to filter the contacts
  * 
  * Example Response
  
  * {
   "data":[
      {
         "added_by_id": null,
         "channel_id": "65f10ba6-f8ce-4e31-a02f-70588aed9590",
         "clicked_link": false,
         "contact": {
         "email": "maya1921@friesen.biz",
         "id": "6c3283bf-cdef-43d9-a278-db594bb7d227",
         "name": "John Adams",
         "phone": "+18679599996"
         },
         "contact_messages": [],
         "created_at": "2023-04-01T12:25:12",
         "id": "179d12fc-9633-47ef-9a42-c671134ae2df",
         "run_created_at": null,
         "run_updated_at": null,
         "scheduled_at": null,
         "status": "active",
         "step_id": "2a0e509f-8260-4294-b743-c6d83db68dfe",
         "unsubscribed": false,
         "updated_at": "2023-04-01T12:25:12"
      }
   ]
}
  */

export const getSequenceContacts = (
  sequence_id: string,
  params: any,
  signal: any
): Promise<SequenceContacts> =>
  api
    .post(`/v2/sequences/${sequence_id}/step_contacts/search`, { ...params }, { signal })
    .then((res) => {
      return res.data;
    });

/**
 * 
 * POST - api/v2/sequences/:sequence_runs/search
 * 
  * @param sequence_id the sequence uuid
  * @param params the query params to filter the contacts
  * 
  * Example Response
  
  * {
   "data": [
      {
         "contact": {
         "email": "fake@example.com",
         "id": "02eab4da-3979-4269-ac4d-8d4f3c8fbdcb",
         "name": "John",
         "phone": "+14254244011",
         "source": null
         },
         "id": "d682577a-6ca9-46cb-ade6-77bca15b5988",
         "inserted_at": "2023-07-14T07:14:43",
         "step_contacts": [
         {
            "clicked_link": false,
            "contact_messages": [],
            "id": "241ce277-d447-4796-bef4-eff2880a1893",
            "status": "active",
            "step_id": "c9716b28-04fb-4bf4-a40e-470827c3adec",
            "unsubscribed": false
         }
         ],
         "updated_at": "2023-07-14T07:14:43"
      }
   ],
   "total": 2
   }
  */

export const getSequenceResponses = (
  sequence_id: string,
  params: any,
  signal: any
): Promise<any> =>
  api
    .post(`/v2/sequences/${sequence_id}/sequence_runs/search`, { ...params }, { signal })
    .then((res) => {
      return res.data;
    });

// Sequence Triggers & Automations API Calls

/**
 *  
 * GET - /api/sequences/:sequence_id/steps/:step_id/steps_triggers
 * 
 * @param sequence_id the sequence uuid
 * @param step_id the step uuid
 * 
 * Example Response:
 * 
 * {
   "data":[
      {
         "id":"c8a04781-dbe2-4b3c-bf49-3d0827c1edaf",
         "inserted_at":"2023-02-13T13:35:57",
         "trigger":{
            "actions":[
               {
                  "enabled":true,
                  "id":"81c1e0ad-bd82-40bb-950d-c78c0a74f834",
                  "inserted_at":"2023-02-13T13:35:57.905033Z",
                  "organization_id":"f2ee4276-7f4f-43c6-93fd-b7f8f6c9ccf0",
                  "params":{
                     "attachment_urls":[
                        
                     ],
                     "attachments":[
                        
                     ],
                     "body":"DEMO"
                  },
                  "type":"send_message",
                  "updated_at":"2023-02-13T13:35:57.905033Z"
               }
            ],
            "id":"47336909-e122-4ae0-993c-e8db1c742c70",
            "keyword":{
               "body":null,
               "enabled":true,
               "exact_match":false,
               "id":"fd3d788e-5f8b-4851-ad4b-5856603fec41",
               "inserted_at":"2023-02-13T13:35:57.881796Z",
               "keywords":[
                  "INTERESTED"
               ],
               "negate_keywords":[
                  "NOT INTERESTED"
               ],
               "organization_id":"f2ee4276-7f4f-43c6-93fd-b7f8f6c9ccf0",
               "updated_at":"2023-02-13T13:35:57.881796Z"
            },
            "type":"reply_containing_keyword"
         }
      }
   ]
}
  */

export const getSequenceStepTriggers = (
  sequence_id: string,
  step_id: string
): Promise<Array<SequenceStepTrigger>> =>
  api.get(`/sequences/${sequence_id}/steps/${step_id}/steps_triggers`).then((res) => {
    return res.data.data as Array<SequenceStepTrigger>;
  });

/**
 * 
 * POST - /api/sequences/:sequence_id/steps/:step_id/steps_triggers
 * 
 * @param sequence_id the sequence uuid
 * @param step_id the step uuid
 * @param trigger the trigger params
 * 
 * Example Request:
 * 
 * {
   "step_trigger":{
      "trigger":{
         "type":"reply_containing_keyword",
         "keyword":{
            "keywords":[
               "INTERESTED"
            ],
            "negate_keywords":[
               "NOT INTERESTED"
            ],
            "enabled":true,
            "exact_match":false
         },
         "actions":[
            {
               "id":"",
               "enabled":true,
               "type":"send_message",
               "params":{
                  "body":"DEMO!",
                  "attachment_urls":[
                     
                  ],
                  "attachments":[
                     
                  ]
               }
            }
         ]
      }
   }
}
  */

export const createSequenceStepTrigger = (
  sequence_id: string,
  step_id: string,
  trigger: Trigger
): Promise<SequenceStepTrigger> =>
  api
    .post(`/sequences/${sequence_id}/steps/${step_id}/steps_triggers`, {
      step_trigger: { trigger },
    })
    .then((res) => {
      return res.data.data as SequenceStepTrigger;
    });

/**
 * 
 * PUT - /api/sequences/:sequence_id/steps/:step_id/steps_triggers/:sequence_step_trigger_id
 * 
 * @param sequence_id the sequence uuid
 * @param step_id the step uuid
 * @param sequence_step_trigger_id the sequence step trigger uuid
 * @param trigger the trigger params
 * 
 * Example Request:
 * 
 * {
   "step_trigger":{
      "trigger":{
         "id":"47336909-e122-4ae0-993c-e8db1c742c70",
         "type":"reply_containing_keyword",
         "keyword":{
            "keywords":[
               "INTERESTED"
            ],
            "negate_keywords":[
               "NOT INTERESTED"
            ],
            "exact_match":"false"
         },
         "actions":[
            {
               "enabled":true,
               "id":"81c1e0ad-bd82-40bb-950d-c78c0a74f834",
               "inserted_at":"2023-02-13T13:35:57.905033Z",
               "organization_id":"f2ee4276-7f4f-43c6-93fd-b7f8f6c9ccf0",
               "params":{
                  "attachment_urls":[
                     
                  ],
                  "attachments":[
                     
                  ],
                  "body":"DEMO!"
               },
               "type":"send_message",
               "updated_at":"2023-02-13T13:35:57.905033Z"
            },
            {
               "id":"",
               "enabled":true,
               "type":"add_tags",
               "params":{
                  "tag_ids":[
                     "7c2f1ba8-800f-4539-b359-73bf8cf742a3",
                     "12030fb1-7361-47e8-a9f2-f9a2c40819b8"
                  ]
               },
               "tempId":"d62572c5-58b3-4c90-819a-5ea39f86b3d4"
            }
         ]
      }
   }
}

Example Response:

{
   "data":{
      "id":"c8a04781-dbe2-4b3c-bf49-3d0827c1edaf",
      "inserted_at":"2023-02-13T13:35:57",
      "trigger":{
         "actions":[
            {
               "enabled":true,
               "id":"81c1e0ad-bd82-40bb-950d-c78c0a74f834",
               "inserted_at":"2023-02-13T13:35:57.905033Z",
               "organization_id":"f2ee4276-7f4f-43c6-93fd-b7f8f6c9ccf0",
               "params":{
                  "attachment_urls":[
                     
                  ],
                  "attachments":[
                     
                  ],
                  "body":"DEMO!"
               },
               "type":"send_message",
               "updated_at":"2023-02-13T13:35:57.905033Z"
            },
            {
               "enabled":true,
               "id":"4c1ac0fa-c7f6-4652-be50-fbec5b1b0fd8",
               "inserted_at":"2023-02-13T13:38:27.267086Z",
               "organization_id":"f2ee4276-7f4f-43c6-93fd-b7f8f6c9ccf0",
               "params":{
                  "tag_ids":[
                     "7c2f1ba8-800f-4539-b359-73bf8cf742a3",
                     "12030fb1-7361-47e8-a9f2-f9a2c40819b8"
                  ]
               },
               "type":"add_tags",
               "updated_at":"2023-02-13T13:38:27.267086Z"
            }
         ],
         "id":"47336909-e122-4ae0-993c-e8db1c742c70",
         "keyword":{
            "body":null,
            "enabled":true,
            "exact_match":false,
            "id":"fd3d788e-5f8b-4851-ad4b-5856603fec41",
            "inserted_at":"2023-02-13T13:35:57.881796Z",
            "keywords":[
               "INTERESTED"
            ],
            "negate_keywords":[
               "NOT INTERESTED"
            ],
            "organization_id":"f2ee4276-7f4f-43c6-93fd-b7f8f6c9ccf0",
            "updated_at":"2023-02-13T13:35:57.881796Z"
         },
         "type":"reply_containing_keyword"
      }
   }
}

  */

export const updateSequenceStepTrigger = (
  sequence_id: string,
  step_id: string,
  sequence_step_trigger_id: string,
  trigger: TriggerType
): Promise<SequenceStepTrigger> =>
  api
    .put(
      `/sequences/${sequence_id}/steps/${step_id}/steps_triggers/${sequence_step_trigger_id}`,
      {
        step_trigger: { trigger },
      }
    )
    .then((res) => {
      return res.data.data as SequenceStepTrigger;
    });

export const deleteSequenceStepTrigger = (
  sequence_id: string,
  step_id: string,
  sequence_step_trigger_id: string
) =>
  api
    .delete(
      `/sequences/${sequence_id}/steps/${step_id}/steps_triggers/${sequence_step_trigger_id}`
    )
    .then((res) => {
      return res;
    });

export const getSequenceRuns = ({
  sequence_id,
  limit,
  offset,
}: {
  sequence_id: string;
  limit?: number;
  offset?: number;
}) =>
  api
    .get(`/sequences/${sequence_id}/sequence_runs`, {
      params: {
        limit,
        offset,
      },
    })
    .then((res) => {
      return res.data.data;
    });

export const duplicateSequence = (id: string): Promise<Sequence> =>
  api.post(`/sequences/${id}/duplicate`).then((res) => {
    return res.data.data as Sequence;
  });

export const sequenceBulkActions = (
  sequence_id: string,
  action: 'sequences.contacts.remove',
  filter: SequenceBulkActionFilter,
  sourceSequenceId: string
) =>
  api
    .post('/v2/bulk_actions', {
      bulk_action: action,
      resource: { ids: [sequence_id] },
      selection: {
        step_contact_selection: {
          ...filter,
        },
      },
      args: {
        sequence_id: sourceSequenceId,
      },
    })
    .then((res) => {
      return res.data.data;
    });

type AddToSequenceBulkActionBody = {
  bulk_action: 'sequences.contacts.add';
  resource: { ids: string[] };
  selection: {
    contact_selection?: SequenceBulkActionFilter;
    step_contact_selection?: SequenceBulkActionFilter;
    campaign_contact_selection?: SequenceBulkActionFilter;
  };
  args: {
    fallback_channel_id: string;
    schedule_options?: IntegerScheduleOptions;
    sequence_id?: string;
    campaign_id?: string;
  };
};
export type SequenceSourceTuple = ['sequence', string];
export type CampaignSourceTuple = ['campaign', string];

/**
 * Asynchronously adds multiple contacts or step contacts to a sequence based on specified filters.
 *
 * This function is used to add a bulk of contacts to a specific sequence (`sinkSequenceId`)
 * while optionally scheduling them according to provided `scheduleOptions`. Contacts can be
 * filtered from another sequence (`sourceSequenceId`) or based on custom criteria provided
 * in the `filter` parameter.
 *
 * @param sinkSequenceId - The ID of the sequence to which contacts or step contacts will be added.
 * @param channel_id - The channel ID associated with the sequence operation.
 * @param filter - The Whippy Query Language param specifying the criteria used to filter which contacts are added to the sequence.
 * @param scheduleOptions - Optional scheduling options for when the contacts should be added to the sequence.
 * @param sourceTuple - Optional tuple that allows us to specify whether the filter being applied should be a
 *                            step_contact_selection or campaign_contact_selection. By default, it will be applied
 *                            to contact_selection.
 * @returns A Promise resolving to any type, indicating the result of the bulk operation.
 */
export const bulkActionAddContactToSequence = async (
  sinkSequenceId: string,
  channel_id: string,
  filter: SequenceBulkActionFilter,
  scheduleOptions: ScheduleOptions | null,
  sourceTuple?: SequenceSourceTuple | CampaignSourceTuple
): Promise<any> => {
  const body: AddToSequenceBulkActionBody = {
    bulk_action: 'sequences.contacts.add',
    resource: { ids: [sinkSequenceId] },
    selection: {},
    args: {
      fallback_channel_id: channel_id,
    },
  };

  if (sourceTuple && sourceTuple[0] == 'sequence') {
    body.args.sequence_id = sourceTuple[1];
    body.selection = {
      step_contact_selection: {
        ...filter,
      },
    };
  } else if (sourceTuple && sourceTuple[0] == 'campaign') {
    body.args.campaign_id = sourceTuple[1];
    body.selection = {
      campaign_contact_selection: {
        ...filter,
      },
    };
  } else {
    body.selection = {
      contact_selection: {
        ...filter,
      },
    };
  }
  // add in the optional scheduleOptions if provided
  if (scheduleOptions)
    body.args.schedule_options = stringifiedScheduleOptions(scheduleOptions);
  const result = await api.post('/v2/bulk_actions', body);
  return result.data.data;
};

type IntegerScheduleOptions = {
  day: number;
  /* the month (note that this is not indexed like the Date module and dayjs) */
  month: number;
  /* the year */
  year: number;
  /* the hour */
  hour: number;
  /* the minute */
  minute: number;
  /* the timezone */
  timezone: string;
};
function stringifiedScheduleOptions(
  scheduleOptions: ScheduleOptions
): IntegerScheduleOptions {
  return {
    timezone: scheduleOptions.timezone,
    year: parseInt(scheduleOptions.year),
    month: parseInt(scheduleOptions.month),
    day: parseInt(scheduleOptions.day),
    hour: parseInt(scheduleOptions.hour),
    minute: parseInt(scheduleOptions.minute),
  };
}
