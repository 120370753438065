/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { MainNavigation } from '@/shared/components/navigation';
import OrganizationUserMenu from '@/shared/components/navigation/OrganizationUserMenu';
import { useDisclosure } from '@/shared/hooks';
import { LayoutContent, MainLayout } from '@/shared/layouts';
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  Flex,
  IconButton,
} from '@/shared/ui';

import { useAuth } from '../auth/context/AuthProvider';
import { useLocations } from '../settings/organization/locations/context/LocationContext';
import { useUserPreferences } from '../settings/user/preferences/context/PreferencesContext';
import { ContactsList } from './ContactsList';
import { ClickType, useEmbed } from './context/EmbedContext';
import { Conversation } from './Conversation';

export const Embed = () => {
  // location context
  const { locationsState, getUserLocations } = useLocations();
  const { locations } = locationsState;

  // embed context
  const { clickType, contact, loading } = useEmbed();

  const auth = useAuth();

  useEffect(() => {
    if (auth.tokens?.user_id && locations.length === 0) {
      getUserLocations({
        user_id: auth.tokens?.user_id,
      });
    }
  }, [locations.length, auth.tokens?.user_id]);

  const isDesktop = useMedia('(min-width: 912px)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const user_preferences = useUserPreferences();
  const { preferences } = user_preferences;

  // is the preferences location in the list of locations?
  // it might not be if the user has access to multiple organizations
  const isLocationInList = locations.some(
    (location) => location.id === preferences?.inbox?.preferred_location_id
  );

  return (
    <MainLayout>
      <LayoutContent>
        <EmbedNavigation
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          isDesktop={isDesktop}
        />
        {clickType === ClickType.SINGLE && !loading && (
          <Flex>
            {locations.length > 0 && contact && (
              <Conversation
                contact_id={contact?.id || ''}
                contact_name={contact?.name || ''}
                contact_phone={contact?.phone || ''}
                location_id={
                  isLocationInList
                    ? preferences?.inbox?.preferred_location_id || locations[0].id || ''
                    : locations[0].id
                }
              />
            )}
          </Flex>
        )}
        {clickType === ClickType.LIST && <ContactsList />}
      </LayoutContent>
    </MainLayout>
  );
};

const EmbedNavigation = ({
  isOpen,
  onOpen,
  onClose,
  isDesktop,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  isDesktop: boolean;
}) => {
  return (
    <Flex
      align="center"
      justify="between"
      css={{
        p: 15,
        width: '100%',
        height: '58px',
        borderBottom: 'thin solid $gray4',
      }}
    >
      <IconButton onClick={onOpen}>
        <HiOutlineMenu fontSize="1rem" />
      </IconButton>
      <Box>
        <OrganizationUserMenu />
      </Box>
      {!isDesktop ? (
        <Drawer open={isOpen}>
          <DrawerPortal>
            <DrawerOverlay />
            <DrawerContent
              onEscapeKeyDown={onClose}
              onPointerDownOutside={onClose}
              css={{ width: 230 }}
              side="left"
            >
              <MainNavigation />
            </DrawerContent>
          </DrawerPortal>
        </Drawer>
      ) : null}
    </Flex>
  );
};

type PhoneEvent = {
  // this is either a single phone number or an array of phone numbers
  // passed from the widget as a stringified array
  whippy_phone: string;
  isExtension: boolean;
};

type WidgetEvent = {
  // this signifies if the drawer is open or closed on the
  // website that the user is using the extension on
  whippy_widget: boolean;
  isExtension: boolean;
};

export type EventData = PhoneEvent | WidgetEvent;
