import dayjs from 'dayjs';
import React from 'react';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { HiPaperAirplane, HiTrash } from 'react-icons/hi';
import { Link } from 'react-router-dom';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { TooltipIconButton } from '@/shared/components/TooltipIconButton';
import { useDisclosure } from '@/shared/hooks';
import { ConversationContactType } from '@/shared/types/conversations';
import { User } from '@/shared/types/users';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { formatPhoneNumber, isValidUuid } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { showContactIcon } from '../list/ConversationPreview';
import { useScheduledMessages } from './context/ScheduledMessagesContext';
import SendNowConfirmation from './SendNowConfirmation';
import { UpdateScheduledMessageDialog } from './UpdateScheduledMessageDialog';

export type ScheduledMessageProps = {
  /* message params created by the user */
  message: {
    body: string | null;
    attachment_urls: Array<string> | null;
  };
  contact: ConversationContactType;
  /* scheduled message location id */
  location_id: string;
  /* scheduled message user id */
  user_id: number | null;
  /* scheduled params object */
  schedule: string;
  /* scheduled message id */
  id: number;
  /* conversation id of the conversation we will schedule the message for */
  conversation_id: string;
  /* scheduled message timezone */
  timezone?: string;
};

export const ScheduledMessage = (props: ScheduledMessageProps) => {
  const scheduledMessages = useScheduledMessages();
  const { deleteOneScheduledMessage, sendScheduledMessageNow } = scheduledMessages;

  const deleteMessage = () => {
    deleteOneScheduledMessage(props.id);
  };

  const users = useUsers();
  const user = users.userState.users.find((user: User) => user.id === props.user_id);

  const locations = useLocations();
  const location = locations.locationsState.locations.find(
    (location) => location.id === props.location_id
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ScheduledMessageContainer direction="column">
      <VStack gap={2}>
        <Flex justify="between" css={{ width: '100%' }}>
          <HStack align="start">
            <Text css={{ fontWeight: 500 }}>
              {dayjs(props?.schedule).format('dddd, MMM D')} at{' '}
              {dayjs(props?.schedule?.substring(0, 19)).format('h:mm a')}{' '}
              {props.timezone && `(${props?.timezone}) `}
            </Text>
            <Text css={{ fontWeight: 500 }}>
              {location?.name && `from ${location?.name}`}
            </Text>
          </HStack>
          <Flex>
            <HStack>
              <ConfirmationDialog
                title="Delete Scheduled Message"
                description="Are you sure you want to delete your scheduled message?"
                onConfirm={deleteMessage}
                confirmButtonTitle="Yes, Delete Message"
                testID="delete-scheduled-message-button"
              >
                <TooltipIconButton
                  tooltipArrow
                  size={2}
                  variant="outline"
                  icon={<HiTrash />}
                  text="Delete Scheduled Message"
                />
              </ConfirmationDialog>
              <SendNowConfirmation id={props.id} onDelete={sendScheduledMessageNow}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <IconButton
                      variant="outline"
                      css={{ transform: 'rotate(90deg)' }}
                      size={2}
                    >
                      <HiPaperAirplane />
                    </IconButton>
                  </TooltipTrigger>
                  <TooltipContent>
                    Send Message Now
                    <TooltipArrow />
                  </TooltipContent>
                </Tooltip>
              </SendNowConfirmation>
              {isValidUuid(props.conversation_id) && (
                <UpdateScheduledMessageDialog
                  id={props.id}
                  location_id={props.location_id}
                  message={props.message}
                  schedule={props.schedule}
                  // fallback to browser timezone if timezone is not set
                  timezone={
                    props.timezone ||
                    Intl.DateTimeFormat().resolvedOptions().timeZone ||
                    ''
                  }
                  conversationId={props.conversation_id}
                  buttonVariant={'outline'}
                  isInbox={false}
                  isOpen={isOpen}
                  onOpen={onOpen}
                  onClose={onClose}
                />
              )}
              {isValidUuid(props.conversation_id) && (
                <Link to={`/inbox/all/open/${props.conversation_id}`}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <IconButton size={2} variant="outline">
                        <BsFillChatDotsFill />
                      </IconButton>
                    </TooltipTrigger>
                    <TooltipContent>
                      View Conversation
                      <TooltipArrow />
                    </TooltipContent>
                  </Tooltip>
                </Link>
              )}
            </HStack>
          </Flex>
        </Flex>
        <Flex direction="column">
          <Flex>
            <Box css={{ mr: 10, mb: 10 }}>
              <Avatar
                src={
                  showContactIcon(props?.contact?.name || '')
                    ? `${window.location.origin}/outline.svg`
                    : ''
                }
                fallback={initials(props?.contact?.name || '')}
                size="assign"
              />
            </Box>
            <Text css={{ fontWeight: 500, mb: 5 }}>
              {props?.contact?.name || formatPhoneNumber(props?.contact?.phone || '')}
            </Text>
          </Flex>
          <Flex justify="between">
            <Flex>
              <MessagePreview>{props?.message?.body}</MessagePreview>
            </Flex>
            <Flex>
              <Box css={{ ml: 10 }}>
                <Avatar
                  src={user?.attachment?.url}
                  fallback={initials(user?.name || user?.email)}
                  variant="pink"
                  size="assign"
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </VStack>
    </ScheduledMessageContainer>
  );
};

export const LoadingScheduledMessage = () => {
  return (
    <ScheduledMessageContainer direction="column" css={{ mb: 0 }}>
      <Flex css={{ width: '100%' }}>
        <Skeleton variant="heading" css={{ width: '50%' }} />
      </Flex>
      <Flex>
        <Skeleton variant="heading" css={{ width: '100%' }} />
      </Flex>
    </ScheduledMessageContainer>
  );
};

const ScheduledMessageContainer = styled(Flex, {
  width: '98%',
  p: 20,
  borderWidth: 2,
  borderColor: '$gray3',
  borderRadius: 8,
  mb: 30,
});

const MessagePreview = styled(Box, {
  fontSize: '14px',
  lineHeight: '20px',
  m: '0',
  p: '0',
  fontWeight: 400,
  display: '-webkit-box',
  '-webkit-line-clamp': '1 !important',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
