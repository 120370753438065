import React, { useEffect, useRef } from 'react';

import { useAuth } from '@/auth/context/AuthProvider';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { Box, Button, Fieldset, Input, ProgressBar } from '@/shared/ui';
import { handleScriptLoad, loadScript, removeScript } from '@/shared/utils/setup/google';

import { useOnboarding } from '../context/OnboardingProvider';
import {
  OnboardingContainer,
  OnboardingDescription,
  OnboardingHeading,
  SkipOnboarding,
} from '../OnboardingContainer';

const FindAddress = () => {
  const onboarding = useOnboarding();
  const auth = useAuth();

  const { locationsState, addLocation } = useLocations();
  const { locations } = locationsState;

  const { setCurrentStep, setOrganizationDetails, organizationDetails } = onboarding;

  const autoCompleteRef = useRef(null);

  useEffect(() => {
    if (locations.length > 0) {
      setCurrentStep('find_website');
    }
    // eslint-disable-next-line
  }, [locations]);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvZkCD8dE8AHfGya8Z_l8coxbhcsKiDvM&libraries=places`,
      () => handleScriptLoad(setOrganizationDetails, autoCompleteRef)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () =>
      removeScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvZkCD8dE8AHfGya8Z_l8coxbhcsKiDvM&libraries=places`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateLocation = () => {
    async function createLocation() {
      await addLocation({
        name: auth.organizationName,
        address: onboarding?.organizationDetails?.formatted_address || '',
        google_place_id: onboarding?.organizationDetails?.place_id || '',
        widget_setting_id: auth?.organizationInfo?.organization?.widget_settings?.id,
      });
    }

    if (locations.length < 1) {
      createLocation();
    } else {
      setCurrentStep('find_website');
    }
  };

  return (
    <Box>
      <ProgressBar value={(1 / 5) * 100} variant="purple" />
      <SkipOnboarding />
      <OnboardingContainer>
        <OnboardingHeading>Find your Business Address on Google</OnboardingHeading>
        <OnboardingDescription>
          We need to know your address to get you more 5 star reviews. If you cannot find
          your business address, manually type it in.
        </OnboardingDescription>
        <Fieldset>
          <Input
            placeholder="Enter your business address"
            ref={autoCompleteRef}
            value={organizationDetails?.formatted_address || locations[0]?.address}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrganizationDetails({ name: e.target.value })
            }
          />
        </Fieldset>
        <Button onClick={handleCreateLocation} css={{ width: '100%' }}>
          Next
        </Button>
      </OnboardingContainer>
    </Box>
  );
};

export default FindAddress;
