import React from 'react';
import { HiX } from 'react-icons/hi';

import { useDisclosure } from '@/shared/hooks/useDisclosure';
import {
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from '@/shared/ui';

type KeywordDialogProps = {
  /* The content of the dialog */
  children: React.ReactNode;
  /* The trigger to open the dialog */
  trigger: React.ReactNode;
};

export function KeywordDialog(props: KeywordDialogProps): JSX.Element {
  const { children, trigger } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={onOpen}>
        {trigger}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          onEscapeKeyDown={onClose}
          onPointerDownOutside={onClose}
          css={{ overflow: 'auto' }}
        >
          <DialogHeader title="Create Keyword" />
          {children}
          <DialogCloseIcon onClick={onClose} size="2">
            <HiX size="15px" style={{ color: 'white' }} />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
