import { ReactNode, useState } from 'react';
import { HiArrowsUpDown } from 'react-icons/hi2';

import { CustomObject, DefaultObject } from '@/shared/types/data';
import { FilterType, Sort, SortConfig } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';

import { QuickSortFilter } from '../filters/TableFilters';
import { FilterBuilder } from './FilterBuilder';

type CombinedFiltersParams = {
  quickSearchPlaceholder?: string;
  quickSearchValue?: string;
  setQuickSearchValue?: (value: string) => void;
  customObjects: Array<CustomObject> | [];
  activeFilters: FilterType[];
  setFilters: (filters: FilterType[]) => void;
  sortConfig?: SortConfig<any, any>;
  activeSort: Array<Sort>;
  onSortUpdate: (sort: Sort[]) => void;
  children?: ReactNode;
  defaultObjects?: Array<DefaultObject> | [];
};

export const CombinedFilters = ({
  quickSearchPlaceholder,
  quickSearchValue,
  setQuickSearchValue,
  customObjects,
  activeFilters,
  setFilters,
  sortConfig,
  activeSort,
  onSortUpdate,
  children,
  defaultObjects,
}: CombinedFiltersParams) => {
  const [isQuickFilter, setIsQuickFilter] = useState(true);
  return (
    <Flex align="start" justify="between" direction="row">
      <Flex direction="row" align="center">
        <FilterBuilder
          defaultObjects={defaultObjects}
          isQuickFilter={isQuickFilter}
          setIsQuickFilter={setIsQuickFilter}
          customObjects={customObjects}
          filters={activeFilters}
          setFilters={setFilters}
          quickSearchPlaceholder={quickSearchPlaceholder}
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={setQuickSearchValue}
        />
      </Flex>
      <Flex gap={2}>
        <Box>
          <QuickSortFilter
            sortConfig={sortConfig}
            activeSort={activeSort}
            onSortUpdate={onSortUpdate}
          >
            <HiArrowsUpDown />
          </QuickSortFilter>
        </Box>
        {children}
      </Flex>
    </Flex>
  );
};
