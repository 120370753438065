// the size of all the icons in the editor
export const ICON_SIZE = 16;
// the max number of characters allowed in the editor
export const MAX_CHARACTERS_COUNT = 1000;
// At what editor width should we switch to icons buttons
export const USE_ICON_BUTTONS_AT = 780;

// pipes - to add a new pipe add another object
export const pipes = [
  {
    character: 'Organization Name',
    description: 'The name of your organization in Whippy.',
    children: '{{organization_name}}',
  },
  {
    character: 'Full Name',
    description: 'The full name of the contact you are messaging.',
    children: '{{full_name}}',
  },
  {
    character: 'First Name',
    description: 'The first name of the contact you are messaging.',
    children: '{{first_name}}',
  },
  {
    character: 'Last Name',
    description: 'The last name of the contact you are messaging.',
    children: '{{last_name}}',
  },
  {
    character: 'Location Name',
    description: 'The name of the Location that you are sending the message from.',
    children: '{{location_name}}',
  },
  {
    character: 'Location Address',
    description: 'The address of the Location that you are sending the message from.',
    children: '{{location_address}}',
  },
  {
    character: 'Review Link',
    description:
      'Your Google Review Link for the location you are sending messages from.',
    children: '{{review_link}}',
  },
  {
    character: 'Custom Field A',
    description: 'Custom Field A',
    children: '{{custom_a}}',
  },
  {
    character: 'Custom Field B',
    description: 'Custom Field B',
    children: '{{custom_b}}',
  },
  {
    character: 'Custom Field C',
    description: 'Custom Field C',
    children: '{{custom_c}}',
  },
  {
    character: 'Today',
    description: "Today's Date",
    children: '{{today}}',
  },
  {
    character: 'Tomorrow',
    description: "Tomorrow's Date",
    children: '{{tomorrow}}',
  },
];

export interface EmojiData {
  activeSkinTone: string;
  emoji: string;
  names: string[];
  unified: string;
  unifiedWithoutSkinTone: string;
}
export interface PipeData {
  character: string;
  children: string;
}

export interface ScheduleParams {
  day: string;
  month: string;
  year: string;
  hour: string;
  minute: string;
  timezone: string;
}

export interface Attachments {
  attachment_urls: string[];
}

export interface FileUpload {
  public_url: string;
  file_extension: string;
  presigned_url?: string;
}
