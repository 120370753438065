export const default_message_template_object = {
  id: 'unique-id-for-lists',
  label: 'Message template',
  key: 'message_template',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'message_template-title',
      label: 'Title',
      type: 'text',
      key: 'title',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-segments',
    },
    {
      default: '',
      id: 'message_template-message',
      label: 'Message',
      type: 'text',
      key: 'message',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-segments',
    },
  ],
};

export const default_created_by_object = {
  id: 'unique-id-for-created_by',
  label: 'Created by',
  key: 'created_by',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'created_by-name',
      label: 'Name',
      type: 'text',
      key: 'name',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-segments',
    },
    {
      default: '',
      id: 'created_by-email',
      label: 'Email',
      type: 'text',
      key: 'email',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-segments',
    },
  ],
};

export const default_updated_by_object = {
  id: 'unique-id-for-updated_by',
  label: 'Updated by',
  key: 'updated_by',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'updated_by-name',
      label: 'Name',
      type: 'text',
      key: 'name',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-segments',
    },
    {
      default: '',
      id: 'unique-id-for-email',
      label: 'Email',
      type: 'text',
      key: 'email',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-segments',
    },
  ],
};
