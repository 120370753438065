export const isDev = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const isPreview = Boolean(window.location.hostname.includes('.onrender.com'));

export const API_URL =
  process.env.REACT_APP_API_HOST + '.onrender.com' || 'api.whippy.co';

export const API_BASE_URL = isDev
  ? 'http://localhost:4000'
  : isPreview
    ? `https://${API_URL}`
    : 'https://api.whippy.co';

export const WEBSOCKET_BASE_URL = isDev
  ? 'ws://localhost:4000/socket'
  : isPreview
    ? `wss://${API_URL}/socket`
    : 'wss://api.whippy.co/socket';

export const DROMO_API_KEY = 'c2bdae1c-38c0-4c54-898e-84be19939555';

// logger that is used in development and preview
export const logger = (str: string) => {
  if (isDev || isPreview) {
    console.log(str);
  }
};
