/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import { HiStar } from 'react-icons/hi';
import { useRouteMatch } from 'react-router-dom';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Review } from '@/shared/types';
import { Location } from '@/shared/types/locations';
import { Box, Button, Center, EmptyState, Flex, Skeleton, Text } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useReviews } from './context/ReviewContext';
import { ReviewCard, ReviewContainer } from './ReviewCard';

export const AllReviews = () => {
  const { locationsState } = useLocations();

  const reviewContext = useReviews();
  const { getReviews, reviewState, getConsentUrl, setFilter } = reviewContext;
  const { reviews, loading, consentUrl } = reviewState;

  const [reviewsList, setReviewsList] = useState(reviews);

  useEffect(() => {
    if (locationsState.locations.length > 0) {
      const locationIds = locationsState.locations.map(
        (location: Location) => location.id
      );
      console.log('locationIds', locationIds);
      getReviews(locationIds);
    }
  }, [locationsState.locations]);

  useEffect(() => {
    getConsentUrl();
  }, []);

  // reviews with no response
  const reviewsUnresponded = reviews.slice();
  const filterUnresponded = reviewsUnresponded.filter(
    (review: Review) => !review.reviewReply
  );

  // sort lowest rating
  const reviewsLowest = reviews.slice();
  const sortLowestRating = reviewsLowest.sort(
    (a: { starRating: any }, b: { starRating: any }) =>
      b.starRating.localeCompare(a.starRating)
  );

  // sort highest rating
  const reviewsHigh = reviews.slice();
  const sortHighestRating = reviewsHigh.sort(
    (a: { starRating: any }, b: { starRating: any }) =>
      a.starRating.localeCompare(b.starRating)
  );

  const getSortedReviews = (filter: string) => {
    const reviewsList: Array<any> = reviews;
    switch (filter) {
      case 'all':
        setReviewsList(reviews);
        break;
      case 'unresponded':
        setReviewsList(filterUnresponded);
        break;
      case 'lowest':
        setReviewsList(sortLowestRating);
        break;
      case 'highest':
        setReviewsList(sortHighestRating);
        break;
      default:
        break;
    }
    return reviewsList;
  };

  const route = useRouteMatch();

  useEffect(() => {
    // get the value after /reviews/:value
    const filter = route.path.split('/')[2];
    setFilter(filter);
  }, [route.path]);

  useEffect(() => {
    getSortedReviews(reviewState.filter);
  }, [reviewState.filter, reviewState.reviews]);

  return (
    <PageLayout breadcrumbs={[{ title: 'Reviews', path: '/reviews' }]}>
      {/* List Reviews */}
      {!loading ? (
        reviews.length > 0 ? (
          reviewsList?.map((review: Review, index: number) => {
            return (
              <ReviewSpacer key={`review-${index}`}>
                <ReviewCard
                  review={review}
                  index={index}
                  stars={getReviewRatingNumber(review.starRating)}
                  showDate={true}
                />
              </ReviewSpacer>
            );
          })
        ) : (
          //  Empty State
          <Center>
            <EmptyState
              icon={<HiStar size="30px" />}
              description={<EmptyStateDescription />}
              title={'Reputation Management'}
              buttonComponent={<ReviewConsentField consentUrl={consentUrl} />}
            />
          </Center>
        )
      ) : (
        // Loading State
        Array.from({ length: 4 }, (_, k) => (
          <ReviewSpacer key={k}>
            <ReviewContainer>
              <Flex direction="column">
                <Flex align="center">
                  <Skeleton variant="avatar" css={{ mr: '$3' }} />
                  <Skeleton variant="heading" css={{ width: '50%' }} />
                </Flex>
                <Skeleton variant="title" />
                <Skeleton variant="heading" />
              </Flex>
            </ReviewContainer>
          </ReviewSpacer>
        ))
      )}
    </PageLayout>
  );
};

export const EmptyStateDescription = () => (
  <>
    <Text css={{ mt: '12px', mb: '12px' }} size={3}>
      Connect your Google My Business to Whippy to send review requests to customers.
      Whippy helps your business get more five star reviews and stops negative feedback
      getting posted publicly.
    </Text>
  </>
);

export const getReviewRatingNumber = (ratingString: string) => {
  let reviewNumber = 0;
  switch (ratingString) {
    case 'FIVE':
      reviewNumber = 5;
      break;
    case 'FOUR':
      reviewNumber = 4;
      break;
    case 'THREE':
      reviewNumber = 3;
      break;
    case 'TWO':
      reviewNumber = 2;
      break;
    case 'ONE':
      reviewNumber = 1;
      break;
    default:
      break;
  }
  return reviewNumber;
};

export const ReviewSpacer = styled(Box, {
  px: 30,
  pt: 20,
});

type ConsentUrlProps = {
  consentUrl: string;
};

export const ReviewConsentField = (props: ConsentUrlProps): JSX.Element => {
  const onClickHandler = () => {
    window.location.href = props.consentUrl;
  };
  return (
    <Button css={{ width: '100% ' }} onClick={onClickHandler}>
      Connect GMB
    </Button>
  );
};
