/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { VStack } from '@/shared/ui';

import { useLocations } from '../context/LocationContext';
import { BusinessHours } from './BusinessHours';
import { BusinessHoursAutoResponses } from './BusinessHoursAutoResponses';
import { LinkTracking } from './LinkTracking';
import { LocationDetails } from './LocationDetails';
import { LocationSettings } from './LocationSettings';
import { PhoneNumberDetails } from './PhoneNumberDetails';

type ParamTypes = {
  id: string;
};

export const Location = () => {
  usePageView();

  const { locationsState, getUserLocations, setCurrent } = useLocations();
  const { locations, current } = locationsState;

  const auth = useAuth();

  const history = useHistory();
  const locationParams = useParams<ParamTypes>();

  useEffect(() => {
    if (!current && auth?.tokens?.user_id) {
      getUserLocations({
        user_id: auth?.tokens?.user_id,
      });
    }

    if (locations.length > 0) {
      const foundLocation = locations.find((l) => l.id === locationParams.id);

      if (foundLocation) {
        setCurrent(foundLocation);
      } else {
        history.push('/settings/locations');
      }
    }
  }, [locations, auth?.tokens?.user_id]);

  const { allowAddCustomChannelKeys } = useFlags();

  return (
    <>
      <Helmet>
        <title>Whippy | Channel</title>
      </Helmet>
      <SettingsLayout
        breadcrumbs={[
          { title: 'Settings', path: '/settings/locations' },
          { title: 'Locations', path: `/settings/locations` },
          { title: current?.name ?? '', path: `/settings/locations` },
        ]}
      >
        {current && current?.id && (
          <VStack gap={6}>
            <LocationDetails />
            {allowAddCustomChannelKeys && <PhoneNumberDetails />}
            <BusinessHours />
            <BusinessHoursAutoResponses />
            <LocationSettings />
            <LinkTracking />
          </VStack>
        )}
      </SettingsLayout>
    </>
  );
};
