import dayjs from 'dayjs';
import React from 'react';
import { DatePicker } from 'react-dayjs-picker';
import { useMedia } from 'react-use';

import { convertTime24to12 } from '@/campaigns/context/utils';
import { TimePicker } from '@/shared/components/timepicker/TimePicker';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { Location } from '@/shared/types/locations';
import {
  Box,
  Flex,
  HStack,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio as Radio,
  StyledRadioIndicator,
  VStack,
} from '@/shared/ui';

type SetScheduleProps = {
  /** show timezone picker */
  show: boolean;
  /** location object */
  location: Location;
  /** on radio click callback */
  onRadioClick: (value: string) => void;
  /** open date picker */
  open: boolean;
  /** set open date picker */
  setOpen: (open: boolean) => void;
  /** date */
  date: string;
  /** time */
  time: string;
  /** set time in parent */
  setTime: (time: string) => void;
  /** date select callback */
  dateSelect: (e: dayjs.Dayjs) => void;
  /** schedule params object */
  scheduleParams: ScheduleParams;
  /** timezone */
  timezone?: string;
  /** set timezone in parent */
  setTimezone: (timezone: string) => void;
};

export type ScheduleParams = {
  /** day to send message */
  day?: string;
  /** hours to send message */
  hour?: string;
  /** minutes to send message */
  minute?: string;
  /** month */
  month?: string;
  /** timezone to send message */
  timezone?: string;
  /** year */
  year?: string;
};

export const SetSchedule = (props: SetScheduleProps) => {
  const {
    show,
    location,
    onRadioClick,
    open,
    setOpen,
    date,
    time,
    setTime,
    dateSelect,
    scheduleParams,
    timezone,
    setTimezone,
  } = props;

  // Determine if the viewport is wide enough to display a dialog
  const isMobile = useMedia('(max-width: 580px)');

  return (
    <Box css={{ mb: 10 }}>
      <RadioGroup value={show ? 'scheduled' : 'default'} onValueChange={onRadioClick}>
        <VStack gap={2}>
          <StyledRadioIndicator align="center">
            <Radio value="default" css={{ mr: '$2' }}>
              <RadioGroupIndicator />
            </Radio>
            <Label htmlFor="r1" css={{ mb: 0, ml: 10 }}>
              Add Now
            </Label>
          </StyledRadioIndicator>
          <StyledRadioIndicator align="center" justify="between" css={{ width: '100%' }}>
            <Flex align="center">
              <Radio value="scheduled" css={{ mr: '$2' }}>
                <RadioGroupIndicator />
              </Radio>
              <Label htmlFor="r2" css={{ mb: 0, mx: '$2', minWidth: 65 }}>
                Add Later
              </Label>
            </Flex>
            <HStack css={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
              <DatePicker
                isOpen={open}
                setIsOpen={setOpen}
                zIndex={9999999999}
                value={dayjs(date).format('ddd MMM D')}
                markToday={true}
                format="MMM-dd-yyyy"
                disableBeforeToday={true}
                inputStyle={{
                  padding: '2px 3px',
                  border: '1px solid #D7DBDF',
                  borderRadius: '4px',
                  maxWidth: '92px',
                  fontSize: '14px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  pointerEvents: show ? 'all' : 'none',
                  backgroundColor: show ? 'transparent' : '#F8F9FA',
                  color: show ? '#000000' : '#C4CCD0',
                  outlineColor: '#3E54CF',
                }}
                colors={{
                  highlighted: 'hsl(0 0% 90.9%)',
                  disabled: 'hsl(0 0% 78.0%',
                  default: '',
                  active: '',
                }}
                onSelect={dateSelect}
                popoverPositions={['bottom']}
                closeOnSelect={true}
              />
              <TimePicker
                setTime={setTime}
                time={
                  convertTime24to12(
                    `${scheduleParams?.hour || ''}:${scheduleParams?.minute || ''}`
                  ) || time
                }
                currentDate={date}
                timezone={
                  location?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
                }
                disabled={show ? false : true}
              />
              <Box css={{ mt: isMobile ? 5 : 0, ml: isMobile ? 0 : 5 }}>
                <TimezonePicker
                  timezone={timezone || scheduleParams?.timezone || ''}
                  setTimezone={setTimezone}
                  isSmall={true}
                  disabled={show ? false : true}
                />
              </Box>
            </HStack>
          </StyledRadioIndicator>
        </VStack>
      </RadioGroup>
    </Box>
  );
};
