import React, { useState } from 'react';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { Location } from '@/shared/types/locations';
import { Label } from '@/shared/ui';

type Props = {
  handleChangeLocation: (location: Location) => void;
  showTimeZonePicker: boolean;
  selected?: Location | null;
};
export const LocationDropdown = ({
  handleChangeLocation,
  showTimeZonePicker,
  selected: selectedLocation,
}: Props) => {
  const { locationsState } = useLocations();
  const { locations } = locationsState;
  const [timezone, setTimezone] = useState<string>(
    selectedLocation?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || ''
  );

  const getLocationName = (
    currentLocation?: Location | null
  ): string | null | undefined => {
    if (!currentLocation) return null;
    return currentLocation.name;
  };

  return (
    <>
      {locations.length > 0 && <Label>From:</Label>}
      {locations.length > 0 && (
        <SingleSelect
          defaultPlaceholder={getLocationName(selectedLocation) ?? 'Select a location'}
          isDropdown={true}
          selectItem={selectedLocation?.id ?? ''}
          setSelectItem={(locationId: string) => {
            const location = locations.find((location) => location.id === locationId);
            if (location) handleChangeLocation(location);
          }}
          options={locations.map((location) => ({
            type: location.name || location.address || '',
            value: location?.id,
          }))}
        />
      )}
      {!selectedLocation?.timezone && showTimeZonePicker && (
        <>
          <Label>Update Location Timezone:</Label>
          <TimezonePicker timezone={timezone} setTimezone={setTimezone} />
        </>
      )}
    </>
  );
};
