/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import { Link, useRouteMatch } from 'react-router-dom';

import { useAuth } from '@/auth/context/AuthProvider';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import {
  SideNavigationHeading,
  SideNavigationItem,
} from '@/shared/components/navigation/SideNavigationItem';
import { Location } from '@/shared/types/locations';
import { User } from '@/shared/types/users';
import {
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Flex,
  IconButton,
  Text,
  VStack,
} from '@/shared/ui';
import { isDev, isPreview } from '@/shared/utils/config';

import { MatchParams } from '../context/ConversationContext';

export function InboxSideNavigation(): JSX.Element {
  const match = useRouteMatch<MatchParams>('/inbox/:filter/:tab');

  const [openLocations, setLocationsOpen] = useState(true);
  const [openUsers, setUsersOpen] = useState(true);

  const { locationsState, getUserLocations } = useLocations();
  const { locations } = locationsState;

  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated && auth?.tokens && auth?.tokens?.user_id) {
      getUserLocations({ user_id: auth?.tokens?.user_id });
    }
  }, [auth?.organizationId, auth?.tokens?.user_id]);

  const { userState } = useUsers();
  const { users } = userState;

  const filteredUsers = users.filter((user: User) => {
    // in prod hide whippy users from list
    if (!isDev && !isPreview && user.email.includes('whippy')) {
      return false;
    }

    // make sure the user is enabled
    if (user.state !== 'enabled') {
      return false;
    }

    // if the has no locations that match an id in the locations list, remove them
    const userLocations = user.locations || [];
    const userLocationsIds = userLocations.map((location: Location) => location.id);

    const locationIds = locations.map((location: Location) => location.id);

    const hasLocation = userLocationsIds.some((id: string) => locationIds.includes(id));

    return hasLocation;
  });

  return (
    <Box css={{ p: 8, pt: 0, marginTop: '10px' }}>
      <VStack gap={2}>
        {locations.length > 1 && (
          <Collapsible open={openLocations} onOpenChange={setLocationsOpen}>
            <CollapsibleTrigger asChild>
              <Flex align="center">
                <IconButton>
                  {openLocations ? (
                    <HiChevronDown size={19} />
                  ) : (
                    <HiChevronRight size={19} />
                  )}
                </IconButton>
                <SideNavigationHeading
                  align="center"
                  size={1}
                  css={{ cursor: 'pointer', mb: 2, mr: 5 }}
                >
                  Locations
                </SideNavigationHeading>
              </Flex>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <VStack gap={1} css={{ overflow: 'auto' }}>
                {locations
                  .sort((a: Location, b: Location) =>
                    (a?.name || '').localeCompare(b?.name || '')
                  )
                  .map((location: Location) => {
                    return (
                      <LocationItem
                        key={`sidebar-${location.id}`}
                        id={location.id}
                        name={location.name}
                        filter={match?.params.filter || 'all'}
                        tab={match?.params.tab || 'open'}
                      />
                    );
                  })}
              </VStack>
            </CollapsibleContent>
          </Collapsible>
        )}
        {filteredUsers.length > 1 && (
          <Collapsible open={openUsers} onOpenChange={setUsersOpen}>
            <CollapsibleTrigger asChild>
              <Flex align="center">
                <IconButton>
                  {openUsers ? <HiChevronDown size={19} /> : <HiChevronRight size={19} />}
                </IconButton>
                <SideNavigationHeading
                  align="center"
                  size={1}
                  css={{ cursor: 'pointer', mb: 2, mr: 5 }}
                >
                  Users
                </SideNavigationHeading>
              </Flex>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <VStack gap={1} css={{ overflow: 'auto' }}>
                {filteredUsers
                  .sort((a: User, b: User) =>
                    (a?.name || a.email || '').localeCompare(b?.name || b.email || '')
                  )
                  .map((user: User) => {
                    return (
                      <LocationItem
                        key={`sidebar-${user.id}`}
                        id={`${user.id}`}
                        name={user.name || user.email}
                        filter={match?.params.filter || 'all'}
                        tab={match?.params.tab || 'open'}
                      />
                    );
                  })}
              </VStack>
            </CollapsibleContent>
          </Collapsible>
        )}
      </VStack>
    </Box>
  );
}

type LocationItemProps = {
  id: string;
  name?: string;
  filter: string;
  tab: string;
};

const LocationItem = (props: LocationItemProps) => {
  const { id, name, filter, tab } = props;
  return (
    <Link to={`/inbox/${id}/${tab}`}>
      <SideNavigationItem active={filter === id}>
        <Flex direction="column" css={{ overflow: 'hidden' }}>
          <Text variant="sidenavigation">{name}</Text>
        </Flex>
      </SideNavigationItem>
    </Link>
  );
};
