import { FilterItem, FilterType } from '@/shared/types/filter';

import { formatValue } from './formatValue';

export const deepCopyObject = (obj: Record<string, any>) =>
  JSON.parse(JSON.stringify(obj));

export const cleanFilters = (filters: FilterType[] | FilterItem[]) => {
  if (!Array.isArray(filters)) {
    console.error('Invalid input to cleanFilters:', filters);
    return []; // Return an empty array or handle as appropriate
  }

  // Function to check if an object is empty
  const isEmpty = (obj: Record<string, any>) =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

  // Recursive function to clean each filter object
  const clean = (obj: Record<string, any>) => {
    const copy = deepCopyObject(obj); // Create a deep copy of the object

    // Format value and comparison if type is 'text'
    if (copy.type === 'text') {
      const { comparison, value } = formatValue(copy.comparison, copy.value);
      copy.comparison = comparison;
      copy.value = value;
    }

    // Remove 'id' and type key
    delete copy.id;
    delete copy.type;

    // Recursively clean and/or/on arrays if they exist and are not undefined
    ['and', 'or', 'on'].forEach((key) => {
      if (copy[key] && Array.isArray(copy[key])) {
        copy[key] = copy[key]
          .map(clean)
          .filter((item: Record<string, any>) => !isEmpty(item));
        if (copy[key].length === 0) {
          delete copy[key];
        }
      }
    });

    return copy;
  };

  // Apply the cleaning process to each filter in the array
  return filters.map(clean);
};
