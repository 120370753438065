import { CampaignsParams } from '@/campaigns/context/CampaignsContext';
import { Audience } from '@/shared/types/audience';
import { FilterParams } from '@/shared/types/filter';
import { Trigger } from '@/shared/types/triggers';
import customParamsSerializer from '@/shared/utils/filterParams';

import {
  Campaign,
  CampaignContact,
  CampaignExcludeContacts,
} from '../../types/campaigns';
import { api } from '../api';

type ReceivedCampaignsParams = {
  data: {
    campaigns: Campaign[] | [];
    total: number;
  };
};

export const getCampaigns = async (
  params: CampaignsParams,
  controller?: AbortController
) => {
  const options = controller ? { signal: controller.signal } : undefined;
  const { data } = await api.get<ReceivedCampaignsParams>(`/campaigns`, {
    params,
    paramsSerializer: {
      serialize: customParamsSerializer,
    },
    ...options,
  });

  return data;
};

export const getOneCampaign = (id: string): Promise<Campaign> =>
  api.get(`/campaigns/${id}`).then((res) => {
    return res.data.data as Campaign;
  });

export const getCampaignContacts = (
  id: string
): Promise<Array<CampaignContact> | Array<null>> =>
  api.get(`/campaigns/${id}/campaigns_contacts`).then((res) => {
    return res.data.data as Array<CampaignContact> | Array<null>;
  });

export const getCampaignContactsByType = (
  id: string,
  type: string,
  offset: number,
  limit: number | null
) =>
  api
    .post(`/campaigns/${id}/campaigns_contacts`, {
      type,
      offset,
      limit,
      search_value: '',
    })
    .then((res) => {
      return res.data.data;
    });

export const getCampaignContactsV2 = (id: string, params: any, signal: any) =>
  api
    .post(`/v2/campaigns/${id}/contacts/search`, { ...params }, { signal })
    .then((res) => {
      return res.data;
    });

export const searchCampaignContactsByType = (id: string, type: string, name: string) =>
  api
    .post(`/campaigns/${id}/campaigns_contacts`, {
      type,
      search_value: name,
      offset: null,
      limit: null,
    })
    .then((res) => {
      return res.data.data;
    });

export const createCampaign = (campaign: Campaign) =>
  api.post('/campaigns', { campaign }).then((res) => {
    return res.data.data;
  });

export const createCampaignV2 = async (campaign: Campaign) => {
  return api.post('/v2/campaigns', { campaign }).then((res) => {
    return res.data.data;
  });
};

export const updateCampaign = (campaignId: string, params: Campaign) =>
  api.put(`/campaigns/${campaignId}`, { campaign: params }).then((res) => {
    return res.data.data;
  });

export const deleteCampaign = (id: string) =>
  api.delete(`/campaigns/${id}`).then((res) => {
    return res.data.data;
  });

type SendCampaignPreviewParams = {
  /* to phone number */
  to: string;
  /* from phone number */
  from: string;
  /* message body */
  body: string;
  /* attachment urls */
  attachment_urls: Array<string>;
};

export const sendCampaignPreview = (preview: SendCampaignPreviewParams) =>
  api.post('/campaigns/preview', preview).then((res) => {
    return res.data.data;
  });

export const getCampaignMessageCount = (
  includeAudience: Audience,
  excludeAudience: CampaignExcludeContacts
) =>
  api
    .post(`/campaigns/messages_count`, {
      audience: includeAudience,
      excluded_contacts: excludeAudience,
    })
    .then((res) => {
      return res.data.data;
    });

export const duplicateCampaign = (id: string, include?: boolean): Promise<Campaign> =>
  api
    .post(`campaigns/${id}/duplicate`, { include_audience: include || false })
    .then((res) => {
      return res.data.data as Campaign;
    });

export const selectAllContacts = (id: string) =>
  api.post(`campaigns/${id}/duplicate`).then((res) => {
    return res.data.data;
  });

export const getContactCampaigns = (id: string) =>
  api.post(`/campaigns/contact/${id}`).then((res) => {
    return res.data.data as { campaigns: Array<Campaign>; total: number };
  });

export const getAudienceContacts = (
  includeAudience: Audience,
  excludeAudience: CampaignExcludeContacts
) =>
  api
    .post(`/campaigns/audience`, {
      audience: includeAudience,
      excluded_contacts: excludeAudience,
    })
    .then((res) => {
      return res.data.data;
    });

export const createCampaignTrigger = (campaign_id: string, trigger_param: Trigger) =>
  api
    .post(`/campaigns/${campaign_id}/campaign_trigger/trigger`, {
      campaign_id,
      trigger_param,
    })
    .then((res) => {
      return res.data;
    });

export const updateCampaignTrigger = (
  campaign_id: string,
  trigger_id: string,
  trigger_param: Trigger
) =>
  api
    .put(`/campaigns/${campaign_id}/campaign_trigger/trigger/${trigger_id}`, {
      trigger_param,
    })
    .then((res) => {
      return res.data.data;
    });

export const deleteCampaignTrigger = (campaign_id: string, trigger_id: string) =>
  api
    .delete(`/campaigns/${campaign_id}/campaign_trigger/trigger/${trigger_id}`)
    .then((res) => {
      return res.data.data;
    });

type UnsubscribeCampaignBulkActionSelection =
  | { contact_selection: FilterParams }
  | { campaign_contact_selection: FilterParams };

type UnsubscribeCampaignBulkActionParams = {
  bulk_action: 'campaigns.unsubscribe.contacts';
  resource: { ids: string[] };
  selection: UnsubscribeCampaignBulkActionSelection;
  args?: {
    channel_ids?: string[];
    campaign_id?: string;
  };
};

export const campaignBulkActions = async ({
  action,
  campaignId,
  filter,
  locationIds,
  sourceCampaignId,
}: {
  action: 'campaigns.unsubscribe.contacts';
  campaignId: string;
  filter: FilterParams;
  locationIds?: string[];
  sourceCampaignId?: string;
}) => {
  let selection: UnsubscribeCampaignBulkActionSelection = {
    contact_selection: { ...filter },
  };

  // if we provide the a source campaign, then use campaign contact selection
  if (sourceCampaignId) {
    selection = { campaign_contact_selection: { ...filter } };
  }

  const params: UnsubscribeCampaignBulkActionParams = {
    bulk_action: action,
    resource: { ids: [campaignId] },
    selection: selection,
  };

  if (sourceCampaignId && locationIds && locationIds.length > 0) {
    params.args = {
      channel_ids: locationIds,
      campaign_id: sourceCampaignId,
    };
  } else if (sourceCampaignId) {
    params.args = {
      campaign_id: sourceCampaignId,
    };
  }

  const response = await api.post('/v2/bulk_actions', params);

  return response.data.data;
};
