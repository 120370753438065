import dayjs from 'dayjs';
import { useState } from 'react';
import { HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { useSequences } from '@/pages/sequences/context/SequenceContext';
import {
  bulkActionAddContactToSequence,
  CampaignSourceTuple,
  SequenceSourceTuple,
} from '@/shared/api/sequences';
import {
  convertTimeString,
  getHours,
} from '@/shared/components/timepicker/utils/getHours';
import { ScheduleOptions } from '@/shared/types/campaigns';
import { Location } from '@/shared/types/locations';
import {
  Sequence,
  SequenceBulkActionFilter,
  SequenceStatus,
} from '@/shared/types/sequences';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  HStack,
  Label,
  VStack,
} from '@/shared/ui';

import {
  RadioGroupOptions,
  ScheduleOptionsRadioGroup,
} from '../../forms/scheduleOptionsRadioGroup/ScheduleOptionsRadioGroup';
import { SingleSelect } from '../../SingleSelect';

type Props = {
  defaultLocation?: Location;
  locations: Location[];
  sequence?: Sequence;
  showTimeZonePicker: boolean;
  /* the boolean value controlling whether or not the dialog is open */
  isOpen: boolean;
  /* the function to control whether or not the dialog is open */
  setIsOpen: (isOpen: boolean) => void;
  /*
   * Additionally us to clarify which type of filter this is. If we want this filter to apply to a campaign_contact_selection
   * we should specify the source campaign id. Likewise, if we want the filter to correspond to step_contact_selection, then
   * we should provide the sequence source tuple.
   * */
  filterSource?: CampaignSourceTuple | SequenceSourceTuple;
  filter: SequenceBulkActionFilter;
  /*
   * The total number of contacts that will be messaged. This could be determined by
   * either the bulk actions sending us this information.
   */
  totalContacts: number;
  /*
    Once the user submits the message we append the input they gave in the location and schedule options
    A selectedOption of null means that it is an immediate message
  */
};
export const AddToSequenceDialog = ({
  isOpen,
  setIsOpen,
  sequence,
  defaultLocation,
  filter,
  filterSource,
  locations,
  totalContacts,
}: Props): JSX.Element => {
  const [sequenceType, setSequenceType] = useState<RadioGroupOptions>('default');
  const [selectedSequenceId, setSelectedSequenceId] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const sequenceContext = useSequences();
  const { sequencesState } = sequenceContext;
  const { sequences } = sequencesState;

  const [scheduleTime, setScheduleTime] = useState<ScheduleOptions>(
    initializeScheduleTime(defaultLocation, new Date())
  );
  const resetModalState = (): void => {
    setIsOpen(false);
    setSelectedLocation(null);
    setSelectedSequenceId('');
    initializeScheduleTime(defaultLocation, new Date());
    setSequenceType('default');
  };

  const handleChangeLocation = (selectedLocation: Location | null) => {
    setSelectedLocation(selectedLocation);
  };
  function handleConfirmAddToSequence(
    sequenceId: string,
    location: Location,
    filter: SequenceBulkActionFilter,
    scheduleOptions: ScheduleOptions | null,
    filterSource?: CampaignSourceTuple | SequenceSourceTuple
  ) {
    toast.info('Adding contacts to sequence...');
    bulkActionAddContactToSequence(
      sequenceId,
      location.id,
      filter,
      scheduleOptions,
      filterSource
    )
      // eslint-disable-next-line
      .then((_data) => {
        toast.success('Adding contacts to sequence in progress');
      })
      // eslint-disable-next-line
      .catch((_e) => toast.error('Failed to add contacts to sequence'));
    setIsOpen(false);
    resetModalState();
  }

  return (
    <Dialog open={isOpen} modal={false}>
      <DialogTrigger asChild onClick={resetModalState}></DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent
            css={{ width: 700 }}
            onPointerDownOutside={resetModalState}
            onEscapeKeyDown={resetModalState}
          >
            <DialogHeader title="Add Contacts to Sequence" />
            <form
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onSubmit={(_e) => {
                // send null if the sequence type is not scheduled
                const scheduleOptions = sequenceType == 'default' ? null : scheduleTime;
                if (
                  sequenceType == 'scheduled' &&
                  scheduleOptions != null &&
                  isScheduledTimeInPast(scheduleOptions, new Date())
                ) {
                  toast.error('Cannot schedule campaigns in the past.');
                  return;
                }
                if (selectedLocation == null) {
                  toast.error('Did not properly select a location');
                  return;
                }
                handleConfirmAddToSequence(
                  sequence?.id ?? selectedSequenceId,
                  selectedLocation as Location,
                  filter,
                  scheduleOptions,
                  filterSource
                );
              }}
              data-testid="quick-add-contacts-to-sequence-form"
            >
              <VStack gap={2}>
                {!sequence && (
                  <>
                    <Label>Select Sequence</Label>
                    <SingleSelect
                      selectItem={selectedSequenceId}
                      setSelectItem={setSelectedSequenceId}
                      options={getSequenceOptions(sequences as Sequence[])}
                      defaultPlaceholder={
                        getSequenceById(sequences, selectedSequenceId)?.title ||
                        'Select a Sequence'
                      }
                      closeOnClick={true}
                      isDropdown={true}
                    />
                  </>
                )}
                <Label>From:</Label>
                <SingleSelect
                  selectItem={selectedLocation?.id ?? ''}
                  setSelectItem={(locationId: string) => {
                    const location = locations.find(
                      (location) => location.id === locationId
                    );
                    if (location) {
                      handleChangeLocation(location);
                    } else {
                      handleChangeLocation(null);
                    }
                  }}
                  options={locations.map((location) => ({
                    type: `${location.name ?? location.address} (${location.phone})`,
                    value: location?.id,
                  }))}
                  defaultPlaceholder={
                    getLocationName(selectedLocation) ?? 'Select a location'
                  }
                  closeOnClick={true}
                  isDropdown={true}
                />
                <Label>Schedule:</Label>
                <ScheduleOptionsRadioGroup
                  initialState={sequenceType}
                  labels={['Add Immediately', 'Schedule for Later']}
                  onRadioClick={(sequenceType: string) => {
                    setSequenceType(sequenceType as RadioGroupOptions);
                  }}
                  scheduleOptions={scheduleTime}
                  onDateTimeUpdated={(dateTime: ScheduleOptions) => {
                    setScheduleTime(dateTime);
                  }}
                />
              </VStack>
              <DialogClose>
                <DialogCloseIcon onClick={resetModalState} size="2">
                  <Box css={{ minWidth: 16 }} onClick={resetModalState}>
                    <HiX
                      size="15px"
                      style={{
                        color: '#ffffff',
                      }}
                    />
                  </Box>
                </DialogCloseIcon>
              </DialogClose>
              <DialogFooter justify="between" css={{ mb: 12, mt: 0 }}>
                <HStack gap={1} css={{ width: '100%', justifyContent: 'space-between' }}>
                  <Label css={{ mb: 0 }}>{`Total Contacts: ${totalContacts}`}</Label>
                  <HStack>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={resetModalState}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        type="submit"
                        disabled={
                          !isValidSubmission(
                            sequence?.id ?? selectedSequenceId,
                            selectedLocation,
                            locations
                          )
                        }
                      >
                        {sequenceType == 'scheduled'
                          ? 'Schedule Sequence'
                          : 'Add Contacts'}
                      </Button>
                    </DialogClose>
                  </HStack>
                </HStack>
              </DialogFooter>
            </form>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

function initializeScheduleTime(location: Location | undefined, currentDate: Date) {
  const timezone = location?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  const firstTimeInFuture = getHours(startDate, timezone)[0];
  const time = convertTimeString(firstTimeInFuture);
  return {
    year: currentDate.getFullYear().toString(),
    // date is zero indexed and schedule options is not
    // so we need to modify the date before passing it as a scheduleOption
    month: (currentDate.getMonth() + 1).toString(),
    day: currentDate.getDate().toString(),
    hour: time.hour.toString(),
    minute: time.minute.toString(),
    timezone: timezone,
  };
}
function validLocation(currentLocation: Location, locations: Location[]) {
  return locations.some((location) => location.id == currentLocation.id);
}

function isScheduledTimeInPast(scheduleTime: ScheduleOptions, now: Date): boolean {
  const { year, month, day, hour, minute } = scheduleTime;
  const dateToCheck = new Date(
    parseInt(year),
    // date is zero indexed and schedule options is not
    // so we need to modify the date before passing it as a scheduleOption
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hour),
    parseInt(minute)
  );

  return dayjs(dateToCheck).isBefore(dayjs(now));
}

const getSequenceById = (sequences: Sequence[] | null[], id: string) => {
  // make sure the sequence is not a null array or undefined
  if (sequences && sequences.length > 0) {
    const sequence = (sequences as Sequence[]).find((sequence) => sequence.id === id);
    return sequence;
  } else {
    return null;
  }
};

function isValidSubmission(
  selectedSequenceId: string,
  selectedLocation: Location | null,
  locations: Location[]
): boolean {
  return (
    selectedLocation != null &&
    selectedSequenceId != null &&
    selectedSequenceId != '' &&
    validLocation(selectedLocation, locations)
  );
}

function getSequenceOptions(sequences: Sequence[]): { type: string; value: string }[] {
  return sequences
    .filter((sequence: Sequence) => sequence.status === SequenceStatus.ACTIVE)
    .map((sequence: Sequence) => ({
      type: sequence?.title || '',
      value: sequence?.id || '',
    }));
}
function getLocationName(currentLocation?: Location | null): string | null | undefined {
  if (!currentLocation) return null;
  return currentLocation.name;
}
