import { memo } from 'react';
import { HiEye } from 'react-icons/hi';

import {
  Toolbar,
  ToolbarButton,
} from '@/pages/inbox/conversation/panels/ConversationToolbar';
import { PanelType } from '@/shared/types/links';
import { VStack } from '@/shared/ui';

type LinkToolbarProps = {
  /* handle link side panel toolbar click */
  handlePanelClick: (panel: PanelType) => void;
};

const LinkToolbar = (props: LinkToolbarProps) => {
  return (
    <Toolbar>
      <VStack gap={4}>
        <ToolbarButton
          description="View Preview"
          onClick={() => props.handlePanelClick(PanelType.VIEW_PREVIEW)}
          testID="show-preview-button"
        >
          <HiEye size={18} />
        </ToolbarButton>
      </VStack>
    </Toolbar>
  );
};

export default memo(LinkToolbar);
