import { useEffect, useRef, useState } from 'react';

import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { Signature } from '@/shared/types';
import { Location } from '@/shared/types/locations';

type Props = {
  message: string;
  attachments: Attachments;
  onInputUpdated: (
    message: string,
    attachments: Attachments,
    isAttachmentLoading: boolean
  ) => void;
  preSelectedAttachments: never[];
  isCampaignsEditPage: any;
  showSchedule: any;
  location: Location | null;
  // the list of the users locations
  locations: Location[];
};

export function MessageEditor(props: Props): JSX.Element {
  // message text
  const [message, setMessage] = useState<string>(props.message);

  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: [],
  });

  // attachment loading
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  // Reference to the textarea HTML element
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // State for the signature
  const [signature, setSignature] = useState<Signature | null>(null);

  // Used to add signature to message when selected
  // As long as the signature has not already been added
  useEffect(() => {
    if (signature && !message.includes(signature.body)) {
      setMessage(`${message}\n${signature.body}`);
      setSignature(null);
    }
  }, [message, signature]);

  // since the MessageEditorV2 expects React.Dispatch, we listen on those
  // variable changes using useEffect
  useEffect(() => {
    props.onInputUpdated(message, attachments, attachmentLoading);
  }, [message, attachments, attachmentLoading]);

  return (
    <MessageEditorV2
      message={message}
      setMessage={setMessage}
      attachments={attachments}
      setAttachments={setAttachments}
      enableAttachments={true}
      attachmentLoading={attachmentLoading}
      setAttachmentLoading={setAttachmentLoading}
      textareaRef={textareaRef}
      signature={signature}
      setSignature={setSignature}
      sendMessageFromTranslationDialog={false}
      showAddAttachment={true}
      showAddTemplate={true}
      showAddVariable={true}
      showAddEmoji={true}
      showCharacterCount={true}
      showAddTranslate={true}
      showAddSignature={true}
      showSendButton={false}
      isForm={true}
      sendButtonCopy={
        props.isCampaignsEditPage
          ? props.showSchedule
            ? 'Save and Schedule'
            : 'Save and Send'
          : props.showSchedule
            ? 'Schedule for Later'
            : 'Send'
      }
    />
  );
}
