/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Table } from '@/shared/components/Table';
import { ApiKey, DeveloperApplication } from '@/shared/types/developers';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Td,
  Text,
  Tr,
  VStack,
} from '@/shared/ui';

import { useDeveloperContext } from '../context/DevelopersContext';
import { AddDeveloperApplication } from './AddApplication';
import RenameConfirmationDialog from './RenameConfirmationDialog';

export const WebhookEndpointsList = () => {
  const apiKeysContext = useDeveloperContext();
  const { developerState, getDeveloperApplications, updateDeveloperApplication } =
    apiKeysContext;
  const { loading, developerApplications } = developerState;

  const history = useHistory();

  // state variable for search value
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getDeveloperApplications();
  }, []);

  // Filter api keys based on search value and reverse the array to show latest first
  const filteredApplications = developerApplications
    .filter((app) => {
      return app?.name?.toLowerCase().includes(searchValue.toLowerCase());
    })
    .reverse(); // need to reverse the array to show latest first

  // offset is the number of keywords to skip
  const [offset, setOffset] = useState(0);

  const data = useMemo(
    () => (loading ? Array(5).fill({}) : filteredApplications),
    [loading, filteredApplications]
  );

  const handleDisableConfirm = async (application: DeveloperApplication | null) => {
    if (application?.active) {
      try {
        application && updateDeveloperApplication({ ...application, active: false });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        application && updateDeveloperApplication({ ...application, active: true });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const columns = useMemo(
    () =>
      loading
        ? [
            // loading state UI
            // Name : API Key : Status : Edit = 30% : 50% : 15% : 5%
            {
              Header: 'Name',
              colWidth: '30%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', m: 0 }} />
                </>
              ),
            },
            {
              Header: 'Status',
              colWidth: '50%',
              disableSortBy: true,
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', m: 0 }} />
                </>
              ),
            },
            {
              Header: 'Edit',
              colWidth: '5%',
              disableSortBy: true,
              id: 'expander',
              Cell: <Skeleton variant="icon" />,
            },
          ]
        : [
            {
              Header: 'Name',
              colWidth: '30%',
              Cell: (props: { row: { original: ApiKey } }) => (
                <HStack gap="2">
                  <Text>{props.row.original?.name || 'No Application Name'}</Text>
                </HStack>
              ),
            },
            {
              Header: 'Status',
              colWidth: '50%',
              disableSortBy: true,
              id: 'status',
              Cell: (props: { row: { original: ApiKey } }) => (
                <HStack gap="2">
                  <Text>{props.row.original.active ? 'Active' : 'Disabled'}</Text>
                </HStack>
              ),
            },
            {
              Header: 'Edit',
              colWidth: '5%',
              disableSortBy: true,
              id: 'expander',
              Cell: (props: { row: { original: DeveloperApplication } }) => (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild style={{ alignContent: 'end' }}>
                    <IconButton aria-label="open dropdown" variant="outline" size="2">
                      <HiDotsHorizontal size="15px" />
                    </IconButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent sideOffset={10}>
                    <DropdownMenuItem
                      onSelect={(e) => {
                        e.preventDefault();
                        history.push(`/developer/webhooks/${props.row.original.id}`);
                      }}
                      css={{ zIndex: 1 }}
                      disabled={!props.row.original.active}
                    >
                      Edit Endpoint
                    </DropdownMenuItem>
                    <RenameConfirmationDialog application={props.row.original}>
                      <DropdownMenuItem
                        onSelect={(e) => e.preventDefault()}
                        css={{ zIndex: 1 }}
                      >
                        Rename Application
                      </DropdownMenuItem>
                    </RenameConfirmationDialog>
                    <ConfirmationDialog
                      title={`${
                        props.row.original?.active ? 'Disable' : 'Enable'
                      } API Application`}
                      description={`Are you sure you want to ${
                        props.row.original?.active ? 'disable' : 'enable'
                      } this API
                      Key?`}
                      onConfirm={() => handleDisableConfirm(props.row.original)}
                      confirmButtonTitle="Confirm"
                      testID="delete-application-button"
                    >
                      <DropdownMenuItem
                        onSelect={(e) => e.preventDefault()}
                        css={{ zIndex: 1 }}
                      >
                        {props.row.original.active
                          ? 'Disable Application'
                          : 'Enable Application'}
                      </DropdownMenuItem>
                    </ConfirmationDialog>
                  </DropdownMenuContent>
                </DropdownMenu>
              ),
            },
          ],
    [loading, filteredApplications]
  );

  return (
    <>
      <Box css={{ p: 30 }}>
        <Table
          data={data}
          columns={columns}
          sortable={true}
          caption="API Keys Table"
          searchFilter={true}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          limit={50}
          offset={offset}
          setOffset={setOffset}
          totalCount={filteredApplications.length}
          empty={
            <Tr>
              <Td
                colSpan={columns.length}
                css={{ textAlign: 'center', backgroundColor: 'white' }}
              >
                <Flex css={{ width: '100%' }} direction="column">
                  <VStack gap={3}>
                    <Box>Create your first Webhook Endpoint.</Box>
                    <Box>
                      <AddDeveloperApplication />
                    </Box>
                  </VStack>
                </Flex>
              </Td>
            </Tr>
          }
        />
      </Box>
    </>
  );
};
