import React from 'react';

import { Location, LocationActions, LocationActionTypes } from '@/shared/types/locations';

import { LocationsState } from './LocationContext';

export const LocationReducer: React.Reducer<LocationsState, LocationActions> = (
  state: LocationsState,
  action: LocationActions
) => {
  switch (action.type) {
    case LocationActionTypes.GET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
        loading: false,
      } as LocationsState;
    case LocationActionTypes.GET_ALL_LOCATIONS:
      return {
        ...state,
        allLocations: action.payload,
        loading: false,
      } as LocationsState;
    case LocationActionTypes.ADD_LOCATION:
      return {
        ...state,
        locations: [action.payload, ...state.locations],
        allLocations: [action.payload, ...state.allLocations],
        loading: false,
      } as LocationsState;
    case LocationActionTypes.UPDATE_LOCATION:
      return {
        ...state,
        locations: state.locations.map((location: Location) =>
          location.id === action.payload.id ? action.payload : location
        ),
        allLocations: state.allLocations.map((location) =>
          location.id === action.payload.id ? action.payload : location
        ),
        loading: false,
      } as LocationsState;
    case LocationActionTypes.DISABLE_LOCATION:
      return {
        ...state,
        locations: state.locations.map((location: Location) =>
          location.id === action.payload.id ? action.payload : location
        ),
        allLocations: state.allLocations.map((location) =>
          location.id === action.payload.id ? action.payload : location
        ),
        loading: false,
      } as LocationsState;
    case LocationActionTypes.ARCHIVE_LOCATION:
      return {
        ...state,
        locations: state.locations.filter(
          (location: Location) => location.id !== action.payload.id
        ),
        allLocations: state.allLocations.filter(
          (location: Location) => location.id !== action.payload.id
        ),
        loading: false,
      } as LocationsState;
    case LocationActionTypes.CLEAR_LOCATIONS:
      return {
        ...state,
        locations: [],
        allLocations: [],
        filtered: null,
        error: null,
        current: null,
      } as LocationsState;
    case LocationActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      } as LocationsState;
    case LocationActionTypes.CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      } as LocationsState;
    default:
      return state;
  }
};

export default LocationReducer;
