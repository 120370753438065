import React, { memo, useCallback } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Team } from '@/shared/types/team';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
} from '@/shared/ui';

import { useTeams } from './context/TeamsContext';

type TeamNavigationButtonProps = {
  team: Team;
};

export const TeamNavigationButton = memo(({ team }: TeamNavigationButtonProps) => {
  const { deleteTeam } = useTeams();
  const history = useHistory();
  const isTeamPage = useRouteMatch('/settings/teams/:id');

  const onEditTeam = useCallback(() => {
    history.push(`/settings/teams/${team.id}`);
  }, [team]);

  const handleConfirm = useCallback(() => {
    team?.id && deleteTeam(team?.id);
    isTeamPage && history.push(`/settings/teams`);
  }, [deleteTeam, team?.id]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild data-testid="team-dropdown-trigger">
        <IconButton aria-label="open team dropdown" variant="outline" size="2">
          <HiDotsHorizontal size="15px" />
        </IconButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={10}>
        <DropdownMenuItem onClick={onEditTeam}>Edit Team</DropdownMenuItem>
        <ConfirmationDialog
          title="Delete Team"
          description="Are you sure want to delete your team? This action cannot be undone."
          confirmButtonTitle="Yes, Delete Team"
          onConfirm={handleConfirm}
        >
          <DropdownMenuItem onClick={(e) => e.preventDefault()}>
            Delete Team
          </DropdownMenuItem>
        </ConfirmationDialog>
      </DropdownMenuContent>
    </DropdownMenu>
  );
});
