import React from 'react';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Location } from '@/shared/types/locations';
import { Box } from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';

type SelectLocationProps = {
  /** the location the campaign is scheduled */
  location: string;
  /** set the location the campaign is scheduled */
  setLocation: (location: string) => void;
  /** is the select disabled? */
  disabled?: boolean;
};

export const SelectLocation = (props: SelectLocationProps): JSX.Element => {
  const { location, setLocation, disabled } = props;

  const locations = useLocations();
  const { locationsState } = locations;

  const getLocationName = (id: string) =>
    locationsState.locations.find((location) => location.id === id)?.name;

  const generateLocationName = (location: Location) => {
    switch (location.type) {
      case 'phone':
        return `SMS: ${location.name} - ${formatPhoneNumber(location.phone)}`;
      case 'email':
        return `Email: ${location.name}`;
      case 'whatsapp':
        return `WhatsApp: ${location.name}`;
      default:
        return `SMS: ${location.name} - ${formatPhoneNumber(location.phone)}`;
    }
  };

  return (
    <Box>
      {locationsState.locations.length > 0 && (
        <SingleSelect
          disabled={disabled ? true : false}
          selectItem={location}
          setSelectItem={setLocation}
          closeOnClick={true}
          options={locationsState.locations.map((location: Location) => ({
            type: generateLocationName(location),
            value: location?.id,
          }))}
          defaultPlaceholder={getLocationName(location || '') || 'Select Channel'}
          isDropdown={true}
        />
      )}
    </Box>
  );
};
