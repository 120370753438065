import { UploadsAction, UploadsActionTypes } from '@/shared/types/contacts/uploads';
import { Tag } from '@/shared/types/tags';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

import { UploadsState } from './UploadContext';

const UploadsReducer = (state: UploadsState, action: UploadsAction) => {
  switch (action.type) {
    case UploadsActionTypes.GET_UPLOADS:
      return {
        ...state,
        uploads: Array.isArray(action.payload)
          ? action.payload
          : removeDuplicates([...state.uploads, ...action.payload.uploads]),
        totalCount: Array.isArray(action.payload)
          ? action.payload.length
          : action.payload.total
            ? action.payload.total
            : state.totalCount,
        loading: false,
      };
    case UploadsActionTypes.GET_RECENT_UPLOADS:
      return {
        ...state,
        recentUploads: Array.isArray(action.payload)
          ? action.payload
          : removeDuplicates(action.payload.uploads),
      };
    case UploadsActionTypes.GET_UPLOAD:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case UploadsActionTypes.SEARCH_UPLOAD:
      return {
        ...state,
        searchedUploads: action.payload,
        loading: false,
      };
    case UploadsActionTypes.UPLOAD_CONTACTS:
      return {
        ...state,
        uploads: [action.payload, ...state.uploads],
        recentUploads: [action.payload, ...state.recentUploads],
        totalCount: state.totalCount + 1,
      };
    case UploadsActionTypes.EDIT_UPLOAD:
      return {
        ...state,
        uploads: state.uploads.map((upload: Tag) => {
          return upload.id === action.payload.id ? action.payload : upload;
        }),
        recentUploads: state.recentUploads.map((upload: Tag) => {
          return upload.id === action.payload.id ? action.payload : upload;
        }),
        current: action.payload,
      };
    case UploadsActionTypes.DELETE_UPLOAD:
      return {
        ...state,
        uploads: state.uploads.filter((upload: Tag) => {
          return upload.id !== action.payload;
        }),
        recentUploads: state.recentUploads.filter((upload: Tag) => {
          return upload.id !== action.payload;
        }),
        current: null,
        totalCount: state.totalCount - 1,
      };
    case UploadsActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case UploadsActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case UploadsActionTypes.GET_UPLOADS_V2:
      return {
        ...state,
        uploads: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
      };
    case UploadsActionTypes.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default UploadsReducer;
