import { OpeningHour } from '@/shared/types/locations/hours';
import { WorkSchedule } from '@/shared/types/team/schedule';

// Transform the type of work schedule to opening hour
export const transformOpeningHours = (openingHour: WorkSchedule) => {
  return {
    id: openingHour?.id || '',
    opens_at: openingHour.starts_at,
    closes_at: openingHour.ends_at,
    weekday: openingHour.weekday,
    state: openingHour.state ? openingHour.state : 'open',
  } as OpeningHour;
};

// Format the time to HH:mm like 09:00
export const formatHour = (time: string) => {
  if (!time || !time.includes(':')) {
    throw new Error('Invalid time format');
  }
  const hour = time?.split(':')[0];
  return hour.length === 1 ? `0${time}` : time;
};
