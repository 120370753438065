import Fuse from 'fuse.js';
import { match } from 'react-router-dom';

import {
  ConversationFilterTypes,
  ConversationItemSourceTypes,
  ConversationItemType,
  ConversationMessageType,
  ConversationStatusTypes,
  ConversationType,
  WhippyQueryLanguageBase,
} from '@/shared/types/conversations';
import { isValidUuid } from '@/shared/utils/validations/validations';

// filter conversations based on route filter and
// optionally filter by user id
export function returnFilteredConversations(
  conversations: Array<ConversationType>,
  filter: string,
  user_ids?: Array<number>,
  search?: string
): Array<ConversationType> {
  const filteredConversations = search
    ? filterByContactValue(conversations, search)
    : conversations;

  if (filter === ConversationFilterTypes.ALL) {
    return filteredConversations;
  } else if (filter === ConversationFilterTypes.ME) {
    // get the conversation where assigned user id is in the user_ids array
    return filteredConversations.filter((conversation: ConversationType) => {
      if (conversation.assigned_user_id) {
        return user_ids?.includes(Number(conversation?.assigned_user_id));
      }
    });
  } else if (filter === ConversationFilterTypes.UNASSIGNED) {
    const unassigned = filteredConversations.filter(
      (conversation: ConversationType) => conversation.assigned_user_id === null
    );
    return unassigned;
  } else if (filter === ConversationFilterTypes.GHOSTED) {
    // Only show the conversations where the last message is INBOUND on the conversationItemsPage.conversationItems type
    const ghosted = filteredConversations.filter((conversation: ConversationType) => {
      const conversation_items = conversation.conversationItemsPage.conversationItems;
      const filteredMessages = conversation_items.filter(
        (m: ConversationItemType) => m !== undefined && !('event' in m)
      );

      // sort the filteredMessages by inserted_at so the most recent message is first
      filteredMessages.sort((a, b) => {
        return new Date(b.inserted_at).getTime() - new Date(a.inserted_at).getTime();
      });

      // get the most recent message
      const lastMessage = filteredMessages[0] as ConversationMessageType;
      return lastMessage?.source_type === ConversationItemSourceTypes.INBOUND;
    });
    return ghosted;
  } else if (isValidUuid(filter)) {
    const location = filteredConversations.filter(
      (conversation: ConversationType) => conversation.location_id === filter
    );
    return location;
  } else if (!isNaN(Number(filter))) {
    const assignedUser = filteredConversations.filter(
      (conversation: ConversationType) => conversation.assigned_user_id === Number(filter)
    );
    return assignedUser;
  } else {
    return filteredConversations;
  }
}

function filterByContactValue(conversations: Array<ConversationType>, search: string) {
  const sanitize = search
    .replace('(', '')
    .replace(')', '')
    .replace('+', '')
    .replace(' ', '')
    .replace('-', '');

  const fuse = new Fuse(conversations, {
    keys: ['contact.name', 'contact.phone'],
  });

  const results = fuse.search(sanitize.toLocaleLowerCase()).map((r) => r.item);

  return results;
}

export type QueryParams = 'limit' | 'offset' | 'status';
export type QueryMap = Record<QueryParams, string>;

export const SEARCH_CONVERSATIONS_LIMIT = 30;

export type MatchParams = {
  filter: string;
};

export const TABS = [
  { name: ConversationStatusTypes.OPEN, value: ConversationStatusTypes.OPEN },
  { name: ConversationStatusTypes.AUTOMATED, value: ConversationStatusTypes.AUTOMATED },
  { name: ConversationStatusTypes.CLOSED, value: ConversationStatusTypes.CLOSED },
];

export type RouteParams = match<MatchParams> | null;

/**
 * Constructs a search clause object for filtering conversations.
 * This function supports filtering by location ID, assigned user ID, or unassigned conversations.
 * It uses a switch-case structure for clear logic flow based on the filter type provided.
 *
 * Used in Search V2 - OpenSearch
 * @param {string} resource - The resource type to filter, e.g., 'conversation'.
 * @param {string} column - The column name in the resource to apply the filter on.
 * @param {string | number} value - The value to match in the specified column.
 * @returns {WhippyQueryLanguageBase} A search clause object compatible with WhippyQueryLanguage.
 */
const buildSearchClause = (
  resource: string,
  column: string,
  value: string | number
): WhippyQueryLanguageBase => {
  return {
    resource,
    column,
    comparison: '==', // hard coded to support equals comparison
    value,
  };
};

/**
 * Generates an array of search clauses based on the user ID and filter type.
 * This function can handle multiple filter types including location ID, assigned user ID,
 * current user, and unassigned conversations. It leverages `buildSearchClause` to construct
 * individual clauses.
 *
 * Used in Search V2 - OpenSearch
 * @param {number | null} userId - The user ID to filter conversations for the current user.
 * @param {string} filter - The filter type or value to apply.
 * @returns {Array<WhippyQueryLanguageBase>} An array of search clauses, empty if no filter matches.
 */
export const generateSearchClauses = (userId: number | null, filter: string) => {
  let clause = null;

  // Use switch-case for clearer logic flow based on filter type
  switch (true) {
    case isValidUuid(filter):
      // Location ID filter
      clause = buildSearchClause('conversation', 'location_id', filter);
      break;
    case parseInt(filter) > 0:
      // Assigned user ID filter
      clause = buildSearchClause('conversation', 'assigned_user_id', parseInt(filter));
      break;
    case filter === ConversationFilterTypes.ME && userId !== null:
      // Current user filter
      clause = buildSearchClause('conversation', 'assigned_user_id', userId);
      break;
    case filter === ConversationFilterTypes.UNASSIGNED:
      // Unassigned conversations filter
      clause = buildSearchClause('conversation', 'assigned_user_id', '');
      break;
  }

  // Return array of clauses, empty if no clause matched
  return clause ? [clause] : [];
};

// Constants defining the length of text to show before and after the highlight
export const PREVIEW_MESSAGE_PRE_LENGTH = 30;
export const PREVIEW_MESSAGE_POST_LENGTH = 120;

/**
 * Truncates the provided text around the first occurrence of the search query.
 * It shows a specified number of characters before and after the query.
 * If the query is not found, it returns the beginning of the text up to postLength.
 *
 * @param {string} text - The full text to truncate.
 * @param {string} query - The search query to highlight in the text.
 * @param {number} preLength - The number of characters to show before the query.
 * @param {number} postLength - The number of characters to show after the query.
 * @returns {string} The truncated text with the query highlighted.
 */
export function truncateTextAroundHighlight(
  text: string,
  query: string,
  preLength: number,
  postLength: number
): string {
  // Find the start index of the query in the text, ignoring case
  const startIndex = text.toLowerCase().indexOf(query.toLowerCase());
  if (startIndex === -1) {
    // If the query is not found, return the beginning of the text up to postLength
    return text.slice(0, postLength) + (text.length > postLength ? '...' : '');
  }
  // Calculate the start and end indices for the substring around the query
  const start = Math.max(startIndex - preLength, 0);
  const end = Math.min(startIndex + query.length + postLength, text.length);
  // Add ellipses at the start/end of the substring if it's cut off from the original text
  const prefix = start > 0 ? '...' : '';
  const suffix = end < text.length ? '...' : '';
  return prefix + text.slice(start, end) + suffix;
}

interface HighlightTextProps {
  wordsToHighlight: string[];
  sentence: string;
  highlightStyle: React.CSSProperties;
}

/**
 * Generates an array of search words and phrases from the given search input.
 * It splits the input into individual words and then generates combinations of those words.
 *
 * Example: "quick brown fox" -> ["quick", "brown", "fox", "quick brown", "brown fox", "quick brown fox"]
 *
 * @param {string} searchInput - The search input string to generate words and phrases from.
 * @returns {string[]} An array of search words and phrases.
 */
export function generateSearchWords(searchInput: string): string[] {
  // Split search input into individual words and filter out empty strings
  const individualWords = searchInput.split(' ').filter((word) => word.trim() !== '');

  // Generate combinations of words to enhance search matching
  const combinedWords = individualWords.reduce<string[]>((acc, _, index) => {
    for (let i = index + 1; i <= individualWords.length; i++) {
      const combined = individualWords.slice(index, i).join(' ');
      if (combined !== '') acc.push(combined);
    }
    return acc;
  }, []);

  // Combine individual words and phrases, and remove duplicates
  return [...new Set([...individualWords, ...combinedWords])];
}

/**
 * React component to highlight specified words or partial words in a sentence.
 * Sorts words by length to prioritize longer matches and uses regex for matching words or partial words.
 * Only words meeting a minimum length defined by sensitivity are highlighted.
 *
 * @param wordsToHighlight Words or partial words to be highlighted.
 * @param sentence Text containing words to highlight.
 * @param highlightStyle CSS properties for highlighted text.
 * @param sensitivity Minimum length of words to be highlighted.
 * @returns JSX element with highlighted words or partial words.
 */
export const HighlightText: React.FC<HighlightTextProps & { sensitivity?: number }> = ({
  wordsToHighlight,
  sentence,
  highlightStyle,
  sensitivity = 2,
}) => {
  // Filter words to highlight based on sensitivity
  const filteredWordsToHighlight = wordsToHighlight.filter(
    (word) => word.length >= sensitivity
  );

  // Prioritize longer matches for highlighting
  const sortedWordsToHighlight = filteredWordsToHighlight.sort(
    (a, b) => b.length - a.length
  );

  // Prepare regex pattern for matching words or partial words, ignoring case
  const escapeRegex = (str: string): string =>
    str.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

  // Removed word boundary markers to allow partial matches
  const pattern: string = sortedWordsToHighlight
    .map((word) => `${escapeRegex(word)}`)
    .join('|');
  const regex = new RegExp(pattern, 'gi');

  // Define the type for parts
  type Part = { text: string; highlight: boolean };

  // Split sentence into highlighted and non-highlighted parts
  const parts: Part[] = [];
  let lastIndex = 0;
  sentence.replace(regex, (match, ...args) => {
    const matchStart: number = args[args.length - 2];
    if (matchStart > lastIndex) {
      // Push non-matching part
      parts.push({ text: sentence.slice(lastIndex, matchStart), highlight: false });
    }
    // Push matching part
    parts.push({ text: match, highlight: true });
    lastIndex = matchStart + match.length;
    return match; // This return is necessary for TypeScript, but not used
  });
  if (lastIndex < sentence.length) {
    // Push any remaining non-matching part
    parts.push({ text: sentence.slice(lastIndex), highlight: false });
  }

  // Render sentence with highlighted parts
  return (
    <div>
      {parts.map((part, index) =>
        part.highlight ? (
          <span key={index} style={highlightStyle}>
            {part.text}
          </span>
        ) : (
          part.text
        )
      )}
    </div>
  );
};
