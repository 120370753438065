import {
  format,
  isPossibleNumber,
  isValidNumber,
  parse,
  parsePhoneNumber,
} from 'libphonenumber-js';
import { LRUCache } from 'lru-cache';

const cachedFormattedNumbers = new LRUCache<string, string>({ max: 2000 });

// Format phone number input to +14245359459 (E164) format
export const toE164 = (number: any) => {
  if (!number) {
    return undefined;
  }
  const parsed = parse(number, 'US');
  return format(parsed, 'E.164') || number;
};

// Checks if the input is valid E164 format
export const isValidPhoneNumber = (number: any) =>
  number && isValidNumber(toE164(number));

// Checks if the input is a possible phone number
export const isPhoneNumber = (value: string): boolean => {
  if (!value) return false;
  return isPossibleNumber(value);
};

// Formats the input to "(424) 525-9459" format
export const formatPhoneNumber = (number: string | undefined) => {
  if (!number) {
    return undefined;
  }
  let formatted = cachedFormattedNumbers.get(number);
  if (!formatted) {
    try {
      const phoneNumber = parsePhoneNumber(number, 'US');
      if (phoneNumber.country === 'US' || phoneNumber.country === 'CA') {
        formatted = phoneNumber.formatNational({ v2: true });
      } else {
        formatted = phoneNumber.formatInternational({ v2: true });
      }
    } catch (e) {
      formatted = number;
    }
    cachedFormattedNumbers.set(number, formatted);
  }
  return formatted || number;
};

export function isValidURL(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

export function isValidEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const isValidUuid = (id: string) => {
  if (!id || typeof id !== 'string' || !id.length) {
    return false;
  }

  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return regex.test(id);
};

export const phoneRegex = {
  containsNumber: /\d+/,
  containsAlphabet: /[a-zA-Z]/,
  containSymbols: /([(])|([)])|([-])|([+])|([^A-Za-z])/,
  onlyLetters: /^[A-Za-z]+$/,
  onlyNumbers: /^[0-9]+$/,
  onlyMixOfAlphaNumeric: /^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/,
};

export const phoneFormatting = (value: string) => {
  return value
    .replace('(', '')
    .replace(')', '')
    .replace('+', '')
    .replace(' ', '')
    .replace('-', '')
    .replace('.', '')
    .replace(/-/g, '');
};
