import { TeamMember } from '@/shared/types/team';
import { Text } from '@/shared/ui';

import { TeamMemberPreview } from './TeamMemberPreview';

type TeamMembersAvatarsProps = {
  members: Array<TeamMember>;
  limit: number;
};

export const TeamMembersAvatars = ({ members, limit = 4 }: TeamMembersAvatarsProps) => {
  const count = members?.length > limit ? members?.length - (limit - 1) : 0;

  return members?.length > 0 ? (
    <>
      {members
        ?.slice(0, limit)
        .map((item: TeamMember, index: number) => (
          <TeamMemberPreview
            key={item.id}
            user_id={item.user_id}
            count={count}
            isLast={index === limit - 1}
          />
        ))}
    </>
  ) : (
    <Text>0</Text>
  );
};
