import { Attachment, isValidAttachment } from '@/shared/components/attachments';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import {
  ConversationAttachmentType,
  ConversationItemVisibilityStatusTypes,
  ConversationMessageType,
} from '@/shared/types/conversations';
import { Box, Flex } from '@/shared/ui';

import {
  MessageError,
  MessageSuccess,
  StyledAttachmentContainer,
  StyledMessage,
  StyledMessageFooter,
} from '..';
import { SenderIcon } from '../SenderIcon';

type OutboundSMSProps = {
  message: ConversationMessageType;
  /* the date the message was sent */
  date?: string | undefined | null;
  /* the email of the user who sent the message */
  user_email?: string;
};

// Combined message container and message divs for outbound messages
export const OutboundSMS = (props: OutboundSMSProps) => {
  const { message, date, user_email } = props;
  const { body, delivery_status, error, type, translated_body, visibility_status } =
    message;

  // filter attachments with .sml extension
  const filteredAttachments = message.attachments.filter(
    (a) => a !== null && isValidAttachment(a.url)
  );

  // default to `visible` when the visibility is not set
  const visibility = visibility_status ?? ConversationItemVisibilityStatusTypes.VISIBLE;

  return (
    <Box>
      {/* Show `Message Removed` bubble when the visibility is `removed` */}
      {visibility === ConversationItemVisibilityStatusTypes.REMOVED ? (
        <StyledMessage direction="outbound_sms">
          {renderText('This message has been deleted...')}
        </StyledMessage>
      ) : null}

      {(body !== null || filteredAttachments.length > 0) && (
        <Box>
          {body !== null ? (
            <Flex justify="end" align="end">
              <StyledMessage
                visibility={`outbound_${visibility}`}
                direction="outbound_sms"
              >
                {renderText(body)}
              </StyledMessage>
              <SenderIcon message={message} />
            </Flex>
          ) : null}
          <Box>
            {translated_body && translated_body !== null ? (
              <Flex justify="end" align="end" css={{ mt: 5, mr: 37 }}>
                <StyledMessage
                  visibility={`outbound_${visibility}`}
                  direction="outbound_sms_translation"
                >
                  {renderText(`Original - ${translated_body}`)}
                </StyledMessage>
              </Flex>
            ) : null}
          </Box>
          <Box css={{ mr: 35 }}>
            {filteredAttachments.length > 0 ? (
              visibility === ConversationItemVisibilityStatusTypes.HIDDEN ? (
                'Attachments have been hidden'
              ) : (
                <Box>
                  {filteredAttachments.map((a: ConversationAttachmentType | null) => {
                    if (a === null) return null;
                    return (
                      <StyledAttachmentContainer align="end" justify="end" key={a.url}>
                        <Flex css={{ width: '300px !important' }} justify="end">
                          <Attachment src={a.url} />
                        </Flex>
                      </StyledAttachmentContainer>
                    );
                  })}
                </Box>
              )
            ) : null}
          </Box>
          <StyledMessageFooter
            align="end"
            justify="end"
            css={{ alignItems: 'center', mr: 5, mt: 4 }}
          >
            {error && (
              <MessageError
                status={delivery_status}
                error={error}
                date={date}
                user={user_email}
                message_type={type}
              />
            )}
            {!error && (
              <MessageSuccess
                status={status}
                error={error}
                date={date}
                user={user_email}
                message_type={type}
              />
            )}
          </StyledMessageFooter>
        </Box>
      )}
    </Box>
  );
};
