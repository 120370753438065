/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, useFormikContext } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import * as Yup from 'yup';

import { FormFieldWrapper, RadioInput } from '@/shared/components/forms';
import { KeywordBuilder } from '@/shared/components/forms/KeywordBuilder';
import {
  ActionRenderer,
  defaultActions,
  sendMessageAction,
} from '@/shared/components/triggers';
import { SelectActionType } from '@/shared/components/triggers/actions/AddAction';
import { Trigger, TriggerTypes } from '@/shared/types/triggers';
import { Action, ActionTypes } from '@/shared/types/triggers/actions';
import { Button, DialogHeader, Fieldset, Flex, HStack, Label, VStack } from '@/shared/ui';

type KeywordFormProps = {
  /* The action to perform when the save button is clicked */
  onSubmit: (values: Trigger) => void;
};

type KeywordTriggerFormValuesType = {
  keywords: string[];
  negate_keywords: string[];
  exact_match: boolean;
  actions: Action[];
};

export const AddKeywordTrigger = (props: KeywordFormProps) => {
  const { onSubmit } = props;

  const [initialvalues, setInitialValues] = useState<KeywordTriggerFormValuesType>({
    keywords: [],
    negate_keywords: [],
    exact_match: false,
    actions: [sendMessageAction as unknown as Action],
  });

  // a state variable to store step0's user input
  const [savedInput, setSavedInput] = useState<KeywordTriggerFormValuesType>({
    keywords: [],
    negate_keywords: [],
    exact_match: false,
    actions: [sendMessageAction as unknown as Action],
  });

  const [actionType, setActionType] = useState<ActionTypes>(ActionTypes.SEND_MESSAGE);

  return (
    <>
      <DialogHeader title="Add Keyword Trigger" />
      <VStack gap={3} css={{ width: '100%' }}>
        <Formik
          enableReinitialize
          initialValues={initialvalues}
          validationSchema={Yup.object({
            keywords: Yup.array().test(
              'len',
              'Required',
              (arr) => Array.isArray(arr) && arr.length > 0
            ),
            negate_keywords: Yup.array(),
            exact_match: Yup.boolean().required('Required'),
            locations: Yup.array(),
            actions: Yup.array(),
          })}
          onSubmit={async (values) => {
            const params: Trigger = {
              type: TriggerTypes.KEYWORD,
              keyword: {
                keywords: values.keywords,
                negate_keywords: values.negate_keywords,
                enabled: true,
                exact_match: values.exact_match as boolean,
              },
              actions: values.actions as unknown as Action[],
            };

            try {
              onSubmit(params);
            } catch (e) {
              console.log(e);
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <VStack gap={4}>
                <KeywordFormFields
                  setInitialValues={setInitialValues}
                  actionType={actionType}
                  setActionType={setActionType}
                  savedInput={savedInput}
                  setSavedInput={setSavedInput}
                />
              </VStack>
            </form>
          )}
        </Formik>
      </VStack>
    </>
  );
};

type FromFieldsProps = {
  /* set the initial values for the form */
  setInitialValues?: (values: KeywordTriggerFormValuesType) => void;
  /* the action type */
  actionType: ActionTypes;
  /* set the action type */
  setActionType: Dispatch<SetStateAction<ActionTypes>>;
  /* the saved input */
  savedInput?: KeywordTriggerFormValuesType;
  /* set the saved input */
  setSavedInput?: (values: KeywordTriggerFormValuesType) => void;
};

export const KeywordFormFields = (props: FromFieldsProps) => {
  const { actionType, setActionType, savedInput, setSavedInput } = props;
  const formik = useFormikContext();
  const [step, setStep] = useState(0);

  const values = formik.values as {
    keywords: string[];
    negate_keywords: string[];
    exact_match: boolean;
    actions: Action[];
  };

  const defaultAction = (type: ActionTypes) =>
    defaultActions?.find((action) => action.type === type)?.default;

  const handleChange = (a: ActionTypes) => {
    setActionType(a);
    formik.setFieldValue('actions', [defaultAction(a)]);
  };

  // if values.keywords is empty, then return true
  const isKeywordsEmpty = () => {
    if (!values.keywords) return true;
    if (values.keywords.length === 0) {
      return true;
    }
    return false;
  };

  // generate keywords options for the KeywordBuilder component based on the values.keywords
  const generateKeywordsOptions = () => {
    // return an array of objects with value and label
    return values.keywords.map((each) => ({ value: each, label: each }));
  };

  // generate negate_keywords options for the KeywordBuilder component based on the values.negate_keywords
  const generateNegateKeywordsOptions = () => {
    // return an array of objects with value and label
    return values.negate_keywords.map((each) => ({ value: each, label: each }));
  };

  // when user clicks 'Next - Add Action', we save the user input to savedInput and setStep(1)
  const handleNext = () => {
    setSavedInput && setSavedInput(values);
    setStep(1);
  };

  // when user clicks 'Back - Edit Keyword', we set the formik values to savedInput and setStep(0)
  const handleBack = () => {
    formik.setValues(savedInput);
    setStep(0);
  };

  return (
    <VStack gap={2} css={{ width: '100%' }}>
      {step === 0 && (
        <>
          <FormFieldWrapper
            label="Keywords"
            tooltip="Keyword you would like to automate."
            name="keywords"
          >
            <KeywordBuilder name="keywords" options={generateKeywordsOptions()} />
          </FormFieldWrapper>
          <FormFieldWrapper
            label="Negate Keywords"
            tooltip="Negate Keywords you don't want to automate."
            name="negate_keywords"
          >
            <KeywordBuilder
              name="negate_keywords"
              options={generateNegateKeywordsOptions()}
            />
          </FormFieldWrapper>
          <FormFieldWrapper
            label="Keyword Match"
            tooltip="Configure when the keyword is going to trigger."
            name="exact_match"
          >
            <RadioInput
              options={[
                { value: 'false', type: 'Message Contains Keyword' },
                { value: 'true', type: 'Message Exactly Matches Keyword' },
              ]}
            />
          </FormFieldWrapper>
          <Flex css={{ width: '100%' }} justify="end">
            <HStack>
              <Button type="button" onClick={handleNext} disabled={isKeywordsEmpty()}>
                Next - Add Action
              </Button>
            </HStack>
          </Flex>
        </>
      )}
      {step === 1 && (
        <>
          <Fieldset>
            <Label>Select Action Type</Label>
            <SelectActionType action_type={actionType} setAction={handleChange} />
          </Fieldset>
          {values?.actions?.map((action: Action, index) => (
            <ActionRenderer key={index} index={index} action={action} />
          ))}
          <Flex css={{ width: '100%' }} justify="end">
            <HStack>
              <Button variant="gray" type="button" onClick={handleBack}>
                Back - Edit Keyword
              </Button>
              <Button type="submit">Save Keyword Automation</Button>
            </HStack>
          </Flex>
        </>
      )}
    </VStack>
  );
};
