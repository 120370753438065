/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HiClipboard } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import {
  initialGroupsState,
  ITEMS_COUNT,
  useGroups,
} from '@/pages/contacts/groups/context/GroupContext';
import {
  ConfirmationDialogDescription,
  CustomDropdownMenuItem,
  CustomDropdownMenuItemWarning,
} from '@/pages/settings/organization/users/UsersTable';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CopyToClipboard } from '@/shared/components/CopyToClipboard';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_segments_object } from '@/shared/components/filterBuilder/objects/segments';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { SearchFilters } from '@/shared/types/contacts';
import { Group } from '@/shared/types/contacts/groups';
import { FilterType, Sort } from '@/shared/types/filter';
import { Avatar, Box, Flex, HStack, VStack } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import { handleFilterChange, handleSortUpdate } from '../utils/filterActions';
import { segmentSortConfig } from './filterConfig';
import { RenameDialog } from './RenameDialog';

const defaultSort: Array<Sort> = [
  {
    label: 'Created At',
    resource: 'segment',
    column: 'inserted_at',
    order: 'desc',
    id: null,
  },
];

export const Segments = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const groupsContext = useGroups();
  const { deleteGroup, updateGroup, updateFilterParams } = groupsContext;

  const { loading, groups, totalCount, filterParams } = groupsContext.groupsState;

  const history = useHistory();

  const handleNavigate = (group: Group) => {
    history.push(`/data/segments/${group.id}`);
  };

  const columns: Array<TableColumn<Group>> = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
        colWidth: '100%',
        Cell: (props: { row: { original: Group } }) => (
          <Flex align="center">
            <GroupColor css={{ backgroundColor: props.row.original.color }} />
            <Box>{props.row.original.name}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'created_by',
        Cell: (props: { row: { original: Group } }) => (
          <>
            {!!props.row.original.created_by && (
              <HStack gap={2} align="center">
                <Box>
                  <Avatar
                    variant="pink"
                    size="newTable"
                    src={props.row.original.created_by?.attachment?.url}
                    fallback={initials(
                      props.row.original.created_by.name ||
                        props.row.original.created_by.email
                    )}
                  />
                </Box>
                <VStack gap="1">
                  <Box>{props.row.original.created_by.name}</Box>
                  <Box css={{ color: '#718196', fontSize: 13 }}>
                    <CopyToClipboard
                      copy={props.row.original.created_by.email ?? ''}
                      description="Copy user email"
                      successMessage="Email copied to clipboard"
                    >
                      <Flex align="center">
                        <Flex>{props.row.original.created_by.email}</Flex>
                        <Flex css={{ mx: 4 }}>
                          <HiClipboard />
                        </Flex>
                      </Flex>
                    </CopyToClipboard>
                  </Box>
                </VStack>
              </HStack>
            )}
          </>
        ),
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        Cell: (props: { row: { original: Group } }) => (
          <>
            {!!props.row.original.updated_by && (
              <HStack gap={2} align="center">
                <Box>
                  <Avatar
                    variant="pink"
                    size="newTable"
                    src={props.row.original.updated_by?.attachment?.url}
                    fallback={initials(
                      props.row.original.updated_by.name ||
                        props.row.original.updated_by.email
                    )}
                  />
                </Box>
                <VStack gap="1">
                  <Box>{props.row.original.updated_by.name}</Box>
                  <Box css={{ color: '#718196', fontSize: 13 }}>
                    <CopyToClipboard
                      copy={props.row.original.updated_by.email ?? ''}
                      description="Copy user email"
                      successMessage="Email copied to clipboard"
                    >
                      <Flex align="center">
                        <Flex>{props.row.original.updated_by.email}</Flex>
                        <Flex css={{ mx: 4 }}>
                          <HiClipboard />
                        </Flex>
                      </Flex>
                    </CopyToClipboard>
                  </Box>
                </VStack>
              </HStack>
            )}
          </>
        ),
      },
      {
        Header: 'Created At',
        id: 'created_at',
        accessor: 'created_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Group } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.inserted_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: 'updated_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Group } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.updated_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props: { row: { original: Group } }) => {
          return (
            <TableActionMenu width={155}>
              <>
                <CustomDropdownMenuItem
                  data-testid="view-segment-option"
                  onClick={() => handleNavigate(props.row.original)}
                >
                  View Segment
                </CustomDropdownMenuItem>
                <RenameDialog
                  title="Rename segment"
                  description="Rename this segment"
                  value={props.row.original}
                  action={updateGroup}
                />

                <ConfirmationDialog
                  width="432px"
                  title="Delete this segment?"
                  description={
                    <ConfirmationDialogDescription
                      value={props.row.original.name}
                      description="segment will be permanently deleted."
                    />
                  }
                  onConfirm={() => deleteGroup(props.row.original.id)}
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="redBackground"
                  cancelButtonVariant="grayBackground"
                >
                  <CustomDropdownMenuItemWarning
                    data-testid="delete-segment-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete segment
                  </CustomDropdownMenuItemWarning>
                </ConfirmationDialog>
              </>
            </TableActionMenu>
          );
        },
      },
    ],
    [loading]
  );

  useEffect(() => {
    resetFilters();
  }, []);

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    updateFilterParams({
      ...initialGroupsState.filterParams,
      sort: defaultSort,
    });
  };

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = [
      {
        column: 'name',
        comparison: 'ilike',
        value: `%${formattedValue}%`,
        resource: 'segment',
      },
    ];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Data', path: '/data/contacts' },
        { title: 'Segments', path: '/data/segments' },
      ]}
    >
      <Flex direction="column" css={{ m: 24, flex: '1 1 auto' }}>
        <Box css={{ paddingBottom: '$space$3' }}>
          <CombinedFilters
            quickSearchPlaceholder="Search segments by name"
            quickSearchValue={quickSearchValue}
            setQuickSearchValue={(value: string) => {
              setQuickSearchValue(value);
              handleQuickSearch(debouncedUpdate, filterParams, value);
            }}
            customObjects={[]}
            defaultObjects={[default_segments_object]}
            activeFilters={activeFilters}
            setFilters={(value: Array<FilterType>) => {
              setActiveFilters(value);
              handleFilterChange(debouncedUpdate, filterParams, value);
            }}
            sortConfig={segmentSortConfig}
            activeSort={filterParams.sort}
            onSortUpdate={(value: Array<Sort>) =>
              handleSortUpdate(debouncedUpdate, filterParams, value, true)
            }
          />
        </Box>
        <Table
          data={groups}
          columns={columns}
          caption="Users Table"
          isLoading={loading}
          totalCount={totalCount}
          setOffset={(offset) => {
            updateFilterParams({
              ...filterParams,
              offset,
            });
          }}
          pageSize={ITEMS_COUNT}
          emptyTitle="No segments were found."
        />
      </Flex>
    </PageLayout>
  );
};

const GroupColor = styled(Box, {
  height: 7,
  width: 7,
  borderRadius: '100%',
  mt: 2,
  mr: 10,
});
