/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useMedia } from 'react-use';
import { VirtuosoGrid } from 'react-virtuoso';

import TemplateLoadingCard from '@/pages/campaigns/templates/TemplateLoadingCard';
import { SequenceTemplatesErrorBoundary } from '@/pages/sequences/templates/boundaries/SequenceTemplatesErrorBoundaries';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useResponsiveColumns } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Location } from '@/shared/types/locations';
import { Sequence, SequenceAccessLevel, SequenceStatus } from '@/shared/types/sequences';
import { User } from '@/shared/types/users';
import { Box, Button, Card, Flex, Grid, HStack, Input, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useSequences } from '../../context/SequenceContext';
import { SequenceEditor } from '../../sequence/SequenceEditor';
import { TemplatePreview } from './TemplatePreview';

type SequenceFilters = {
  /* array of location ids */
  locations: string[];
  /* array of user ids */
  users: number[];
};

type SequenceTemplatesProps = {
  /* is the sequence template global */
  isGlobal?: boolean;
};

const LIMIT = 24;

export const SequenceTemplates = ({ isGlobal }: SequenceTemplatesProps) => {
  const {
    getSequenceTemplates,
    sequencesState: { sequenceTemplates, loadingTemplates, totalSequencesTemplates },
    setLoadingTemplates,
  } = useSequences();
  const {
    locationsState: { locations },
  } = useLocations();
  const {
    userState: { users },
  } = useUsers();
  const columns = useResponsiveColumns();

  const isLargeScreen = useMedia('(min-width: 1600px)');

  const [query, setQuery] = useState<{ title: string; filters: SequenceFilters }>({
    title: '',
    filters: { locations: [], users: [] },
  });
  const [offset, setOffset] = useState(0);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  const isListComplete = sequenceTemplates.length >= totalSequencesTemplates;

  const getSequenceTemplatesDebounced = useCallback(
    debounce(async (query, isGlobal) => {
      await getSequenceTemplates({
        title: query.title || '',
        access_level: isGlobal ? SequenceAccessLevel.GLOBAL : undefined,
        location_ids: query.filters.locations,
        user_ids: query.filters.users,
        limit: LIMIT,
        offset: 0,
      });
    }, 500), // 500ms debounce time
    []
  );

  useEffect(() => {
    setOffset(0);
    getSequenceTemplatesDebounced(query, isGlobal);
  }, [query, isGlobal]);

  // fetch next set of campaign templates to add the the list
  const fetchNextSequences = async (offset: number) => {
    setIsLoadingNext(true);
    await getSequenceTemplates({
      title: query.title || '',
      access_level: isGlobal ? SequenceAccessLevel.GLOBAL : undefined,
      location_ids: query.filters.locations,
      user_ids: query.filters.users,
      limit: LIMIT,
      offset: offset,
    });
    setIsLoadingNext(false);
  };

  useEffect(() => {
    if (offset > 0 && !loadingTemplates) {
      fetchNextSequences(offset);
    }
  }, [offset, loadingTemplates]);

  useEffect(() => {
    return () => {
      setLoadingTemplates(true);
    };
  }, []);

  const increaseOffset = () => {
    setOffset((prevCoefficient) => prevCoefficient + LIMIT);
  };

  const enabledLoadingNext =
    // If the list is not already complete
    !isListComplete &&
    // Is next sequences templates list loading
    !isLoadingNext;

  const templatesToDisplay = useMemo(
    () =>
      loadingTemplates
        ? []
        : sequenceTemplates?.filter(
            (template) =>
              template &&
              template?.status === SequenceStatus.TEMPLATE &&
              (isGlobal
                ? template?.access_level === SequenceAccessLevel.GLOBAL
                : template?.access_level !== SequenceAccessLevel.GLOBAL)
          ),
    [isGlobal, sequenceTemplates, loadingTemplates]
  );

  return (
    <SequenceTemplatesErrorBoundary
      feature={isGlobal ? 'SequenceGlobalTemplates' : 'SequenceMyTemplates'}
      isGlobal={isGlobal}
    >
      <PageLayout
        breadcrumbs={[
          { title: 'Sequences', path: '/sequences' },
          {
            title: isGlobal ? 'Browse Templates' : 'My Templates',
            path: isGlobal ? '/sequences/templates/browse' : '/sequences/templates',
          },
        ]}
        actions={
          !isGlobal && (
            <SequenceEditor isTemplateEditor={true}>
              <Button>Create Template</Button>
            </SequenceEditor>
          )
        }
      >
        <ListContainer gap={1}>
          <HStack gap={1} css={{ width: '100%', px: 30, mb: 20 }}>
            <Flex align="center" css={{ width: isLargeScreen ? '100%' : '50%' }}>
              <Input
                value={query.title}
                placeholder="Search Sequence Templates"
                onChange={(e) => setQuery((prev) => ({ ...prev, title: e.target.value }))}
                css={{ width: '409px' }}
              />
            </Flex>
            {!isGlobal && (
              <>
                {locations.length > 0 && (
                  <Box css={{ width: '25%' }}>
                    <MultiSelect
                      defaultPlaceholder="Locations"
                      defaultSelectedItems={[]}
                      isDropdown
                      options={locations.map((location: Location) => ({
                        type: location?.name || '',
                        value: location.id,
                      }))}
                      parentSelectedItems={query.filters}
                      setParentSelectedItems={(filters: SequenceFilters) =>
                        setQuery((prev) => ({ ...prev, filters }))
                      }
                      isCampaigns
                    />
                  </Box>
                )}
                {users.length > 0 && (
                  <Box css={{ width: '25%' }}>
                    <MultiSelect
                      defaultPlaceholder="Users"
                      defaultSelectedItems={[]}
                      isDropdown
                      options={users.map((user: User) => ({
                        type: user.name || user.email || '',
                        value: user.id,
                      }))}
                      parentSelectedItems={query.filters}
                      setParentSelectedItems={(filters: SequenceFilters) =>
                        setQuery((prev) => ({ ...prev, filters }))
                      }
                      isCampaigns
                    />
                  </Box>
                )}
              </>
            )}
          </HStack>
          {loadingTemplates && templatesToDisplay?.length === 0 && (
            <Grid columns={columns as any} gap={2} css={{ px: 30 }}>
              {Array.from({ length: 20 }, (_, k) => (
                <TemplateLoadingCard key={k} />
              ))}
            </Grid>
          )}
          {templatesToDisplay.length > 0 && (
            <VirtuosoGrid
              data={templatesToDisplay as Sequence[]}
              atBottomStateChange={(isAtBottom) => {
                if (isAtBottom && enabledLoadingNext) {
                  increaseOffset();
                }
              }}
              totalCount={totalSequencesTemplates}
              itemContent={(index: number, template: Sequence) => (
                <TemplatePreview key={template.id} template={template} />
              )}
              components={{
                Footer: () =>
                  enabledLoadingNext ? (
                    <Grid columns={columns as any} gap={2} css={{ px: 30 }}>
                      {Array.from(
                        { length: columns },
                        (_: any, k: React.Key | null | undefined) => (
                          <Box css={{ pb: 10 }} key={k}>
                            <TemplateLoadingCard key={k} />
                          </Box>
                        )
                      )}
                    </Grid>
                  ) : (
                    <Box css={{ pb: 20 }} />
                  ),
                List: forwardRef(({ style, children, ...props }, ref) => (
                  <div
                    ref={ref}
                    {...props}
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      paddingLeft: 25,
                      paddingRight: 25,
                      ...style,
                    }}
                  >
                    {children}
                  </div>
                )),
                Item: ({ children, ...props }: any) => (
                  <div
                    {...props}
                    style={{
                      padding: '0 5px 10px',
                      width: `calc(100%/${columns})`,
                      display: 'flex',
                      flex: 'none',
                      alignContent: 'stretch',
                      boxSizing: 'border-box',
                    }}
                  >
                    {children}
                  </div>
                ),
              }}
            />
          )}
          {!loadingTemplates && templatesToDisplay?.length === 0 && (
            <Box
              css={{
                p: 30,
                mb: 30,
                minHeight: 'calc(100% - 88px)',
                height: 'fit-content',
                overflow: 'auto',
              }}
            >
              {(query.title !== '' ||
                query.filters.locations.length > 0 ||
                query.filters.users.length > 0) && (
                <SearchedSequenceTemplateEmptyState isGlobal={isGlobal} />
              )}
              {query.title === '' && <SequenceTemplateEmptyState isGlobal={isGlobal} />}
            </Box>
          )}
        </ListContainer>
      </PageLayout>
    </SequenceTemplatesErrorBoundary>
  );
};

export const SearchedSequenceTemplateEmptyState = ({
  isGlobal,
}: {
  isGlobal?: boolean;
}) => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>{`No ${
        isGlobal ? 'Global ' : ''
      }Sequence Template Matches Your Search`}</Text>
      <Text css={{ mt: 10 }}>Please try a different filter</Text>
    </EmptyStateContainer>
  );
};

export const SequenceTemplateEmptyState = ({ isGlobal }: { isGlobal?: boolean }) => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>{`No ${
        isGlobal ? 'Global ' : ''
      }Sequence Templates`}</Text>
      {!isGlobal && (
        <Text css={{ mt: 10 }}>{`Click "Create Template" to get started`}</Text>
      )}
    </EmptyStateContainer>
  );
};

export const EmptyStateContainer = styled(Flex, {
  p: 20,
  height: '100%',
  width: '100%',
});

export const ListContainer = styled(VStack, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minHeight: '100%',
  overflow: 'auto',
  maxWidth: '100%',
  pt: 30,
  pb: 0,
});

export const TemplateCard = styled(Card, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.01)',
  borderRadius: '$3',
});

export const TemplateCardTop = styled(Flex, {
  width: '100%',
  p: 20,
  height: 287,
  borderTopRightRadius: '$3',
  borderTopLeftRadius: '$3',
  maxHeight: 287,
  overflow: 'auto',
});

export const TemplateMessage = styled(Box, {
  px: 20,
  py: 8,
  fontWeight: 500,
  fontSize: '12px',
  color: '#11181C',
  backgroundColor: '$slate3 !important',
  lineHeight: 1.4,
  borderRadius: '20px 20px 20px 6.5px',
  alignItems: 'center',
  display: '-webkit-box',
  '-webkit-line-clamp': 10,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  a: {
    wordBreak: 'break-all',
  },
});

export const TemplateTitle = styled(Box, {
  fontSize: '16px',
  fontWeight: 500,
  textTransform: 'capitalize',
  lineHeight: '19px',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const TemplateCardBottom = styled(Flex, {
  py: 20,
  px: 15,
  width: '100%',
  justifyContent: 'space-between',
  borderTop: '1px solid $slate6',
  borderBottomRightRadius: '$1',
  borderBottomLeftRadius: '$1',
});
