import { api } from './api';

/*
  POST - create a new organization
*/

export const getPhone = (code: any) =>
  api.get(`/phones/new?area=${code}`).then((res) => {
    return res.data.data;
  });

export const buyPhoneNumber = (params: any) =>
  api.post('/phones', params).then((res) => {
    return res.data.data;
  });
