/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCall } from '@/shared/api/calls';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Call } from '@/shared/types/calls';
import { Box, Flex, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';
import { Contact } from '@/shared/types/contacts';

export const AICall = () => {
  const { id } = useParams<{ id: string }>();
  const [call, setCall] = useState<Call | null>(null);
  const [data, setData] = useState<TranscriptData | null>(null);
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    fetchCall();
  }, [id]);

  const fetchCall = async () => {
    try {
      const call = await getCall(id);
      setCall(call.data);
      // filter the call.data.attachment for url ending with .mp3
      const transcript = call.data.attachments.filter((a) =>
        a.url.endsWith('/transcript.json')
      );
      setUrl(transcript[0].url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!url) return;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setData(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [url]);

  // Filter the attachments objects for content_type audio/x-wav
  const audioAttachments = call?.attachments?.filter(
    (attachment) => attachment?.content_type === 'audio/x-wav'
  );

  const callAnalysisAttachments = call?.attachments?.filter((attachment) =>
    attachment?.url.endsWith('/analysis.json')
  );

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'AI', path: '/ai/agents' },
        { title: 'Calls', path: '/ai/calls' },
        { title: 'Call Analysis', path: '/ai/calls/:id' },
      ]}
    >
      <Flex
        css={{
          backgroundColor: 'white',
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
        direction="row"
      >
        <Flex direction="column" css={{ flex: 1, overflow: 'hidden' }}>
          <Box css={{ overflowY: 'auto', paddingBottom: '125px', height: '100%' }}>
            <Transcript data={data || []} contact={call?.contact} />
          </Box>
          <Flex
            css={{
              height: 125,
              maxHeight: 125,
              borderTopWidth: 1,
              borderTopColor: '$slate3',
              p: 15,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AudioControl controls src={audioAttachments?.[0]?.url ?? ''}>
              <track kind="captions" />
              Your browser does not support the audio element.
            </AudioControl>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          css={{
            overflowY: 'auto',
            borderLeftWidth: 1,
            borderLeftColor: '$slate3',
            width: '400px',
            p: 15,
          }}
        >
          <Box css={{ fontWeight: 'bold', pb: 20 }}>Call Analysis</Box>
          <CallAnalysis url={callAnalysisAttachments?.[0]?.url ?? ''} />
        </Flex>
      </Flex>
    </PageLayout>
  );
};

interface Word {
  word: string;
  start: number; // Starting timestamp in seconds
  end: number; // Ending timestamp in seconds
}

interface Message {
  content: string;
  role: 'agent' | 'user';
  words: Word[];
  metadata?: {
    response_id: number;
  };
}

interface ToolInvocation {
  arguments: string;
  name: string;
  role: string;
  tool_call_id: string;
}

export type TranscriptData = (Message | ToolInvocation)[];

const Transcript: React.FC<{ data: TranscriptData; contact?: Contact }> = ({
  data,
  contact,
}) => {
  return (
    <Container>
      {data?.map((message, index) => {
        if ('tool_call_id' in message) {
          // Render tool invocation
          return (
            <MessageContainer key={index} role={message.role}>
              <Box>
                <Box>Tool Invocation</Box>
                <Timestamp>Tool: {message.name}</Timestamp>
              </Box>
            </MessageContainer>
          );
        } else {
          // Ensure message.words is defined and has elements
          const timestamp = message.words?.length
            ? new Date(message.words[0].start * 1000).toISOString().substr(11, 8)
            : '00:00:00';
          return (
            <MessageContainer key={index} role={message.role}>
              <Box>
                <Box>
                  {message.role === 'user'
                    ? contact?.name || contact?.email || contact?.phone || 'Contact'
                    : 'Agent'}
                </Box>
                <Timestamp>{timestamp}</Timestamp>
                {message.content}
              </Box>
            </MessageContainer>
          );
        }
      })}
    </Container>
  );
};

type CallAnalysisType = {
  agent_sentiment: string;
  agent_task_completion_rating: string;
  agent_task_completion_rating_reason: string;
  call_completion_rating: string;
  call_completion_rating_reason: string;
  call_summary: string;
  user_sentiment: string;
};

const CallAnalysis = ({ url }: { url: string }) => {
  const [data, setData] = useState<CallAnalysisType | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
    };
    fetchData();
  }, [url]);

  return (
    <VStack>
      <VStack gap="1">
        <AnalysisTitle>Call Summary</AnalysisTitle>
        <AnalysisDescription>{data?.call_summary}</AnalysisDescription>
      </VStack>
      <VStack gap="1">
        <AnalysisTitle>Call Completion Reason</AnalysisTitle>
        <AnalysisDescription>{data?.call_completion_rating_reason}</AnalysisDescription>
      </VStack>
      <VStack gap="1">
        <AnalysisTitle>User Sentiment</AnalysisTitle>
        <AnalysisDescription>{data?.user_sentiment}</AnalysisDescription>
      </VStack>
      <VStack gap="1">
        <AnalysisTitle>Agent Sentiment</AnalysisTitle>
        <AnalysisDescription>{data?.agent_sentiment}</AnalysisDescription>
      </VStack>
      <VStack gap="1">
        <AnalysisTitle>Agent Task Completion Rating</AnalysisTitle>
        <AnalysisDescription>{data?.agent_task_completion_rating}</AnalysisDescription>
      </VStack>
      <VStack gap="1">
        <AnalysisTitle>Agent Task Completion Reason</AnalysisTitle>
        <AnalysisDescription>
          {data?.agent_task_completion_rating_reason}
        </AnalysisDescription>
      </VStack>
    </VStack>
  );
};

const AnalysisTitle = styled('div', {
  fontWeight: 500,
  fontSize: '14px',
});

const AnalysisDescription = styled('div', {
  fontWeight: 400,
  fontSize: '13px',
});

const Container = styled('div', {
  px: '20px',
  py: '10px',
});

const MessageContainer = styled('div', {
  padding: '10px',
  borderRadius: '8px',
  margin: '10px 0',
});

const Timestamp = styled('span', {
  fontSize: '0.85em',
  color: '#888',
  marginRight: '10px',
});

const AudioControl = styled('audio', {
  width: '50%',
  outline: 'none',
});
