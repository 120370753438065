import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  HiArchive,
  HiCalendar,
  HiCheckCircle,
  HiDocument,
  HiSearch,
  HiSpeakerphone,
  HiTemplate,
} from 'react-icons/hi';
import { useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

export function CampaignsNavigation() {
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();

  const isCampaigns = useRouteMatch('/campaigns')?.isExact;
  const isDrafts = useRouteMatch('/campaigns/draft')?.isExact;
  const isScheduled = useRouteMatch('/campaigns/scheduled')?.isExact;
  const isComplete = useRouteMatch('/campaigns/complete')?.isExact;
  const isArchived = useRouteMatch('/campaigns/archive')?.isExact;
  const isGlobalTemplates = useRouteMatch('/campaigns/templates/browse')?.isExact;
  const isMyTemplates = useRouteMatch('/campaigns/templates')?.isExact;

  const campaignsSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'All Campaigns',
          image: <HiSpeakerphone size={18} />,
          isActive: !!isCampaigns,
          link: '/campaigns',
        },
        {
          title: 'Drafts',
          image: <HiDocument size={18} />,
          isActive: !!isDrafts,
          link: '/campaigns/draft',
        },
        {
          title: 'Scheduled',
          image: <HiCalendar size={18} />,
          isActive: !!isScheduled,
          link: '/campaigns/scheduled',
        },
        {
          title: 'Complete',
          image: <HiCheckCircle size={18} />,
          isActive: !!isComplete,
          link: '/campaigns/complete',
        },
        {
          title: 'Archived',
          image: <HiArchive size={18} />,
          isActive: !!isArchived,
          link: '/campaigns/archive',
        },
      ],
    },
    {
      sideNavigationGroup: [
        {
          title: 'My Templates',
          image: <HiTemplate size={18} />,
          isActive: !!isMyTemplates,
          link: '/campaigns/templates',
        },
        {
          title: 'Browse Templates',
          image: <HiSearch size={18} />,
          isActive: !!isGlobalTemplates,
          link: '/campaigns/templates/browse',
        },
      ],
    },
  ];
  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Campaigns"
          sideNavigationTabs={campaignsSideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        />
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
}

export const CampaignsLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');
  return (
    <MainLayout>
      {isDesktop && <CampaignsNavigation />}
      <LayoutContent>{children}</LayoutContent>
    </MainLayout>
  );
};

export const CampaignContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Helmet>
        <title>Whippy | Campaigns</title>
      </Helmet>
      <CampaignsLayoutContainer>{children}</CampaignsLayoutContainer>
    </>
  );
};
