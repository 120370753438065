/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { HiX } from 'react-icons/hi';

import * as Util from '@/pages/integrations/utils';
import { useDisclosure } from '@/shared/hooks';
import {
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from '@/shared/ui';
import { capitalizeWords } from '@/shared/utils/dromo';

import { useIntegrationConfigurations } from './context/IntegrationConfigurationContext';
import { IntegrationConfigurationForm } from './IntegrationConfigurationForm';

type IntegrationConfigurationProps = {
  configurationId: string;
  applicationId: string;
  /* The content of the dialog */
  children: React.ReactNode;
};

export const IntegrationConfiguration = (props: IntegrationConfigurationProps) => {
  console.log('IntegrationConfiguration render, configurationId:', props.configurationId);
  const { applicationId, configurationId, children } = props;
  const { configurationsState, getConfiguration } = useIntegrationConfigurations();
  const { current } = configurationsState;

  useEffect(() => {
    getConfiguration(applicationId, configurationId);
  }, [configurationId]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCloseDialog = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={() => onOpen()}>
        {children}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          onEscapeKeyDown={() => handleCloseDialog()}
          onPointerDownOutside={() => handleCloseDialog()}
          css={{ overflow: 'auto' }}
        >
          <DialogHeader
            title={
              current && current.data
                ? 'Edit ' +
                  capitalizeWords(Util.deSnakeCase(current.data.action.toString())) +
                  ' Configuration'
                : 'Edit Configuration'
            }
          />
          <IntegrationConfigurationForm
            configuration={current}
            onClose={handleCloseDialog}
          />
          <DialogCloseIcon onClick={() => handleCloseDialog()} size="2">
            <HiX size="15px" style={{ color: 'white' }} />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
