/* eslint-disable react-hooks/exhaustive-deps */
import Fuse from 'fuse.js';
import { Channel } from 'phoenix';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { useAuth } from '@/auth/context/AuthProvider';
import { useSocket } from '@/pages/auth/context/socket/SocketProvider';
import * as API from '@/shared/api/contacts/v1';
import {
  BulkAddTagsParams,
  getOneContact,
  getPortionOfContactsParams,
} from '@/shared/api/contacts/v1';
import { useDisclosure } from '@/shared/hooks';
import { AddNote, Contact, ContactTagItem, NewContact } from '@/shared/types';
import { getViewSettings, setViewSettings } from '@/shared/utils/storage';
import i18next from '@/shared/utils/translation';

import ContactReducer from './ContactReducer';
import {
  ADD_CONTACT,
  ADD_NOTE,
  ARCHIVE_CONTACT,
  ARCHIVE_MULTIPLE_CONTACTS,
  BLOCK_CONTACT,
  CLEAR_CURRENT,
  CLEAR_SEARCHED_CONTACTS,
  CONTACT_ERROR,
  CREATE_CONTACT_TAG,
  DELETE_CONTACT_TAG,
  DELETE_NOTE,
  FILTER_CONTACTS,
  FILTER_PART_OF_CONTACTS,
  GET_PART_OF_CONTACTS_IN_GROUP,
  GET_PORTION_OF_CONTACTS,
  SEARCH_CONTACTS,
  SET_CONTACTS_CHECKED,
  SET_CURRENT,
  SET_IS_SEARCHED,
  SET_LAST_ADDED_CONTACT,
  SET_LOADING_CONTACT,
  SET_LOADING_TAGS,
  SET_SEARCH_RESULT_CHECKED,
  SET_VIEW,
  UPDATE_CONTACT,
  UPDATE_CONTACTS_NOTES,
  UPDATE_CONTACTS_TAGS,
} from './types';

export type ContactsState = {
  contacts: Array<Contact>;
  searchedContacts: Array<Contact>;
  groupContacts: Array<Contact>;
  filteredContacts: Array<Contact>;
  loading: boolean;
  loadingContact: boolean;
  loadingTags: boolean;
  current: Contact | null;
  lastAddedContact: Contact | null;
  isFiltered: boolean;
  isSearched: boolean;
  error: unknown | null;
  viewSettings: Record<string, any>;
  contactsTotal: number | null;
  allContactsChecked: boolean;
  searchResultChecked: boolean;
  groupContactsTotal: number | null;
  dynamicGroupContactsTotal: number | null;
  currentUploadId: string | null;
  currentDynamicGroupId: string | null;
  setSearchResultChecked?: () => void;
};

export const initialContactsState: ContactsState = {
  contacts: [],
  searchedContacts: [],
  groupContacts: [],
  filteredContacts: [],
  contactsTotal: null,
  allContactsChecked: false,
  searchResultChecked: false,
  groupContactsTotal: null,
  dynamicGroupContactsTotal: null,
  currentUploadId: null,
  currentDynamicGroupId: null,
  current: null,
  lastAddedContact: null,
  isFiltered: false,
  error: null,
  loading: true,
  loadingContact: true,
  loadingTags: true,
  isSearched: false,
  viewSettings: getViewSettings(),
};

type ContactsData = {
  /** contact array */
  contacts: Contact[];
  /** total number of contacts */
  total: number;
};

export const ContactContext = createContext<{
  contactState: ContactsState;
  updateViewSettings: (key: string, value: boolean) => Promise<void>;
  getContact: (id: string) => Promise<Contact>;
  getAndSetCurrent: (id: string) => Promise<void>;
  getPartOfContacts: (params: getPortionOfContactsParams) => Promise<void>;
  getPartOfUploadsContacts: (
    uploadId: string,
    offset?: number
  ) => Promise<ContactsData | void>;
  addContact: (contact: NewContact) => void;
  addContactHttp: (contact: NewContact) => void;
  searchForContacts: (name: string, phone: string) => void;
  filterContacts: (params: any) => void;
  filterPartOfContacts: (
    params: any,
    dynamicGroupId: string,
    offset?: number
  ) => Promise<{ data: any; dynamicGroupId: string } | void>;
  clearSearchedContacts: () => void;
  archiveContact: (id: string) => void;
  archiveContacts: (contactIds: Array<string>) => void;
  updateContact: (contact: Contact) => void;
  updateContactHttp: (contact: Contact) => void;
  setCurrent: (contact: Contact | null) => void;
  setLastAddedContact: (contact: Contact | null) => void;
  setAllContactsChecked: (a: boolean) => void;
  setSearchResultChecked: (b: boolean) => void;
  setIsSearched: (searchStatus: boolean) => void;
  findAndSetCurrent: (contactId: string) => void;
  clearCurrent: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  getUserNotes: (contact_id: string | undefined) => Promise<void>;
  addNote: (note: AddNote) => Promise<void>;
  deleteNote: (id: string, contact_id: string) => Promise<void>;
  updateContactTags: (contactId: string, tagId: string) => void;
  deleteOneContactTag: (contactId: string, tagId: string, contact_tag: any) => void;
  setLoadingTags: (state: boolean) => void;
  bulkAddTags: (params: BulkAddTagsParams) => void;
  blockOneContact: (id: string, isBlocked: boolean) => void;
  getContactTags: (id: string) => Promise<void | Array<ContactTagItem>>;
}>({
  contactState: initialContactsState,
  updateViewSettings: () => Promise.resolve(),
  getContact: () => Promise.resolve({} as Contact),
  getAndSetCurrent: () => Promise.resolve(),
  getPartOfUploadsContacts: () => Promise.resolve(),
  getPartOfContacts: () => Promise.resolve(),
  addContact: () => Promise.resolve(),
  addContactHttp: () => Promise.resolve(),
  searchForContacts: () => Promise.resolve(),
  filterContacts: () => Promise.resolve(),
  filterPartOfContacts: () => Promise.resolve(),
  clearSearchedContacts: () => Promise.resolve(),
  archiveContact: () => Promise.resolve(),
  archiveContacts: () => Promise.resolve(),
  updateContact: () => Promise.resolve(),
  updateContactHttp: () => Promise.resolve(),
  setCurrent: () => Promise.resolve(),
  setLastAddedContact: () => Promise.resolve(),
  setAllContactsChecked: () => Promise.resolve(),
  setSearchResultChecked: () => Promise.resolve(),
  setIsSearched: () => Promise.resolve(),
  findAndSetCurrent: () => Promise.resolve(),
  clearCurrent: () => Promise.resolve(),
  isOpen: false,
  onOpen: () => Promise.resolve(),
  onClose: () => Promise.resolve(),
  getUserNotes: () => Promise.resolve(),
  addNote: () => Promise.resolve(),
  deleteNote: () => Promise.resolve(),
  updateContactTags: () => Promise.resolve(),
  deleteOneContactTag: () => Promise.resolve(),
  setLoadingTags: () => Promise.resolve(),
  bulkAddTags: () => Promise.resolve(),
  blockOneContact: () => Promise.resolve(),
  getContactTags: () => Promise.resolve(),
});

export const useContacts = () => useContext(ContactContext);

const ContactState = ({ children }: { children: React.ReactNode }) => {
  const [contactState, dispatch] = useReducer(ContactReducer, initialContactsState);

  const location = useLocation();
  const isContactsPage = location.pathname.includes('/contacts');

  // manage screen size state
  const isDesktop = useMedia('(min-width: 912px)');

  // manage open and close modal state
  const { isOpen, onOpen, onClose } = useDisclosure();

  const auth = useAuth();

  const { socket, joinChannel, handleConnect } = useSocket();
  const [contactsChannel, setContactsChannel] = useState<Channel | null>(null);

  // connect to organizations contacts channel
  useEffect(() => {
    if (!socket) {
      handleConnect();
    }
  }, [socket]);

  useEffect(() => {
    if (auth.organizationId) {
      const channel = joinChannel(`organization_contacts:${auth.organizationId}`);
      setContactsChannel(channel);
    }
  }, [auth.organizationId, socket]);

  // handle contact channel events
  useEffect(() => {
    if (!contactsChannel) return;

    contactsChannel.on(ARCHIVE_CONTACT, (payload: Record<string, any>) => {
      dispatch({
        type: ARCHIVE_CONTACT,
        payload: payload,
      });
    });

    contactsChannel.on(UPDATE_CONTACTS_TAGS, (payload: Record<string, any>) => {
      dispatch({
        type: UPDATE_CONTACTS_TAGS,
        payload: payload,
      });
    });

    contactsChannel.on(UPDATE_CONTACTS_NOTES, (payload: Record<string, any>) => {
      dispatch({
        type: UPDATE_CONTACTS_NOTES,
        payload: payload,
      });
    });
  }, [contactsChannel]);

  // controls the open/close state of contacts panel
  const updateViewSettings = async (key: string, value: boolean) => {
    setViewSettings(key, value);

    dispatch({
      type: SET_VIEW,
      payload: getViewSettings(),
    });
  };

  const getContact = async (id: string) => {
    try {
      const data = await API.getOneContact(id);
      return data;
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const getAndSetCurrent = async (id: string) => {
    try {
      dispatch({
        type: SET_LOADING_CONTACT,
        payload: true,
      });
      const data = await API.getOneContact(id);

      dispatch({
        type: SET_CURRENT,
        payload: data,
      });

      // return data;
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    } finally {
      dispatch({
        type: SET_LOADING_CONTACT,
        payload: false,
      });
    }
  };

  const searchForContacts = async (name: string, phone: string) => {
    try {
      const data = await API.searchContacts(name, phone);

      const fuse = new Fuse(data, {
        keys: ['name', 'phone'],
      });

      const results = fuse.search(name || phone).map((r) => r.item);

      dispatch({
        type: SEARCH_CONTACTS,
        payload: results,
      });
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  // this is used when we want to filter through all contacts
  // for example, when applying filters from the contacts all page
  const filterContacts = async (params: any) => {
    try {
      const data = await API.dynamicFilterContacts(params);
      dispatch({
        type: FILTER_CONTACTS,
        payload: data,
      });
      return data;
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const clearSearchedContacts = () => {
    dispatch({
      type: CLEAR_SEARCHED_CONTACTS,
      payload: [],
    });
  };

  const addContactHttp = async (contact: NewContact) => {
    try {
      const data = await API.createContact(contact);
      dispatch({
        type: ADD_CONTACT,
        payload: data,
      });
      // only set current if we are on the contacts page
      if (isContactsPage) {
        // If the request completes open drawer on mobile
        if (!isDesktop) {
          dispatch({ type: SET_CURRENT, payload: data });
          onOpen();
        }

        dispatch({ type: SET_CURRENT, payload: data });

        // comment out for now
        // toast.success(i18next.t('contact_added') as string);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err.response?.data.errors[0].description);
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response?.data.errors[0].description,
        });
      }
    }
  };

  const updateContactHttp = async (contact: Contact) => {
    try {
      const data = await API.updateContact(contact);

      dispatch({
        type: UPDATE_CONTACT,
        payload: data,
      });

      toast.success(i18next.t('contact_updated') as string);
    } catch (err) {
      if (err?.response?.data?.errors?.length > 0) {
        toast.error(err.response?.data?.errors[0]?.description);
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response?.data?.errors[0]?.description,
        });
      }
    }
  };

  const getPartOfContacts = async (params: getPortionOfContactsParams) => {
    try {
      const data = await API.getContacts(params);

      dispatch({
        type: GET_PORTION_OF_CONTACTS,
        payload: data,
      });
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const getPartOfUploadsContacts = async (uploadId: string, offset = 0) => {
    try {
      const data = await API.getContactsByGroup(uploadId, offset);

      dispatch({
        type: GET_PART_OF_CONTACTS_IN_GROUP,
        payload: { data: data, uploadId: uploadId },
      });

      return data;
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const filterPartOfContacts = async (params: any, dynamicGroupId: any, offset = 0) => {
    try {
      const data = await API.dynamicFilterPartOfContacts(params, offset);

      dispatch({
        type: FILTER_PART_OF_CONTACTS,
        payload: { data: data, dynamicGroupId: dynamicGroupId },
      });
      // maybe non null assertion would be better here
      return { data: data.contacts, dynamicGroupId: contactState.currentDynamicGroupId! };
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const addContact = (contact: NewContact) => {
    contactsChannel?.push(ADD_CONTACT, contact);
  };

  const archiveContact = (id: string) => {
    contactsChannel?.push(ARCHIVE_CONTACT, { id: id });

    toast.success(i18next.t('contact_deleted') as string);
  };

  const updateContact = (contact: Contact) => {
    contactsChannel?.push(UPDATE_CONTACT, contact);
  };

  const archiveContacts = async (contactIds: Array<string>) => {
    try {
      await API.deleteMultipleContacts(contactIds);

      dispatch({
        type: ARCHIVE_MULTIPLE_CONTACTS,
        payload: contactIds,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setCurrent = (contact: Contact | null) => {
    dispatch({ type: SET_CURRENT, payload: contact });
  };

  const setLastAddedContact = (contact: Contact | null) => {
    dispatch({ type: SET_LAST_ADDED_CONTACT, payload: contact });
  };

  const findAndSetCurrent = async (contactId: string) => {
    dispatch({
      type: SET_LOADING_CONTACT,
      payload: true,
    });
    const current = contactState.contacts.find((c: Contact) => c.id === contactId);

    if (current) {
      setCurrent(current);
    } else {
      const contact = await getOneContact(contactId);
      setCurrent(contact);
    }
    dispatch({
      type: SET_LOADING_CONTACT,
      payload: false,
    });
  };

  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  const getUserNotes = async (contact_id: string | undefined) => {
    try {
      const data = await API.getNotes(contact_id);

      dispatch({
        type: UPDATE_CONTACTS_NOTES,
        payload: { id: contact_id, notes: data },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const addNote = async (note: AddNote) => {
    contactsChannel?.push(ADD_NOTE, note);
  };

  const deleteNote = async (id: string, contact_id: string) => {
    contactsChannel?.push(DELETE_NOTE, { id: id, contact_id: contact_id });
  };

  const updateContactTags = async (contactId: string, tagId: string) => {
    contactsChannel?.push(CREATE_CONTACT_TAG, {
      contact_id: contactId,
      tag_id: tagId,
    });
  };

  const deleteOneContactTag = async (contactId: string, tagId: string) => {
    contactsChannel?.push(DELETE_CONTACT_TAG, {
      contact_id: contactId,
      contact_tag_id: tagId,
    });
  };

  const setLoadingTags = (state: boolean) => {
    dispatch({
      type: SET_LOADING_TAGS,
      payload: state,
    });
  };

  const setIsSearched = (searchStatus: boolean) => {
    dispatch({
      type: SET_IS_SEARCHED,
      payload: searchStatus,
    });
  };

  const bulkAddTags = async (params: BulkAddTagsParams) => {
    try {
      await API.bulkAddTagsToContacts(params);
      toast.success(i18next.t('tag_updated_success') as string);
    } catch (err) {
      console.error(err);
    }
  };

  const setAllContactsChecked = (checked: boolean) => {
    dispatch({
      type: SET_CONTACTS_CHECKED,
      payload: checked,
    });
  };

  const setSearchResultChecked = (checked: boolean) => {
    dispatch({
      type: SET_SEARCH_RESULT_CHECKED,
      payload: checked,
    });
  };

  const blockOneContact = async (id: string, isBlocked: boolean) => {
    const data = await API.blockContact(id, isBlocked);

    dispatch({
      type: BLOCK_CONTACT,
      payload: data,
    });
  };

  const getContactTags = async (id: string) => {
    try {
      const { data } = await API.getContactTags(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContactContext.Provider
      value={{
        contactState,
        addContact,
        addContactHttp,
        archiveContact,
        archiveContacts,
        setCurrent,
        setLastAddedContact,
        findAndSetCurrent,
        clearCurrent,
        updateContact,
        updateContactHttp,
        getContact,
        getAndSetCurrent,
        getPartOfUploadsContacts,
        getPartOfContacts,
        searchForContacts,
        filterContacts,
        filterPartOfContacts,
        clearSearchedContacts,
        updateViewSettings,
        isOpen,
        onClose,
        onOpen,
        getUserNotes,
        addNote,
        deleteNote,
        updateContactTags,
        deleteOneContactTag,
        setAllContactsChecked,
        setSearchResultChecked,
        setLoadingTags,
        setIsSearched,
        bulkAddTags,
        blockOneContact,
        getContactTags,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export default ContactState;
