import { useState } from 'react';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { Location } from '@/shared/types/locations';
import { Box } from '@/shared/ui';

import { ValueCombobox } from './Combobox';

type ChannelValuesComboboxProps = {
  selected: { label?: string; value: string | number } | null | undefined;
  onSelect: (option: { label: string; value: string | number }) => void;
  selectLabel?: string;
  searchLabel?: string;
  selectorStyles?: { [key: string]: any };
  isOpen?: boolean;
};

export const ChannelValuesCombobox = (props: ChannelValuesComboboxProps): JSX.Element => {
  const { selected, onSelect, selectLabel, searchLabel } = props;

  const [searchValue, setSearchValue] = useState('');

  const locationsContext = useLocations();
  const { locationsState } = locationsContext;
  const { locations } = locationsState;

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Box>
      <ValueCombobox
        isOpen={props.isOpen}
        selectorStyles={props.selectorStyles}
        options={locations.map((location: Location) => ({
          label: location.name || '',
          value: location.id || '',
        }))}
        selected={{ label: selected?.label || '', value: selected?.value || '' }}
        onSelect={onSelect}
        searchValue={searchValue}
        handleSearchValue={handleSearchValue}
        selectLabel={selectLabel}
        searchLabel={searchLabel}
      />
    </Box>
  );
};
