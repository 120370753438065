/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { languages } from '@/shared/components/editor/utils';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Box, Button, Fieldset, Flex, Label, VStack } from '@/shared/ui';

type LanguagePanelProps = {
  /* the conversation id */
  conversationId?: string;
  /* the language of the organization user for the given conversation */
  language?: string | null;
  /* the language of the contact for the given conversation */
  contact_language?: string | null;
  /* change the conversation languages */
  updateLanguages?: (language: string, contact_language: string) => void;
};

export const LanguagePanel = (props: LanguagePanelProps) => {
  const { language, contact_language } = props;

  // the language of the organization user
  const [sourceLanguage, setSourceLanguage] = useState(language || 'en');
  // the language of the contact
  const [translationLanguage, setTranslationLanguage] = useState(
    contact_language || 'en'
  );

  // using this to display the correct placeholder
  const getLanguageLabel = (value: string) => {
    return languages.find((language) => language.value === value)?.label;
  };

  // update the language of the conversation
  const handleSave = () => {
    setSourceLanguage(sourceLanguage);
    setTranslationLanguage(translationLanguage);
    props.updateLanguages?.(sourceLanguage, translationLanguage);
  };

  return (
    <Flex css={{ p: 20, width: '100%' }} direction="column" justify="between">
      <VStack css={{ width: '100%' }} gap="3">
        <Fieldset>
          <Label>Your Language</Label>
          <Box>
            <SingleSelect
              selectItem={sourceLanguage}
              setSelectItem={setSourceLanguage}
              closeOnClick={true}
              options={languages.map((language: { label: string; value: string }) => ({
                type: language.label,
                value: language.value,
              }))}
              defaultPlaceholder={
                getLanguageLabel(sourceLanguage) || 'Select source language'
              }
              isDropdown={true}
            />
          </Box>
        </Fieldset>
        <Fieldset>
          <Label>{"Contact's Language"}</Label>
          <SingleSelect
            selectItem={translationLanguage}
            setSelectItem={setTranslationLanguage}
            closeOnClick={true}
            options={languages.map((language: { label: string; value: string }) => ({
              type: language.label,
              value: language.value,
            }))}
            defaultPlaceholder={
              getLanguageLabel(translationLanguage) || 'Select translation language'
            }
            isDropdown={true}
          />
        </Fieldset>
        <Button onClick={() => handleSave()}>Save Language Settings</Button>
      </VStack>
    </Flex>
  );
};
