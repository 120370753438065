import { FormikValues, useFormikContext } from 'formik';
import React from 'react';

import {
  Flex,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio,
} from '@/shared/ui';

import { RadioInputProps } from './types';

/** A radio input that can be used in conjunction with Formik, must be placed within a FormFieldWrapper */
export function RadioInput(props: RadioInputProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  return (
    <RadioGroup
      defaultValue={formik.values[props.name as string]?.toString() as string}
      aria-label={props.ariaLabel}
    >
      {props.options.map((item, index: number) => (
        <Flex
          align="center"
          css={{ margin: '10px 0' }}
          key={`${props.ariaLabel}${index}`}
        >
          <RadioGroupRadio
            value={item.value as string}
            id={`${props.ariaLabel}${index}`}
            disabled={item.disabled}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              formik.setFieldValue(
                props.name as string,
                (e.target as HTMLButtonElement).value
              )
            }
          >
            <RadioGroupIndicator />
          </RadioGroupRadio>
          <Label htmlFor={`${props.ariaLabel}${index}`} css={{ margin: '0 0 0 10px' }}>
            {item.type}
          </Label>
        </Flex>
      ))}
    </RadioGroup>
  );
}
