import { DialogDescription } from '@ariakit/react';

import { Formik } from 'formik';
import { HiX } from 'react-icons/hi';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { bulkUpdateTags } from '@/shared/api/contacts/v2';
import { FormFieldWrapper } from '@/shared/components/forms';
import { FilterItem } from '@/shared/types/filter';
import { Tag } from '@/shared/types/tags';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  HStack,
  Label,
  VStack,
} from '@/shared/ui';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxMultiselectTrigger } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';
import { ComboboxMultiselectInput } from '@/shared/v2/components/forms/comboboxMultiselect/ComboboxMultiselectInput';
import { styled } from '@/stitches.config';
import { CampaignSourceTuple, SequenceSourceTuple } from '@/shared/api/sequences';
import { useEffect } from 'react';
import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';

type Props = {
  onOpenChange: (open: boolean) => void;
  filterSource?: CampaignSourceTuple | SequenceSourceTuple;
  open: boolean;
  totalContacts: number;
  filter: FilterItem[];
};

export const BulkTagModal = ({
  open,
  filterSource,
  totalContacts,
  filter,
  onOpenChange,
}: Props) => {
  const { tagsState, getTags } = useTags();
  const { tags } = tagsState;

  const resetModalState = (): void => {
    onOpenChange(false);
  };

  useEffect(() => {
    getTags();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = async (values: { tagIds: string[] }, _actions: any) => {
    const { tagIds } = values;
    toast.info('Requesting removal of tags');
    bulkUpdateTags('unassign', tagIds, filter, filterSource)
      // eslint-disable-next-line
      .then((_data) => {
        toast.success('Un-assigning tags from contacts in progress');
      })
      // eslint-disable-next-line
      .catch((error) => {
        console.error('Failed to un-assign tags with error: ', error);
        toast.error('Failed to un-assign tags from contacts');
      });
    resetModalState();
  };

  return (
    <Dialog open={open} modal={true}>
      <DialogTrigger asChild onClick={resetModalState}></DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              tagIds: [],
            }}
            validationSchema={Yup.object({
              tagIds: Yup.array().min(1, 'Please provide at least one tag'),
            })}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <DialogContent
                  css={{ width: 700 }}
                  onPointerDownOutside={resetModalState}
                  onEscapeKeyDown={resetModalState}
                >
                  <DialogHeader title="Unassign Tags to Contacts" />
                  <StyledDialogDescription>
                    Unassign the following tags from the chosen contacts.
                  </StyledDialogDescription>
                  <VStack gap={2}>
                    <FormFieldWrapper name="tagIds" label="Tag(s)">
                      <ComboboxMultiselectInput
                        options={tags.map((tag: Tag) => ({
                          label: tag.name,
                          value: tag.id,
                        }))}
                        searchLabel="Search"
                        selectLabel="Select Tag(s)"
                        Trigger={ComboboxMultiselectTrigger}
                        Option={ComboboxMultiselectItem}
                        disabled={!formik.isValid}
                        selectAll
                      />
                    </FormFieldWrapper>
                  </VStack>
                  <DialogClose asChild>
                    <DialogCloseIcon onClick={resetModalState} size="2">
                      <Box css={{ minWidth: 16 }} onClick={resetModalState}>
                        <HiX
                          size="15px"
                          style={{
                            color: '#ffffff',
                          }}
                        />
                      </Box>
                    </DialogCloseIcon>
                  </DialogClose>
                  <DialogFooter justify="between" css={{ mb: 12, mt: 0 }}>
                    <HStack
                      gap={1}
                      css={{ width: '100%', justifyContent: 'space-between' }}
                    >
                      <Label css={{ mb: 0 }}>
                        {totalContacts > 0 ? `Total Contacts: ${totalContacts}` : ''}
                      </Label>
                      <HStack>
                        <DialogClose asChild>
                          <Button
                            variant="gray"
                            css={{ mr: '$1' }}
                            onClick={resetModalState}
                          >
                            Cancel
                          </Button>
                        </DialogClose>
                        <DialogClose asChild>
                          {/* 
                          the submit button should also be disabled when no tags are selected 
                          in the beginning since we start with an empty list of tag ids
                          but we require at least one to be selected.
                          */}
                          <Button
                            type="submit"
                            disabled={!formik.isValid || !formik.dirty}
                          >
                            Confirm
                          </Button>
                        </DialogClose>
                      </HStack>
                    </HStack>
                  </DialogFooter>
                </DialogContent>
              </form>
            )}
          </Formik>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
const StyledDialogDescription = styled(DialogDescription, {
  marginBottom: '12px',
  fontSize: '15px',
  color: '$slate11',
});
