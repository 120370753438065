/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { HiX } from 'react-icons/hi';

import { ReplyTriggerType } from '@/shared/components/triggers/AddTrigger';
import { LinkClickTriggerType } from '@/shared/components/triggers/AddTrigger';
import { KeywordTriggerType } from '@/shared/components/triggers/AddTrigger';
import { KeywordAutomationsPreview } from '@/shared/components/triggers/previews/KeywordAutomationsPreview';
import { LinkClickAutomationsPreview } from '@/shared/components/triggers/previews/LinkClickAutomationsPreview';
import { ReplyAutomationsPreview } from '@/shared/components/triggers/previews/ReplyAutomationsPreview';
import { AutomationTemplate } from '@/shared/types/automations/index';
import { TriggerTypes } from '@/shared/types/triggers';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  Flex,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

type ViewAutomationTemplateDialogPropsType = {
  /* The template to view */
  template: AutomationTemplate | null;
  /* The action to perform when the dialog is closed */
  setIsDialogVisible: (isDialogVisible: boolean) => void;
  /* Are we at template selection dialog or not - fix double overlay */
  isTemplateSelectionDialog?: boolean;
};

export const ViewAutomationTemplateDialog = (
  props: ViewAutomationTemplateDialogPropsType
) => {
  const { template, setIsDialogVisible, isTemplateSelectionDialog } = props;

  const triggers = template?.triggers || [];

  const keyWordTriggers =
    (triggers?.filter(
      (trigger: { type: string }) =>
        trigger.type === TriggerTypes.KEYWORD || trigger.type === 'reply_with_keyword'
    ) as Array<KeywordTriggerType>) || [];

  const replyTriggers =
    (triggers?.filter(
      (trigger: { type: string }) => trigger.type === TriggerTypes.REPLY
    ) as Array<ReplyTriggerType>) || [];

  const linkClickTriggers =
    (triggers?.filter(
      (trigger: { type: string }) => trigger.type === TriggerTypes.LINK_CLICK
    ) as Array<LinkClickTriggerType>) || [];

  const handleClickCancel = () => {
    setIsDialogVisible(false);
  };

  return (
    <Dialog open={true} modal={true}>
      <DialogPortal>
        {!isTemplateSelectionDialog && <DialogOverlay />}
        <DialogContent
          css={{ width: 900 }}
          onEscapeKeyDown={() => setIsDialogVisible(false)}
          onPointerDownOutside={() => setIsDialogVisible(false)}
        >
          <DialogHeader title="Automation Template Preview" />
          <ScrollableContainer>
            <Box>
              <KeywordAutomationsPreview keyword_triggers={keyWordTriggers} />
            </Box>
            <Box>
              <ReplyAutomationsPreview reply_triggers={replyTriggers} />
            </Box>
            <Box>
              <LinkClickAutomationsPreview link_click_triggers={linkClickTriggers} />
            </Box>
          </ScrollableContainer>
          <Box style={{ paddingTop: '10px' }}>
            <Flex justify="end">
              <Button
                variant="gray"
                onClick={handleClickCancel}
                style={{ marginRight: '10px' }}
              >
                Close
              </Button>

              <Button
                variant="primary"
                onClick={handleClickCancel}
                style={{ marginRight: '10px' }}
              >
                <a
                  href={template ? `/automations/templates/${template.id}` : undefined}
                  target="_blank"
                  rel="noopener noreferrer" // this is for security reasons
                >
                  Go to Template
                </a>
              </Button>
            </Flex>
          </Box>
          <DialogCloseIcon onClick={() => setIsDialogVisible(false)} size="2">
            <HiX size="15px" style={{ color: 'white' }} />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

const ScrollableContainer = styled('div', {
  maxHeight: '300px',
  overflowY: 'auto',
  paddingRight: '10px', // to prevent the scrollbar from touching the content
});
