import { Invite } from '@/shared/types';
import {
  InviteParams,
  InvitesType,
  User,
  UserParams,
  UsersType,
  UserV2,
} from '@/shared/types/users';

import { api } from '../api';

/*
  GET - the organization user
*/

export const fetchUser = (id: number) =>
  api.get(`/users/${id}`).then((res) => {
    return res.data.data;
  });

/*
  GET - the organizations's users
*/

export const getUsers = (queryParams: string): Promise<Array<User>> =>
  api.get(queryParams ? `/users?${queryParams}` : `/users`).then((res) => {
    return res.data.data;
  });

export const searchUsers = (params: any): Promise<UsersType> =>
  api.post('/v2/users/search', { ...params }).then((res) => {
    const data = res.data.data.map((user: UserV2) => {
      return { ...user, locations: user.channels };
    });
    return { ...res.data, data };
  });

/*
  GET - the organization user
*/

export const getUser = (id: number): Promise<User | null> =>
  api.get(`/users/${id}`).then((res) => {
    return res.data.data;
  });

/*
  PUT - update a users's role
*/

export const updateUser = (userParams: Partial<UserParams>): Promise<User> =>
  api
    .put(`/users/${userParams.id}`, {
      user: userParams,
    })
    .then((res) => {
      return res.data.data;
    });

/*
  POST - enable a user
*/

export const createUser = (id: number): Promise<User> =>
  api.post('/users', { user_id: id }).then((res) => {
    return res.data.data;
  });

/*
  DELETE - disables and logs user out
*/

export const deleteUser = (id: number): Promise<null> =>
  api.delete(`/users/${id}`).then((res) => {
    return res.data.data;
  });

/*
  POST - add a new location to an organization
*/

export const createInvite = (
  params: InviteParams
): Promise<Array<Invite>> | Promise<User> =>
  api.post('/invites', { invite: params }).then((res) => {
    return res.data.data;
  });

/*
  GET - the organizations's pending invites
*/

export const getInvites = (params: any): Promise<InvitesType> =>
  api.post('/v2/invites/search', { ...params }).then((res) => {
    return res.data;
  });

/*
  DELETE - delete invite
*/

export const deleteInvite = (id: string): Promise<null> =>
  api.delete(`/invites/${id}`).then((res) => {
    return res.data.data;
  });
