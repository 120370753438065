import React, { useEffect, useRef, useState } from 'react';
import { HiX } from 'react-icons/hi';

import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { useDisclosure } from '@/shared/hooks';
import { SequenceStep } from '@/shared/types/sequences';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useSequences } from '../../context/SequenceContext';

type UpdateStepProps = {
  /** passed DOM element will be used for the DialogTrigger */
  children: React.ReactNode;
  /** the step to update */
  step?: SequenceStep | null;
};

export const UpdateStep = (props: UpdateStepProps) => {
  const { children, step } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isScheduled =
    step?.schedule_options.days !== '0' ||
    step?.schedule_options.hours !== '0' ||
    step?.schedule_options.minutes !== '0';

  const sequenceContext = useSequences();
  const { updateSequenceStep, sequencesState, createSequenceStep } = sequenceContext;
  const { current, currentSequenceSteps } = sequencesState;

  const [title, setTitle] = useState(step?.title || '');
  const [radioValue, setRadioValue] = useState(isScheduled ? 'schedule' : 'now');

  // message editor value
  const [message, setMessage] = useState(step?.body || '');

  // message editor ref
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // message attachments
  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: step?.attachment_urls || [],
  });

  // loading state for attachments
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);

  // scheduling params
  const [days, setDays] = useState(step?.schedule_options?.days || '1');
  const [hours, setHours] = useState(step?.schedule_options?.hours || '0');
  const [minutes, setMinutes] = useState(step?.schedule_options?.minutes || '0');
  const [timezone, setTimezone] = useState(
    step?.schedule_options?.timezone || 'America/Detroit'
  );

  useEffect(() => {
    // prevents states not updating when step is edited from
    // the ScheduleStep
    setRadioValue(isScheduled ? 'schedule' : 'now');
    setDays(step?.schedule_options.days || '1');
    setHours(step?.schedule_options.hours || '0');
    setMinutes(step?.schedule_options.minutes || '0');
    setTimezone(step?.schedule_options.timezone || 'America/Detroit');
  }, [isScheduled, step]);

  const scheduleOptions =
    radioValue === 'schedule'
      ? {
          days: days ? days : '1',
          hours: hours ? hours : '0',
          minutes: minutes ? minutes : '0',
          timezone: timezone,
        }
      : {
          days: '0',
          hours: '0',
          minutes: '0',
          timezone: timezone,
        };

  const onUpdate = () => {
    if (step) {
      const params = {
        id: step.id,
        sequence_id: current?.id || '',
        title: title,
        body: message,
        attachment_urls: attachments.attachment_urls,
        position: step.position,
        schedule_options: scheduleOptions,
      };
      updateSequenceStep(params);
      onClose();
    }
  };

  const clearStates = () => {
    setRadioValue('now');
    setTitle('');
    setMessage('');
    setAttachments({
      attachment_urls: [],
    });
    setDays('1');
    setHours('0');
    setMinutes('0');
  };

  const onCreate = () => {
    const params = {
      sequence_id: current?.id || '',
      body: message,
      title: title,
      attachment_urls: attachments.attachment_urls,
      position: currentSequenceSteps.length,
      schedule_options: scheduleOptions,
    };
    createSequenceStep(params);
    onClose();
    clearStates();
  };

  const handleSave = () => {
    if (step) {
      onUpdate();
    } else {
      onCreate();
    }
  };

  // are day, hours, minutes, and timezone filled out and valid numbers (string numbers)
  const isScheduleValid =
    !isNaN(Number(days)) &&
    !isNaN(Number(hours)) &&
    !isNaN(Number(minutes)) &&
    timezone !== '';

  return (
    <Dialog open={isOpen} modal={true}>
      <DialogTrigger onClick={onOpen}>{children}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent onEscapeKeyDown={onClose} onPointerDownOutside={onClose}>
            <DialogHeader title="Sequence Step" />
            <VStack gap="2">
              <Fieldset>
                <Label>Step Title (optional)</Label>
                <Input
                  placeholder="Title"
                  value={title}
                  onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                    setTitle(e.target.value)
                  }
                />
              </Fieldset>
              <Fieldset>
                <Label>Enter Message</Label>
                <MessageEditorV2
                  message={message}
                  setMessage={setMessage}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  attachmentLoading={attachmentsLoading}
                  setAttachmentLoading={setAttachmentsLoading}
                  textareaRef={textareaRef}
                  showAddVariable={true}
                  showAddTemplate={true}
                  showAddAttachment={true}
                  showAddEmoji={true}
                  enableAttachments={true}
                  showCharacterCount={true}
                />
              </Fieldset>
              <Fieldset>
                <Label>Select Timezone</Label>
                <TimezonePicker timezone={timezone} setTimezone={setTimezone} />
              </Fieldset>
              <Fieldset>
                <Label>Select Schedule</Label>
                <VStack gap="3">
                  <RadioGroup value={radioValue} onValueChange={setRadioValue}>
                    <Flex gap={2}>
                      <HStack align="center">
                        <RadioGroupRadio value="now" id="r1">
                          <RadioGroupIndicator />
                        </RadioGroupRadio>
                        <Label htmlFor="r1" css={{ m: 0, ml: 5 }}>
                          Send immediately
                        </Label>
                      </HStack>
                      <HStack align="center">
                        <RadioGroupRadio value="schedule" id="r2">
                          <RadioGroupIndicator />
                        </RadioGroupRadio>
                        <Label htmlFor="r2" css={{ m: 0, ml: 5 }}>
                          Send with delay
                        </Label>
                      </HStack>
                    </Flex>
                  </RadioGroup>
                  {radioValue === 'schedule' && (
                    <HStack>
                      <ScheduleInput
                        value={days}
                        onChange={(e: {
                          target: { value: React.SetStateAction<string> };
                        }) => setDays(e.target.value)}
                      />
                      <ScheduleText>days</ScheduleText>
                      <ScheduleInput
                        value={hours}
                        onChange={(e: {
                          target: { value: React.SetStateAction<string> };
                        }) => setHours(e.target.value)}
                      />
                      <ScheduleText>hours</ScheduleText>
                      <ScheduleInput
                        value={minutes}
                        onChange={(e: {
                          target: { value: React.SetStateAction<string> };
                        }) => setMinutes(e.target.value)}
                      />
                      <ScheduleText>minutes</ScheduleText>
                    </HStack>
                  )}
                </VStack>
              </Fieldset>
              <DialogFooter justify="end">
                <HStack>
                  <Button variant="gray" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSave}
                    disabled={!message || (radioValue === 'schedule' && !isScheduleValid)}
                  >
                    Save Step
                  </Button>
                </HStack>
              </DialogFooter>
            </VStack>
            <DialogCloseIcon onClick={onClose} size="2">
              <HiX size="15px" style={{ color: '#FFFFFF' }} />
            </DialogCloseIcon>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

const ScheduleInput = styled(Input, {
  maxHeight: 35,
  maxWidth: 55,
});

const ScheduleText = styled(Box, {
  fontWeight: 400,
  fontSize: 14,
  color: '#7F7F86',
});
