import { TriggerType } from '@/shared/components/triggers/AddTrigger';
import {
  DropdownFilterItem,
  ExtendedFilterItem,
  FilterItem,
  FilterParams,
  SortItem,
} from '@/shared/types/filter';

import { ConversationMessageType } from '../conversations';
import { Location } from '../locations';
import { User } from '../users';

export type Sequence = {
  /** sequence uuid */
  id?: string;
  /** sequence title */
  title?: string;
  /** sequence description */
  description?: string;
  /** sequence type */
  type?: SequenceType;
  /** sequence access level */
  access_level: SequenceAccessLevel;
  /** sequence status */
  status?: SequenceStatus;
  /** sequence creator */
  created_by?: User;
  /** sequence locations */
  locations?: Array<Location> | Array<string>;
  /** sequence steps */
  steps?: Array<SequenceStep>;
  /** sequence tab analytics */
  contact_tabs?: SequenceAnalytics;
  /** sequence last updated at */
  updated_at?: string;
  /** sequence created at */
  inserted_at?: string;
  /** sequence settings */
  settings: SequenceSettings;
  /** organization id */
  organization_id?: string | null;
};

export enum SequenceType {
  SEQUENTIAL = 'sequential',
  BRANCHED = 'branched',
}

export enum SequenceStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  DELETED = 'deleted',
  ARCHIVED = 'archived',
  TEMPLATE = 'template',
}

export type SequenceSettings = {
  /** what to do when a contact is re-added to the same sequence */
  on_re_add: OnReAdd;
  /** what to do when a contact responds to any sequence message */
  on_response: OnResponse;
  /** max adds per time - 5*/
  max_add_per_time: number;
  /** max adds per time in seconds - 86400 */
  max_add_time: number;
  /** max number of times a contact can be added to a sequence - 5*/
  max_contact_sequence_runs: number;
  /** max time a contact can be in a sequence - null*/
  max_time: number | null;
  /** abide by sms Quiet Hours */
  support_sms_quiet_hours: {
    enabled: boolean;
    use_contact_timezone: boolean;
  };
  /** pause sending on the weekend */
  skip_sending_on_weekend: {
    enabled: boolean;
    use_contact_timezone: boolean;
  };
  /** pause sending when business is closed */
  only_send_during_business_hours: {
    enabled: boolean;
    use_contact_timezone: boolean;
  };
  /** link tracking sequence settings */
  link_tracking: {
    disabled: boolean;
    domain_id: string | null;
  };
};

// What to do when a contact is re-added to the same sequence?
export enum OnReAdd {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}

// What to do when a contact responds to any sequence message?
export enum OnResponse {
  REMOVE = 'remove',
  CONTINUE = 'continue',
}

// Who has access to the sequence?
export enum SequenceAccessLevel {
  ORGANIZATION = 'organization',
  LOCATION = 'location',
  USER = 'user',
  GLOBAL = 'global',
}

// Where is a contact in the sequence?
export enum SequenceContactTabsType {
  FINISHED = 'finished',
  TOTAL = 'total',
  UNSUBSCRIBED = 'unsubscribed',
  CLICKED = 'clicked',
  RESPONDED = 'responded',
  UNRESPONDED = 'unresponded',
  ACTIVE = 'active',
  PENDING = 'pending',
}

export type SequenceAnalytics = {
  [key in SequenceContactTabsType]: number;
};

export type SequenceStep = {
  /** sequence step uuid */
  id?: string;
  /** sequence uuid */
  sequence_id: string;
  /** sequence step title */
  title?: string;
  /** sequence step message body */
  body?: string;
  /** sequence step attachment urls */
  attachment_urls?: Array<string>;
  /** sequence step position */
  position?: number;
  /** sequence step schedule offset from previous position */
  schedule_options: SequenceStepScheduleOptions;
};

export type SequenceStepScheduleOptions = {
  /** days from previous step */
  days?: string;
  /** hours from previous step */
  hours?: string;
  /** minutes from previous step */
  minutes?: string;
  /** timezone for schedule options */
  timezone?: string;
};

// Reducer action types
export enum SequenceActionTypes {
  GET_SEQUENCES = 'GET_SEQUENCES',
  GET_SEQUENCE = 'GET_SEQUENCE',
  ADD_SEQUENCE = 'ADD_SEQUENCE',
  ADD_SEQUENCE_STEP = 'ADD_SEQUENCE_STEP',
  UPDATE_SEQUENCE = 'UPDATE_SEQUENCE',
  DELETE_SEQUENCE = 'DELETE_SEQUENCE',
  GET_SEQUENCE_STEPS = 'GET_SEQUENCE_STEPS',
  GET_SEQUENCE_STEP = 'GET_SEQUENCE_STEP',
  CREATE_SEQUENCE_STEP = 'CREATE_SEQUENCE_STEP',
  UPDATE_SEQUENCE_STEP = 'UPDATE_SEQUENCE_STEP',
  ADD_CONTACT_TO_SEQUENCE = 'ADD_CONTACT_TO_SEQUENCE',
  SET_LOADING = 'SET_LOADING',
  SET_LOADING_CONTACTS = 'SET_LOADING_CONTACTS',
  SET_CURRENT = 'SET_CURRENT',
  DELETE_STEP = 'DELETE_STEP',
  GET_SEQUENCE_CONTACTS = 'GET_SEQUENCE_CONTACTS',
  GET_SEQUENCE_CONTACTS_NEW_SEARCH = 'GET_SEQUENCE_CONTACTS_NEW_SEARCH',
  SET_SEQUENCE_CONTACTS_PARAMS = 'SET_SEQUENCE_CONTACTS_PARAMS',
  SET_SEQUENCE_CONTACTS = 'SET_SEQUENCE_CONTACTS',
  SET_SEQUENCE_CONTACTS_TAB = 'SET_SEQUENCE_CONTACTS_TAB',
  RESET_SEQUENCE_CONTACTS = 'RESET_SEQUENCE_CONTACTS',
  UPDATE_SEQUENCE_STEP_CONTACT = 'UPDATE_SEQUENCE_STEP_CONTACT',
  REMOVE_CONTACTS_FROM_SEQUENCE = 'REMOVE_CONTACTS_FROM_SEQUENCE',
  GET_SEQUENCE_TEMPLATES = 'GET_SEQUENCE_TEMPLATES',
  CREATE_SEQUENCE_TEMPLATE = 'CREATE_SEQUENCE_TEMPLATE',
  UPDATE_SEQUENCE_TEMPLATE = 'UPDATE_SEQUENCE_TEMPLATE',
  DELETE_SEQUENCE_TEMPLATE = 'DELETE_SEQUENCE_TEMPLATE',
  SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE',
  SET_LOADING_TEMPLATES = 'SET_LOADING_TEMPLATES',
  GET_SEQUENCE_RESPONSES = 'GET_SEQUENCE_RESPONSES',
  GET_SEQUENCE_RESPONSES_NEW_SEARCH = 'GET_SEQUENCE_RESPONSES_NEW_SEARCH',
  SET_SEQUENCE_RESPONSES_LOADING = 'SET_SEQUENCE_RESPONSES_LOADING',
  SET_SEQUENCE_RESPONSES_PARAMS = 'SET_SEQUENCE_RESPONSES_PARAMS',
}

export type SequenceActions =
  | {
      type: SequenceActionTypes.GET_SEQUENCES;
      payload: {
        sequences: Array<Sequence> | Array<null>;
        total: number;
        offset: number;
      };
    }
  | { type: SequenceActionTypes.GET_SEQUENCE; payload: Sequence | null }
  | { type: SequenceActionTypes.ADD_SEQUENCE; payload: Sequence }
  | { type: SequenceActionTypes.DELETE_SEQUENCE; payload: string }
  | { type: SequenceActionTypes.ADD_SEQUENCE_STEP; payload: SequenceStep }
  | { type: SequenceActionTypes.UPDATE_SEQUENCE; payload: Sequence }
  | { type: SequenceActionTypes.GET_SEQUENCE_STEPS; payload: Array<SequenceStep> }
  | { type: SequenceActionTypes.GET_SEQUENCE_STEP; payload: SequenceStep }
  | { type: SequenceActionTypes.CREATE_SEQUENCE_STEP; payload: SequenceStep }
  | { type: SequenceActionTypes.UPDATE_SEQUENCE_STEP; payload: SequenceStep }
  | { type: SequenceActionTypes.ADD_CONTACT_TO_SEQUENCE }
  | { type: SequenceActionTypes.REMOVE_CONTACTS_FROM_SEQUENCE; payload: any }
  | { type: SequenceActionTypes.SET_LOADING; payload: boolean }
  | { type: SequenceActionTypes.SET_CURRENT; payload: Sequence | null }
  | { type: SequenceActionTypes.DELETE_STEP; payload: string }
  | {
      type: SequenceActionTypes.GET_SEQUENCE_CONTACTS;
      payload: SequenceContacts;
    }
  | {
      type: SequenceActionTypes.GET_SEQUENCE_CONTACTS_NEW_SEARCH;
      payload: SequenceContacts;
    }
  | {
      type: SequenceActionTypes.SET_SEQUENCE_CONTACTS_PARAMS;
      payload: any;
    }
  | {
      type: SequenceActionTypes.SET_SEQUENCE_CONTACTS_TAB;
      payload: SequenceContactTabsType;
    }
  | { type: SequenceActionTypes.RESET_SEQUENCE_CONTACTS; payload: Array<null> }
  | { type: SequenceActionTypes.SET_LOADING_CONTACTS; payload: boolean }
  | {
      type: SequenceActionTypes.UPDATE_SEQUENCE_STEP_CONTACT;
      payload: SequenceStepContact;
    }
  | {
      type: SequenceActionTypes.GET_SEQUENCE_TEMPLATES;
      payload: { data: Array<Sequence> | Array<null>; total: number; offset: number };
    }
  | {
      type: SequenceActionTypes.CREATE_SEQUENCE_TEMPLATE;
      payload: Sequence;
    }
  | {
      type: SequenceActionTypes.UPDATE_SEQUENCE_TEMPLATE;
      payload: Sequence;
    }
  | {
      type: SequenceActionTypes.DELETE_SEQUENCE_TEMPLATE;
      payload: string;
    }
  | {
      type: SequenceActionTypes.SET_CURRENT_TEMPLATE;
      payload: Sequence | null;
    }
  | {
      type: SequenceActionTypes.SET_LOADING_TEMPLATES;
      payload: boolean;
    }
  | {
      type: SequenceActionTypes.GET_SEQUENCE_RESPONSES;
      payload: SequenceResponses;
    }
  | {
      type: SequenceActionTypes.GET_SEQUENCE_RESPONSES_NEW_SEARCH;
      payload: SequenceResponses;
    }
  | {
      type: SequenceActionTypes.SET_SEQUENCE_RESPONSES_LOADING;
      payload: boolean;
    }
  | {
      type: SequenceActionTypes.SET_SEQUENCE_RESPONSES_PARAMS;
      payload: FilterParams;
    };

export type SequenceStepContact = {
  /* sequence step contact id */
  id: string;
  /* sequence step id */
  step_id: string;
  /* location id */
  location_id: string;
  /* contact object */
  contact: {
    /* contact id */
    id: string;
    /* contact name */
    name: string;
    /* contact email */
    email: string;
    /* contact phone */
    phone: string;
  };
  /* contact responses */
  contact_messages: Array<{
    /* conversation message body */
    body: string;
    /* conversation contact uui */
    contact_id: string;
    /* conversation uuid */
    conversation_id: string;
    /* conversation message delivery status */
    delivery_status: string | null;
    /* message uuid */
    id: string;
    /* conversation message inserted_at */
    inserted_at: string;
    /* conversation message updated_at */
    updated_at: string;
  }>;
  /* sequence step contact status */
  status: SequenceContactStatusType;
  /* did the contact click the link */
  clicked_link: boolean;
  /* did the contact unsubscribe to the message */
  unsubscribed: boolean;
  /* user who added the contact to a sequence */
  added_by_id: number | null;
  /* contact scheduled_at */
  scheduled_at: string | null;
  /* sequence run inserted_at */
  run_inserted_at: string;
  /* sequence run updated_at */
  run_updated_at: string;
  /* contact step created_at */
  inserted_at: string;
  /* contact step updated_at */
  updated_at: string;
};

export type SequenceResponseStepContact = {
  /* sequence step contact id */
  id: string;
  /* sequence step id */
  step_id: string;
  /* sequence step contact status */
  status: SequenceContactStatusType;
  /* did the contact click the link */
  clicked_link: boolean;
  /* did the contact unsubscribe to the message */
  unsubscribed: boolean;
  /* contact responses */
  contact_messages: Array<ConversationMessageType>;
};

export type SequenceResponseContact = {
  /* sequence step contact id */
  id: string;
  /* location id */
  location_id: string;
  /* contact object */
  contact: {
    /* contact id */
    id: string;
    /* contact name */
    name: string;
    /* contact email */
    email: string;
    /* contact phone */
    phone: string;
  };
  step_contacts: SequenceResponseStepContact[];
  /* user who added the contact to a sequence */
  added_by_id: number;
  /* contact step created_at */
  inserted_at: string;
  /* contact step updated_at */
  updated_at: string;
};

export enum SequenceContactStatusType {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  COMPLETE = 'complete',
  REMOVED = 'removed',
  FINISHED = 'finished',
  MOVED = 'moved',
}

export type GetSequencesParams = {
  /* filter by title */
  title?: string;
  /* filter by location_ids */
  location_ids?: Array<string>;
  /* filter by user_ids */
  user_ids?: Array<number>;
  /* access level */
  access_level?: SequenceAccessLevel;
  /* sequence type */
  status?: SequenceStatus;
  /* limit results */
  limit?: number;
  /* offset results */
  offset?: number;
};

export type SequenceContactsParams = {
  /* an extended filter param */
  extended_filter?: ExtendedFilterItem[];
  /* array of filter objects */
  filter: FilterItem[];
  /* array of sort objects */
  sort: SortItem[];
  /* number of contacts to fetch */
  limit: number;
  /* offset of the contacts to fetch */
  offset: number;
};

export type SequenceResponsesParams = {
  /* array of filter objects */
  filter: FilterItem[];
  /* array of sort objects */
  sort: SortItem[];
  /* number of contacts to fetch */
  limit: number;
  /* offset of the contacts to fetch */
  offset: number;
};

export type SequenceStepTrigger = {
  /* sequence step trigger uuid */
  id?: string;
  /* sequence step trigger object */
  trigger: TriggerType;
  /* when the sequence step trigger was created */
  inserted_at?: string;
  /* optional step id - this is only merged in with the flow builder */
  step_id?: string | null;
};

export type AddContactsToSequenceResponse = {
  /* description of the response action */
  message: string;
  /* sequence uuid */
  sequence_id: string;
  /* sequence step uuid */
  sequence_step_id: string;
};

export type RemoveContactsFromSequenceResponse = AddContactsToSequenceResponse;

// Different types of options for creating a new sequence
export enum CreateSequenceStepType {
  NEW = 'new',
  TEMPLATE = 'template',
  CLONE = 'clone',
}

export type SequenceRun = {
  /* sequence run id */
  id: string;
  /* contact in the sequence run */
  contact: {
    /* contact id */
    id: string;
    /* contact name */
    name: string;
    /* contact email */
    email: string;
    /* contact phone */
    phone: string;
    /* contact source */
    source: string;
  };
  /* sequence step contacts */
  step_contacts: Array<SequenceStepContact>;
  /* sequence run inserted_at */
  inserted_at: string;
  /* sequence run updated_at */
  updated_at: string;
};

export type SequenceContacts = {
  data: Array<SequenceStepContact>;
  total: number;
};

export type SequenceResponse = {
  contact: {
    email: string;
    id: string;
    name: string;
    phone: string;
    source: null;
  };
  id: string;
  inserted_at: string;
  step_contacts: [
    {
      clicked_link: boolean;
      contact_messages: ConversationMessageType[];
      id: string;
      status: SequenceContactStatusType;
      step_id: string;
      unsubscribed: boolean;
    },
  ];
  updated_at: string;
};

export type SequenceResponses = {
  data: Array<SequenceResponse>;
  total: number;
};

export type SequenceBulkActionFilter = {
  extended_filter?: Array<ExtendedFilterItem>;
  filter: Array<FilterItem | DropdownFilterItem | null>;
};
