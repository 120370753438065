import { styled } from '@stitches/react';

import { Box, Divider, VStack } from '@/shared/ui';

import { ToggleIconClose, ToggleIconOpen } from '../Icons';
import {
  sideNavigationTabType,
  sideNavigationType,
  SideToggleButton,
} from './SideNavigation';
import { SideNavigationHeading, SideNavigationTooltipButton } from './SideNavigationItem';

const SideBarHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export function SideBar({
  header,
  sideNavigationTabs,
  expanded,
  toggleExpandedState,
  setShowDrawer,
  showToggleButton,
  children,
  hideDivider,
}: sideNavigationType & {
  setShowDrawer?: (state: boolean) => void;
  showToggleButton?: boolean;
}) {
  const filteredSideNavigation = () => {
    return sideNavigationTabs.map((navigationGroup) => {
      return {
        title: navigationGroup.title,
        sideNavigationGroup: navigationGroup.sideNavigationGroup.filter(
          (navigation) => !navigation.hidden
        ),
      };
    });
  };

  return (
    <>
      <Box css={{ paddingLeft: expanded ? 8 : 10, paddingRight: expanded ? 8 : 3 }}>
        {expanded ? (
          <SideBarHeader>
            <SideNavigationHeading
              data-testid="side-navigation-heading"
              css={{ flexWrap: 'wrap', gap: '0 10px ' }}
              align="center"
            >
              {header}
            </SideNavigationHeading>
            {showToggleButton && (
              <SideToggleButton css={{ margin: 0 }} onClick={toggleExpandedState}>
                <div style={{ transform: 'rotate(180deg)' }}>
                  <ToggleIconOpen />
                </div>
              </SideToggleButton>
            )}
          </SideBarHeader>
        ) : (
          <SideToggleButton
            onMouseLeave={() => setShowDrawer && setShowDrawer(false)}
            onMouseOver={() => setShowDrawer && setShowDrawer(true)}
            onClick={toggleExpandedState}
          >
            <div style={{ transform: 'rotate(180deg)' }}>
              <ToggleIconClose />
            </div>
          </SideToggleButton>
        )}
        <VStack gap={expanded ? 1 : 4}>
          {filteredSideNavigation().map((navigationGroup, index: number) => (
            <div key={index}>
              {navigationGroup.title && expanded && (
                <SideNavigationHeading
                  data-testid="side-navigation-heading"
                  css={{ flexWrap: 'wrap', gap: '0 10px ', marginTop: '15px' }}
                  align="center"
                >
                  {navigationGroup.title}
                </SideNavigationHeading>
              )}
              <VStack
                gap={expanded ? 1 : 4}
                key={index}
                data-testid="side-navigation-group"
              >
                {navigationGroup.sideNavigationGroup.map(
                  (navigation: sideNavigationTabType) => {
                    return (
                      <SideNavigationTooltipButton
                        key={navigation.title}
                        navigation={navigation}
                        expanded={expanded}
                      />
                    );
                  }
                )}
                {sideNavigationTabs.length - 1 !== index && !hideDivider && (
                  <Divider css={{ my: 15 }} />
                )}
              </VStack>
            </div>
          ))}
        </VStack>
      </Box>
      {expanded && children}
    </>
  );
}
