import React from 'react';

import { ContactEditor } from '@/contacts/editor';

type ContactPanelProps = {
  contactId?: string;
};

export const ContactPanel = (props: ContactPanelProps) => {
  return <ContactEditor isInbox={true} contactId={props.contactId} />;
};
