/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';

import { DomainSelect } from '@/shared/components/domainSelect/DomainSelect';
import { LocationSettings } from '@/shared/types/locations';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Divider,
  Flex,
  Label,
  Switch,
  SwitchThumb,
  Text,
  VStack,
} from '@/shared/ui';

import { useLocations } from '../context/LocationContext';

export const LinkTracking = () => {
  const {
    locationsState: { current },
    updateLocation,
  } = useLocations();

  const [domainId, setDomainId] = useState('');

  const handleLinkTrackingUpdate = useCallback(
    async (value: boolean) => {
      // If no current location or its id, exit the function early
      if (!current || !current.id) {
        return;
      }
      await updateLocation({
        ...current,
        settings: {
          ...current?.settings,
          link_tracking: {
            ...current?.settings?.link_tracking,
            disabled: value,
          },
        } as LocationSettings,
      });
    },
    [current]
  );

  const handleChangeDomain = useCallback(
    async (newDomainId: string) => {
      setDomainId(newDomainId);
      // If no current location or its id, exit the function early
      if (!current || !current.id) {
        return;
      }
      await updateLocation({
        ...current,
        settings: {
          ...current?.settings,
          link_tracking: {
            ...current?.settings?.link_tracking,
            default_domain_id: newDomainId || null,
          },
        } as LocationSettings,
      });
    },
    [current]
  );

  return (
    <Accordion
      css={{ background: 'white' }}
      type="single"
      collapsible
      data-testid="location-link-tracking"
    >
      <AccordionItem value="basic-information" variant="neumorphic">
        <Flex justify="between" align="center">
          <VStack gap={2} css={{ lineHeight: 1.5 }}>
            <Text css={{ fontWeight: 600 }}>Link Tracking Settings</Text>
            <Text>
              You can disable link tracking for this location only. Links will no longer
              be shortened by Whippy. Using long links or third-party URL shorteners
              increase the risk that your messages will be flagged for SPAM
            </Text>
          </VStack>
          <AccordionTrigger />
        </Flex>
        <AccordionContent variant="neumorphic">
          <Divider css={{ mt: -20, mb: 20 }} />
          <Flex
            align="center"
            justify="between"
            data-testid="location-link-tracking-disable-toggle"
          >
            <Label>Disable</Label>
            <Switch
              checked={current?.settings?.link_tracking?.disabled}
              onCheckedChange={handleLinkTrackingUpdate}
            >
              <SwitchThumb />
            </Switch>
          </Flex>
          <DomainSelect
            value={domainId || current?.settings?.link_tracking?.default_domain_id || ''}
            onChange={handleChangeDomain}
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
