export type tabDataType = {
  title: string;
  path: string;
  hidden: boolean;
  dropdown?: Array<tabDataType>;
};

export type navigationDataType = {
  showSearch: boolean;
  tabsData: Array<tabDataType>;
};

const tabsData: Array<tabDataType> = [
  {
    title: 'Inbox',
    path: '/inbox',
    hidden: false,
  },
  {
    title: 'Data',
    path: '/data/contacts',
    hidden: false,
  },
  {
    title: 'Contacts',
    path: '/contacts',
    hidden: false,
  },
  {
    title: 'Campaigns',
    path: '/campaigns',
    hidden: false,
    dropdown: [
      {
        title: 'SMS',
        path: '/campaigns',
        hidden: false,
      },
      {
        title: 'Links',
        path: '/campaigns/links',
        hidden: false,
      },
      {
        title: 'Domains',
        path: '/campaigns/domains',
        hidden: true,
      },
    ],
  },
  {
    title: 'Sequences',
    path: '/sequences',
    hidden: false,
  },
  {
    title: 'Agents',
    path: '/ai/agents',
    hidden: false,
  },
  {
    title: 'Automations',
    path: '/automations/keywords',
    hidden: false,
  },
  {
    title: 'Reviews',
    path: '/reviews/all',
    hidden: false,
  },
  {
    title: 'Reports',
    path: '/reports/overview',
    hidden: false,
  },
];

export const navigationData: navigationDataType = {
  showSearch: true,
  tabsData,
};
