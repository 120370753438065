/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import React, { useEffect } from 'react';

import { useAuth } from '@/auth/context/AuthProvider';
import { Button, Fieldset, Input, ProgressBar, VStack } from '@/shared/ui';

import { useOnboarding } from '../context/OnboardingProvider';
import {
  OnboardingContainer,
  OnboardingDescription,
  OnboardingHeading,
  SkipOnboarding,
} from '../OnboardingContainer';

const FindWebsite = () => {
  const onboarding = useOnboarding();
  const auth = useAuth();

  const { setCurrentStep, setOrganizationDetails, organizationDetails } = onboarding;

  useEffect(() => {
    setOrganizationDetails({
      website: auth?.organizationInfo?.organization?.website_url,
    });
  }, [auth.organizationInfo]);

  return (
    <>
      <ProgressBar value={(2 / 5) * 100} variant="purple" />
      <SkipOnboarding />
      <OnboardingContainer>
        <OnboardingHeading>What is your website?</OnboardingHeading>
        <OnboardingDescription>
          Whippy helps you convert more website visitors into real customers and know
          where these customers found you.
        </OnboardingDescription>
        <Fieldset>
          <VStack gap={2} css={{ width: '100%' }}>
            <Input
              defaultValue={organizationDetails?.website || ''}
              onChange={debounce(
                (e: React.ChangeEvent<HTMLInputElement>) =>
                  setOrganizationDetails({
                    website: e.target.value,
                  }),
                700
              )}
            />
            <Button onClick={() => setCurrentStep('install_webchat')}>Continue</Button>
            <Button
              onClick={() => setCurrentStep('install_webchat')}
              variant="gray"
              ghost="true"
            >
              {"I'll do this later"}
            </Button>
          </VStack>
        </Fieldset>
      </OnboardingContainer>
    </>
  );
};

export default FindWebsite;
