import { Call } from '@/shared/types/calls';
import { FilterItem, Sort } from '@/shared/types/filter';

import { api } from '../api';

export const searchCalls = async (
  filter: Array<FilterItem> = [],
  sort: Array<Sort> = [],
  limit = 100,
  offset = 0
): Promise<{ data: Call[]; total: number }> => {
  const { data } = await api.post('v2/calls/search', {
    filter,
    sort,
    limit,
    offset,
  });
  return data;
};

export const getCall = async (id: string): Promise<{ data: Call }> => {
  const { data } = await api.get(`v2/calls/${id}`);
  return data;
};

type CreatePreviewCall = {
  agent_id: string;
  channel_id: string;
  to: string;
};

export const createPreviewCall = async (
  params: CreatePreviewCall
): Promise<{ data: { message: string } }> => {
  const { data } = await api.post(`v2/agents/${params.agent_id}/call`, params);
  return data;
};
