/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { HiCloudUpload, HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Divider,
  Flex,
  HStack,
  Input,
  Label,
  VStack,
} from '@/shared/ui';
import { Kbd } from '@/shared/ui/Kbd';

import { PasteContacts } from '../paste';
import { BulkImport } from './BulkImport';
import { useUploads } from './context/UploadContext';

type CreateUploadProps = {
  open?: boolean;
  setOpen?: (value: boolean) => void;
  children?: React.ReactNode;
  navigateTo?: string;
};

export const CreateUpload = (props: CreateUploadProps) => {
  const history = useHistory();
  const { children } = props;
  const { uploadName, setUploadName, setUploadLocations } = useUploads();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const locationsContext = useLocations();
  const { locationsState } = locationsContext;

  const locations = locationsState.allLocations || [];

  const [uploadNameLength, setUploadNameLength] = React.useState(0);
  const [selectedLocations, setSelectedLocations] = React.useState({
    locations: locations.map((location) => location.id) || [],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadName(e.target.value);
    setUploadNameLength(e.target.value.length);
  };

  const closeDialog = (setLocationsToDefault: boolean) => {
    onClose();
    props.setOpen ? props.setOpen(false) : null;
    setLocationsToDefault
      ? setSelectedLocations({
          locations: locations.map((location) => location.id) || [],
        })
      : null;
  };

  const onSuccess = (id: string) => {
    history.push(`${props.navigateTo}${id}`);
    closeDialog(true);
  };

  // if props.open is true open the dialog
  useEffect(() => {
    if (props.open !== undefined) {
      if (props.open) {
        onOpen();
      }
    }
  }, [props.open]);

  useEffect(() => {
    setUploadLocations(selectedLocations.locations);
  }, [selectedLocations.locations]);

  return (
    <>
      <Dialog open={isOpen} modal={false}>
        <DialogTrigger asChild>
          <Box onClick={() => onOpen()}>{children}</Box>
        </DialogTrigger>
        <DialogPortal>
          <DialogOverlay as="div" css={{ zIndex: 999999 }}>
            <DialogContent
              css={{ zIndex: 999999 }}
              onEscapeKeyDown={() => closeDialog(true)}
              onPointerDownOutside={() => closeDialog(true)}
            >
              <PasteContacts onSuccess={onSuccess} />
              <DialogHeader title="Create New Upload" />
              <VStack gap={6} data-testid="upload-contacts-dialog">
                <VStack gap={3}>
                  <Box>
                    <Label htmlFor="name">
                      <Flex>
                        Name Upload{' '}
                        <Box css={{ color: 'red', ml: 5 }}>
                          {uploadNameLength > 28
                            ? '(max 30 characters)'
                            : '(name must be unique)'}
                        </Box>
                      </Flex>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      value={uploadName}
                      maxLength={29}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(e)
                      }
                    />
                  </Box>
                  <Box>
                    <Label htmlFor="locations">
                      <Flex>
                        Opt in to{' '}
                        <Box css={{ color: 'red', ml: 5 }}>
                          {selectedLocations.locations.length < 1
                            ? '(at least one location is required)'
                            : ''}
                        </Box>
                      </Flex>
                    </Label>
                    {locations.length > 0 && (
                      <MultiSelect
                        defaultPlaceholder="Locations"
                        defaultSelectedItems={selectedLocations.locations}
                        isDropdown={true}
                        options={locations.map((location: any) => ({
                          type: location?.name || '',
                          value: location.id,
                        }))}
                        parentSelectedItems={selectedLocations}
                        setParentSelectedItems={setSelectedLocations}
                        isCampaigns={true}
                      />
                    )}
                  </Box>
                </VStack>
                <BulkImport onSuccess={onSuccess}>
                  <Button
                    variant="gray"
                    size={4}
                    as="div"
                    onClick={() => closeDialog(false)}
                    css={{ width: '100%' }}
                  >
                    <HiCloudUpload size={16} /> Click to Upload a File
                  </Button>
                </BulkImport>
                <Flex align="center">
                  <Divider />
                  <Box css={{ px: 10, fontWeight: 500 }}>OR</Box>
                  <Divider />
                </Flex>
                <Flex
                  css={{
                    width: '100%',
                    height: 100,
                    border: '2px dotted $slate8',
                    borderRadius: '6px',
                  }}
                  align="center"
                  justify="center"
                >
                  <VStack gap={2}>
                    <Flex
                      align="center"
                      justify="center"
                      css={{ width: '100%', fontSize: 13 }}
                    >
                      Paste Numbers from Clipboard
                    </Flex>
                    <HStack
                      align="center"
                      css={{ width: '100%', justifyContent: 'center' }}
                    >
                      <Kbd>CMD ⌘</Kbd>
                      <Kbd>+</Kbd>
                      <Kbd>V</Kbd>
                    </HStack>
                  </VStack>
                </Flex>
              </VStack>
              <DialogClose asChild>
                <DialogCloseIcon onClick={() => closeDialog(true)} size="2">
                  <HiX size="15px" style={{ color: 'white' }} />
                </DialogCloseIcon>
              </DialogClose>
            </DialogContent>
          </DialogOverlay>
        </DialogPortal>
      </Dialog>
    </>
  );
};
