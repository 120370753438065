import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Agent } from '@/shared/types/ai/agents';
import { FilterType, Sort } from '@/shared/types/filter';
import { Box, Button, Flex, HStack, IconButton, Text } from '@/shared/ui';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';

import { useLLMAgent } from '../context/AgentsContext';
import { CreateAgent } from '../editor/CreateAgent';
import {
  created_by,
  current_agent_version_object,
  default_agents_object,
  updated_by,
} from './config/filterConfig';
import { sortConfig } from './config/sortConfig';

export const Agents = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [activeSort, setActiveSort] = useState<Sort[]>([
    {
      label: 'Updated At',
      column: 'updated_at',
      resource: 'agent',
      order: 'desc',
      id: 1,
    },
  ]);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  // Default filter for agents that are not archived
  const default_status_filter = {
    resource: 'agent',
    column: 'status',
    comparison: '!=',
    value: 'archived',
  };

  const agentContext = useLLMAgent();
  const { fetchAgents, state } = agentContext;
  const { agents, loading } = state;

  useEffect(() => {
    fetchAgents(mergeDefaultStatusFilter(cleanFilters(activeFilters)), [], limit, offset);
  }, []);

  const history = useHistory();

  const onCardClick = (id: string) => {
    history.push(`/ai/agents/${id}/editor`);
  };

  const updateFilters = useCallback((filter: Array<FilterType>) => {
    setActiveFilters(filter);
  }, []);

  const mergeDefaultStatusFilter = useCallback(
    (filter: Array<FilterType>) => {
      const quickFilter = quickSearchValue
        ? {
            resource: 'current_version',
            column: 'name',
            comparison: 'ilike',
            value: `%${quickSearchValue}%`,
          }
        : null;

      return quickFilter
        ? [...filter, default_status_filter, quickFilter]
        : [...filter, default_status_filter];
    },
    [quickSearchValue]
  );

  const debouncedFetchAgents = useCallback(
    debounce(async (filter: Array<FilterType>) => {
      await fetchAgents(
        mergeDefaultStatusFilter(cleanFilters(filter)),
        [],
        limit,
        offset
      );
    }, 500),
    []
  );

  const handleFilterChange = useCallback(
    (filter: Array<FilterType>) => {
      updateFilters(filter);
      if (areFiltersValid(cleanFilters(filter))) {
        debouncedFetchAgents(filter);
      }
    },
    [updateFilters, debouncedFetchAgents]
  );

  const handleSortChange = useCallback((sort: Array<Sort>) => {
    setActiveSort(sort);
    fetchAgents(
      mergeDefaultStatusFilter(cleanFilters(activeFilters)),
      sort,
      limit,
      offset
    );
  }, []);

  const handleOffsetChange = useCallback((offset: number) => {
    setOffset(offset);
    fetchAgents(
      mergeDefaultStatusFilter(cleanFilters(activeFilters)),
      activeSort,
      limit,
      offset
    );
  }, []);

  const handleQuickSearchChange = useCallback((value: string) => {
    setQuickSearchValue(value);
    const filter = {
      resource: 'current_version',
      column: 'name',
      comparison: 'ilike',
      value: `%${value}%`,
    };
    fetchAgents(
      mergeDefaultStatusFilter(cleanFilters([filter])),
      activeSort,
      limit,
      offset
    );
  }, []);

  const columns: Array<TableColumn<Agent>> = useMemo(
    () => [
      {
        Header: 'Agent',
        colWidth: '100%',
        accessor: 'name',
        Cell: (props: { row: { original: Agent } }) => (
          <Flex direction="column">
            <Text css={{ fontWeight: 500 }}>
              {props.row.original.current_version.name}
            </Text>
            <Text>{props.row.original.current_version.description}</Text>
          </Flex>
        ),
      },
      {
        Header: 'Created By',
        colWidth: '100%',
        accessor: 'created_by',
        Cell: (props: { row: { original: Agent } }) => (
          <Flex direction="column">
            <Text>
              {props.row.original.created_by.name || props.row.original.created_by.email}
            </Text>
          </Flex>
        ),
      },
      {
        Header: 'Updated By',
        colWidth: '100%',
        accessor: 'updated_by',
        Cell: (props: { row: { original: Agent } }) => (
          <Flex direction="column">
            <Text>
              {props.row.original.updated_by.name || props.row.original.updated_by.email}
            </Text>
          </Flex>
        ),
      },
      {
        Header: 'Updated At',
        colWidth: '100%',
        accessor: 'updated_at',
        Cell: (props: { row: { original: Agent } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.updated_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Actions',
        colWidth: '100%',
        accessor: 'actions',
        Cell: (props: { row: { original: Agent } }) => (
          <Flex direction="column" css={{ minWidth: 135 }}>
            <HStack>
              <IconButton variant="outline" size="2">
                <HiDotsVertical />
              </IconButton>
              <Button variant="gray" onClick={() => onCardClick(props.row.original.id)}>
                Edit Agent
              </Button>
            </HStack>
          </Flex>
        ),
      },
    ],
    [loading, agents]
  );

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'AI', path: '/ai/agents' },
        { title: 'Agents', path: '/ai/agents' },
      ]}
      actions={<CreateAgent />}
    >
      <Flex direction="column" css={{ flex: '1 1 auto', p: 30 }}>
        <Box css={{ paddingBottom: '$space$3' }}>
          <CombinedFilters
            quickSearchPlaceholder="Search Agents"
            quickSearchValue={quickSearchValue}
            setQuickSearchValue={handleQuickSearchChange}
            defaultObjects={[
              default_agents_object,
              current_agent_version_object,
              updated_by,
              created_by,
            ]}
            customObjects={[]}
            activeFilters={activeFilters}
            setFilters={handleFilterChange}
            sortConfig={sortConfig}
            activeSort={activeSort}
            onSortUpdate={handleSortChange}
          />
        </Box>
        <Table
          data={agents.data}
          columns={columns}
          caption="Agents Table"
          emptyTitle="No Agents Match Search"
          totalCount={agents.total}
          pageSize={10}
          isLoading={loading}
          setOffset={handleOffsetChange}
          empty={
            <Box css={{ p: 20 }}>
              <CreateAgent />
            </Box>
          }
        />
      </Flex>
    </PageLayout>
  );
};
