import React from 'react';

import * as API from '@/shared/api/phone';
import { Button, Fieldset, Input, ProgressBar } from '@/shared/ui';

import { useOnboarding } from '../context/OnboardingProvider';
import {
  OnboardingContainer,
  OnboardingDescription,
  OnboardingHeading,
  SkipOnboarding,
} from '../OnboardingContainer';

const SearchPhone = () => {
  const onboarding = useOnboarding();
  const { setCurrentStep, setPhone, areaCode, setAreaCode } = onboarding;

  async function getPhoneNumber() {
    const data = await API.getPhone(areaCode);
    setPhone(data.phone);
    setCurrentStep('confirm_phone');
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAreaCode(e.target.value);
  };

  return (
    <>
      <ProgressBar value={(4 / 5) * 100} variant="purple" />
      <SkipOnboarding />
      <OnboardingContainer>
        <OnboardingHeading>Create New Number</OnboardingHeading>
        <OnboardingDescription>
          Search for a local number by area code. If that area code is not available, we
          will provide you the closest alternative.
        </OnboardingDescription>
        <Fieldset>
          <Input
            css={{
              height: 80,
              fontSize: 22,
              fontWeight: 600,
              textAlign: 'center',
            }}
            placeholder="( 323 )"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
            maxLength={3}
          />
        </Fieldset>
        <Button onClick={() => getPhoneNumber()} css={{ width: '100%' }}>
          Generate New Number
        </Button>
      </OnboardingContainer>
    </>
  );
};

export default SearchPhone;
